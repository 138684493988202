'use client';

import type { PropsWithChildren } from 'react';
import React from 'react';

import {
  FormType,
  LeadsApp,
  globalStyling,
} from '@vcc-package/leads-utils/constants';
import { cssMerge } from '@volvo-cars/css/utils';

import {
  LeadsContext,
  LeadsExternalContextProps,
  LeadsExternalContextProvider,
  LeadsExternalContextProviderProps,
  LeadsTrackingContextProvider,
  TranslateContextProvider,
  VolvoTrackingHelper,
} from '@vcc-package/leads-utils';
import { useLeadsContextSettings } from '../hooks/useLeadsContextSettings';
import EmbeddableFormsPartial from './EmbeddableFormsPartial';
import type { EmbeddedFormProps } from './GenericFormComponent';
import type { Settings } from './types';

export type EmbeddableFormsWrapperProps = Exclude<
  Partial<EmbeddedFormProps>,
  'hideModelImage'
> & {
  childrenPosition?: 'top' | 'bottom';
  featureFlags?: {
    removeOuterPaddingAndSpacing?: boolean;
    hideMissingTranslationMessage?: boolean;
    ignoreUrlQueryAsFormParameters?: boolean;
    hideModelImage?: boolean;
  };
  formType: FormType;
  configMapperFn?: LeadsExternalContextProps['contextMapperFn'];
  settings?: Settings;
  setOverlayTitle?: (title: string) => void;
  consumerApp: string;
  onExitIntentIntroductionComponent?: LeadsExternalContextProviderProps['onExitIntentIntroductionComponent'];
  className?: string;
};

export const EmbeddableFormsWrapper = (
  props: PropsWithChildren<EmbeddableFormsWrapperProps>,
) => {
  const inOverlay = props.setOverlayTitle !== undefined;
  const contextSettings = useLeadsContextSettings({
    ...props,
    appId: LeadsApp[props.formType],
  });
  return (
    <LeadsExternalContextProvider {...contextSettings}>
      <LeadsContext.Consumer>
        {(value) => (
          <TranslateContextProvider>
            <LeadsTrackingContextProvider>
              <VolvoTrackingHelper trackPageViewOnLoad={false}>
                <div
                  id="leads-embeddable-forms"
                  style={{
                    maxWidth: !inOverlay
                      ? globalStyling.formMaxWidth
                      : globalStyling.columnMaxWidth,
                  }}
                  className={cssMerge(
                    'flex flex-col mx-auto h-full',
                    !inOverlay && globalStyling.pagePadding,
                    inOverlay && 'max-w-full',
                    props.className,
                  )}
                >
                  <EmbeddableFormsPartial value={value} parentProps={props}>
                    <div
                      id="leads-embeddable-forms-form"
                      className="mt-32 w-full overflow-auto"
                    >
                      {props.childrenPosition === undefined
                        ? props.children
                        : null}
                    </div>
                  </EmbeddableFormsPartial>
                </div>
              </VolvoTrackingHelper>
            </LeadsTrackingContextProvider>
          </TranslateContextProvider>
        )}
      </LeadsContext.Consumer>
    </LeadsExternalContextProvider>
  );
};
