'use client';

import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { IconButton } from '@volvo-cars/react-icons';
import React from 'react';

export type DialogBackProps = {
  onBackNav?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
  showBackButton?: boolean;
};

export const DialogBack = ({ onBackNav, showBackButton }: DialogBackProps) => {
  const translate = useSharedComponentsTranslate();
  const ariaLabel = translate('ReactOverlay.labels.back');
  if (!ariaLabel && process.env.NODE_ENV === 'development') {
    console.error(`Missing dictionaries for aria labels in vcc-package/overlays
If you haven't directly imported the Overlays package, it's probably being used by a component you have imported.
This error occurs when you have not added shared translations from @vcc-www/shared-dictionaries
See https://storybooks.ccdp.io/?path=/docs/dotcom-packages-overlays-docs--docs#prerquisites
`);
  }

  return (
    <div slot="back">
      {showBackButton ? (
        <IconButton
          icon="chevron-back"
          className="fade-in"
          aria-label={ariaLabel}
          onClick={onBackNav}
          variant="clear"
          bleed
        />
      ) : null}
    </div>
  );
};
