import React from 'react';

import { TranslationKey } from '@vcc-package/leads-utils/api';

import { useTranslate } from '../../context/translateContext';

export const ThankYouPageCta = ({ ctaLink }: { ctaLink: string }) => {
  const translate = useTranslate();

  return (
    <a
      className="button-outlined"
      href={ctaLink}
      style={{ width: 'min-content', marginTop: '16px' }}
    >
      {translate(TranslationKey.FINISHED_CTA_LINK_TEXT)}
    </a>
  );
};
