import type { LeadsContextProperties } from '../../../src/context';
import type { ABTest } from '../types';

export const leadFormJourneyUpdates: ABTest = async (
  context: LeadsContextProperties,
) => {
  if (context.features.leadFormJourneyUpdates) {
    console.log('Running experiment: leadFormJourneyUpdates');

    context.features = {
      ...context.features,
      retailerPickerOnTop: true,
      useNewCopyAndStyling: true,
      useNewQuoteFlow: true,
      NewThankYouPage: true,
      useDynamicCTA: true,
      useDynamicTitle: true,
      notesWithHeader: true,
      requiredToOptional: true,
      phoneWithCountry: true,
      useSingleColumn: true,
    };
  }

  return context;
};
