'use client';
import { isStorybookVisualRegression } from '@vcc-package/storybook-utils/constants';
import { useBreakpoints } from '@vcc-www/hooks';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Sources } from '@vcc-www/utils/getMediaSources';
import React from 'react';
import { IconType } from '../Video.icons';
import { getSrc } from '../Video.utils';
import VideoCore, { Props as CoreProps } from '../VideoCore';
import VideoLazyContainer from '../VideoLazyContainer';
import VideoRegular from '../VideoRegular';
import {
  DEFAULT_GA3_TRACK_LABEL,
  DEFAULT_TRACK_LABEL,
  usePosterClickHandler,
  useVideoManager,
} from './hooks';

type Props = {
  srcWebM?: string | Sources;
  srcMp4?: string | Sources;
} & Pick<
  CoreProps,
  | 'videoDescription'
  | 'muted'
  | 'poster'
  | 'shouldAutoplay'
  | 'loop'
  | 'lazyLoadPoster'
  | 'extend'
  | 'aspectRatio'
  | 'subtitleTrack'
  | 'onTimeUpdate'
  | 'onPlay'
  | 'onEnded'
  | 'controls'
  | 'largePlayIcon'
  | 'centeredPlayIcon'
  | 'iconVariant'
  | 'trackLabel'
  | 'ga3TrackLabel'
  | 'fullscreenFit'
  | 'currentTime'
  | 'onLoadedMetadata'
  | 'forceLoad'
  | 'background'
  | 'loading'
  | 'offsetYVideoControl'
  | 'offsetXVideoControl'
  | 'withBlackVideoIcons'
  | 'setVideoReload'
  | 'customizePlayIconSIze'
  | 'playPauseButtonTabIndex'
  | 'fetchPriority'
  | 'preload'
  | 'disableScrollLogicOnDirty'
  | 'forcePlayOnPosterClick'
>;

const Video: React.FC<React.PropsWithChildren<Props>> = ({
  poster = '',
  srcWebM,
  srcMp4,
  shouldAutoplay = true,
  loop = false,
  lazyLoadPoster = false,
  controls = 'enabled',
  trackLabel = DEFAULT_TRACK_LABEL,
  ga3TrackLabel = DEFAULT_GA3_TRACK_LABEL,
  fullscreenFit = 'cover',
  aspectRatio,
  iconVariant,
  background,
  loading,
  offsetYVideoControl,
  offsetXVideoControl,
  withBlackVideoIcons,
  setVideoReload,
  customizePlayIconSIze,
  playPauseButtonTabIndex,
  fetchPriority,
  children,
  disableScrollLogicOnDirty = true,
  forcePlayOnPosterClick,
  ...props
}) => {
  const autoplayIsEnabled = shouldAutoplay && !isStorybookVisualRegression;
  const {
    inViewRef,
    videoRef,
    isPaused,
    togglePaused,
    inView,
    interactedState,
    setInteractedState,
  } = useVideoManager({
    shouldAutoplay: autoplayIsEnabled,
    loop,
    disableScrollLogicOnDirty,
  });
  const breakpoints = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints
  const actionText: IconType = isPaused ? 'play' : 'pause';

  const handlePosterClick = usePosterClickHandler({
    trackLabel,
    actionText,
    togglePaused,
    setInteractedState,
    interactedState,
    controls,
    forcePlayOnPosterClick,
  });

  const responsiveSrcWebM = getSrc(breakpoints, srcWebM);
  const responsiveSrcMp4 = getSrc(breakpoints, srcMp4);

  const { localizedLanguageName, locale } = useCurrentMarketSite();

  const commonProps = {
    videoRef,
    inViewRef,
    loop,
    locale,
    controls,
    actionText,
    fullscreenFit,
    handlePosterClick,
    autoplayIsEnabled,
    interactedState,
    inView,
    poster,
    responsiveSrcWebM,
    responsiveSrcMp4,
    togglePaused,
    trackLabel,
    ga3TrackLabel,
    localizedLanguageName,
    shouldAutoplay,
    aspectRatio,
    lazyLoadPoster,
    iconVariant,
    background,
    loading,
    offsetYVideoControl,
    offsetXVideoControl,
    withBlackVideoIcons,
    setVideoReload,
    customizePlayIconSIze,
    playPauseButtonTabIndex,
    fetchPriority,
    children,
    ...props,
  };

  if (lazyLoadPoster && !isStorybookVisualRegression) {
    const responsivePoster = getSrc(breakpoints, poster);
    return (
      <VideoLazyContainer
        inView={inView}
        inViewRef={inViewRef}
        responsivePoster={responsivePoster}
        aspectRatio={aspectRatio}
      >
        <VideoCore data-testid="video:core" {...commonProps} inView={inView}>
          {children}
        </VideoCore>
      </VideoLazyContainer>
    );
  }

  return <VideoRegular data-testid="video:regular" {...commonProps} />;
};

export default Video;
