'use client';

import {
  ConsentGroups,
  getOneTrustConsentGroups,
} from '@vcc-package/storage/consent';
import { useCallback, useRef } from 'react';
import { PublicRuntimeConfig, getCookieValue } from '../util';
import { type API, APIClient, type ApiClientProps } from '../util/api';

const INTERVAL_TIME = 100; //ms

const waitForCondition = async <T = boolean>(
  condition: () => T,
  timeout?: number,
): Promise<NonNullable<T> | null> => {
  const start = Date.now();
  return new Promise((resolve) => {
    let interval: ReturnType<typeof setInterval> | null = null;
    const checkFn = () => {
      const result = condition();
      if (result) {
        interval && clearInterval(interval);
        resolve(result);
      }
      if (timeout && Date.now() - start > timeout) {
        console.info('Timeout waiting for condition', condition);
        interval && clearInterval(interval);
        resolve(null);
      }
    };
    interval = setInterval(checkFn, INTERVAL_TIME);
    checkFn();
  });
};

const isPrivacyMode = () => {
  const isClientSide = typeof window !== 'undefined';
  if (!isClientSide) {
    return false;
  }
  try {
    const testKey = '__privacy_test';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return false;
  } catch (e) {
    return true;
  }
};

const getConsent = (publicRuntimeConfig: PublicRuntimeConfig) => {
  const isClientSide = typeof window !== 'undefined';
  const isDev = publicRuntimeConfig?.appEnv === 'dev';
  const isPrivacy = isPrivacyMode();

  if (isDev) {
    return {
      TARGETING_AND_ADVERTISING: true,
      alertClosed: true,
    };
  }

  if (!isClientSide) {
    return {
      TARGETING_AND_ADVERTISING: false,
      alertClosed: false,
    };
  }

  const groups = getOneTrustConsentGroups();
  const cookies = isClientSide ? document.cookie : null;

  const hasOptanon = 'Optanon' in window;
  const optanonCookieClosed = !!getCookieValue(
    cookies,
    'OptanonAlertBoxClosed',
  );
  const optanonAlertClosed = hasOptanon
    ? (window as any).Optanon?.IsAlertBoxClosed?.()
    : optanonCookieClosed;

  return {
    TARGETING_AND_ADVERTISING:
      groups[ConsentGroups['TARGETING_AND_ADVERTISING']],
    alertClosed: optanonAlertClosed || isPrivacy,
  };
};

type GetFlags = ReturnType<API['getFlags']>;

export type useApiReturn = API & {
  getDynamicFeatureFlags: () => GetFlags;
};
export const useApi = (props: ApiClientProps): useApiReturn => {
  const isClientSide = typeof window !== 'undefined';
  const consent = useRef(getConsent(props.publicRuntimeConfig));

  const featureFlags = useRef<GetFlags | null>(null);

  const waitForUserChoice = useCallback(
    () =>
      waitForCondition(() => {
        consent.current = getConsent(props.publicRuntimeConfig);
        return (
          consent.current.TARGETING_AND_ADVERTISING ||
          consent.current.alertClosed
        );
      }),
    [props.publicRuntimeConfig],
  );

  const waitForGaCookie = useCallback(
    () =>
      waitForCondition(() => {
        const cookies = isClientSide ? document.cookie : null;
        return getCookieValue(cookies, '_ga');
      }, 5_000),
    [isClientSide],
  );

  const api = APIClient({
    ...props,
    consent: consent.current,
  });

  const getDynamicFeatureFlags: useApiReturn['getDynamicFeatureFlags'] =
    useCallback(async () => {
      if (!isClientSide) {
        return {};
      }
      console.info('Waiting for user choice');
      await waitForUserChoice();

      if (
        featureFlags.current === null &&
        consent.current.TARGETING_AND_ADVERTISING
      ) {
        console.info('Waiting for GA cookie');
        const ga = await waitForGaCookie();
        if (!ga) {
          console.info('No GA cookie found');
          return {};
        }
        const { dynamicFeatures } = await api.getFlags(ga);
        featureFlags.current = Promise.resolve(dynamicFeatures);
      }

      return {
        dynamicFeatures: (await featureFlags.current) ?? {},
      };
    }, [api, isClientSide, waitForGaCookie, waitForUserChoice]);
  return {
    ...api,
    getDynamicFeatureFlags,
  };
};
