'use client';
import { TranslationKey } from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import { NonApiFieldName } from '@vcc-package/leads-utils/types';
import { getTranslationKeyForDealer } from '@vcc-package/leads-utils/util';
import React from 'react';
import { SectionHeader } from '../../components';
import { useTranslate } from '../../context';
import { useLeadsContext } from '../../context/leadsContext';
import { useRetailer } from '../../context/retailerContext';
import { RetailerPicker } from '../components/inputs/retailer-picker';
import {
  useFlexFormContext,
  useFlexFormDealerPickerContext,
} from '../providers/FlexibleFormsProvider';
import { AllRetailersPicker } from './AllRetailersPicker';

export const name = NonApiFieldName.RetailerId;

/**
 * This section contains its own field in order to break it out of the normal form-flow.
 * The code has mostly been borrowed/stolen from 'shared\leads-utils\customer-form\inputs\dealerPicker.tsx'
 * Because of this, it contains pretty much the same functionality but slightly modified.
 */
export const DealerPickerSection = () => {
  const dealerPickerProps = useFlexFormDealerPickerContext();
  const translate = useTranslate();
  const {
    API,
    navigation: { query },
  } = useLeadsContext();
  const { selectedRetailer } = useRetailer();
  const { hideRequired } = useFlexFormContext();
  const { features } = useLeadsContext();

  if (!dealerPickerProps || !API || !translate) {
    throw new Error(
      "dealerPickerProps or rootLegacyProps hasn't been initialized",
    );
  }

  const { title, byAddress } = dealerPickerProps;
  const customTranslations = dealerPickerProps.customTranslation ?? [];

  const messages = getInputErrorMessages(translate);

  const customByAddressLabels = {
    addressFieldPlaceholder: getTranslationKeyForDealer(
      customTranslations,
      'addressFieldPlaceholder',
    ),
    change: getTranslationKeyForDealer(customTranslations, 'change'),
    selectRetailerCloseToAddress: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailerCloseToAddress',
    ),
    selectRetailerEnterAddress: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailerEnterAddress',
    ),
    selectRetailerLoadMore: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailerLoadMore',
    ),
    selectRetailerVerificationNotice: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailerVerificationNotice',
    ),
    technicalIssuesMessage: getTranslationKeyForDealer(
      customTranslations,
      'technicalIssuesMessage',
    ),
  };

  const byAddressLabels = {
    addressFieldPlaceholder: customByAddressLabels.addressFieldPlaceholder
      ? translate(customByAddressLabels.addressFieldPlaceholder.label)
      : translate(TranslationKey.ADDRESS_FIELD_PLACEHOLDER),
    change: customByAddressLabels.change
      ? translate(customByAddressLabels.change.label)
      : translate(TranslationKey.CHANGE),
    selectRetailerCloseToAddress:
      customByAddressLabels.selectRetailerCloseToAddress
        ? translate(customByAddressLabels.selectRetailerCloseToAddress.label)
        : translate(TranslationKey.SELECT_RETAILER_CLOSE_TO_ADDRESS),
    selectRetailerEnterAddress: customByAddressLabels.selectRetailerEnterAddress
      ? translate(customByAddressLabels.selectRetailerEnterAddress.label)
      : translate(TranslationKey.SELECT_RETAILER_ENTER_ADDRESS),
    selectRetailerLoadMore: customByAddressLabels.selectRetailerLoadMore
      ? translate(customByAddressLabels.selectRetailerLoadMore.label)
      : translate(TranslationKey.SELECT_RETAILER_LOAD_MORE),
    selectRetailerVerificationNotice:
      customByAddressLabels.selectRetailerVerificationNotice
        ? translate(
            customByAddressLabels.selectRetailerVerificationNotice.label,
          )
        : translate(TranslationKey.SELECT_RETAILER_VERIFICATION_NOTICE),
    technicalIssuesMessage: customByAddressLabels.technicalIssuesMessage
      ? translate(customByAddressLabels.technicalIssuesMessage.label)
      : translate(TranslationKey.TECHNICAL_ISSUES_MESSAGE),
  };

  const dealerPickerCustomHeading = getTranslationKeyForDealer(
    customTranslations,
    'dealerPickerHeading',
  );

  const hasRetailerInQuery = !!query.retailerid;
  const retailerIsFromQuery =
    hasRetailerInQuery && query.retailerid === selectedRetailer?.dealerId;
  if (retailerIsFromQuery) {
    return null;
  }

  const heading = features?.useNewCopyAndStyling
    ? translate(TranslationKey.DEALER_PICKER_HEADING)
    : dealerPickerCustomHeading
      ? translate(dealerPickerCustomHeading.label)
      : title
        ? translate(title)
        : '';

  if (!byAddress) {
    return (
      <AllRetailersPicker
        heading={heading}
        retailerPickerChangeLabel={byAddressLabels.change}
        messages={messages}
      />
    );
  }
  return (
    <div>
      {heading ? <SectionHeader title={heading} /> : null}
      <RetailerPicker
        selectRetailerCloseToAddressLabel={
          byAddressLabels.selectRetailerCloseToAddress
        }
        changeRetailerLabel={byAddressLabels.change}
        selectRetailerLoadMoreLabel={byAddressLabels.selectRetailerLoadMore}
        messages={messages}
        required
        hideRequired={hideRequired}
        name={name}
      />
    </div>
  );
};
