'use client';
import { FeaturedDocuments } from '@vcc-package/contextual-support-content';
import React from 'react';
import type cbvContextualSupportContentProps from 'src/content-management/editorial-components/ContextualSupportContent/ContextualSupportContent.props';

export const ContextualSupportContent = ({
  content,
}: cbvContextualSupportContentProps) => {
  const { featureListName, limit } = content;
  return (
    <FeaturedDocuments featureListName={featureListName} limit={limit || 6} />
  );
};
