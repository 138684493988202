import React from 'react';

import { globalStyling } from '@vcc-package/leads-utils/constants';
import { PurposeType } from '../../api';
import { useLeadsContext } from '../context';

type HeadingProps = {
  heading: string;
  subheading?: string;
  description?: string;
};

export function Header(props: HeadingProps) {
  const { heading, subheading, description } = props;
  const { features, purpose } = useLeadsContext();

  const useNewSubHeading =
    subheading &&
    ((features?.showSubHeadingOnEF && purpose === PurposeType.OFFER_REQUEST) ||
      (features?.useDynamicTitle && purpose === PurposeType.KEEP_ME_UPDATED));

  return (
    <header
      className="flex justify-between flex-col gap-16 lg:flex-row lg:items-center w-full mx-auto"
      style={{ maxWidth: globalStyling.maxWidth }}
    >
      <div className="flex flex-col">
        {!features?.useDynamicTitle && !features?.showSubHeadingOnEF && (
          <p className="micro text-secondary">{subheading}</p>
        )}
        <h1 data-testid="leads-form-header" className="heading-1 font-medium">
          {heading}
        </h1>

        {useNewSubHeading && (
          <p className="font-20 mt-16 text-secondary">{subheading}</p>
        )}
      </div>
      {description && (
        <p
          className="text-secondary font-20 lg:text-end"
          style={{ maxWidth: globalStyling.columnMaxWidth }}
        >
          {description}
        </p>
      )}
    </header>
  );
}
