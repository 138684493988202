'use client';
import { getDictionariesProvider } from '@vcc-www/react-translate';
import { DictionaryItemTypes } from '../content-management/DictionaryItemTypes';

const { useTranslate: useTranslateTemp, DictionariesProvider } =
  getDictionariesProvider<DictionaryItemTypes>();

/**
 * Re-exporting and explocitly type useTranslate to avoid TS issues:
 *
 * error TS2742: The inferred type of 'useTranslate' cannot be named without a
 * reference to '@vcc-www/react-translate/node_modules/intl-messageformat'. This
 * is likely not portable. A type annotation is necessary.
 *
 * Based on previous fixes after TS update: https://github.com/volvo-cars/cdls-mono-volvocars/pull/43932/files#diff-b9b5b30f8d02920aded647cd9e4392427ca69bce3ffb28eb11728f793d73853bR19
 */
const useTranslate: ReturnType<typeof getDictionariesProvider>['useTranslate'] =
  useTranslateTemp;

export { useTranslate, DictionariesProvider };
