import { PurposeType, TranslationKey } from '@vcc-package/leads-utils/api';
import type { SiteSlug } from '@volvo-cars/market-sites';
import type { Global } from '@volvo-cars/tracking';

export enum LeadsApp {
  AccessoryRequest = 'accessory',
  CallbackRequest = 'callback',
  ContactRequest = 'contact',
  KeepMeUpdated = 'kmu',
  QuoteRequest = 'quote',
}

export type FormType = keyof typeof LeadsApp;

export const getFormTypeByAppId = (appId: LeadsApp): FormType => {
  const formType = Object.keys(LeadsApp).find(
    (key) => LeadsApp[key as FormType] === appId,
  ) as FormType;
  return formType || 'QuoteRequest';
};

export const getClientIdByFormVariant = (
  appId: LeadsApp,
  siteSlug: SiteSlug,
  variant: 'simplified',
) => {
  return `${siteSlug}_${appId}_${variant}`;
};

type AppTranslationKey =
  | 'formHeading'
  | 'formSubHeading'
  | 'headTitle'
  | 'thankYouHeading';

type optionalTranslationKeys = 'thankYouSubHeading';

export type ApplicationInfo = Readonly<{
  appId: LeadsApp;
  appPath: string;
  canonicalName: 'leads-forms' | 'leads-quote';
  pageName: Global['pageName'];
  purpose: PurposeType;
  translationKeys: {
    [key in AppTranslationKey]: TranslationKey;
  } & {
    [key in optionalTranslationKeys]?: TranslationKey;
  };
}>;

export const LeadsApplications: Record<LeadsApp, ApplicationInfo> = {
  [LeadsApp.AccessoryRequest]: {
    appId: LeadsApp.AccessoryRequest,
    appPath: 'accessory-request',
    canonicalName: 'leads-forms',
    pageName: 'accessory request',
    purpose: PurposeType.ACCESSORY_REQUEST,
    translationKeys: {
      formHeading: TranslationKey.AR_FORM_HEADING,
      formSubHeading: TranslationKey.AR_FORM_SUB_HEADING,
      headTitle: TranslationKey.AR_HEADER_TITLE,
      thankYouHeading: TranslationKey.AR_THANK_YOU_HEADING,
      thankYouSubHeading: TranslationKey.AR_THANK_YOU_SUB_HEADING,
    },
  },
  [LeadsApp.CallbackRequest]: {
    appId: LeadsApp.CallbackRequest,
    appPath: 'callback-request',
    canonicalName: 'leads-forms',
    pageName: 'callback request',
    purpose: PurposeType.CBV_CALLBACK,
    translationKeys: {
      formHeading: TranslationKey.CR_CBV_FORM_HEADING,
      formSubHeading: TranslationKey.CR_CBV_FORM_SUB_HEADING,
      headTitle: TranslationKey.CR_HEADER_TITLE,
      thankYouHeading: TranslationKey.CR_CBV_THANK_YOU_HEADING,
      thankYouSubHeading: TranslationKey.CR_CBV_THANK_YOU_SUB_HEADING,
    },
  },
  [LeadsApp.ContactRequest]: {
    appId: LeadsApp.ContactRequest,
    appPath: 'contact-request',
    canonicalName: 'leads-forms',
    pageName: 'contact request',
    purpose: PurposeType.CONTACT_REQUEST,
    translationKeys: {
      formHeading: TranslationKey.CR_FORM_HEADING,
      formSubHeading: TranslationKey.CR_FORM_SUB_HEADING,
      headTitle: TranslationKey.CR_HEADER_TITLE,
      thankYouHeading: TranslationKey.CR_THANK_YOU_HEADING,
      thankYouSubHeading: TranslationKey.CR_THANK_YOU_SUB_HEADING,
    },
  },
  [LeadsApp.KeepMeUpdated]: {
    appId: LeadsApp.KeepMeUpdated,
    appPath: 'keep-me-updated',
    canonicalName: 'leads-forms',
    pageName: 'keep me updated',
    purpose: PurposeType.KEEP_ME_UPDATED,
    translationKeys: {
      formHeading: TranslationKey.KMU_FORM_HEADING,
      formSubHeading: TranslationKey.KMU_FORM_SUB_HEADING,
      headTitle: TranslationKey.KMU_HEADER_TITLE,
      thankYouHeading: TranslationKey.KMU_THANK_YOU_HEADING,
      thankYouSubHeading: TranslationKey.KMU_THANK_YOU_SUB_HEADING,
    },
  },
  [LeadsApp.QuoteRequest]: {
    appId: LeadsApp.QuoteRequest,
    appPath: 'quote',
    canonicalName: 'leads-quote',
    pageName: 'request a quote',
    purpose: PurposeType.OFFER_REQUEST,
    translationKeys: {
      formHeading: TranslationKey.CONTACT_INFORMATION,
      formSubHeading: TranslationKey.REQUEST_QUOTE_UPPERCASE,
      headTitle: TranslationKey.REQUEST_QUOTE_HEADER_TITLE,
      thankYouHeading: TranslationKey.REQUEST_QUOTE_THANK_YOU,
      thankYouSubHeading: TranslationKey.REQUEST_QUOTE_THANK_YOU_SUB_HEADING,
    },
  },
};

export const PurposePrefixLookup: Record<PurposeType, string> = {
  ACCESSORY_REQUEST: 'AR',
  CBV_CALLBACK: 'CBV',
  CONTACT_REQUEST: 'CR',
  KEEP_ME_UPDATED: 'KMU',
  OFFER_REQUEST: 'QR',
};
