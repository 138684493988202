'use client';

import { useInView } from '@vcc-www/hooks';
import { useElementDimensions } from '@volvo-cars/react-layout-utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Block, BlockProps } from 'vcc-ui';
import { PropsImageWithLoadingStrategy } from './Image.types';
import { containerCSS, getSrc, imageCSS } from './Image.utils';
import { JS_LAZY, STATES } from './constants';

type ReadyState = 'initial' | 'loading' | 'loaded';

const ImageWithJsLazy: React.FC<
  React.PropsWithChildren<PropsImageWithLoadingStrategy>
> = ({
  alt,
  imageWidth,
  aspectRatio,
  extend,
  src,
  srcSet,
  source,
  sources,
  onLoad,
  onError,
  objectFit,
  objectPosition,
  loading,
  loadingStrategy,
  disableAutoOptimize = false,
  animateImageInView,
  fetchPriority,
  ...otherProps
}) => {
  const [readyState, setReadyState] = useState<ReadyState>('initial');
  const state = STATES[loadingStrategy][readyState];

  const [dimensionRef, containerDimensions] = useElementDimensions({
    disabled: loadingStrategy !== JS_LAZY,
    throttle: 50,
  });

  const legacySrc = getSrc(
    source,
    state.shouldLoad,
    loadingStrategy,
    disableAutoOptimize,
    containerDimensions,
  );

  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '1000px 200px',
    disabled: loadingStrategy !== JS_LAZY,
  });
  const containerRef = useCallback(
    (el: HTMLDivElement) => {
      dimensionRef.current = el;
      inViewRef.current = el;
    },
    [dimensionRef, inViewRef],
  );
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    inView && readyState === 'initial' && setReadyState('loading');
  }, [inView, readyState]);

  useEffect(() => {
    const isImageLoaded =
      imageRef.current?.complete && imageRef.current?.naturalHeight !== 0;
    // src changed, update readyState accordingly
    if (loadingStrategy === JS_LAZY) {
      setReadyState((readyState) => {
        // in case the image has to load and the src has not been updated yet
        if (readyState === 'loading') {
          return readyState;
        }
        // with jsLazy we set to loading when image is scrolled close to viewport
        return isImageLoaded ? 'loaded' : 'initial';
      });
    } else {
      // in eager or native lazy the image will start "maybe loading" immediately
      setReadyState(isImageLoaded ? 'loaded' : 'loading');
    }
    return () => setReadyState('initial');
  }, [loadingStrategy, src, imageRef]);

  const handleOnLoad = () => {
    setReadyState('loaded');
    if (onLoad) {
      onLoad();
    }
  };

  const handleError = (e: React.SyntheticEvent) => {
    if (readyState !== 'initial' && onError && legacySrc) {
      onError({
        src: src || legacySrc,
        srcSet,
        sources,
        error: e.nativeEvent,
      });
    }
  };

  const hasSrc = state.shouldLoad && legacySrc;

  return (
    <Block
      as="picture"
      extend={[containerCSS({ imageWidth, aspectRatio, objectFit }), extend]}
      ref={containerRef}
    >
      {!hasSrc ? null : (
        <Block
          as="img"
          ref={state.shouldListenToViewport ? imageRef : null}
          {...(otherProps as BlockProps<'img'>)}
          onLoad={handleOnLoad}
          onError={handleError}
          src={state.shouldLoad ? legacySrc : ''}
          {...(fetchPriority ? { fetchPriority } : {})}
          extend={imageCSS({
            hasLoaded: state.hasLoaded,
            objectFit,
            objectPosition,
            aspectRatio,
          })}
          alt={alt}
        />
      )}
    </Block>
  );
};

export default ImageWithJsLazy;
