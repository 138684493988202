import { ResponsiveImage } from '@vcc-package/media';
import { cssJoin, cssMerge } from '@volvo-cars/css/utils';
import dynamic from 'next/dynamic';
import React from 'react';
import styles from '../../Carousel.module.css';
import { CarouselCarData, CarouselItemProps } from '../../types/carouselTypes';
import { getModelSlugFromKey } from '../../utils/getModelSlugFromKey';
import CarInfo from './CarInfo';

const CarPriceView = dynamic(() =>
  import('@vcc-package/car-price').then((mod) => mod.CarPriceView),
);
const Price = dynamic(() =>
  import('./CarouselItemPrice').then((mod) => mod.default),
);

const CarouselItem = ({
  car,
  purchaseFromText,
  carPriceProps,
  badge,
  altText,
  className,
  hideIfNoPrice,
}: CarouselItemProps & {
  car: CarouselCarData;
  className?: string;
}) => {
  const modelSlug = getModelSlugFromKey(car.key);
  const altTextWithModel = altText?.replace(
    '{model}',
    `${car.displayName} ${car.engineType}`,
  );
  const sanitizedModelTitle = sanitizeModelTitle(car.displayName, modelSlug);

  if (car.priceData && !car.priceData.isValidCarPrice && hideIfNoPrice) {
    throw new Error('No price found'); // Throwing this so NoCarPriceErrorBoundary can catch this and remove the item from the carousel
  }

  return (
    <div
      className={cssMerge(
        'p-24 gap-24 flex flex-col justify-between',
        styles.card_wrapper,
        className,
      )}
    >
      <small
        className={cssJoin(
          'micro font-medium',
          badge?.color ?? 'text-feedback-green',
          styles.badge,
        )}
      >
        {badge?.text}
      </small>
      <ResponsiveImage
        alt={altTextWithModel}
        images={{
          sm: { alt: altTextWithModel, src: car.image },
        }}
        className="object-contain aspect-21/9 w-xs mx-auto"
      />
      <hgroup className="stack-text">
        <h2 className="font-20 font-medium" data-autoid="model-name">
          {sanitizedModelTitle}
        </h2>
        <p
          className="font-16 text-secondary"
          data-autoid="car-engine-type-and-body"
        >
          <CarInfo engineType={car.engineType} body={car.body} />
        </p>
        <p className="mt-8">
          {car.priceData ? (
            <CarPriceView {...car.priceData} {...carPriceProps} />
          ) : (
            <Price
              hideIfNoPrice={hideIfNoPrice}
              purchaseFromText={purchaseFromText}
              cashPrice={car.cashPrice}
              modelSlug={modelSlug}
              carPriceProps={carPriceProps}
            />
          )}
        </p>
      </hgroup>
    </div>
  );
};

export default CarouselItem;

// TODO: Once fed graph has updated the MY25 model names correctly in `modelFamily`, we can remove this function and migrate back to use `modelFamily.displayName` instead of `model.displayName`
function sanitizeModelTitle(
  displayName: string,
  modelSlug: string,
): string | undefined {
  if (modelSlug.includes('-cross-country')) {
    return displayName;
  }
  return displayName.split(' ').shift();
}
