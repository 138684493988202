'use client';

import type { PropsWithChildren } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import type { MarketVehicleConfiguration as Model } from '@vcc-package/leads-utils/api';
import type { Navigation } from '@vcc-package/leads-utils/types';
import {
  StorageKey,
  getModel,
  readFromSession,
  removeSessionItem,
  saveToSession,
} from '@vcc-package/leads-utils/util';

import { useLeadsContext } from './leadsContext';

export interface ModelContextValues {
  /** Callback for clearing the selected model. Clears state & session */
  clearSelectedModel: () => void;
  /** Callback when a new model has been selected. Sets state & saves to session */
  onModelSelect: (r: Model) => void;
  /** Model saved in session */
  savedModel: Model | undefined;
  /** Currently selected model, uses query parameters "pno", "modelkey" & "model" - in that order */
  selectedModel: Model | undefined;
}

export const useModel = () => useContext(ModelContext);
const ModelContext = createContext<ModelContextValues>({
  clearSelectedModel: () => {},
  onModelSelect: () => {},
  savedModel: undefined,
  selectedModel: undefined,
});

export const ModelContextProvider = ({
  children,
}: PropsWithChildren<{ navigation: Navigation }>) => {
  const {
    appId,
    vehicles,
    isEmbeddableForms,
    navigation: { clearSelectedModelQuery, query, isClientSideNavigation },
  } = useLeadsContext();

  const savedModel =
    readFromSession<Model>(appId, StorageKey.selectedModel) ?? undefined;
  const modelkey = query.modelkey ?? query.model;
  const pno = query.pno;

  const [selectedModel, setSelectedModel] = useState<Model | undefined>(
    undefined,
  );

  const clearSelectedModel = useCallback(() => {
    clearSelectedModelQuery();
    setSelectedModel(undefined);
    removeSessionItem(appId, StorageKey.selectedModel);
  }, [appId, clearSelectedModelQuery]);

  const onModelSelect = useCallback(
    (model: Model) => {
      setSelectedModel(model);
      saveToSession(appId, StorageKey.selectedModel, model);
    },
    [appId],
  );

  const useEffectDependencies: any = [onModelSelect, modelkey, pno];

  if (isEmbeddableForms) {
    useEffectDependencies.push(vehicles);
  }

  // Handle model selection when navigating directly via url
  useEffect(
    () => {
      if (modelkey || (pno && selectedModel?.pno34 !== pno)) {
        const model = getModel({ pno, modelkey, vehicles });
        // TODO: Add check if model is enabled for current purpose/formflow before setting state
        if (model) {
          onModelSelect(model);
        }
      } else if (!isEmbeddableForms && !isClientSideNavigation) {
        clearSelectedModel();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffectDependencies,
  );

  return (
    <ModelContext.Provider
      value={{ clearSelectedModel, onModelSelect, savedModel, selectedModel }}
    >
      {children}
    </ModelContext.Provider>
  );
};
