'use client';
import React, {
  type Dispatch,
  type SetStateAction,
  createContext,
  useState,
} from 'react';

type Stack = {
  path: string;
  lastScrollPosition: number;
};

export const DialogNavigationContext = createContext<{
  stack: Stack[];
  navigate: (path: string) => void;
  goBack: () => void;
  goHome: () => void;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
  scrollToPrevScrollPosition: (persistScrollPosition?: boolean) => void;
  currentPage: string;
  previousPage?: string;
  dirty: boolean;
  title?: string;
  scrollableElementRef: React.RefObject<HTMLElement>;
}>({
  stack: [],
  navigate: () => {},
  goBack: () => {},
  goHome: () => {},
  setTitle: () => {},
  scrollToPrevScrollPosition: () => {},
  currentPage: '/',
  previousPage: undefined,
  dirty: false,
  title: undefined,
  scrollableElementRef: { current: null },
});

/**
 * Provides navigation functionality for dialog components.
 *
 * @component
 * @example
 * ```tsx
 * <DialogNavigationProvider elementRef={dialogRef}>
 *   {/* Dialog content goes here * /}
 * </DialogNavigationProvider>
 * ```
 *
 * @param elementRef - A React ref object for the dialog element.
 * @param scrollableElementRef - A React ref object for the scrollable element inside the dialog.
 * @returns A React component that wraps the provided children with navigation context.
 */
export const DialogNavigationProvider: React.FC<
  React.PropsWithChildren<{
    elementRef: React.RefObject<HTMLDialogElement>;
    scrollableElementRef: React.RefObject<HTMLElement>;
  }>
> = ({ elementRef, scrollableElementRef, children }) => {
  const [stack, setStack] = useState<Stack[]>([
    { path: '/', lastScrollPosition: 0 },
  ]);
  const [currentPage, setCurrentPage] = useState<string>('/');
  const [previousPage, setPreviousPage] = useState<string | undefined>();
  const [dirty, setDirty] = useState(false);
  const [title, setTitle] = useState<string | undefined>();

  const scrollToTop = () => {
    if (elementRef?.current) {
      elementRef.current.scrollTop = 0;
    }

    if (scrollableElementRef?.current) {
      scrollableElementRef.current.scrollTop = 0;
    }
  };

  const scrollToPrevScrollPosition = (persistScrollPosition?: boolean) => {
    const position = persistScrollPosition
      ? stack.find((page) => page.path === currentPage)?.lastScrollPosition
      : 0;
    scrollableElementRef?.current?.scrollTo({
      top: position,
    });
  };

  const navigate = (path: string) => {
    const currentPosition = scrollableElementRef?.current?.scrollTop || 0;
    setDirty(true);
    setStack((prevStack) => {
      if (path === prevStack[prevStack.length - 1].path) {
        return prevStack.map((page) =>
          page.path === currentPage
            ? { path: page.path, lastScrollPosition: 0 }
            : page,
        );
      }
      setCurrentPage(path);
      setPreviousPage(prevStack[prevStack.length - 1].path);
      if (prevStack.map((path) => path.path).includes(path)) {
        return prevStack.map((page) =>
          page.path === path
            ? { path: page.path, lastScrollPosition: 0 }
            : page,
        );
      }

      const newStack = prevStack.map((page) =>
        page.path === prevStack[prevStack.length - 1].path
          ? { path: page.path, lastScrollPosition: currentPosition }
          : page,
      );
      return [...newStack, { path: path, lastScrollPosition: 0 }];
    });
    scrollToTop();
  };

  const goBack = () => {
    setStack((prevStack) => {
      if (prevStack.length === 1) {
        return prevStack;
      }
      setPreviousPage(prevStack[prevStack.length - 1].path);
      const newStack = prevStack.slice(0, -1);
      setCurrentPage(newStack[newStack.length - 1].path);

      return newStack;
    });
    scrollToTop();
  };

  const goHome = () => {
    setStack([{ path: '/', lastScrollPosition: 0 }]);
    setCurrentPage('/');
    setPreviousPage(undefined);
    setDirty(false);
    scrollToTop();
  };

  return (
    <DialogNavigationContext.Provider
      value={{
        stack,
        currentPage,
        previousPage,
        dirty,
        title,
        navigate,
        goBack,
        goHome,
        setTitle,
        scrollToPrevScrollPosition,
        scrollableElementRef,
      }}
    >
      {children}
    </DialogNavigationContext.Provider>
  );
};
