import {
  BooleanFieldDefinition,
  EditorialComponentDefinition,
  EnumFieldDefinition,
  FieldType,
} from '@vcc-www/content-management-jss-client';
import { DesktopSpacings } from './calculateSpacing';
import { SpacingParams } from './getSpacing';

export type Params = Readonly<
  Partial<SpacingParams> & {
    background?: BackgroundColor;
    marginsOnMobile?: boolean;
  }
>;

export const backgroundColors = ['white', 'light', 'dark', 'black'] as const;

export type BackgroundColor = (typeof backgroundColors)[number];

export const extendedSizes = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '8': 8,
  '12': 12,
} as const;

export const sizes = {
  none: extendedSizes[0],
  xsmall: extendedSizes[2],
  '3': extendedSizes[3],
  small: extendedSizes[4],
  medium: extendedSizes[8],
  large: extendedSizes[12],
} as const;

export type SpacingSize = keyof typeof sizes | keyof typeof extendedSizes;

const sizeOptions = Object.keys(sizes);

export const topOuterSpacing: EnumFieldDefinition = {
  id: 'topOuterSpacing',
  displayName: 'Top outer spacing',
  type: FieldType.Enum,
  options: sizeOptions,
};

export const bottomOuterSpacing: EnumFieldDefinition = {
  id: 'bottomOuterSpacing',
  displayName: 'Bottom outer spacing',
  type: FieldType.Enum,
  options: sizeOptions,
};

export const topInnerSpacing: EnumFieldDefinition = {
  id: 'topInnerSpacing',
  displayName: 'Top inner spacing',
  type: FieldType.Enum,
  options: sizeOptions,
};

export const bottomInnerSpacing: EnumFieldDefinition = {
  id: 'bottomInnerSpacing',
  displayName: 'Bottom inner spacing',
  type: FieldType.Enum,
  options: sizeOptions,
};

export const background: EnumFieldDefinition = {
  id: 'background',
  displayName: 'Background color',
  type: FieldType.Enum,
  options: backgroundColors,
};

export const marginsOnMobile: BooleanFieldDefinition = {
  id: 'marginsOnMobile',
  displayName: 'Side margins on mobile',
  type: FieldType.Boolean,
  default: false,
};

export const sectionParams: NonNullable<
  EditorialComponentDefinition['params']
> = [
  topOuterSpacing,
  bottomOuterSpacing,
  topInnerSpacing,
  bottomInnerSpacing,
  background,
  marginsOnMobile,
];

export const sectionParamsWithouthMarginsOnMobile: NonNullable<
  EditorialComponentDefinition['params']
> = [
  topOuterSpacing,
  bottomOuterSpacing,
  topInnerSpacing,
  bottomInnerSpacing,
  background,
];

export type SectionParamSize = keyof typeof sizes;
export interface SectionParamsWithoutMarginsOnMobileProps {
  topOuterSpacing?: SectionParamSize;
  bottomOuterSpacing?: SectionParamSize;
  topInnerSpacing?: SectionParamSize;
  bottomInnerSpacing?: SectionParamSize;
  background?: BackgroundColor;
}
export interface SectionParamProps
  extends SectionParamsWithoutMarginsOnMobileProps {
  marginsOnMobile?: boolean;
}

export type IdParam = {
  id?: string;
};

export function getParams(
  props: Record<string, any> & Params,
): Params & IdParam & DesktopSpacings {
  const {
    topInnerSpacing,
    topOuterSpacing,
    bottomInnerSpacing,
    bottomOuterSpacing,
    topInnerSpacingDesktop,
    topOuterSpacingDesktop,
    bottomInnerSpacingDesktop,
    bottomOuterSpacingDesktop,
    background,
    marginsOnMobile,
    id,
  } = props;
  return {
    topInnerSpacing,
    topOuterSpacing,
    bottomInnerSpacing,
    bottomOuterSpacing,
    topInnerSpacingDesktop,
    topOuterSpacingDesktop,
    bottomInnerSpacingDesktop,
    bottomOuterSpacingDesktop,
    background,
    marginsOnMobile,
    id,
  };
}
