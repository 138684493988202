import {
  DiscoveryCardHorizontal,
  DiscoveryCardStacked,
} from '@vcc-package/discovery';
import { Markdown } from '@vcc-package/text';
import {
  AccordionDetails,
  AccordionSummary,
} from '@volvo-cars/react-accordion';
import React from 'react';
import MerchandisingOfferDetail from '../content-management/content-types/merchandisingOfferDetail.props';

type OfferDetailProps = {
  selectedOffer: {
    offerDetail?: MerchandisingOfferDetail;
  };
};

const OfferDetail: React.FC<OfferDetailProps> = ({ selectedOffer }) => {
  const { offerDetail } = selectedOffer;

  if (!offerDetail || Object.keys(offerDetail).length === 0) {
    return null;
  }

  const { image, stats, body, accordionDetails, disclaimer } = offerDetail;

  return (
    <>
      <DiscoveryCardStacked
        responsiveImages={{
          sm: {
            src: image.src,
            alt: image.alt,
          },
          smAspectRatio: '21:9',
        }}
      >
        {(stats && stats.length > 0) || body ? (
          <div className="stack-text">
            {stats && stats.length > 0 && (
              <div className="grid md:grid-cols-3 gap-x-gutter gap-y-16">
                {stats.map(({ price, label, text }, index) => (
                  <div key={index}>
                    <div className="flex-row">
                      <h2 className="font-20 font-medium">{price}</h2>
                      <p className="text-secondary micro ml-4 self-end">
                        {label}
                      </p>
                    </div>
                    {text && <p className="text-secondary micro">{text}</p>}
                  </div>
                ))}
              </div>
            )}
            {body && <p>{body}</p>}
          </div>
        ) : null}
      </DiscoveryCardStacked>

      {accordionDetails &&
        accordionDetails.length > 0 &&
        accordionDetails.map(({ title, image, body }, index) => (
          <AccordionDetails key={index} className="border-b-0">
            <AccordionSummary className="font-medium">{title}</AccordionSummary>
            <DiscoveryCardHorizontal
              responsiveImages={{
                sm: {
                  src: image.src,
                  alt: image.alt,
                },
                smAspectRatio: '4:3',
              }}
            >
              <p>{body}</p>
            </DiscoveryCardHorizontal>
          </AccordionDetails>
        ))}

      {disclaimer && (
        <Markdown
          className="micro text-secondary mt-16"
          markdown={disclaimer}
        />
      )}
    </>
  );
};

export default OfferDetail;
