'use client';
import { useInView } from '@vcc-www/hooks';
import { cssJoin } from '@volvo-cars/css/utils';
import { useReel } from '@volvo-cars/react-headless';
import { IconButton } from '@volvo-cars/react-icons';
import React, { useState } from 'react';
import styles from '../SubNavigation.module.css';
import { debounce } from '../utils/debounce';
import { useResizeObserver } from '../utils/useResizeObserver';
import { useSubNavigationScroll } from '../utils/useSubNavigationScroll';
import { Link } from './Link.view';

export type LinkProps = {
  text: string;
  href: string;
  eventLabel: string;
  accessibilityLabel?: string;
  sources?: {
    text?: string;
    href?: string;
    accessibilityLabel?: string;
  };
};

export type LinksProps = {
  links: Array<LinkProps>;
  /* Determines the background of the currently active link, only has an effect when colorMode of container is "light" */
  theme?: 'light' | 'dark';
  // TODO: Make required once we have some shared dictionaries for this
  backAriaLabel?: string;
  forwardAriaLabel?: string;
};

const Links: React.FC<LinksProps> = ({
  links,
  theme = 'light',
  backAriaLabel = '',
  forwardAriaLabel = '',
}) => {
  const [navRef, isInView] = useInView<HTMLDivElement>();
  const [isScrollable, setIsScrollable] = useState(false);
  const { previousButtonProps, nextButtonProps } = useReel({ ref: navRef });

  const navHasScrollbar = () => {
    if (navRef.current) {
      return navRef.current.scrollWidth > navRef.current.clientWidth;
    }
    return false;
  };

  useResizeObserver({
    ref: navRef,
    callback: debounce(() => {
      if (links.length > 0 && navHasScrollbar()) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    }, 10),
  });

  const { scrollPosition } = useSubNavigationScroll(navRef);

  return links.length > 0 ? (
    <div slot="main" className={styles.links}>
      <div
        className={cssJoin('flex items-center', styles.scrollBack)}
        style={{
          opacity: scrollPosition !== 'start' && isScrollable ? 1 : 0,
          pointerEvents:
            scrollPosition !== 'start' && isScrollable ? 'auto' : 'none',
        }}
      >
        <div className={styles.scrollBackground}>
          <IconButton
            onClick={previousButtonProps.onClick}
            aria-label={backAriaLabel}
            aria-disabled={scrollPosition === 'start' || !isScrollable}
            icon="chevron-back"
            variant="clear"
            tabIndex={-1}
          />
        </div>
      </div>
      <div
        // TODO: 'contain-none' is a temporary fix, remove once the 'contain' issue is resolved in the CSS library: https://vcc-consumer-digital.slack.com/archives/C041K092P4P/p1732808188090609
        className="relative reel scrollbar-none h-full contain-none"
        ref={navRef}
      >
        <ul
          className={cssJoin('flex gap-8 items-center mx-auto md:px-0')}
          data-autoid="sub-navigation:links"
          data-testid="sub-navigation:links"
        >
          {links.map((link, i) => (
            <li key={i}>
              <Link {...link} theme={theme} isInView={isInView} />
            </li>
          ))}
        </ul>
      </div>
      <div
        className={cssJoin(
          'flex items-center justify-end',
          styles.scrollForward,
        )}
        style={{
          opacity: scrollPosition !== 'end' && isScrollable ? 1 : 0,
          pointerEvents:
            scrollPosition !== 'end' && isScrollable ? 'auto' : 'none',
        }}
      >
        <div className={styles.scrollBackground}>
          <IconButton
            onClick={nextButtonProps.onClick}
            aria-label={forwardAriaLabel}
            aria-disabled={scrollPosition === 'end' || !isScrollable}
            icon="chevron-forward"
            variant="clear"
            tabIndex={-1}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default Links;
