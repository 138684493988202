'use client';
import { Markdown } from '@vcc-package/text';
import { Spacing } from 'src/components/layout/Spacing';
import type cbvTextIntroProps from 'src/content-management/editorial-components/TextIntro/TextIntro.props';

export const TextIntro = ({ content }: cbvTextIntroProps) => {
  const { label, title, subTitle, body } = content;

  return (
    <Spacing>
      <div
        data-testid="text-intro"
        className="mt-64 container-md md:text-center"
      >
        {!!label && (
          <p
            data-testid="text-intro:label"
            className="uppercase mb-24 font-medium"
          >
            {label}
          </p>
        )}
        <h1 data-testid="text-intro:title" className="statement-2">
          {title}
          {!!subTitle && (
            <span data-testid="text-intro:subtitle" className="text-secondary">
              {` ${subTitle}`}
            </span>
          )}
        </h1>
        {!!body && (
          <Markdown
            className="font-20 mt-24"
            markdown={body}
            data-testid="text-intro:body"
          />
        )}
      </div>
    </Spacing>
  );
};
