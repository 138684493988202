'use client';

import { cssJoin } from '@volvo-cars/css/utils';
import React, { useState } from 'react';
import Carousel, { useCarousel } from '../../..';
import { CarouselProps } from '../types/carouselTypes';
import { getModelSlugFromKey } from '../utils/getModelSlugFromKey';
import CarouselItem from './CarouselItem';
import NoCarPriceErrorBoundary from './ErrorBoundary';

type SliderProps = Pick<
  CarouselProps,
  | 'centerAlign'
  | 'content'
  | 'carPriceProps'
  | 'siteSlug'
  | 'environment'
  | 'hideIfNoPrice'
  | 'cars'
>;

const CarouselSliderWrapper = ({
  centerAlign,
  cars,
  hideIfNoPrice,
  carPriceProps,
  siteSlug,
  environment,
  content,
}: SliderProps) => {
  const [errorCount, setErrorCount] = useState(0);
  const { carouselIsScrollable } = useCarousel();
  const { purchaseFromText, altText, carPriceVariant, frontendFilters } =
    content;

  if (errorCount === cars.length) {
    throw new Error('No car prices found');
  }

  return (
    <Carousel.Slider
      className={cssJoin(
        centerAlign && !carouselIsScrollable && 'justify-center',
      )}
    >
      {cars.map((car, index) => {
        const filterKey = (
          typeof frontendFilters === 'string'
            ? [frontendFilters]
            : frontendFilters
        )
          ?.map((filter) => filter in car && car[filter])
          .filter(Boolean); // TODO: Sitecore does not support multiselect so we need to convert string to array for supporting old CMS
        const pdpUrl = getPdpUrlFromCarKey(siteSlug, car.key, environment);

        return (
          <NoCarPriceErrorBoundary
            key={car.key}
            onError={() => setErrorCount((prev) => prev + 1)}
          >
            <Carousel.Card
              href={pdpUrl}
              filterKey={filterKey}
              trackingLabel={`${index} | ${pdpUrl}`}
            >
              <CarouselItem
                purchaseFromText={purchaseFromText}
                car={car}
                altText={altText}
                hideIfNoPrice={hideIfNoPrice}
                carPriceProps={{
                  variant: carPriceVariant,
                  ...carPriceProps,
                }}
              />
            </Carousel.Card>
          </NoCarPriceErrorBoundary>
        );
      })}
    </Carousel.Slider>
  );
};

export default CarouselSliderWrapper;

const getPdpUrlFromCarKey = (
  siteSlug: string,
  carKey: string,
  environment: string = process.env.NODE_ENV,
) => {
  // Return early if in development or storybook
  if (environment === 'development' || environment === 'storybook') return;
  const modelSlug = getModelSlugFromKey(carKey);
  return `/${siteSlug}/cars/${modelSlug}/`;
};
