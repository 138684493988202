import React from 'react';
import { ExtendCSS, Flex } from 'vcc-ui';
import IconTextItem from './IconTextItem';
import IconTextListProps from './IconTextList.props';

const IconTextList: React.FC<React.PropsWithChildren<IconTextListProps>> = ({
  content,
  ...params
}) => {
  params.orientation = params.orientation || 'horizontal';
  params.textVariant = params.textVariant || 'bates';

  const showAll = params.showAll;
  const isCenteredForTwoColumns = ['center', 'center-from-m'].includes(
    params.variant || '',
  );
  const twoColumnLayout =
    (content?.iconTexts?.length ?? 0) > 3 &&
    (!isCenteredForTwoColumns || (isCenteredForTwoColumns && showAll));

  return content.iconTexts?.length ? (
    <Flex
      extend={rootCSS(
        params.orientation,
        twoColumnLayout,
        params.variant === 'center',
      )}
    >
      {showAll || params.variant !== 'center'
        ? content.iconTexts.map((item, i) => (
            <IconTextItem key={i} {...params} {...item} />
          ))
        : content.iconTexts
            .slice(0, 3)
            .map((item, i) => <IconTextItem key={i} {...params} {...item} />)}
    </Flex>
  ) : null;
};

export default IconTextList;

const rootCSS =
  (
    orientation: string,
    twoColumnLayout?: boolean,
    centerItems?: boolean,
  ): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    margin: `0 -${baselineGrid * 2}px`,
    flexDirection: 'column',
    ...(centerItems && { justifyContent: 'center' }),

    fromM: {
      flexDirection: orientation === 'vertical' ? 'column' : 'row',
      margin: `0 -${baselineGrid * 2}px`,

      ...(twoColumnLayout &&
        orientation !== 'vertical' && {
          flexWrap: 'wrap',

          '> div': {
            width: '50%',
            marginBottom: 4 * baselineGrid,
          },
        }),
    },
  });
