import { ImageEntry } from '@volvo-cars/content-management-client';
import queries from '@volvo-cars/css/media-queries';
import { cssMerge } from '@volvo-cars/css/utils';
import { getImageProps } from 'next/image';
import React from 'react';

import { generateSizesString } from '../utils/image';
import { findRatioClass } from '../utils/responsive-aspect-ratio';
import { BaseImage } from './BaseImage';
import akamaiImageLoader from './akamai-loader';
import { ResponsiveImageProps } from './types';

/**
 * <ResponsiveImage /> should be our default component for rendering images coming
 * from our CMS. It integrates nicely with our ImageEntry type definition from the
 * cmc client, and produce either a <picture> element with multiple <source> elements
 * or a single <img> element, depending on the number of image entries provided.
 *
 * @prop {ImageEntry} images An object with image entries for each viewport. The
 * type is based on the ImageEntry type from the cmc client.
 *
 * @prop {Sizes} sizes Sizes for each viewport is defined by a number between
 * 0 and 1. Default is 1, representing 100% of the viewport. Use 1 / 2 for 50%
 * of the viewport, 1 / 3 for a third, etc.
 */
export function ResponsiveImage({
  images: imageEntries,
  sizes,
  // Set width and height to a 16/9 ratio initially. We would probably want
  // to depend on modern CSS like aspect-ratio to avoid layout shift, and not
  // have to set width and height on every instance. next/image currently requires
  // these attributes to be set (and they are reuired in getImageProps), but
  // the passed values have no effect on our current setup.
  width = 1024,
  height = 576,
  className,
  loading,
  ...props
}: ResponsiveImageProps) {
  if (
    !imageEntries?.sm &&
    !imageEntries?.md &&
    !imageEntries?.lg &&
    !imageEntries?.xl
  ) {
    return null;
  }

  // Generate a sizes string based on our own custom Sizes configuration.
  const generatedSizes = generateSizesString(sizes);

  // Always generate a <picture> element, even if we only have a single image.
  // This is okay semantically, and it makes it so you always get the same markup
  // output, making it easier and more reliable for styling.
  const sources: { media?: string; imageEntry: ImageEntry }[] = [];
  if (imageEntries.xl) {
    sources.push({ imageEntry: imageEntries.xl, media: queries.xl });
  }
  if (imageEntries.lg) {
    sources.push({ imageEntry: imageEntries.lg, media: queries.lg });
  }
  if (imageEntries.md) {
    sources.push({ imageEntry: imageEntries.md, media: queries.md });
  }

  return (
    <picture>
      {sources.map(({ imageEntry, media }) => (
        <Source
          key={`${imageEntry.src}${media}`}
          src={imageEntry.src}
          media={media}
          // An imageEntry has optional width and height fields as well, but we
          // don't rely on them to avoid unexpected results.
          width={width}
          height={height}
          sizes={generatedSizes}
        />
      ))}
      <BaseImage
        src={imageEntries.sm.src}
        width={width}
        height={height}
        className={cssMerge(
          imageEntries.smAspectRatio &&
            findRatioClass(imageEntries.smAspectRatio).sm,
          imageEntries.mdAspectRatio &&
            findRatioClass(imageEntries.mdAspectRatio).md,
          imageEntries.lgAspectRatio &&
            findRatioClass(imageEntries.lgAspectRatio).lg,
          imageEntries.xlAspectRatio &&
            findRatioClass(imageEntries.xlAspectRatio).xl,
          className,
        )}
        loading={imageEntries?.loading || loading}
        sizes={generatedSizes}
        {...props}
      />
    </picture>
  );
}

/**
 * Generates a <source> element for a <picture> element. Use getImageProps
 * from next/image to generate a value for srcSet with image alternative for a large
 * set of vireports.
 */
function Source({
  src,
  width,
  height,
  media,
  sizes,
}: {
  src: string;
  width?: number | `${number}`;
  height?: number | `${number}`;
  media?: string;
  sizes?: string;
}) {
  // We need to pass sizes attribute to getImgProps in order to produce a srcSet
  // based on width rather than pixel density. Potentially, go with pixel density
  // instead of widths, if we reverse the media queries from min- to max-width.
  const {
    props: { srcSet },
  } = getImageProps({
    src,
    width,
    height,
    alt: '',
    sizes,
    loader: akamaiImageLoader,
  });

  return (
    <source
      key={`${src}${media}`}
      srcSet={srcSet}
      sizes={sizes}
      media={media}
    />
  );
}
