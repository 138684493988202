'use client';
import type { DealerModel } from '@vcc-package/leads-utils/api';
import { getTranslationKeyForDealer } from '@vcc-package/leads-utils/util';
import { ErrorMessage, Select } from '@volvo-cars/react-forms';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TranslationKey } from '../../../api';
import { useRequiredLabel } from '../../../hooks/useRequiredLabel';
import { FlexFormTestID } from '../../../types';
import { SectionHeader } from '../../components';
import { useTranslate } from '../../context';
import { useRetailer } from '../../context/retailerContext';
import { useGetAllDealers } from '../components/inputs/retailer-picker/hooks/useGetAllDealers';
import { RetailerPickerLabels } from '../components/inputs/retailer-picker/types';
import { Messages, SelectOption } from '../components/types';
import {
  useFlexFormContext,
  useFlexFormDealerPickerContext,
} from '../providers/FlexibleFormsProvider';
import { SimplifiedRetailerList } from './SimplifiedRetailerList';
import { name } from './dealerpicker';

const NUMBER_OF_DEALERS = 4;

export const AllRetailersPicker = ({
  heading,
  retailerPickerChangeLabel,
  messages,
}: {
  heading: string;
  retailerPickerChangeLabel: RetailerPickerLabels['change'];
  messages: Messages;
}) => {
  const dealerPickerProps = useFlexFormDealerPickerContext();
  const customTranslations = dealerPickerProps?.customTranslation ?? [];
  const translate = useTranslate();
  const { clearErrors, control, setValue } = useFormContext();

  const { onRetailerSelect } = useRetailer();
  const { hideRequired } = useFlexFormContext();

  const [selectedRetailerState, setSelectedRetailerState] =
    useState<DealerModel | null>(null);

  const labels = {
    retailerSelection: getTranslationKeyForDealer(
      customTranslations,
      'retailerSelection',
    ),
    selectRetailer: getTranslationKeyForDealer(
      customTranslations,
      'selectRetailer',
    ),
  };

  const selectRetailer = (dealer: DealerModel | null) => {
    setSelectedRetailerState(dealer);
    if (dealer === null) return;
    if (dealer) {
      onRetailerSelect(dealer);
      dealer.dealerId && clearErrors(name);
    }
  };

  const {
    data: dealerData,
    isLoading,
    error: dealerDataError,
  } = useGetAllDealers({ selectRetailer, setValue, name });

  const hasAdditionalAddressInfo = (dealer: DealerModel) =>
    dealer.address?.addressLine1 ||
    dealer.address?.city ||
    dealer.address?.postalCode;

  const dealerOptions = useMemo((): SelectOption[] => {
    if (!dealerData) return [];
    return dealerData?.map((dealer) => {
      return dealer.dealerId
        ? {
            label: `${dealer.name}${hasAdditionalAddressInfo(dealer) ? ',' : ''} ${dealer.address?.addressLine1 ?? ''} ${
              dealer.address?.city ?? ''
            } ${dealer.address?.postalCode ?? ''}`,
            value: dealer.dealerId,
          }
        : { label: '', value: '' };
    });
  }, [dealerData]);

  const labelText = labels.selectRetailer
    ? translate(labels.selectRetailer.label)
    : translate(TranslationKey.SELECT_RETAILER);

  const formattedLabel = useRequiredLabel(
    labelText,
    true,
    hideRequired ?? false,
    true,
  );

  return (
    <div>
      {heading ? <SectionHeader title={heading} /> : null}
      <Controller
        control={control}
        defaultValue={undefined}
        name={name}
        rules={{
          required: {
            value: true,
            message: messages?.required ? messages.required(labelText) : '',
          },
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { error, invalid },
        }) => (
          <>
            {isLoading && !dealerData ? (
              <div className="flex flex-col items-center">
                <progress aria-label="Loading" className="spinner w-32 h-32" />
              </div>
            ) : null}
            {dealerDataError ? (
              <>
                <ErrorMessage
                  errorMessage={dealerDataError}
                  id="dealer-data-error"
                />
                {error ? (
                  <ErrorMessage
                    errorMessage={error.message}
                    id="retailer-field-error"
                  />
                ) : null}
              </>
            ) : null}
            {dealerData ? (
              dealerData.length > NUMBER_OF_DEALERS ? (
                <Select
                  ref={ref}
                  name={name}
                  label={formattedLabel}
                  data-testid={FlexFormTestID.DealerPicker}
                  value={value}
                  onChange={(v) => {
                    onChange(v);
                    const selected = dealerData?.find(
                      (d) => d.dealerId === v.target.value,
                    );
                    selected && onRetailerSelect(selected);
                  }}
                  onBlur={onBlur}
                  errorMessage={error ? error.message : ''}
                  // @ts-ignore
                  // Overflow: Prevent (long selected option's) hidden text to widen the viewport on iOS
                  // Padding: Prevent overlapping long text & chevron
                  style={{ overflowX: 'hidden', paddingRight: '54px' }}
                >
                  {dealerOptions?.length &&
                    dealerOptions.map((option, i) => (
                      <option
                        key={`${name}_option_${i}`}
                        data-testid={`${FlexFormTestID.DealerPicker}_option_${i}`}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                </Select>
              ) : (
                <SimplifiedRetailerList
                  dealerData={dealerData}
                  invalid={invalid}
                  error={error}
                  ref={ref}
                  onChange={onChange}
                  retailerPickerChangeLabel={retailerPickerChangeLabel}
                  selectedRetailerState={selectedRetailerState}
                  selectRetailer={selectRetailer}
                />
              )
            ) : null}
          </>
        )}
      />
    </div>
  );
};
