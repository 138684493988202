'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import {
  getInputErrorMessages,
  useValidation,
} from '@vcc-package/leads-utils/hooks';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { ValidatedField } from '../../flexible-forms/components';

const FALLBACK_REGEX = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$';

export const LandlineField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps) => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.LANDLINE,
  );
  const { regexValidate } = useValidation({
    ...validation?.landline,
    args: validation?.landline?.args ?? [FALLBACK_REGEX],
  });

  return (
    <ValidatedField
      hideRequired={hideRequired}
      key="landline"
      name={FlexFormFieldType.LANDLINE}
      testid={FlexFormTestID.Landline}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.LANDLINE_FIELD_LABEL)
      }
      inputType="Tel"
      autoComplete="tel"
      control={control}
      validate={regexValidate}
      messages={messages}
      {...validation.phone}
      {...validation.landline}
    />
  );
};
