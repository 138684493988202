import React from 'react';

import { cssJoin } from '@volvo-cars/css/utils';

type CardProps = {
  ariaSelected: boolean;
  dataTestid?: string;
  tabIndex?: number;
  onClick: () => void;
  onKeyDown?: () => void;
  variant?: 'medium' | 'large' | 'small';
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
};

export default function Card({
  ariaSelected,
  dataTestid,
  tabIndex = 0,
  onClick,
  onKeyDown,
  variant = 'medium',
  className = '',
  children,
  disabled = false,
}: CardProps) {
  return (
    <button
      type="button"
      role="tab"
      className={cssJoin(
        'border transition-colors selected:border-2 selected:border-accent-blue w-full hover:border-primary',
        variant === 'large' && 'p-24',
        variant === 'medium' && 'p-16',
        variant === 'small' ? 'rounded-sm' : 'rounded-md',
        className,
      )}
      tabIndex={tabIndex}
      data-testid={dataTestid}
      aria-selected={ariaSelected}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
      style={{ margin: ariaSelected ? 0 : 1 }}
    >
      {children}
    </button>
  );
}
