import type { ParsedUrlQuery } from 'querystring';

import { QUERY_PARAMETER } from '@vcc-package/leads-utils/constants';
import type { Query, QueryParameter } from '@vcc-package/leads-utils/types';

import { firstTruthyElementIfArray } from './typeUtils';

export const isQueryParam = (param: string): param is QueryParameter =>
  Object.values(QUERY_PARAMETER).includes(param as QueryParameter);

/**
 * Takes in the NextJs router.query params object, filters out "illegal" query params & parses
 * each param to it's expected type. List of query params & their respective types is set in
 * constants/queryParameter & /types/query respectively.
 * For string params: In case of multiple same param values, the first value will be used (as string).
 * For array params ("skip"): Single/multiple values will always be saved as an array value.
 * @returns Filtered & lower-cased query parameter object
 */
export const getQueryObject = (
  query: ParsedUrlQuery | URLSearchParams,
): Query => {
  if (!query) return {};

  if (query instanceof URLSearchParams) {
    query = Object.fromEntries(query);
  }

  const obj: Query = {};
  for (const [key, value] of Object.entries(query)) {
    const lcKey = key.toLowerCase();

    if (isQueryParam(lcKey) && value) {
      if (lcKey === 'skip') {
        obj[lcKey] = Array.isArray(value) ? value : [value];
      } else {
        obj[lcKey] = firstTruthyElementIfArray(value);
      }
    }
  }
  return getLowerCasedQueryObject(obj);
};

export const getLowerCasedQueryObject = (query: Query): Query => {
  const obj: Query = {};
  for (const [key, value] of Object.entries(query)) {
    (obj as any)[key.toLowerCase()] = value;
  }
  return obj;
};
