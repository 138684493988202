import { cssMerge } from '@volvo-cars/css/utils';
/* eslint-disable @volvo-cars/css/no-custom-class */
import React from 'react';
import styles from './DialogFooter.module.css';

export type DialogFooterProps = {
  open?: boolean;
  className?: string;
};

export const DialogFooter: React.FC<
  React.PropsWithChildren<DialogFooterProps>
> = ({ open = true, className, children }) => {
  return (
    <footer
      slot="footer"
      className={cssMerge(
        !open && styles['closing'],
        'button-group justify-end',
        'dialog__cta-slot', // custom class needed to style dialog and react to its presence outside of this component
        className,
      )}
    >
      {children}
    </footer>
  );
};
