'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { TitleFieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { SelectField } from '../../flexible-forms/components';

export const TitleField = ({
  translate,
  validation,
  options,
  customTranslation,
  hideRequired,
}: TitleFieldProps) => {
  const { control } = useFormContext();
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.TITLE,
  );

  const messages = getInputErrorMessages(translate);
  const titleValidation = validation[FlexFormFieldType.TITLE.toLowerCase()];

  return (
    <SelectField
      hideRequired={hideRequired}
      key="title"
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.TITLE_FIELD_LABEL)
      }
      placeholder={
        customTranslationKeys?.placeholder
          ? translate(customTranslationKeys.placeholder)
          : translate(TranslationKey.TITLE_FIELD_PLACEHOLDER)
      }
      name={FlexFormFieldType.TITLE}
      testid={FlexFormTestID.Title}
      control={control}
      options={options.Title}
      required={titleValidation?.required}
      messages={messages}
      {...validation.title}
    />
  );
};
