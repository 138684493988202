import React from 'react';
import { useListItem } from './list-item.hook';
import { ListItemProps } from './list-item.types';
import { ListItemView } from './list-item.view';

export const ListItem: React.FC<React.PropsWithChildren<ListItemProps>> = ({
  children,
  href,
  nonInteractive,
  onClick,
  ...props
}) => {
  const { interactive, hasChildren } = useListItem({
    children,
    href,
    nonInteractive,
    onClick,
  });

  return (
    <ListItemView
      interactive={interactive}
      hasChildren={hasChildren}
      href={href}
      onClick={onClick}
      {...props}
    >
      {children}
    </ListItemView>
  );
};
