import { getElasticVariablesFromEnv } from '@vcc-package/opentelemetry';
import { createPublicEnv } from '@vcc-www/public-env';

export const { PublicEnv, getPublicEnv } = createPublicEnv({
  deployEnv: process.env.DEPLOY_ENV || 'qa',
  rootPath: process.env.ROOT_PATH || '/[market]/',
  appEnv: process.env.APP_ENVIRONMENT || 'LOCAL',
  apiHost: process.env.API_URL || 'https://qawww.volvocars.com',
  commitId: process.env.COMMIT_ID || 'unknown',
  setBy: 'server',
  assetPath: process.env.NEXT_PUBLIC_ASSETS_PREFIX || '/static/leads-forms/',
  authHeaderName: process.env.AUTH_HEADER_NAME || '',
  authHeaderValue: process.env.AUTH_HEADER_VALUE || '',
  useCors: process.env.USE_CORS === 'true',
  port: process.env.PORT || '3000',
  version: process.env.VERSION || '1.0.0',
  trailingSlash: process.env.TRAILING_SLASH || false,
  hideMissingTranslationMessage:
    process.env.HIDE_MISSING_TRANSLATION_MESSAGE || 'false',
  ...getElasticVariablesFromEnv(),
});
