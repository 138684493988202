import Card from './src/components/Card';
import Core from './src/components/Core';
import Filter from './src/components/Filter';
import Indicator from './src/components/Indicator';
import Navigation from './src/components/Navigation';
import Skeleton from './src/components/Skeleton';
import SkeletonPart from './src/components/SkeletonPart';
import Slider from './src/components/Slider';
export { useCarousel } from './src/providers/CarouselProvider';

const Carousel = {
  Core,
  Slider,
  Card,
  Indicator,
  Navigation,
  Filter,
  Skeleton,
  SkeletonPart,
};

export default Carousel;
