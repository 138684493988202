import React from 'react';

export const NoticeBox = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <div
      className="w-full border p-16 rounded"
      style={{ backgroundColor: '#fffceb' }}
    >
      {children}
    </div>
  );
};
