'use client';
import React from 'react';

import {
  FlexFormLegalFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import type {
  CustomTranslations,
  Translator,
} from '@vcc-package/leads-utils/types';
import { FlexFormLegalTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../components';

type PhoneOptinProps = {
  translate: Translator;
  defaultValue: boolean;
  disabled: boolean;
  customTranslation: CustomTranslations;
};

export const PhoneOptinField = ({
  translate,
  defaultValue,
  disabled,
  customTranslation,
}: PhoneOptinProps) => {
  const { control } = useFormContext();
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormLegalFieldType.PHONE_OPTIN,
  );
  return (
    <CheckboxField
      name={FlexFormLegalFieldType.PHONE_OPTIN}
      testid={FlexFormLegalTestID.Phone}
      control={control}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.PHONE_OPTION_FIELD_LABEL)
      }
      required={false}
      key={FlexFormLegalFieldType.PHONE_OPTIN}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
};
