'use client';

import { useTracker } from '@volvo-cars/tracking';
import type {
  CustomEventData,
  EventAction,
  EventStrict,
} from '@volvo-cars/tracking';
import React, { cloneElement, useCallback } from 'react';

interface CtaTrackingWrapperProps {
  eventCategory: string;
  eventAction: EventAction;
  eventLabel: string;
  children: React.ReactNode;
}
function isReactElement(child: React.ReactNode): child is React.ReactElement {
  return React.isValidElement(child);
}

export const CtaTrackingWrapper = ({
  eventCategory,
  eventAction,
  eventLabel,
  children,
}: CtaTrackingWrapperProps) => {
  const tracker = useTracker();

  const handleClick = useCallback(() => {
    const trackingData = {
      eventCategory,
      eventAction,
      eventLabel,
    } satisfies CustomEventData & EventStrict;

    tracker.customEvent(trackingData);
  }, [eventCategory, eventAction, eventLabel, tracker]);

  const addTrackingToChildren = React.Children.map(children, (child) => {
    if (isReactElement(child)) {
      const originalOnClick = child.props.onClick;

      return cloneElement(child, {
        onClick: (e: React.MouseEvent) => {
          handleClick();
          if (originalOnClick) {
            originalOnClick(e);
          }
        },
      });
    }
    return child;
  });

  return <>{addTrackingToChildren}</>;
};
