import { validateAndParseCookie } from './validator';

export const EW_USER_REGION_COOKIE_NAME = 'ew_user_region';
export const USER_LOCATION_COOKIE_NAME = 'user_location';

export const getParsedCookie = <T>(
  name: string,
  getCookieFunc: (name: string) => string | null,
): T | null => {
  if (!getCookieFunc) return null;

  try {
    const cookie = getCookieFunc(name);

    return validateAndParseCookie<T>(cookie, name);
  } catch (error) {
    return null;
  }
};
