import { FlexFormFieldType, RequestApiModel } from '../api';
import { FormValues } from '../types/flexibleForm';

export const requestApiModelToFormValues = (
  requestApiModel: RequestApiModel,
): FormValues => {
  const formValues = {
    [FlexFormFieldType.TITLE]: requestApiModel.title ?? '',
    [FlexFormFieldType.FIRSTNAME]: requestApiModel.firstName ?? '',
    [FlexFormFieldType.SURNAME]: requestApiModel.surName ?? '',
    [FlexFormFieldType.MOBILE_PHONE]: requestApiModel.mobilePhone ?? '',
    [FlexFormFieldType.EMAIL]: requestApiModel.email ?? '',
    [FlexFormFieldType.LEGAL_ADDRESS]: requestApiModel.legalAddress as any,
  } as FormValues;
  return formValues;
};
