import { Button, Link } from '@vcc-www/buttons';
import { useFeatureFlags } from '@vcc-www/dotcom-feature-flags';
import { cssMerge } from '@volvo-cars/css/utils';
import { TrackingProvider } from '@volvo-cars/tracking';
import React from 'react';
import styles from './ImageWithText.module.css';
import { CtaParams, CtaProps } from './ImageWithText.types';

const stripBaseUrl = (url?: string) =>
  (url || '').replace(/^https?:\/\/[^/]+volvocars\.com/, '');

const Ctas: React.FC<
  React.PropsWithChildren<CtaProps & CtaParams & { openOverlay: () => void }>
> = ({
  primaryCta,
  primaryCtaTrackLabel,
  primaryCtaTrackCategory,
  cta,
  ctaTrackLabel,
  ctaTrackCategory,
  overlayCallouts = [],
  primaryCtaAlternate,
  ctasAlignment = 'left',
  primaryCtaVariant = 'outline',
  openOverlay,
  buttonAccessibilityLabel,
  linkAccessibilityLabel,
}) => {
  const showPrimaryCta = !!(primaryCta?.href || primaryCta?.text);
  const showSecondaryCta =
    !!(cta?.href || cta?.text) || overlayCallouts?.length > 0;
  const showCtas = showPrimaryCta || showSecondaryCta;

  const group = useFeatureFlags().launchDarklyFlags['shopXC40-CBV-CC'];
  const augmentedLink = ['c', 'd'].includes(group || '')
    ? primaryCtaAlternate
    : primaryCta;
  const buttonAriaLabel =
    buttonAccessibilityLabel || primaryCta?.accessibilityLabel;
  const linkAriaLabel = linkAccessibilityLabel || cta?.accessibilityLabel;

  return showCtas ? (
    <>
      <div
        className={cssMerge(
          'flex items-center md:flex-row md:self-stretch lg:justify-start mt-48',
          ctasAlignment === 'left'
            ? cssMerge(styles.ctaLeft, 'md:justify-start')
            : ctasAlignment === 'center'
              ? cssMerge(styles.ctaCenetred, 'md:justify-center')
              : cssMerge(styles.cta, 'md:justify-end'),
        )}
      >
        {showPrimaryCta && (
          <div>
            <TrackingProvider eventCategory={primaryCtaTrackCategory}>
              <Button
                aria-label={
                  buttonAriaLabel || augmentedLink?.text || primaryCta?.text
                }
                href={augmentedLink?.href || primaryCta?.href}
                target={augmentedLink?.target || primaryCta?.target}
                trackEventLabel={`${
                  primaryCtaTrackLabel ||
                  augmentedLink?.text ||
                  primaryCta?.text ||
                  ''
                }|${stripBaseUrl(augmentedLink?.href || primaryCta?.href)}`}
                trackEventAction="button|click"
                variant={primaryCtaVariant}
                trackData={{ eventCategory: 'imageWithText' }}
                data-autoid="imageWithText:primaryCta"
              >
                {augmentedLink?.text || primaryCta?.text}
              </Button>
            </TrackingProvider>
          </div>
        )}
        {showSecondaryCta && (
          <>
            {showPrimaryCta && <div className="m-24" />}
            <TrackingProvider eventCategory={ctaTrackCategory}>
              <Link
                aria-label={linkAriaLabel || cta?.text}
                onClick={
                  cta?.href && !overlayCallouts?.length
                    ? undefined
                    : openOverlay
                }
                href={
                  cta?.href && !overlayCallouts?.length ? cta?.href : undefined
                }
                target={
                  cta?.target && !overlayCallouts?.length
                    ? cta?.target
                    : undefined
                }
                trackEventLabel={`${
                  ctaTrackLabel || cta?.text || ''
                }|${stripBaseUrl(cta?.href)}`}
                trackEventAction="link|click"
                trackData={{ eventCategory: 'imageWithText' }}
                data-autoid="imageWithText:secondaryCta"
              >
                {cta?.text}
              </Link>
            </TrackingProvider>
          </>
        )}
      </div>
    </>
  ) : null;
};

export default Ctas;
