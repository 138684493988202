import type {
  FlexFormConfiguration,
  Validation,
} from '@vcc-package/leads-utils/api';
import { FlexFormFieldType } from '@vcc-package/leads-utils/api';
import flatten from 'lodash/flatten';

export const validateValidationConfiguration = (
  formConfig: FlexFormConfiguration | null,
) => {
  if (!formConfig) {
    return;
  }
  const { main } = formConfig;
  const validationKeys = Object.keys(main?.validation ?? {});

  // Check for duplicates
  const duplicates = getDuplicateKeys(validationKeys);
  duplicates.forEach((duplicateField) => {
    console.warn(
      `Found duplicate validation ${duplicateField}, check the form validation configuration`,
    );
  });
  // Check for misspelled validation keys.
  const invalidKeys = getInvalidKeys(validationKeys);
  invalidKeys.forEach((invalidKey) => {
    console.warn(
      `Found unrecognized key in validation (${invalidKey}), check the form validation configuration`,
    );
  });
};

export const validateFieldConfiguration = (
  formConfig: FlexFormConfiguration | null,
) => {
  if (!formConfig) {
    return;
  }
  const { main } = formConfig;
  const flattenedRows = flatten(normalizeRowValues(main?.rows ?? []));
  checkForIllegalCombination(
    flattenedRows,
    FlexFormFieldType.MOBILE_PHONE,
    FlexFormFieldType.MOBILE_PHONE_ASYNC,
  );

  checkForIllegalCombination(
    flattenedRows,
    FlexFormFieldType.EMAIL,
    FlexFormFieldType.EMAIL_ASYNC,
  );

  const duplicates = getDuplicateKeys(flattenedRows);
  duplicates.forEach((duplicateField) => {
    console.warn(
      `Found duplicate field ${duplicateField}, check the form configuration`,
    );
  });

  const invalidKeys = getInvalidKeys(flattenedRows);
  invalidKeys.forEach((invalidKey) => {
    console.warn(
      `Found unrecognized key in rows (${invalidKey}), check the form row configuration`,
    );
  });
};

export const normalizeRowValues = (rows: FlexFormFieldType[][]) => {
  return rows.map((fields) => {
    return fields.map((field) => {
      return field;
    });
  });
};

export const normalizeValidationKeys = (
  validation: Partial<Record<FlexFormFieldType, Validation>>,
) => {
  return Object.fromEntries(
    Object.entries(validation).map(([k, v]) => [k.toLowerCase().trim(), v]),
  );
};

function checkForIllegalCombination(
  rows: string[],
  fieldA: FlexFormFieldType,
  fieldB: FlexFormFieldType,
) {
  if (rows.includes(fieldA) && rows.includes(fieldB)) {
    console.warn(
      `Form should not contain both ${fieldA} and ${fieldB}, only one should be included`,
    );
  }
}

export function getDuplicateKeys(keys: string[]) {
  return keys.filter((key, index, self) => {
    return self.indexOf(key) !== index && key !== FlexFormFieldType.SPACE;
  });
}

export function getInvalidKeys(keys: string[]) {
  return keys.filter((key) => {
    return !Object.values(FlexFormFieldType).includes(key as FlexFormFieldType);
  });
}
