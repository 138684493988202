'use client';

import type { PropsWithChildren } from 'react';
import React from 'react';

import { LeadsApp, globalStyling } from '@vcc-package/leads-utils/constants';

import {
  Header,
  Page,
  RetailerAddress,
  Spinner,
  VehicleTitle,
} from '../components';
import type { LeadsImageSize } from '../components/imageComponents/LeadsImages';
import { ModelImage } from '../components/imageComponents/LeadsImages';
import { useLeadsContext } from '../context/leadsContext';
import { useModel } from '../context/modelContext';
import { useRetailer } from '../context/retailerContext';

import { usePathname, useSearchParams } from 'next/navigation';
import { PurposeType } from '../../api';
import { useTranslate } from '../context';
import InfoBox from './InfoBox';
import styles from './LeadsDefaultLayout.module.css';

type LayoutProps = {
  hideCTA?: boolean;
  hideModel?: boolean;
  onThankYouPage?: boolean;
};

const sizes: LeadsImageSize[] = [
  { screenWidth: 480, imageWidth: 480, type: 'max' },
  { screenWidth: 1025, imageWidth: 464, type: 'max' },
  { screenWidth: 1025, imageWidth: 592, type: 'min' },
];

export const LeadsDefaultLayout = (props: PropsWithChildren<LayoutProps>) => {
  const {
    hideCTA = false,
    children,
    hideModel,
    onThankYouPage = false,
  } = props;

  const {
    navigation: { query },
    features,
    isEmbeddableForms,
    isLoaded,
    formConfig,
    translationKeys,
    inOverlay,
    appId,
    purpose,
  } = useLeadsContext();

  const { selectedModel } = useModel();
  const { selectedRetailer, isInsideSales } = useRetailer();

  const translate = useTranslate();

  const searchParams = useSearchParams();
  const pathname = usePathname();

  const hasRetailerId = searchParams?.get('retailerid');
  const isThankYouPage = pathname?.split('/')[3] === 'finished';

  const showRetailer =
    !isInsideSales && selectedRetailer && (!!hasRetailerId || isThankYouPage);

  if (!isLoaded || (!formConfig && isEmbeddableForms)) {
    const extraStyles = isEmbeddableForms
      ? undefined
      : {
          height: '100vh',
        };
    return <Spinner style={extraStyles} />;
  }

  const header = formConfig?.header;

  const heading = header?.heading
    ? translate(header.heading)
    : (header?.heading ?? translate(translationKeys.formHeading));

  const subHeading = inOverlay
    ? (features.useDynamicTitle && purpose === PurposeType.KEEP_ME_UPDATED) ||
      (features.showSubHeadingOnEF && purpose === PurposeType.OFFER_REQUEST)
      ? header?.subHeading
        ? translate(header?.subHeading)
        : undefined
      : undefined
    : header?.subHeading
      ? translate(header?.subHeading)
      : (header?.subHeading ?? translate(translationKeys.formSubHeading));

  if (features.useNewQuoteFlow || appId !== LeadsApp.QuoteRequest) {
    return (
      <Page>
        <Header heading={heading} subheading={subHeading} />
        {props.children}
      </Page>
    );
  }

  if (hideModel) {
    return (
      <>
        {heading &&
        features.showSubHeadingOnEF &&
        isEmbeddableForms &&
        !onThankYouPage ? (
          <div className="mb-32 lg:mb-64">
            <Header heading={heading} subheading={subHeading} />
          </div>
        ) : null}
        {children}
      </>
    );
  }

  return (
    <Page>
      {heading && (
        <div className="md:hidden">
          <Header heading={heading} subheading={subHeading} />
        </div>
      )}
      <div className={`flex flex-col md:flex-row ${globalStyling.sectionGap}`}>
        {!features.useNewQuoteFlow && (
          <div className={styles.vehicleModelContainer}>
            {selectedModel && (
              <ModelImage
                model={selectedModel}
                sizes={sizes}
                className="until-md:hidden"
              />
            )}
            <div className="p-24 flex flex-col w-full gap-24 bg-secondary">
              {selectedModel && (
                <InfoBox type="model" hideCTA={hideCTA || !!query.cceid}>
                  <VehicleTitle vehicle={selectedModel} />
                </InfoBox>
              )}

              {showRetailer && (
                <>
                  <hr className="w-full border-t m-0 p-0" />

                  <InfoBox type="retailer" hideCTA={hideCTA}>
                    <p>
                      <strong>{selectedRetailer.name}</strong>
                    </p>
                    <RetailerAddress retailer={selectedRetailer} />
                  </InfoBox>
                </>
              )}
            </div>
          </div>
        )}

        <div
          className={`flex flex-col md:mx-auto ${globalStyling.sectionGap}`}
          style={{ maxWidth: globalStyling.columnMaxWidth }}
        >
          {heading && (
            <div className="hidden md:flex">
              <Header heading={heading} subheading={subHeading} />
            </div>
          )}

          {children}
        </div>
      </div>
    </Page>
  );
};
