const areaCodes = {
  ad: {
    areaCodes: ['376'],
    countryName: 'Andorra',
    emojiFlag: '🇦🇩',
  },
  ae: {
    areaCodes: ['971'],
    countryName: 'United Arab Emirates',
    emojiFlag: '🇦🇪',
  },
  af: {
    areaCodes: ['93'],
    countryName: 'Afghanistan',
    emojiFlag: '🇦🇫',
  },
  ag: {
    areaCodes: ['1-268'],
    countryName: 'Antigua and Barbuda',
    emojiFlag: '🇦🇬',
  },
  ai: {
    areaCodes: ['1-264'],
    countryName: 'Anguilla',
    emojiFlag: '🇦🇮',
  },
  al: {
    areaCodes: ['355'],
    countryName: 'Albania',
    emojiFlag: '🇦🇱',
  },
  am: {
    areaCodes: ['374'],
    countryName: 'Armenia',
    emojiFlag: '🇦🇲',
  },
  an: {
    areaCodes: ['599'],
    countryName: 'Netherlands Antilles',
    emojiFlag: '🇦🇳',
  },
  ao: {
    areaCodes: ['244'],
    countryName: 'Angola',
    emojiFlag: '🇦🇴',
  },
  aq: {
    areaCodes: ['672'],
    countryName: 'Antarctica',
    emojiFlag: '🇦🇶',
  },
  ar: {
    areaCodes: ['54'],
    countryName: 'Argentina',
    emojiFlag: '🇦🇷',
  },
  as: {
    areaCodes: ['1-684'],
    countryName: 'American Samoa',
    emojiFlag: '🇦🇸',
  },
  at: {
    areaCodes: ['43'],
    countryName: 'Austria',
    emojiFlag: '🇦🇹',
  },
  au: {
    areaCodes: ['61'],
    countryName: 'Australia',
    emojiFlag: '🇦🇺',
  },
  aw: {
    areaCodes: ['297'],
    countryName: 'Aruba',
    emojiFlag: '🇦🇼',
  },
  az: {
    areaCodes: ['994'],
    countryName: 'Azerbaijan',
    emojiFlag: '🇦🇿',
  },
  ba: {
    areaCodes: ['387'],
    countryName: 'Bosnia and Herzegovina',
    emojiFlag: '🇧🇦',
  },
  bb: {
    areaCodes: ['1-246'],
    countryName: 'Barbados',
    emojiFlag: '🇧🇧',
  },
  bd: {
    areaCodes: ['880'],
    countryName: 'Bangladesh',
    emojiFlag: '🇧🇩',
  },
  be: {
    areaCodes: ['32'],
    countryName: 'Belgium',
    emojiFlag: '🇧🇪',
  },
  bf: {
    areaCodes: ['226'],
    countryName: 'Burkina Faso',
    emojiFlag: '🇧🇫',
  },
  bg: {
    areaCodes: ['359'],
    countryName: 'Bulgaria',
    emojiFlag: '🇧🇬',
  },
  bh: {
    areaCodes: ['973'],
    countryName: 'Bahrain',
    emojiFlag: '🇧🇭',
  },
  bi: {
    areaCodes: ['257'],
    countryName: 'Burundi',
    emojiFlag: '🇧🇮',
  },
  bj: {
    areaCodes: ['229'],
    countryName: 'Benin',
    emojiFlag: '🇧🇯',
  },
  bl: {
    areaCodes: ['590'],
    countryName: 'Saint Barthelemy',
    emojiFlag: '🇧🇱',
  },
  bm: {
    areaCodes: ['1-441'],
    countryName: 'Bermuda',
    emojiFlag: '🇧🇲',
  },
  bn: {
    areaCodes: ['673'],
    countryName: 'Brunei',
    emojiFlag: '🇧🇳',
  },
  bo: {
    areaCodes: ['591'],
    countryName: 'Bolivia',
    emojiFlag: '🇧🇴',
  },
  br: {
    areaCodes: ['55'],
    countryName: 'Brazil',
    emojiFlag: '🇧🇷',
  },
  bs: {
    areaCodes: ['1-242'],
    countryName: 'Bahamas',
    emojiFlag: '🇧🇸',
  },
  bt: {
    areaCodes: ['975'],
    countryName: 'Bhutan',
    emojiFlag: '🇧🇹',
  },
  bw: {
    areaCodes: ['267'],
    countryName: 'Botswana',
    emojiFlag: '🇧🇼',
  },
  by: {
    areaCodes: ['375'],
    countryName: 'Belarus',
    emojiFlag: '🇧🇾',
  },
  bz: {
    areaCodes: ['501'],
    countryName: 'Belize',
    emojiFlag: '🇧🇿',
  },
  ca: {
    areaCodes: ['1'],
    countryName: 'Canada',
    emojiFlag: '🇨🇦',
  },
  cc: {
    areaCodes: ['61'],
    countryName: 'Cocos Islands',
    emojiFlag: '🇨🇨',
  },
  cd: {
    areaCodes: ['243'],
    countryName: 'Democratic Republic of the Congo',
    emojiFlag: '🇨🇩',
  },
  cf: {
    areaCodes: ['236'],
    countryName: 'Central African Republic',
    emojiFlag: '🇨🇫',
  },
  cg: {
    areaCodes: ['242'],
    countryName: 'Republic of the Congo',
    emojiFlag: '🇨🇬',
  },
  ch: {
    areaCodes: ['41'],
    countryName: 'Switzerland',
    emojiFlag: '🇨🇭',
  },
  ci: {
    areaCodes: ['225'],
    countryName: 'Ivory Coast',
    emojiFlag: '🇨🇮',
  },
  ck: {
    areaCodes: ['682'],
    countryName: 'Cook Islands',
    emojiFlag: '🇨🇰',
  },
  cl: {
    areaCodes: ['56'],
    countryName: 'Chile',
    emojiFlag: '🇨🇱',
  },
  cm: {
    areaCodes: ['237'],
    countryName: 'Cameroon',
    emojiFlag: '🇨🇲',
  },
  cn: {
    areaCodes: ['86'],
    countryName: 'China',
    emojiFlag: '🇨🇳',
  },
  co: {
    areaCodes: ['57'],
    countryName: 'Colombia',
    emojiFlag: '🇨🇴',
  },
  cr: {
    areaCodes: ['506'],
    countryName: 'Costa Rica',
    emojiFlag: '🇨🇷',
  },
  cu: {
    areaCodes: ['53'],
    countryName: 'Cuba',
    emojiFlag: '🇨🇺',
  },
  cv: {
    areaCodes: ['238'],
    countryName: 'Cape Verde',
    emojiFlag: '🇨🇻',
  },
  cw: {
    areaCodes: ['599'],
    countryName: 'Curacao',
    emojiFlag: '🇨🇼',
  },
  cx: {
    areaCodes: ['61'],
    countryName: 'Christmas Island',
    emojiFlag: '🇨🇽',
  },
  cy: {
    areaCodes: ['357'],
    countryName: 'Cyprus',
    emojiFlag: '🇨🇾',
  },
  cz: {
    areaCodes: ['420'],
    countryName: 'Czech Republic',
    emojiFlag: '🇨🇿',
  },
  de: {
    areaCodes: ['49'],
    countryName: 'Germany',
    emojiFlag: '🇩🇪',
  },
  dj: {
    areaCodes: ['253'],
    countryName: 'Djibouti',
    emojiFlag: '🇩🇯',
  },
  dk: {
    areaCodes: ['45'],
    countryName: 'Denmark',
    emojiFlag: '🇩🇰',
  },
  dm: {
    areaCodes: ['1-767'],
    countryName: 'Dominica',
    emojiFlag: '🇩🇲',
  },
  do: {
    areaCodes: ['1-809', '1-829', '1-849'],
    countryName: 'Dominican Republic',
    emojiFlag: '🇩🇴',
  },
  dz: {
    areaCodes: ['213'],
    countryName: 'Algeria',
    emojiFlag: '🇩🇿',
  },
  ec: {
    areaCodes: ['593'],
    countryName: 'Ecuador',
    emojiFlag: '🇪🇨',
  },
  ee: {
    areaCodes: ['372'],
    countryName: 'Estonia',
    emojiFlag: '🇪🇪',
  },
  eg: {
    areaCodes: ['20'],
    countryName: 'Egypt',
    emojiFlag: '🇪🇬',
  },
  eh: {
    areaCodes: ['212'],
    countryName: 'Western Sahara',
    emojiFlag: '🇪🇭',
  },
  er: {
    areaCodes: ['291'],
    countryName: 'Eritrea',
    emojiFlag: '🇪🇷',
  },
  es: {
    areaCodes: ['34'],
    countryName: 'Spain',
    emojiFlag: '🇪🇸',
  },
  et: {
    areaCodes: ['251'],
    countryName: 'Ethiopia',
    emojiFlag: '🇪🇹',
  },
  fi: {
    areaCodes: ['358'],
    countryName: 'Finland',
    emojiFlag: '🇫🇮',
  },
  fj: {
    areaCodes: ['679'],
    countryName: 'Fiji',
    emojiFlag: '🇫🇯',
  },
  fk: {
    areaCodes: ['500'],
    countryName: 'Falkland Islands',
    emojiFlag: '🇫🇰',
  },
  fm: {
    areaCodes: ['691'],
    countryName: 'Micronesia',
    emojiFlag: '🇫🇲',
  },
  fo: {
    areaCodes: ['298'],
    countryName: 'Faroe Islands',
    emojiFlag: '🇫🇴',
  },
  fr: {
    areaCodes: ['33'],
    countryName: 'France',
    emojiFlag: '🇫🇷',
  },
  ga: {
    areaCodes: ['241'],
    countryName: 'Gabon',
    emojiFlag: '🇬🇦',
  },
  gb: {
    areaCodes: ['44'],
    countryName: 'United Kingdom',
    emojiFlag: '🇬🇧',
  },
  gd: {
    areaCodes: ['1-473'],
    countryName: 'Grenada',
    emojiFlag: '🇬🇩',
  },
  ge: {
    areaCodes: ['995'],
    countryName: 'Georgia',
    emojiFlag: '🇬🇪',
  },
  gg: {
    areaCodes: ['44-1481'],
    countryName: 'Guernsey',
    emojiFlag: '🇬🇬',
  },
  gh: {
    areaCodes: ['233'],
    countryName: 'Ghana',
    emojiFlag: '🇬🇭',
  },
  gi: {
    areaCodes: ['350'],
    countryName: 'Gibraltar',
    emojiFlag: '🇬🇮',
  },
  gl: {
    areaCodes: ['299'],
    countryName: 'Greenland',
    emojiFlag: '🇬🇱',
  },
  gm: {
    areaCodes: ['220'],
    countryName: 'Gambia',
    emojiFlag: '🇬🇲',
  },
  gn: {
    areaCodes: ['224'],
    countryName: 'Guinea',
    emojiFlag: '🇬🇳',
  },
  gq: {
    areaCodes: ['240'],
    countryName: 'Equatorial Guinea',
    emojiFlag: '🇬🇶',
  },
  gr: {
    areaCodes: ['30'],
    countryName: 'Greece',
    emojiFlag: '🇬🇷',
  },
  gt: {
    areaCodes: ['502'],
    countryName: 'Guatemala',
    emojiFlag: '🇬🇹',
  },
  gu: {
    areaCodes: ['1-671'],
    countryName: 'Guam',
    emojiFlag: '🇬🇺',
  },
  gw: {
    areaCodes: ['245'],
    countryName: 'Guinea-Bissau',
    emojiFlag: '🇬🇼',
  },
  gy: {
    areaCodes: ['592'],
    countryName: 'Guyana',
    emojiFlag: '🇬🇾',
  },
  hk: {
    areaCodes: ['852'],
    countryName: 'Hong Kong',
    emojiFlag: '🇭🇰',
  },
  hn: {
    areaCodes: ['504'],
    countryName: 'Honduras',
    emojiFlag: '🇭🇳',
  },
  hr: {
    areaCodes: ['385'],
    countryName: 'Croatia',
    emojiFlag: '🇭🇷',
  },
  ht: {
    areaCodes: ['509'],
    countryName: 'Haiti',
    emojiFlag: '🇭🇹',
  },
  hu: {
    areaCodes: ['36'],
    countryName: 'Hungary',
    emojiFlag: '🇭🇺',
  },
  id: {
    areaCodes: ['62'],
    countryName: 'Indonesia',
    emojiFlag: '🇮🇩',
  },
  ie: {
    areaCodes: ['353'],
    countryName: 'Ireland',
    emojiFlag: '🇮🇪',
  },
  il: {
    areaCodes: ['972'],
    countryName: 'Israel',
    emojiFlag: '🇮🇱',
  },
  im: {
    areaCodes: ['44-1624'],
    countryName: 'Isle of Man',
    emojiFlag: '🇮🇲',
  },
  in: {
    areaCodes: ['91'],
    countryName: 'India',
    emojiFlag: '🇮🇳',
  },
  io: {
    areaCodes: ['246'],
    countryName: 'British Indian Ocean Territory',
    emojiFlag: '🇮🇴',
  },
  iq: {
    areaCodes: ['964'],
    countryName: 'Iraq',
    emojiFlag: '🇮🇶',
  },
  ir: {
    areaCodes: ['98'],
    countryName: 'Iran',
    emojiFlag: '🇮🇷',
  },
  is: {
    areaCodes: ['354'],
    countryName: 'Iceland',
    emojiFlag: '🇮🇸',
  },
  it: {
    areaCodes: ['39'],
    countryName: 'Italy',
    emojiFlag: '🇮🇹',
  },
  je: {
    areaCodes: ['44-1534'],
    countryName: 'Jersey',
    emojiFlag: '🇯🇪',
  },
  jm: {
    areaCodes: ['1-876'],
    countryName: 'Jamaica',
    emojiFlag: '🇯🇲',
  },
  jo: {
    areaCodes: ['962'],
    countryName: 'Jordan',
    emojiFlag: '🇯🇴',
  },
  jp: {
    areaCodes: ['81'],
    countryName: 'Japan',
    emojiFlag: '🇯🇵',
  },
  ke: {
    areaCodes: ['254'],
    countryName: 'Kenya',
    emojiFlag: '🇰🇪',
  },
  kg: {
    areaCodes: ['996'],
    countryName: 'Kyrgyzstan',
    emojiFlag: '🇰🇬',
  },
  kh: {
    areaCodes: ['855'],
    countryName: 'Cambodia',
    emojiFlag: '🇰🇭',
  },
  ki: {
    areaCodes: ['686'],
    countryName: 'Kiribati',
    emojiFlag: '🇰🇮',
  },
  km: {
    areaCodes: ['269'],
    countryName: 'Comoros',
    emojiFlag: '🇰🇲',
  },
  kn: {
    areaCodes: ['1-869'],
    countryName: 'Saint Kitts and Nevis',
    emojiFlag: '🇰🇳',
  },
  kp: {
    areaCodes: ['850'],
    countryName: 'North Korea',
    emojiFlag: '🇰🇵',
  },
  kr: {
    areaCodes: ['82'],
    countryName: 'South Korea',
    emojiFlag: '🇰🇷',
  },
  kw: {
    areaCodes: ['965'],
    countryName: 'Kuwait',
    emojiFlag: '🇰🇼',
  },
  ky: {
    areaCodes: ['1-345'],
    countryName: 'Cayman Islands',
    emojiFlag: '🇰🇾',
  },
  kz: {
    areaCodes: ['7'],
    countryName: 'Kazakhstan',
    emojiFlag: '🇰🇿',
  },
  la: {
    areaCodes: ['856'],
    countryName: 'Laos',
    emojiFlag: '🇱🇦',
  },
  lb: {
    areaCodes: ['961'],
    countryName: 'Lebanon',
    emojiFlag: '🇱🇧',
  },
  lc: {
    areaCodes: ['1-758'],
    countryName: 'Saint Lucia',
    emojiFlag: '🇱🇨',
  },
  li: {
    areaCodes: ['423'],
    countryName: 'Liechtenstein',
    emojiFlag: '🇱🇮',
  },
  lk: {
    areaCodes: ['94'],
    countryName: 'Sri Lanka',
    emojiFlag: '🇱🇰',
  },
  lr: {
    areaCodes: ['231'],
    countryName: 'Liberia',
    emojiFlag: '🇱🇷',
  },
  ls: {
    areaCodes: ['266'],
    countryName: 'Lesotho',
    emojiFlag: '🇱🇸',
  },
  lt: {
    areaCodes: ['370'],
    countryName: 'Lithuania',
    emojiFlag: '🇱🇹',
  },
  lu: {
    areaCodes: ['352'],
    countryName: 'Luxembourg',
    emojiFlag: '🇱🇺',
  },
  lv: {
    areaCodes: ['371'],
    countryName: 'Latvia',
    emojiFlag: '🇱🇻',
  },
  ly: {
    areaCodes: ['218'],
    countryName: 'Libya',
    emojiFlag: '🇱🇾',
  },
  ma: {
    areaCodes: ['212'],
    countryName: 'Morocco',
    emojiFlag: '🇲🇦',
  },
  mc: {
    areaCodes: ['377'],
    countryName: 'Monaco',
    emojiFlag: '🇲🇨',
  },
  md: {
    areaCodes: ['373'],
    countryName: 'Moldova',
    emojiFlag: '🇲🇩',
  },
  me: {
    areaCodes: ['382'],
    countryName: 'Montenegro',
    emojiFlag: '🇲🇪',
  },
  mf: {
    areaCodes: ['590'],
    countryName: 'Saint Martin',
    emojiFlag: '🇲🇫',
  },
  mg: {
    areaCodes: ['261'],
    countryName: 'Madagascar',
    emojiFlag: '🇲🇬',
  },
  mh: {
    areaCodes: ['692'],
    countryName: 'Marshall Islands',
    emojiFlag: '🇲🇭',
  },
  mk: {
    areaCodes: ['389'],
    countryName: 'Macedonia',
    emojiFlag: '🇲🇰',
  },
  ml: {
    areaCodes: ['223'],
    countryName: 'Mali',
    emojiFlag: '🇲🇱',
  },
  mm: {
    areaCodes: ['95'],
    countryName: 'Myanmar',
    emojiFlag: '🇲🇲',
  },
  mn: {
    areaCodes: ['976'],
    countryName: 'Mongolia',
    emojiFlag: '🇲🇳',
  },
  mo: {
    areaCodes: ['853'],
    countryName: 'Macau',
    emojiFlag: '🇲🇴',
  },
  mp: {
    areaCodes: ['1-670'],
    countryName: 'Northern Mariana Islands',
    emojiFlag: '🇲🇵',
  },
  mr: {
    areaCodes: ['222'],
    countryName: 'Mauritania',
    emojiFlag: '🇲🇷',
  },
  ms: {
    areaCodes: ['1-664'],
    countryName: 'Montserrat',
    emojiFlag: '🇲🇸',
  },
  mt: {
    areaCodes: ['356'],
    countryName: 'Malta',
    emojiFlag: '🇲🇹',
  },
  mu: {
    areaCodes: ['230'],
    countryName: 'Mauritius',
    emojiFlag: '🇲🇺',
  },
  mv: {
    areaCodes: ['960'],
    countryName: 'Maldives',
    emojiFlag: '🇲🇻',
  },
  mw: {
    areaCodes: ['265'],
    countryName: 'Malawi',
    emojiFlag: '🇲🇼',
  },
  mx: {
    areaCodes: ['52'],
    countryName: 'Mexico',
    emojiFlag: '🇲🇽',
  },
  my: {
    areaCodes: ['60'],
    countryName: 'Malaysia',
    emojiFlag: '🇲🇾',
  },
  mz: {
    areaCodes: ['258'],
    countryName: 'Mozambique',
    emojiFlag: '🇲🇿',
  },
  na: {
    areaCodes: ['264'],
    countryName: 'Namibia',
    emojiFlag: '🇳🇦',
  },
  nc: {
    areaCodes: ['687'],
    countryName: 'New Caledonia',
    emojiFlag: '🇳🇨',
  },
  ne: {
    areaCodes: ['227'],
    countryName: 'Niger',
    emojiFlag: '🇳🇪',
  },
  ng: {
    areaCodes: ['234'],
    countryName: 'Nigeria',
    emojiFlag: '🇳🇬',
  },
  ni: {
    areaCodes: ['505'],
    countryName: 'Nicaragua',
    emojiFlag: '🇳🇮',
  },
  nl: {
    areaCodes: ['31'],
    countryName: 'Netherlands',
    emojiFlag: '🇳🇱',
  },
  no: {
    areaCodes: ['47'],
    countryName: 'Norway',
    emojiFlag: '🇳🇴',
  },
  np: {
    areaCodes: ['977'],
    countryName: 'Nepal',
    emojiFlag: '🇳🇵',
  },
  nr: {
    areaCodes: ['674'],
    countryName: 'Nauru',
    emojiFlag: '🇳🇷',
  },
  nu: {
    areaCodes: ['683'],
    countryName: 'Niue',
    emojiFlag: '🇳🇺',
  },
  nz: {
    areaCodes: ['64'],
    countryName: 'New Zealand',
    emojiFlag: '🇳🇿',
  },
  om: {
    areaCodes: ['968'],
    countryName: 'Oman',
    emojiFlag: '🇴🇲',
  },
  pa: {
    areaCodes: ['507'],
    countryName: 'Panama',
    emojiFlag: '🇵🇦',
  },
  pe: {
    areaCodes: ['51'],
    countryName: 'Peru',
    emojiFlag: '🇵🇪',
  },
  pf: {
    areaCodes: ['689'],
    countryName: 'French Polynesia',
    emojiFlag: '🇵🇫',
  },
  pg: {
    areaCodes: ['675'],
    countryName: 'Papua New Guinea',
    emojiFlag: '🇵🇬',
  },
  ph: {
    areaCodes: ['63'],
    countryName: 'Philippines',
    emojiFlag: '🇵🇭',
  },
  pk: {
    areaCodes: ['92'],
    countryName: 'Pakistan',
    emojiFlag: '🇵🇰',
  },
  pl: {
    areaCodes: ['48'],
    countryName: 'Poland',
    emojiFlag: '🇵🇱',
  },
  pm: {
    areaCodes: ['508'],
    countryName: 'Saint Pierre and Miquelon',
    emojiFlag: '🇵🇲',
  },
  pn: {
    areaCodes: ['64'],
    countryName: 'Pitcairn',
    emojiFlag: '🇵🇳',
  },
  pr: {
    areaCodes: ['1-787', '1-939'],
    countryName: 'Puerto Rico',
    emojiFlag: '🇵🇷',
  },
  ps: {
    areaCodes: ['970'],
    countryName: 'Palestine',
    emojiFlag: '🇵🇸',
  },
  pt: {
    areaCodes: ['351'],
    countryName: 'Portugal',
    emojiFlag: '🇵🇹',
  },
  pw: {
    areaCodes: ['680'],
    countryName: 'Palau',
    emojiFlag: '🇵🇼',
  },
  py: {
    areaCodes: ['595'],
    countryName: 'Paraguay',
    emojiFlag: '🇵🇾',
  },
  qa: {
    areaCodes: ['974'],
    countryName: 'Qatar',
    emojiFlag: '🇶🇦',
  },
  re: {
    areaCodes: ['262'],
    countryName: 'Reunion',
    emojiFlag: '🇷🇪',
  },
  ro: {
    areaCodes: ['40'],
    countryName: 'Romania',
    emojiFlag: '🇷🇴',
  },
  rs: {
    areaCodes: ['381'],
    countryName: 'Serbia',
    emojiFlag: '🇷🇸',
  },
  ru: {
    areaCodes: ['7'],
    countryName: 'Russia',
    emojiFlag: '🇷🇺',
  },
  rw: {
    areaCodes: ['250'],
    countryName: 'Rwanda',
    emojiFlag: '🇷🇼',
  },
  sa: {
    areaCodes: ['966'],
    countryName: 'Saudi Arabia',
    emojiFlag: '🇸🇦',
  },
  sb: {
    areaCodes: ['677'],
    countryName: 'Solomon Islands',
    emojiFlag: '🇸🇧',
  },
  sc: {
    areaCodes: ['248'],
    countryName: 'Seychelles',
    emojiFlag: '🇸🇨',
  },
  sd: {
    areaCodes: ['249'],
    countryName: 'Sudan',
    emojiFlag: '🇸🇩',
  },
  se: {
    areaCodes: ['46'],
    countryName: 'Sweden',
    emojiFlag: '🇸🇪',
  },
  sg: {
    areaCodes: ['65'],
    countryName: 'Singapore',
    emojiFlag: '🇸🇬',
  },
  sh: {
    areaCodes: ['290'],
    countryName: 'Saint Helena',
    emojiFlag: '🇸🇭',
  },
  si: {
    areaCodes: ['386'],
    countryName: 'Slovenia',
    emojiFlag: '🇸🇮',
  },
  sj: {
    areaCodes: ['47'],
    countryName: 'Svalbard and Jan Mayen',
    emojiFlag: '🇸🇯',
  },
  sk: {
    areaCodes: ['421'],
    countryName: 'Slovakia',
    emojiFlag: '🇸🇰',
  },
  sl: {
    areaCodes: ['232'],
    countryName: 'Sierra Leone',
    emojiFlag: '🇸🇱',
  },
  sm: {
    areaCodes: ['378'],
    countryName: 'San Marino',
    emojiFlag: '🇸🇲',
  },
  sn: {
    areaCodes: ['221'],
    countryName: 'Senegal',
    emojiFlag: '🇸🇳',
  },
  so: {
    areaCodes: ['252'],
    countryName: 'Somalia',
    emojiFlag: '🇸🇴',
  },
  sr: {
    areaCodes: ['597'],
    countryName: 'Suriname',
    emojiFlag: '🇸🇷',
  },
  ss: {
    areaCodes: ['211'],
    countryName: 'South Sudan',
    emojiFlag: '🇸🇸',
  },
  st: {
    areaCodes: ['239'],
    countryName: 'Sao Tome and Principe',
    emojiFlag: '🇸🇹',
  },
  sv: {
    areaCodes: ['503'],
    countryName: 'El Salvador',
    emojiFlag: '🇸🇻',
  },
  sx: {
    areaCodes: ['1-721'],
    countryName: 'Sint Maarten',
    emojiFlag: '🇸🇽',
  },
  sy: {
    areaCodes: ['963'],
    countryName: 'Syria',
    emojiFlag: '🇸🇾',
  },
  sz: {
    areaCodes: ['268'],
    countryName: 'Swaziland',
    emojiFlag: '🇸🇿',
  },
  tc: {
    areaCodes: ['1-649'],
    countryName: 'Turks and Caicos Islands',
    emojiFlag: '🇹🇨',
  },
  td: {
    areaCodes: ['235'],
    countryName: 'Chad',
    emojiFlag: '🇹🇩',
  },
  tg: {
    areaCodes: ['228'],
    countryName: 'Togo',
    emojiFlag: '🇹🇬',
  },
  th: {
    areaCodes: ['66'],
    countryName: 'Thailand',
    emojiFlag: '🇹🇭',
  },
  tj: {
    areaCodes: ['992'],
    countryName: 'Tajikistan',
    emojiFlag: '🇹🇯',
  },
  tk: {
    areaCodes: ['690'],
    countryName: 'Tokelau',
    emojiFlag: '🇹🇰',
  },
  tl: {
    areaCodes: ['670'],
    countryName: 'East Timor',
    emojiFlag: '🇹🇱',
  },
  tm: {
    areaCodes: ['993'],
    countryName: 'Turkmenistan',
    emojiFlag: '🇹🇲',
  },
  tn: {
    areaCodes: ['216'],
    countryName: 'Tunisia',
    emojiFlag: '🇹🇳',
  },
  to: {
    areaCodes: ['676'],
    countryName: 'Tonga',
    emojiFlag: '🇹🇴',
  },
  tr: {
    areaCodes: ['90'],
    countryName: 'Turkey',
    emojiFlag: '🇹🇷',
  },
  tt: {
    areaCodes: ['1-868'],
    countryName: 'Trinidad and Tobago',
    emojiFlag: '🇹🇹',
  },
  tv: {
    areaCodes: ['688'],
    countryName: 'Tuvalu',
    emojiFlag: '🇹🇻',
  },
  tw: {
    areaCodes: ['886'],
    countryName: 'Taiwan',
    emojiFlag: '🇹🇼',
  },
  tz: {
    areaCodes: ['255'],
    countryName: 'Tanzania',
    emojiFlag: '🇹🇿',
  },
  ua: {
    areaCodes: ['380'],
    countryName: 'Ukraine',
    emojiFlag: '🇺🇦',
  },
  ug: {
    areaCodes: ['256'],
    countryName: 'Uganda',
    emojiFlag: '🇺🇬',
  },
  uk: {
    areaCodes: ['44'],
    countryName: 'United Kingdom',
    emojiFlag: '🇬🇧',
  },
  us: {
    areaCodes: ['1'],
    countryName: 'United States',
    emojiFlag: '🇺🇸',
  },
  uy: {
    areaCodes: ['598'],
    countryName: 'Uruguay',
    emojiFlag: '🇺🇾',
  },
  uz: {
    areaCodes: ['998'],
    countryName: 'Uzbekistan',
    emojiFlag: '🇺🇿',
  },
  va: {
    areaCodes: ['379'],
    countryName: 'Vatican',
    emojiFlag: '🇻🇦',
  },
  vc: {
    areaCodes: ['1-784'],
    countryName: 'Saint Vincent and the Grenadines',
    emojiFlag: '🇻🇨',
  },
  ve: {
    areaCodes: ['58'],
    countryName: 'Venezuela',
    emojiFlag: '🇻🇪',
  },
  vg: {
    areaCodes: ['1-284'],
    countryName: 'British Virgin Islands',
    emojiFlag: '🇻🇬',
  },
  vi: {
    areaCodes: ['1-340'],
    countryName: 'U.S. Virgin Islands',
    emojiFlag: '🇻🇮',
  },
  vn: {
    areaCodes: ['84'],
    countryName: 'Vietnam',
    emojiFlag: '🇻🇳',
  },
  vu: {
    areaCodes: ['678'],
    countryName: 'Vanuatu',
    emojiFlag: '🇻🇺',
  },
  wf: {
    areaCodes: ['681'],
    countryName: 'Wallis and Futuna',
    emojiFlag: '🇼🇫',
  },
  ws: {
    areaCodes: ['685'],
    countryName: 'Samoa',
    emojiFlag: '🇼🇸',
  },
  xk: {
    areaCodes: ['383'],
    countryName: 'Kosovo',
    emojiFlag: '🇽🇰',
  },
  ye: {
    areaCodes: ['967'],
    countryName: 'Yemen',
    emojiFlag: '🇾🇪',
  },
  yt: {
    areaCodes: ['262'],
    countryName: 'Mayotte',
    emojiFlag: '🇾🇹',
  },
  za: {
    areaCodes: ['27'],
    countryName: 'South Africa',
    emojiFlag: '🇿🇦',
  },
  zm: {
    areaCodes: ['260'],
    countryName: 'Zambia',
    emojiFlag: '🇿🇲',
  },
  zw: {
    areaCodes: ['263'],
    countryName: 'Zimbabwe',
    emojiFlag: '🇿🇼',
  },
};

export default areaCodes;
