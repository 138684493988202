'use client';

import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { cssMerge } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';
import React from 'react';
import { useCarouselDictionaries } from '../hooks/useCarouselDictionaries';

const CarouselCTALink = ({ className }: { className: string }) => {
  const { ctaText } = useCarouselDictionaries();
  const { siteSlug } = useCurrentMarketSite();

  return typeof ctaText === 'string' && ctaText.length > 0 ? (
    <Track
      eventAction="link|click"
      eventLabel={`compare-models|${`/${siteSlug}/cars/compare-cars/`}`}
    >
      <a
        className={cssMerge('button-text', className)}
        href={`/${siteSlug}/cars/compare-cars/`}
      >
        {ctaText}
      </a>
    </Track>
  ) : null;
};

export default CarouselCTALink;
