import { Field } from '@sitecore-jss/sitecore-jss';
import { RouteData } from '@vcc-www/api/sitecore9/SitecoreTypes';
import { getFieldValues } from '@vcc-www/api/sitecore9/layoutServiceAdapter';
import { ImageField } from '@vcc-www/content-management-jss-client';
import CarModel from '@vcc-www/content-types/CarModel.props';
import React, { useState, createContext, useContext, useCallback } from 'react';

type SitecoreRouteFields = {
  [name: string]: Field;
};

export type RouteFields = Record<
  string,
  string | boolean | number | undefined | object
> & {
  carModel?: CarModel;
  carCategorySlug?: string;
  canonicalUrl?: string;
  pageTitle?: string;
  metaDescription?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: ImageField;
  excludeFromSearchEngine?: boolean;
  enableRevealAnimations?: boolean;
};

const RouteFieldsContext = createContext<{
  routeFields: RouteFields;
  setRouteFields(fields: SitecoreRouteFields | undefined): void;
}>({ routeFields: {}, setRouteFields() {} });

export const RouteFieldsProvider: React.FC<
  React.PropsWithChildren<{
    initialRouteFields?: RouteFields | RouteData['fields'];
  }>
> = ({ children, initialRouteFields = {} }) => {
  const [fields, setRouteFields] = useState<RouteFields>(
    getFieldValues(initialRouteFields as any) as RouteFields,
  );
  const setFields = useCallback((fields: RouteData['fields']) => {
    if (fields) {
      setRouteFields(getFieldValues(fields) as RouteFields);
    }
  }, []);
  return (
    <RouteFieldsContext.Provider
      value={{
        routeFields: fields,
        setRouteFields: setFields,
      }}
    >
      {children}
    </RouteFieldsContext.Provider>
  );
};

export function useUpdateRouteFields() {
  return useContext(RouteFieldsContext).setRouteFields;
}

/**
 * Get all fields defined on the page itself.
 */
export function useRouteFields<T extends RouteFields>(): T {
  return useContext(RouteFieldsContext).routeFields as T;
}
