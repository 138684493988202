import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import type { Sources } from '@vcc-www/utils/getMediaSources';
import React from 'react';
import OptimizedVideo from './OptimizedVideo';
import type { Props as CoreProps } from './VideoCore';
import { IconVariant, LoadingType } from './VideoCore';
import VideoEditorial from './VideoEditorial';
import type VideoProps from './VideoProps';
export type Interaction = 'play' | 'pause' | '';
export type VideoRef = React.MutableRefObject<HTMLVideoElement | null>;
type Props = {
  srcWebM?: string | Sources;
  srcMp4?: string | Sources;
} & Pick<
  CoreProps,
  | 'videoDescription'
  | 'poster'
  | 'shouldAutoplay'
  | 'loop'
  | 'lazyLoadPoster'
  | 'extend'
  | 'aspectRatio'
  | 'subtitleTrack'
  | 'controls'
  | 'largePlayIcon'
  | 'centeredPlayIcon'
  | 'trackLabel'
  | 'ga3TrackLabel'
  | 'fullscreenFit'
  | 'muted'
  | 'onLoadedMetadata'
  | 'forceLoad'
  | 'trackEventAction'
  | 'iconVariant'
  | 'loading'
  | 'customizePlayIconSIze'
  | 'playPauseButtonTabIndex'
  | 'fetchPriority'
  | 'resetVideo'
  | 'preload'
  | 'hideControlsOnPause'
  | 'playPauseZIndex'
>;

export type ExtraProps = {
  withContainer?: boolean;
  'data-autoid'?: string;
  'data-testid'?: string;
  onTimeUpdate?: (currentVideoTime: number) => void;
  onPlay?: () => void;
  onEnded?: () => void;
  currentTime?: HTMLMediaElement['currentTime'];
  forceLoad?: boolean;
  trackLabel?: string;
  ga3TrackLabel?: string;
  trackEventAction?: string;
  iconVariant?: IconVariant;
  aspectRatio?: AspectRatio;
  loading?: LoadingType;
  offsetYVideoControl?: number;
  offsetXVideoControl?: number;
  withBlackVideoIcons?: boolean;
  setVideoReload?: number;
  fetchPriority?: 'high' | 'low' | 'auto';
  videoCtaAutoId?: string;
  shouldPause?: boolean;
  disableScrollLogicOnDirty?: boolean;
  forcePlayOnPosterClick?: boolean;
} & Pick<React.VideoHTMLAttributes<HTMLVideoElement>, 'onLoadedMetadata'>;

export type VideoComponentProps = (Props | VideoProps) & ExtraProps;
export type VideoContentType = VideoProps['content'];

const Video: React.FC<React.PropsWithChildren<VideoComponentProps>> = (
  props,
) => {
  if ('content' in props) {
    return <VideoEditorial {...props} />;
  }
  return <OptimizedVideo {...props} />;
};

export default Video;
