'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { TitleFieldProps } from '@vcc-package/leads-utils/types';
import { Checkbox, ErrorMessage } from '@volvo-cars/react-forms';
import { Controller, useFormContext } from 'react-hook-form';

type ContactChannelsFieldProps = Omit<TitleFieldProps, 'customTranslation'>;

export const ContactChannelsField = ({
  translate,
  options,
  hideRequired,
  validation,
}: ContactChannelsFieldProps) => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const header = translate(TranslationKey.CB_CONTACT_CHANNELS_FIELD);
  const errorMessage = translate(TranslationKey.CB_CHANNELS_REQUIRED_MESSAGE);
  return (
    <div className="w-full pb-16 gap-16 flex flex-col">
      <p className="text-secondary">
        {header}{' '}
        {validation.contactChannels.required && !hideRequired ? '*' : ''}
      </p>
      <Controller
        control={control}
        defaultValue={[]}
        name={FlexFormFieldType.CONTACT_CHANNELS}
        rules={{
          validate: (data: Array<string>) => {
            return data.length > 0
              ? true
              : messages?.required
                ? messages.required(errorMessage)
                : false;
          },
        }}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error },
        }) => {
          const newValues: string[] = [...value];

          return (
            <div className="w-full flex gap-24">
              {options?.ContactChannels?.map((option, index) => {
                return (
                  <div key={`block_${index}`}>
                    <Checkbox
                      name={FlexFormFieldType.CONTACT_CHANNELS}
                      aria-invalid={!!error}
                      label={option.label}
                      checked={newValues.includes(option.value)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          newValues.push(option.value);
                        } else {
                          newValues.splice(newValues.indexOf(option.value), 1);
                        }
                        onChange(newValues);
                        onBlur();
                      }}
                    />
                  </div>
                );
              })}
              {error?.message && (
                <ErrorMessage
                  errorMessage={error.root?.message}
                  id={FlexFormFieldType.CONTACT_CHANNELS + '-error'}
                />
              )}
            </div>
          );
        }}
      />
    </div>
  );
};
