import { ResponsiveImage, ResponsiveImageProps } from '@vcc-package/media';
import React, { HTMLAttributes } from 'react';

const Image = ({
  responsiveImages,
  ...rest
}: {
  responsiveImages: ResponsiveImageProps['images'];
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div slot="media" {...rest}>
      <ResponsiveImage
        className="w-full object-cover h-full"
        images={responsiveImages}
        alt={responsiveImages.sm.alt}
      />
    </div>
  );
};

export default Image;
