export const NON_BREAKING_SPACE = '\u00A0'; // https://en.wikipedia.org/wiki/Non-breaking_space

const isBrowserTest =
  typeof window !== 'undefined' &&
  ['cbv-learn.test-emea.ccdp-origin.io', 'testwww.volvocars.com'].includes(
    window.location.hostname,
  );

const isBrowserQA =
  typeof window !== 'undefined' &&
  (['cbv-learn.qa-emea.ccdp-origin.io', 'qawww.volvocars.com'].includes(
    window.location.hostname,
  ) ||
    /pr-\d+\.westeurope\.cloudapp\.azure\.com/.test(window.location.hostname));

export const NEXT_PUBLIC_HOST =
  process.env.NEXT_PUBLIC_HOST ||
  (isBrowserTest
    ? 'https://testwww.volvocars.com'
    : isBrowserQA
      ? 'https://qawww.volvocars.com'
      : 'https://www.volvocars.com');
