import { PopUpDisclaimer } from '@vcc-package/overlays';
import { applyJsxTokenTemplate } from '@vcc-www/utils/stringTemplate';
import React from 'react';
import { PriceFirstLine } from '../components/car-price.view';
import type { GetCarPriceElementProps } from '../type/car-price.types';

export const getCarPriceElement = (props: GetCarPriceElementProps) => {
  const { label, hasMonthly, monthlyPriceDisclaimer, msrpDisclaimer } = props;
  const labelLines = label?.split('\n') ?? [];
  const monthlyPriceLine = hasMonthly
    ? replaceTextWithJSXForUS({
        ...props,
        label: labelLines?.[0],
      }).filter(Boolean)
    : [];

  const msrpLine = replaceTextWithJSXForUS({
    ...props,
    label: labelLines?.[1],
  }).filter(Boolean);

  return (
    <>
      <div>
        {hasMonthly && <span>{monthlyPriceLine}</span>}
        {hasMonthly && (
          <PopUpDisclaimer
            description={
              monthlyPriceDisclaimer ? monthlyPriceDisclaimer.join('\n\n') : ''
            }
            addMarginInlineStart
            trackLabel="open PopUpDisclaimer"
          >
            {(icon) => <span>{icon}</span>}
          </PopUpDisclaimer>
        )}
      </div>
      <div>
        <span>{msrpLine}</span>
        <PopUpDisclaimer
          description={msrpDisclaimer ? msrpDisclaimer : ''}
          addMarginInlineStart
          trackLabel="open PopUpDisclaimer"
        >
          {(icon) => <span>{icon}</span>}
        </PopUpDisclaimer>
      </div>
    </>
  );
};

export const replaceTextWithJSXForUS = ({
  label,
  hasMonthly,
  monthlyPrice,
  hasPurchase,
  purchasePrice,
}: GetCarPriceElementProps) => {
  if (!label) return [];

  return applyJsxTokenTemplate(label, {
    purchasePrice: () => {
      return hasPurchase ? (
        <PriceFirstLine key="purchase-price" price={purchasePrice} />
      ) : (
        ''
      );
    },
    monthlyPrice: () => {
      return hasMonthly ? (
        <PriceFirstLine key="monthly-price" price={monthlyPrice} />
      ) : (
        ''
      );
    },
  })?.filter(Boolean);
};
