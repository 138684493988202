'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import {
  getInputErrorMessages,
  useValidation,
} from '@vcc-package/leads-utils/hooks';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { NonApiFieldName } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import {
  CheckboxField,
  TextField,
  ValidatedAddressSearch,
  ValidatedField,
} from '../components';
import { RowContainer } from '../rowContainer';

export const CombinedAddressField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps) => {
  const { control, watch } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const { regexValidate: validateCity } = useValidation(validation?.city);
  const { regexValidate: validatePostalCode } = useValidation(
    validation?.postalcode,
  );

  const shouldUseManualAddress = watch(NonApiFieldName.ManualAddress);

  const addressAsyncValidation = !shouldUseManualAddress
    ? { ...validation[FlexFormFieldType.LEGAL_ADDRESS_ASYNC] }
    : undefined;
  const postalCodeValidation = shouldUseManualAddress
    ? { ...validation[FlexFormFieldType.POSTAL_CODE] }
    : undefined;
  const cityValidation = shouldUseManualAddress
    ? { ...validation[FlexFormFieldType.CITY] }
    : undefined;
  const addressValidation = shouldUseManualAddress
    ? { ...validation[FlexFormFieldType.ADDRESS_ONE] }
    : undefined;

  const labels = {
    legalAddress: getTranslationKey(
      customTranslation,
      FlexFormFieldType.ADDRESS_ASYNC_WITH_MANUAL,
      FlexFormFieldType.LEGAL_ADDRESS.toLowerCase(),
    ),
    address: getTranslationKey(
      customTranslation,
      FlexFormFieldType.ADDRESS_ASYNC_WITH_MANUAL,
      FlexFormFieldType.ADDRESS_ONE.toLowerCase(),
    ),
    postalCode: getTranslationKey(
      customTranslation,
      FlexFormFieldType.ADDRESS_ASYNC_WITH_MANUAL,
      FlexFormFieldType.POSTAL_CODE.toLowerCase(),
    ),
    city: getTranslationKey(
      customTranslation,
      FlexFormFieldType.ADDRESS_ASYNC_WITH_MANUAL,
      FlexFormFieldType.CITY.toLowerCase(),
    ),
    manualAddressCheckbox: getTranslationKey(
      customTranslation,
      FlexFormFieldType.ADDRESS_ASYNC_WITH_MANUAL,
      NonApiFieldName.ManualAddress,
    ),
  };

  return (
    <div className="flex flex-col gap-16 w-full">
      <CheckboxField
        hideRequired={hideRequired}
        name="manualaddress"
        control={control}
        label={
          labels.manualAddressCheckbox
            ? translate(labels.manualAddressCheckbox.label)
            : translate(TranslationKey.MANUAL_ADDRESS_CHECKBOX_LABEL)
        }
        required={false}
        key="manualaddress"
        description={translate(
          TranslationKey.MANUAL_ADDRESS_CHECKBOX_DESCRIPTION,
        )}
      />

      {!shouldUseManualAddress && (
        <ValidatedAddressSearch
          hideRequired={hideRequired}
          key="legaladdress"
          name={FlexFormFieldType.LEGAL_ADDRESS}
          label={
            labels.legalAddress?.label
              ? translate(labels.legalAddress.label)
              : translate(TranslationKey.ADDRESS_FIELD_LABEL)
          }
          control={control}
          messages={messages}
          maxLength={250}
          waitOnInput={300}
          type={validation.addressasync?.type}
          {...addressAsyncValidation}
        />
      )}
      {shouldUseManualAddress && (
        <div className="w-full flex flex-col gap-16">
          <TextField
            hideRequired={hideRequired}
            key="address"
            name={FlexFormFieldType.ADDRESS_ONE}
            label={
              labels.address
                ? translate(labels.address.label)
                : translate(TranslationKey.ADDRESS_ONE_FIELD_LABEL)
            }
            messages={messages}
            control={control}
            {...addressValidation}
          />
          <RowContainer>
            <ValidatedField
              hideRequired={hideRequired}
              key="postal-code"
              name={FlexFormFieldType.POSTAL_CODE}
              label={
                labels.postalCode
                  ? translate(labels.postalCode.label)
                  : translate(TranslationKey.POSTAL_CODE_FIELD_LABEL)
              }
              inputType="Text"
              autoComplete="postal-code"
              validate={validatePostalCode}
              messages={messages}
              control={control}
              {...postalCodeValidation}
            />
            <ValidatedField
              hideRequired={hideRequired}
              key="city"
              inputType="Text"
              name={FlexFormFieldType.CITY}
              label={
                labels.city?.label
                  ? translate(labels.city.label)
                  : translate(TranslationKey.CITY_FIELD_LABEL)
              }
              validate={validateCity}
              messages={messages}
              control={control}
              {...cityValidation}
            />
          </RowContainer>
        </div>
      )}
    </div>
  );
};
