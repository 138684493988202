'use client';
import { cssMerge } from '@volvo-cars/css/utils';
import { ErrorMessage } from '@volvo-cars/react-forms';
import React, { forwardRef, RefObject, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import { DealerModel } from '../../../api';
import { RetailerItem } from '../components/inputs/retailer-picker';
import { RetailerPickerLabels } from '../components/inputs/retailer-picker/types';
import styles from './AllRetailersPickerStyle.module.css';

type SimplifiedRetailerListProps = {
  dealerData: DealerModel[];
  invalid: boolean;
  error: FieldError | undefined;
  onChange: (...event: any[]) => void;
  retailerPickerChangeLabel: RetailerPickerLabels['change'];
  selectedRetailerState: DealerModel | null;
  selectRetailer: (dealer: DealerModel | null) => void;
};

export const SimplifiedRetailerList = forwardRef<
  HTMLDivElement,
  SimplifiedRetailerListProps
>(
  (
    {
      dealerData,
      invalid,
      error,
      onChange,
      retailerPickerChangeLabel,
      selectedRetailerState,
      selectRetailer,
    },
    ref,
  ) => {
    const listRef = useRef<HTMLDivElement | null>(null);

    const scrollRetailerIntoView = (ref: RefObject<HTMLDivElement>) => {
      if (!ref?.current) return;

      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    };

    return (
      <div
        className="flex flex-col"
        ref={listRef}
        style={{ scrollMarginTop: '140px' }}
      >
        <div
          className={
            invalid
              ? cssMerge('flex flex-col', styles.invalidRetailerField)
              : 'flex flex-col'
          }
          ref={ref}
          tabIndex={-1}
        >
          {selectedRetailerState ? (
            <>
              <RetailerItem
                id={selectedRetailerState.dealerId}
                key={selectedRetailerState.dealerId}
                item={selectedRetailerState}
                onClick={() => {
                  if (dealerData.length > 1) {
                    selectRetailer(null);
                    onChange(null);
                  }
                }}
                selected
                disabled={dealerData.length === 1}
              />
              {dealerData.length > 1 ? (
                <button
                  type="button"
                  className="button-text text-accent-black w-fit"
                  data-arrow="none"
                  data-color="black"
                  data-testid="change-retailer"
                  onClick={() => {
                    selectRetailer(null);
                    onChange(null);
                  }}
                >
                  {retailerPickerChangeLabel}
                </button>
              ) : null}
            </>
          ) : (
            <div className="flex flex-col gap-16">
              {dealerData.map((item, i) => (
                <RetailerItem
                  item={item}
                  key={item.dealerId + i.toString()}
                  onClick={(dealer) => {
                    selectRetailer(dealer);
                    scrollRetailerIntoView(listRef);
                    onChange(dealer.dealerId);
                  }}
                  id={item.dealerId}
                />
              ))}
            </div>
          )}
        </div>
        {error ? (
          <ErrorMessage
            errorMessage={error.message}
            id="retailer-field-error"
          />
        ) : null}
      </div>
    );
  },
);

SimplifiedRetailerList.displayName = 'SimplifiedRetailerList';
