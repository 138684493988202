import { PurposeType, TranslationKey } from '../../../api';
import type { LeadsContextProperties } from '../../../src/context';
import type { ABTest } from '../types';

export const showDynamicTitle: ABTest = async (
  context: LeadsContextProperties,
) => {
  if (context.features.useDynamicTitle && context.formConfig) {
    console.log('Running experiment: showDynamicTitle');

    switch (context.purpose) {
      case PurposeType.KEEP_ME_UPDATED:
        context.formConfig.header = {
          heading: TranslationKey.KMU_TITLE,
          subHeading: TranslationKey.KMUSUB_HEADING,
        };
        break;
      case PurposeType.CONTACT_REQUEST:
        context.formConfig.header.heading = TranslationKey.CR_TITLE;
        break;
      case PurposeType.CBV_CALLBACK:
        context.formConfig.header.heading = TranslationKey.CB_TITLE;
        break;
      case PurposeType.OFFER_REQUEST:
        context.formConfig.header.heading = TranslationKey.QR_TITLE;
        break;
      default:
        break;
    }
  }

  return context;
};
