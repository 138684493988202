'use client';

import { CarPriceProvider } from '@vcc-package/car-price';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import React from 'react';

export const CarPriceProviderWithSiteSlug = ({
  children,
}: React.PropsWithChildren) => {
  const { siteSlug } = useCurrentMarketSite();
  const { isAuthoringUrl } = useVolvoCarsUrl();

  if (!siteSlug) {
    throw new Error('siteSlug is required');
  }

  return (
    <CarPriceProvider
      siteSlug={siteSlug}
      clientName="carousel"
      contentSource={isAuthoringUrl ? 'preview' : undefined}
    >
      {children}
    </CarPriceProvider>
  );
};
