import { PNO34_MAP } from '@vcc-package/leads-utils/constants';

/**
 * Takes a proposed model name & checks the value to obtain the formatted PNO34 candidates for the model (if available).
 * @returns string[] | null
 */
export const getPno34FromModelName = (name?: string): string[] | null => {
  if (!name) return null;

  // Clear any dash, space or hyphen to cover for any old modelkey configuration
  let cleared = name;

  try {
    cleared = decodeURIComponent(cleared);
  } catch (e) {
    console.info('Failed to decode model name', e);
  }

  cleared = cleared.replace(/[^A-Z0-9]/gi, '').toUpperCase();

  return findPno34(cleared);
};

const findPno34 = (modelString: string): string[] | null => {
  switch (modelString) {
    case 'C40':
    case 'C40BEV':
    case 'C40ELECTRIC':
      return PNO34_MAP.C40;

    case 'EC40':
    case 'EC40ELECTRIC':
      return PNO34_MAP.EC40;

    case 'EX30':
    case 'EX30BEV':
    case 'EX30ELECTRIC':
      return PNO34_MAP.EX30;

    case 'EX40':
      return PNO34_MAP.EX40;

    case 'EX90':
    case 'EX90BEV':
    case 'EX90ELECTRIC':
      return PNO34_MAP.EX90;

    case 'S60':
    case 'S60ICE':
      return PNO34_MAP.S60;

    case 'S60HYBRID':
    case 'S60PHEV':
      return PNO34_MAP.S60HYBRID;

    case 'S90':
    case 'S90ICE':
      return PNO34_MAP.S90;

    case 'S90HYBRID':
    case 'S90PHEV':
      return PNO34_MAP.S90HYBRID;

    case 'V60':
    case 'V60ICE':
      return PNO34_MAP.V60;

    case 'V60CC':
    case 'V60CROSSCOUNTRY':
      return PNO34_MAP.V60CC;

    case 'V60HYBRID':
    case 'V60PHEV':
      return PNO34_MAP.V60HYBRID;

    case 'V90':
    case 'V90ICE':
      return PNO34_MAP.V90;

    case 'V90CC':
    case 'V90CROSSCOUNTRY':
      return PNO34_MAP.V90CC;

    case 'V90HYBRID':
    case 'V90PHEV':
      return PNO34_MAP.V90HYBRID;

    case 'XC40':
    case 'XC40ICE':
      return PNO34_MAP.XC40;

    case 'XC40HYBRID':
    case 'XC40PHEV':
      return PNO34_MAP.XC40HYBRID;

    case 'EX40ELECTRIC':
    case 'XC40BEV':
    case 'XC40ELECTRIC':
    case 'XC40RECHARGE':
      return PNO34_MAP.XC40BEV;

    case 'XC60':
    case 'XC60ICE':
      return PNO34_MAP.XC60;

    case 'XC60HYBRID':
    case 'XC60PHEV':
      return PNO34_MAP.XC60HYBRID;

    case 'XC90':
    case 'XC90ICE':
      return PNO34_MAP.XC90;

    case 'XC90HYBRID':
    case 'XC90PHEV':
      return PNO34_MAP.XC90HYBRID;
    case 'ES90':
    case 'ES90ELECTRIC':
      return PNO34_MAP.ES90;

    default:
      return null;
  }
};
