import { isStorybookVisualRegression } from '@vcc-package/storybook-utils/constants';
import {
  AspectRatio,
  getAspectRatioCSS,
} from '@vcc-www/utils/getAspectRatioCSS';
import { ExtendCSS } from 'vcc-ui';
import { Breakpoints, LoadingStrategy, ObjectFit } from './Image.types';
import { JS_LAZY } from './constants';
const urlPrepend = 'http://localhost'; // only used to make new URL() work correctly in dev env and is removed again later.
const sizeRoundUpLevel = 100;
const QUERY_PARAM_WIDTH = 'w';
const QUERY_PARAM_HEIGHT = 'h';

type Dimensions = {
  width: number;
  height: number;
};

const hasNoURLSearchParams =
  typeof window !== 'undefined' && typeof window.URLSearchParams !== 'function';

const isDataOrBlobUrl = (src: string) =>
  src.startsWith('data:') || src.startsWith('blob:');

const getPixelRatio = () =>
  typeof window !== 'undefined' ? window.devicePixelRatio : 1;

const getUrlData = (src: string) => {
  const isFullUrl = src.startsWith('http');
  const fullUrl = isFullUrl ? src : `${urlPrepend}${src}`;
  return {
    url: new URL(fullUrl),
    isFullUrl,
  };
};

const getImageParamValue = (value: number) => {
  const pixelRatio = getPixelRatio();
  return Math.ceil((pixelRatio * value) / sizeRoundUpLevel) * sizeRoundUpLevel;
};

const getImageParam = (src: string, containerDimensions: Dimensions) => {
  const { url } = getUrlData(src);
  const imageWidth = Number(url.searchParams.get(QUERY_PARAM_WIDTH));
  const imageHeight = Number(url.searchParams.get(QUERY_PARAM_HEIGHT));
  const imageAR = imageWidth / imageHeight;
  const containerAR = containerDimensions.width / containerDimensions.height;
  const shouldUseWidthParam =
    !containerAR || (!imageAR ? containerAR > 1 : imageAR < containerAR);
  const direction = shouldUseWidthParam
    ? QUERY_PARAM_WIDTH
    : QUERY_PARAM_HEIGHT;
  const value = getImageParamValue(
    shouldUseWidthParam
      ? containerDimensions.width
      : containerDimensions.height,
  );

  return { direction, value };
};

export const getDefaultSrcSet = (src: string, imageDensity?: number) => `
  ${cleanSrcAndAddWidthParam(src, QUERY_PARAM_WIDTH, 480, imageDensity)} 480w,
  ${cleanSrcAndAddWidthParam(src, QUERY_PARAM_WIDTH, 720, imageDensity)} 720w,
  ${cleanSrcAndAddWidthParam(src, QUERY_PARAM_WIDTH, 1080, imageDensity)} 1080w,
  ${cleanSrcAndAddWidthParam(src, QUERY_PARAM_WIDTH, 1240, imageDensity)} 1240w,
  ${cleanSrcAndAddWidthParam(src, QUERY_PARAM_WIDTH, 1366, imageDensity)} 1366w,
  ${cleanSrcAndAddWidthParam(src, QUERY_PARAM_WIDTH, 1440, imageDensity)} 1440w,
  ${cleanSrcAndAddWidthParam(src, QUERY_PARAM_WIDTH, 1600, imageDensity)} 1600w,
  ${cleanSrcAndAddWidthParam(src, QUERY_PARAM_WIDTH, 1920, imageDensity)} 1920w,
`;

export const cleanSrcAndAddWidthParam = (
  src: string,
  paramKey: string,
  value: number,
  imageDensity?: number,
) => {
  if (isDataOrBlobUrl(src)) return src; // Makes image uploads work in Storybook

  const { url, isFullUrl } = getUrlData(src);
  url.searchParams.set(paramKey, value.toString());
  url.searchParams.delete(
    paramKey === QUERY_PARAM_WIDTH ? QUERY_PARAM_HEIGHT : QUERY_PARAM_WIDTH,
  );
  const urlWithResolution = isFullUrl
    ? url.toString()
    : url.toString().replace(urlPrepend, '');
  return `${urlWithResolution}${
    imageDensity ? `&imdensity=${imageDensity}` : ''
  }`;
};

export const getSrcSet = (
  src: string,
  srcSet: string | undefined = undefined,
  shouldLoad: boolean = true,
  imageDensity?: number,
) => {
  if (shouldLoad && srcSet) {
    return srcSet;
  }

  return getDefaultSrcSet(src, imageDensity);
};

export const getSrc = (
  src: string,
  shouldLoad: boolean,
  loadingStrategy: LoadingStrategy,
  disableAutoOptimize: boolean,
  containerDimensions?: Dimensions,
) => {
  if (
    loadingStrategy !== JS_LAZY ||
    !containerDimensions ||
    hasNoURLSearchParams ||
    isDataOrBlobUrl(src) ||
    disableAutoOptimize
  ) {
    return src;
  }

  if (!src || !shouldLoad) {
    return '';
  }

  const { direction, value } = getImageParam(src, containerDimensions);
  return cleanSrcAndAddWidthParam(src, direction, value);
};

export const getResponsiveFit = (
  breakpoints: Breakpoints,
  objectFit: ObjectFit,
) => {
  if (typeof objectFit !== 'object') {
    return { objectFit };
  }

  return Object.keys(objectFit)
    .filter((key) => key !== 'default')
    .reduce(
      (a, key) => ({
        ...a,
        [breakpoints[key as keyof Breakpoints]]: {
          objectFit: objectFit[key as keyof ObjectFit],
        },
      }),
      {
        objectFit: objectFit.default,
      },
    );
};

export const getOptimizedImageURL = (
  url: string,
  breakpoint: 'S' | 'M' | 'L' | 'XL',
  imageDensity?: number,
): string => {
  if (imageDensity !== 1) return url;

  switch (breakpoint) {
    case 'S':
      return cleanSrcAndAddWidthParam(url, 'w', 480, imageDensity);
    case 'M':
      return cleanSrcAndAddWidthParam(url, 'w', 1024, imageDensity);
    case 'L':
      return cleanSrcAndAddWidthParam(url, 'w', 1600, imageDensity);
    default:
      return url;
  }
};

const disableFadeIn = isStorybookVisualRegression;

export const imageCSS: (args: {
  hasLoaded?: boolean;
  objectFit?: ObjectFit;
  objectPosition?: string;
  aspectRatio?: AspectRatio;
}) => ExtendCSS =
  ({ hasLoaded = true, objectFit, objectPosition, aspectRatio }) =>
  ({ theme: { breakpoints } }) => ({
    opacity: hasLoaded ? 1 : 0,
    transition: !disableFadeIn ? 'opacity 300ms ease-in-out' : '',
    width: '100%',

    ...(typeof objectFit === 'string' && {
      objectFit: !aspectRatio ? objectFit : objectFit || 'cover',
    }),

    ...(objectFit && {
      ...getResponsiveFit(breakpoints, objectFit),
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectPosition: objectPosition || 'center center',
    }),
  });

export const containerCSS: (args: {
  aspectRatio?: AspectRatio;
  objectFit?: ObjectFit;
  imageWidth?: string;
}) => ExtendCSS =
  ({ imageWidth = '100%', aspectRatio, objectFit }) =>
  ({ theme: { breakpoints } }) => ({
    position: 'relative',
    width: imageWidth,
    height:
      aspectRatio === 'responsive'
        ? '100%'
        : !aspectRatio && objectFit
          ? '100%'
          : 'auto',
    minHeight: '1px', // for VisibilitySensor to always work if height is auto
    ...(!!aspectRatio &&
      objectFit && {
        maxHeight: '100%',
        ...getAspectRatioCSS(breakpoints, aspectRatio),
        overflow: 'hidden',
      }),
  });
