import React from 'react';

import { TranslationKey } from '@vcc-package/leads-utils/api';
import { globalStyling } from '@vcc-package/leads-utils/constants';
import { SubmitButton } from '@volvo-cars/react-forms';

import { useSubmitButtonLabel } from '../../../hooks';
import { useLeadsContext, useTranslate } from '../../context';
import { useFlexFormContext } from '../providers/FlexibleFormsProvider';

export const SubmitSection = () => {
  const rootLegacyProps = useFlexFormContext();
  if (!rootLegacyProps) {
    throw new Error('No rootLegacyProps');
  }

  const { customSubmitLabel, loading, setLoadingDebounced, hideRequired } =
    rootLegacyProps;

  const translate = useTranslate();

  const submitLabel = useSubmitButtonLabel(customSubmitLabel);

  if (!translate) {
    throw new Error('No translate provided');
  }
  const { features } = useLeadsContext();

  //remove after AB-test is done, ticket 4261133
  const shouldHideRequiredText =
    !features.requiredToOptional && !hideRequired ? (
      <p className="micro text-secondary" data-testid="required">
        * {translate(TranslationKey.REQUIRED)}
      </p>
    ) : null;

  return (
    <div className={`flex flex-col items-start ${globalStyling.sectionGap}`}>
      {shouldHideRequiredText}
      <SubmitButton
        data-testid="customer-information-submit"
        type="submit"
        color="accent"
        onClick={(e) => setLoadingDebounced && setLoadingDebounced(true, e)}
        loading={loading}
      >
        {submitLabel}
      </SubmitButton>
    </div>
  );
};
