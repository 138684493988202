import { cssJoin } from '@volvo-cars/css/utils';
import React from 'react';
import { SingleSelect } from '../../single-select/src/single-select';
import { YesNoQuestionViewProps } from './yes-no-question.types';

export const YesNoQuestionView: React.FC<
  React.PropsWithChildren<YesNoQuestionViewProps>
> = ({
  defaultValue,
  title,
  titleVariant,
  titleWeight,
  description,
  descriptionVariant,
  options,
  onChange,
}) => {
  if (title.length === 0 || options?.length < 2) {
    return null;
  }
  return (
    <>
      <div className="pb-8">
        <h3
          className={cssJoin(
            titleVariant || 'font-20',
            titleWeight !== 'standard' && 'font-medium',
          )}
        >
          {title}
        </h3>
      </div>
      <div>
        <p
          className={cssJoin(descriptionVariant || 'font-16', 'text-secondary')}
        >
          {description}
        </p>
      </div>
      <div
        className={cssJoin(
          titleVariant !== 'font-16' ? 'pt-24' : description ? 'pt-16' : 'pt-8',
        )}
      >
        <SingleSelect
          columnCount={2}
          centerTitle
          defaultSelectedIndex={
            defaultValue === 'yes' ? 0 : defaultValue === 'no' ? 1 : null
          }
          options={options}
          onChange={onChange}
        />
      </div>
    </>
  );
};
