/* eslint-disable jsx-a11y/no-static-element-interactions */
'use client';

import { cssMerge } from '@volvo-cars/css/utils';
import React, { useEffect, useRef } from 'react';
import { getCarFilterKeyFromReactChild } from '../../utils/getCarFilterKeyFromReactChild';
import styles from '../Carousel.module.css';
import { useCarousel } from '../providers/CarouselProvider';

const CarouselSlider = ({
  children,
  className,
  ...attrs
}: React.PropsWithChildren & React.HTMLAttributes<HTMLDivElement>) => {
  const {
    ref,
    filter,
    setFilters,
    filters,
    carouselIsScrollable,
    bleed,
    setTotalAmountOfCards,
  } = useCarousel();
  const prevChildrenCount = useRef(React.Children.count(children));

  const filteredChildren = React.Children.toArray(children).filter((child) => {
    const childFilter = getCarFilterKeyFromReactChild(child);
    if (!childFilter) return true;
    if (!filter) return true;
    return childFilter.includes(filter);
  });

  useEffect(() => {
    setTotalAmountOfCards(React.Children.count(children));
  }, [children, setTotalAmountOfCards]);

  useEffect(() => {
    const currentChildrenCount = React.Children.count(children);

    const shouldUpdateFilters =
      prevChildrenCount.current !== currentChildrenCount ||
      filters.length === 0;
    prevChildrenCount.current = currentChildrenCount;

    if (shouldUpdateFilters) {
      const availableFilters = React.Children.toArray(children)
        .flatMap((child) =>
          React.isValidElement(child)
            ? getCarFilterKeyFromReactChild(child)
            : null,
        )
        .filter(Boolean);

      if (availableFilters.length > 0) {
        setFilters(availableFilters);
      }
    }
  }, [children, setFilters, filters]);

  useEffect(() => {
    ref.current?.scrollTo({
      left: 0,
      behavior: 'instant',
    });
  }, [filter, ref]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={ref}
        className={cssMerge(
          'reel gap-x-16 scrollbar-none',
          styles.carousel,
          carouselIsScrollable && 'justify-start',
          bleed && carouselIsScrollable && styles.bleed,
          bleed && styles[bleed],
          className,
        )}
        aria-label="Carousel of cars"
        {...attrs}
      >
        {filteredChildren}
      </div>
    </div>
  );
};

export default CarouselSlider;
