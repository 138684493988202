'use client';
import { Markdown } from '@vcc-package/text';
import { FeaturesOverview } from '@vcc-package/unique-selling-points';
import { TrackingProvider } from '@volvo-cars/tracking';
import React from 'react';
import { Spacing } from 'src/components/layout/Spacing';
import type cbvStepsModuleProps from 'src/content-management/editorial-components/StepsModule/StepsModule.props';
import { useQueries } from 'src/hooks/useQueries';

export const StepsModule = ({ content }: cbvStepsModuleProps) => {
  let { stepsItems = [] } = content;
  const { title, salesModel = 'none', loopVideos, disclaimer } = content;
  const { financeOptions } = useQueries();

  if (salesModel !== 'none') {
    const financeOption = financeOptions[salesModel];
    if (financeOption) {
      stepsItems = financeOption.howItWorks.map(
        ({ title, image, video, shortDescription }) => ({
          title,
          body: shortDescription,
          landscapeImage: { src: image, alt: title },
          portraitImage: { src: image, alt: title },
          landscapeVideo: { src: video, mimeType: 'video/mp4' },
          portraitVideo: { src: video, mimeType: 'video/mp4' },
        }),
      );
    }
  }

  // stepsItems might be null from CMS, and if there is no map for a salesModel above,
  // set stepsItems to an empty array to keep being able to iterate on it below.
  if (!stepsItems) {
    stepsItems = [];
  }

  const featuresOverviewStepsItem = stepsItems.map(
    (
      {
        title,
        body,
        portraitImage,
        landscapeImage,
        portraitVideo,
        landscapeVideo,
      },
      index,
    ) => ({
      title,
      description: body,
      image: portraitImage,
      imageUltrawide: landscapeImage,
      video: portraitVideo,
      videoUltrawide: landscapeVideo,
      eventLabel: `steps module tab | ${index + 1}`,
    }),
  );

  return (
    <TrackingProvider eventCategory="steps module">
      <Spacing>
        <div className="container lg:px-64">
          <FeaturesOverview
            title={title}
            enableAutoplay
            loopVideos={loopVideos}
            shouldAutoChangeItems={false}
            items={featuresOverviewStepsItem}
          />
          {disclaimer && (
            <Markdown
              className="micro text-center text-secondary mt-24"
              markdown={disclaimer}
            />
          )}
        </div>
      </Spacing>
    </TrackingProvider>
  );
};
