'use client';
import type { PropsWithChildren } from 'react';
import React, { useEffect, useState } from 'react';

import { PageTrackingProvider, TrackingProvider } from '@volvo-cars/tracking';

import { useLeadsContext } from '../context/leadsContext';
import { useLeadsTracking } from '../context/leadsTrackingContext';

// Need to split the Volvo trackers to its own to access useLeadsTracking()
export const VolvoTrackingHelper = ({
  trackPageViewOnLoad = true,
  children,
}: PropsWithChildren<{ trackPageViewOnLoad?: boolean }>) => {
  const { pageName, purposeConfig } = useLeadsContext();

  const leadsTracking = useLeadsTracking();

  if (!leadsTracking) {
    throw Error('Volvo Tracker Helper requires leadsTrackerHelper');
  }
  const pageViewData = leadsTracking.pageView?.() ?? {};
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <TrackingProvider
      forceLowerCase={false}
      pageName={pageName}
      pageType="form"
    >
      {trackPageViewOnLoad && mounted && purposeConfig?.events?.pageView ? (
        <PageTrackingProvider forceLowerCase={false} {...pageViewData}>
          {children}
        </PageTrackingProvider>
      ) : (
        children
      )}
    </TrackingProvider>
  );
};
