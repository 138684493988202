import Cookies from 'js-cookie';
import {
  EW_USER_REGION_COOKIE_NAME,
  USER_LOCATION_COOKIE_NAME,
  getParsedCookie,
} from './cookies.common';
import {
  type Coordinates,
  type IpLocationCookie,
  UserInput,
  type UserLocation,
  type UserLocationCookie,
} from './validator';

const isLocalhost = (): boolean =>
  typeof window !== 'undefined'
    ? window.location.hostname === 'localhost'
    : process.env.NODE_ENV !== 'production';

export const getUserLocation = (): UserLocation => {
  return {
    ip:
      getParsedCookie<IpLocationCookie>(
        EW_USER_REGION_COOKIE_NAME,
        getCookie,
      ) || null,
    //we only need to get the userInput from the cookie since we
    //get the broswer coords from the browser directly
    userInput:
      getParsedCookie<UserLocationCookie>(
        USER_LOCATION_COOKIE_NAME,
        getCookie,
      )?.['userInput'] || null,
  };
};

const getCookie = (name: string): string | null => Cookies.get(name) || null;

export const setCookie = (name: string, value: string): void => {
  const options: Parameters<typeof Cookies.set>[2] = {
    sameSite: 'lax',
    path: '/',
    expires: 7,
    secure: !isLocalhost(),
  };
  Cookies.set(name, value, options);
};

export const removeCookie = (name: string): void => {
  Cookies.remove(name);
};

export const updateUserInputInCookieClient = (
  updateValue: UserInput | null,
): void => {
  try {
    const locationData =
      getParsedCookie<UserLocationCookie>(
        USER_LOCATION_COOKIE_NAME,
        getCookie,
      ) || {};

    if (updateValue) {
      locationData['userInput'] = updateValue;
    } else {
      delete locationData['userInput'];
    }

    if (Object.keys(locationData).length > 0) {
      setCookie(USER_LOCATION_COOKIE_NAME, JSON.stringify(locationData));
    } else {
      removeCookie(USER_LOCATION_COOKIE_NAME);
    }
  } catch (error) {
    console.error(`Error updating user location cookie:`, error);
  }
};

export const updateBrowserCoordsInCookieClient = (
  updateValue: Coordinates | null,
): void => {
  try {
    const locationData =
      getParsedCookie<UserLocationCookie>(
        USER_LOCATION_COOKIE_NAME,
        getCookie,
      ) || {};

    if (updateValue) {
      locationData['browserCoords'] = updateValue;
    } else {
      delete locationData['browserCoords'];
    }

    if (Object.keys(locationData).length > 0) {
      setCookie(USER_LOCATION_COOKIE_NAME, JSON.stringify(locationData));
    } else {
      removeCookie(USER_LOCATION_COOKIE_NAME);
    }
  } catch (error) {
    console.error(`Error updating user location cookie:`, error);
  }
};
