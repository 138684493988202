'use client';

import { isValidSiteSlug } from '@volvo-cars/market-sites';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CarPriceContextType, CarPriceProviderProps } from './car-price.types';
import { fetchPrices } from './car-price.utils/car-price.utils.fetch';

export const CarPriceContext = createContext<CarPriceContextType | null>(null);
CarPriceContext.displayName = 'CarPriceContext';

export const CarPriceProvider: React.FC<
  React.PropsWithChildren<CarPriceProviderProps>
> = ({
  siteSlug,
  clientName,
  isInStore,
  taxTerritory,
  contentSource,
  deployEnv,
  testwwwHeader,
  qawwwHeader,
  initialData,
  children,
}) => {
  const [data, setData] = useState<CarPriceContextType>({
    settings: initialData?.settings,
    content: initialData?.content,
    prices: initialData?.prices,
  });
  const [isLoaded, setIsLoaded] = useState(initialData?.isLoaded ?? false);
  const [isLoading, setIsLoading] = useState(initialData?.isLoading ?? false);

  const refetchPrices = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await fetchPrices({
        siteSlug,
        clientName,
        isInStore,
        taxTerritory,
        deployEnv,
        contentSource,
        testwwwHeader,
        qawwwHeader,
      });
      setData(result);
    } finally {
      setIsLoading(false);
      setIsLoaded(true);
    }
  }, [
    siteSlug,
    clientName,
    isInStore,
    taxTerritory,
    deployEnv,
    contentSource,
    testwwwHeader,
    qawwwHeader,
  ]);

  useEffect(() => {
    // Return early if data already fetched
    if (isLoaded) return;
    if (!isValidSiteSlug(siteSlug)) return;

    refetchPrices();
  }, [siteSlug, refetchPrices, isLoaded]);

  return (
    <CarPriceContext.Provider value={{ ...data, isLoading, refetchPrices }}>
      {children}
    </CarPriceContext.Provider>
  );
};

export const useCarPriceContext = () => {
  const context = useContext(CarPriceContext);
  if (!context) {
    throw new Error(
      'useCarPriceContext must be used within a CarPriceProvider',
    );
  }
  return context;
};
