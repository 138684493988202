import { ProductsOverlayViewIncludedServices } from '@vcc-package/products/src/overlay/products-overlay.view';
import React from 'react';
import { ServiceDetailsProps } from '../finance-details.types';

export const ServiceDetailsView = ({
  title,
  image,
  imageAltText = '',
  description,
  disclaimer,
  items,
  includedServicesTitle,
  includedServicesSources,
  styles,
}: ServiceDetailsProps) => {
  return (
    <div className={styles ?? 'container-xl flex-col gap-32 py-32 md:px-24'}>
      <img src={image} alt={imageAltText} />
      <div className="flex-col gap-48">
        <div className="flex-col gap-24">
          <h1 className="heading-2">{title}</h1>
          <p className="font-16">{description}</p>
        </div>
        {!!items?.services?.length && (
          <ProductsOverlayViewIncludedServices
            title={includedServicesTitle ?? ''}
            titleSources={includedServicesSources ?? []}
            includedServices={items?.services}
          />
        )}
        {disclaimer && <p className="micro text-secondary">{disclaimer}</p>}
      </div>
    </div>
  );
};
