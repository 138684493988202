'use client';
import React from 'react';

import type { CustomItem } from '@vcc-package/leads-utils/api';
import type { Translator } from '@vcc-package/leads-utils/types';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../components';

export interface CustomFieldProp {
  field: CustomItem;
  translate: Translator;
  hideRequired?: boolean;
}

export const CustomCheckboxField = ({
  translate,
  field,
  hideRequired,
}: CustomFieldProp) => {
  const { control } = useFormContext();

  return (
    <CheckboxField
      hideRequired={hideRequired}
      key={`${field.translationKey}_checkbox`}
      name={`extra_${field.translationKey}`}
      required
      label={translate(field.translationKey ?? '')}
      control={control}
    />
  );
};
