import { SelectableCard } from '@vcc-package/selectable-card';
import { cssJoin } from '@volvo-cars/css/utils';
import React from 'react';
import { SingleSelectViewProps } from './single-select.types';

export const SingleSelectView: React.FC<
  React.PropsWithChildren<SingleSelectViewProps>
> = ({
  alwaysShowChildren,
  centerTitle,
  children,
  currentIndex,
  disabled,
  onChangeHandler,
  optionsWithTitles,
  readOnly,
  columnCount,
  testId,
}) => {
  if (optionsWithTitles?.length < 1) return null;

  const gridColumns =
    !!columnCount && optionsWithTitles.length > 1
      ? `grid-cols-${columnCount}`
      : '';

  return (
    <div className={cssJoin('grid gap-16', gridColumns)}>
      {optionsWithTitles?.map((option, index) => {
        const isSelected =
          option.isActive ??
          (optionsWithTitles?.length === 1 ? true : currentIndex === index);
        return (
          <div
            data-testid={testId}
            className="grid gap-16"
            key={`${index}-${option.title}`}
          >
            <SelectableCard
              alwaysShowChildren={alwaysShowChildren}
              annotation={option?.annotation}
              disabled={disabled}
              disclaimer={option?.disclaimer}
              centerTitle={centerTitle}
              href={option?.href ?? ''}
              isActive={isSelected}
              key={`${index}-${option.title}`}
              linkText={option?.linkText ?? ''}
              linkTextSources={option?.linkTextSources}
              onClick={option?.onClick}
              onSelect={() => onChangeHandler(index, option)}
              readOnly={readOnly}
              secondaryInfo={option.secondaryInfo}
              secondaryInfoSources={option?.secondaryInfoSources}
              title={option.title}
              titleSources={option?.titleSources}
              titleTag={option?.titleTag ?? ''}
              titleTagSources={option?.titleTagSources}
              vatLabel={option?.vatLabel ?? ''}
              vatLabelSources={option?.vatLabelSources}
              name={option?.name ?? ''}
              value={option?.value ?? ''}
              testId={testId}
            >
              {option.children || children}
            </SelectableCard>
          </div>
        );
      })}
    </div>
  );
};
