'use client';

import { PopUpDisclaimer } from '@vcc-package/overlays';
import { cssJoin, cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import { DisclaimerView } from '../../disclaimers';
import { Markdown } from '../markdown';
import styles from './TextStatement.module.css';
import Props from './TextStatement.props';

const TextStatement: React.FC<Props> = ({
  title,
  text,
  body,
  description,
  breakLine,
  disclaimer,
  TitleElement = 'p',
  alignContent = 'center',
  withInlinePopUpDisclaimer,
  trackLabel,
  fullWidth,
  titleClassName,
  bodyClassName,
}) => {
  return (
    <div className="flex-shrink-1 justify-start flex-row">
      <div
        className={cssMerge(
          'mx-auto',
          alignContent === 'left' && 'm-0',
          !fullWidth && styles.maxWidth,
        )}
      >
        {!!withInlinePopUpDisclaimer &&
        withInlinePopUpDisclaimer.description ? (
          <PopUpDisclaimer
            title={withInlinePopUpDisclaimer.title}
            description={withInlinePopUpDisclaimer.description}
            trackLabel={trackLabel}
            addMarginInlineStart
          >
            {(icon) => (
              <TitleElement
                data-testid="text-statement-wrapper"
                className={cssMerge(
                  'pt-16 pb-16 px-0 text-start heading-2 text-wrap',
                  alignContent === 'center' && 'text-center',
                  titleClassName,
                )}
              >
                {title && <span className="text-primary">{title}</span>}
                {breakLine ? <br /> : ' '}
                {text && (
                  <span className="text-secondary whitespace-pre-line">
                    {text}
                    {icon}
                  </span>
                )}
              </TitleElement>
            )}
          </PopUpDisclaimer>
        ) : (
          <TitleElement
            data-testid="text-statement-wrapper"
            className={cssMerge(
              'pt-16 pb-16 px-0 text-start heading-2 text-wrap',
              alignContent === 'center' && 'text-center',
              titleClassName,
            )}
          >
            {title && <span className="text-primary">{title}</span>}
            {breakLine ? <br /> : ' '}
            {text && (
              <span className="text-secondary whitespace-pre-line">{text}</span>
            )}
          </TitleElement>
        )}

        {body && (
          <p className={cssMerge('text-secondary', bodyClassName)}>{body}</p>
        )}
        {description && (
          <Markdown
            className={cssMerge('text-secondary mt-16', bodyClassName)}
            markdown={description}
          />
        )}
        {disclaimer && (
          <DisclaimerView
            className={cssJoin(
              'mt-16',
              alignContent === 'center' ? 'self-center' : 'self-start',
              alignContent === 'center' ? 'text-center' : 'text-start',
            )}
            markdown={disclaimer}
          />
        )}
      </div>
    </div>
  );
};

export default TextStatement;
