'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import type {
  CustomTranslations,
  Translator,
} from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../components';

type AccompaniedFormProps = {
  translate: Translator;
  defaultValue: boolean;
  fieldType: string;
  customTranslation: CustomTranslations;
  hideRequired?: boolean;
};

export const AccompaniedField = ({
  translate,
  defaultValue,
  fieldType,
  customTranslation,
  hideRequired,
}: AccompaniedFormProps) => {
  const { control } = useFormContext();
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.ACCOMPANIED,
  );

  return (
    <div>
      <CheckboxField
        hideRequired={hideRequired}
        name={FlexFormFieldType.ACCOMPANIED}
        testid={FlexFormTestID.Accompanied}
        control={control}
        label={
          customTranslationKeys
            ? translate(customTranslationKeys.label)
            : translate(TranslationKey.ACCOMPANIED_TEST_DRIVE_LABEL)
        }
        required={false}
        key={fieldType}
        defaultValue={defaultValue}
      />
      <small className="micro">
        {translate(TranslationKey.ACCOMPANIED_TEST_DRIVE_DESCRIPTION)}
      </small>
    </div>
  );
};
