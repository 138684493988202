'use client';

import type { PropsWithChildren } from 'react';
import React, { useCallback, useEffect } from 'react';

import {
  AdditionalProps,
  FormLoadEventTracker,
  LeadsDefaultLayout,
  LeadsForm,
  LeadsFormSubmitHandler,
  useFeatureFlagTracker,
  useLeadsContext,
  useLeadsTracking,
  useModel,
  useRetailer,
} from '@vcc-package/leads-utils';
import {
  FlexFormFieldType,
  FlexFormLegalFieldType,
  PurposeType,
  type RequestApiModel,
  type RequestResponseApiModel,
} from '@vcc-package/leads-utils/api';
import { type JSONObject } from '@vcc-package/leads-utils/types';
import type { CreateRequestInput } from '@vcc-package/leads-utils/util';
import {
  StorageKey,
  convertStringToBoolean,
  createRequestFromFlexForm,
  getCampaignCode,
  getCarInfoFromFormValues,
  getGa3EventAction,
  getGa3EventCategory,
  getGtmFormTags,
  readFromSession,
  saveToSession,
  sendGASuccessEvent,
} from '@vcc-package/leads-utils/util';
import { useTracker } from '@volvo-cars/tracking';
import { LeadsApp } from '../constants';
import { useEmbeddableFormsTracking } from '../hooks/useEmbeddableFormsTracking';
import { type EmbeddedFormErrorType } from './types';

export type EmbeddedFormProps = {
  OnSubmit(
    submitData: RequestApiModel,
    apiResponse: RequestResponseApiModel,
  ): void;
  OnError(type: EmbeddedFormErrorType, e: Error): void;
  hideModelImage?: boolean;
  additionalInfo?: JSONObject;
};

export const FormPage = (props: PropsWithChildren<EmbeddedFormProps>) => {
  const leadsTracking = useLeadsTracking();
  const track = useTracker();
  const { selectedModel } = useModel();
  const { isInsideSales, selectedRetailer } = useRetailer();
  const { getFeatureList } = useFeatureFlagTracker();

  const {
    API,
    analytics,
    appId,
    formConfig,
    marketConfig,
    navigation: { pathname },
    purposeConfig,
    siteSlug,
    urlData,
    purpose,
    consumerApp,
    isEmbeddableForms,
    navigation: { goTo, query },
    features,
    publicRuntimeConfig,
    setPurpose,
    inOverlay,
  } = useLeadsContext();

  useEmbeddableFormsTracking({
    inDialog: inOverlay,
    appId,
    purpose,
    isEmbeddableForms,
    consumerApp,
  });

  const hasRetailerSelection =
    formConfig?.order?.includes('dealerpicker') || selectedRetailer;
  const hasModelParams = !!query.pno || !!query.model || !!query.modelkey;

  useEffect(() => {
    if (isEmbeddableForms || appId !== LeadsApp.QuoteRequest) {
      return;
    }

    if (!selectedModel && !hasModelParams) {
      console.warn('No model selected');
      props.OnError('MissingModel', new Error('No model selected'));
    } else if (!hasRetailerSelection && !query.retailerid) {
      console.warn('No retailer selected');
      props.OnError('MissingRetailer', new Error('No retailer selected'));
    }

    const newPurpose = isInsideSales
      ? PurposeType.CBV_CALLBACK
      : PurposeType.OFFER_REQUEST;
    if (purpose !== newPurpose) {
      setPurpose(newPurpose);
    }
  }, [
    isEmbeddableForms,
    isInsideSales,
    hasRetailerSelection,
    purpose,
    hasModelParams,
    props,
    query,
    selectedModel,
    appId,
    setPurpose,
  ]);

  const b2bQuery = convertStringToBoolean(query.b2b);
  const retailerid = query.retailerid ?? query.customretailerid;
  const hideDealerPicker = !!retailerid && !!selectedRetailer;
  const redirected = !!readFromSession(
    LeadsApp.QuoteRequest,
    StorageKey.redirected,
  );
  const isVbs = !!query.cceurl || !!query.vbscardatapath;
  const salesChannel =
    isInsideSales || purpose === PurposeType.CBV_CALLBACK
      ? 'online'
      : undefined;

  const b2bOptions = {
    shouldRender: b2bQuery || !!features.showB2bSection,
    isB2B: b2bQuery,
  };

  // Callback: Hide products of interest if model query exists
  const hideFields: FlexFormFieldType[] = [];
  if (selectedModel?.pno34) {
    hideFields.push(FlexFormFieldType.PRODUCTS_OF_INTEREST);
  }

  const additionalParameters: AdditionalProps = {
    b2b: b2bOptions,
    hideDealerPicker: hideDealerPicker,
    hideFields: hideFields,
    useSingleColumn: inOverlay || purpose === PurposeType.OFFER_REQUEST,
    inOverlay: inOverlay,
  };

  const sendRequest: LeadsFormSubmitHandler = useCallback(
    async (formValues, recaptchaToken, setLoading, loading) => {
      if (loading) {
        return false;
      }
      if (purpose === null) {
        console.error('No purpose set for form');
        return false;
      }
      setLoading(true);

      const carInformation = getCarInfoFromFormValues(formValues);
      const description = formValues.description ?? '';

      formValues.description = carInformation
        ? `${carInformation} \nAdditional information: ${description}`
        : description;

      //legalName used for some legacy reason as B2b check for quote, not sure why...
      const legalName = formValues?.legalname;
      const isB2B =
        Boolean(formValues.business) ||
        b2bOptions.isB2B ||
        (purpose === PurposeType.OFFER_REQUEST && !!legalName);
      const campaignCode = getCampaignCode({ isB2B, purposeConfig, query });
      const brand = purpose === PurposeType.CBV_CALLBACK ? 'cbv' : query.brand;

      if (selectedModel?.pno34) {
        formValues[FlexFormFieldType.PRODUCTS_OF_INTEREST] = [
          selectedModel.pno34,
        ];
      }

      if (purpose === PurposeType.KEEP_ME_UPDATED) {
        formValues[FlexFormLegalFieldType.EMAIL_OPTIN] =
          formValues[FlexFormLegalFieldType.EMAIL_OPTIN] ?? true;
      }

      const requestInput: CreateRequestInput = {
        additionalInformation: {
          analytics,
          brand,
          business: isB2B,
          campaignCode: campaignCode ?? undefined,
          recaptchaToken: recaptchaToken,
          urlData,
        },
        formValues,
        languageId: marketConfig.lang ?? 'en',
        marketId: siteSlug,
        appId,
        query,
        retailerInformation: {
          retailerId: selectedRetailer?.dealerId || query.retailerid,
          customRetailerId: query.customretailerid,
        },
        consumerApp: consumerApp ?? pathname ?? 'unkown',
        embeddableFormsAdditionalInfo: props.additionalInfo,
        isEmbeddableForms,
        publicRuntimeConfig,
      };

      const request = createRequestFromFlexForm(requestInput);

      saveToSession(appId, StorageKey.submitData, request);

      const trackingData = leadsTracking?.formSubmit
        ? await leadsTracking.formSubmit({ b2b: isB2B })
        : undefined;
      const response = await API.sendRequest(request);
      const success = response !== undefined;

      if (success) {
        const { formId, formType } = getGtmFormTags({
          appId,
          isB2b: !!request.business,
          isVbs: isVbs,
          marketId: request.marketId,
        });
        sendGASuccessEvent(formId, formType, salesChannel);
        //Needs verifing
        track.interaction({
          carDriveLineType: selectedModel?.gtm?.powerTrain,
          carModelName: selectedModel?.gtm?.carModel,
          deeplink: purpose === PurposeType.OFFER_REQUEST && redirected,
          eventAction: getGa3EventAction(pathname, query),
          eventCategory: getGa3EventCategory(appId),
          eventLabel: 'success',
          salesChannel: salesChannel,
          ...getFeatureList(),
        });

        if (trackingData) {
          track.formSubmit(trackingData);
        }

        const redirectUrl: string | undefined =
          response?.redirectUrl || query.redirecturl;
        if (redirectUrl && !isEmbeddableForms) {
          goTo(redirectUrl, { fullNavigation: true, external: true });
        } else {
          props.OnSubmit(request, response);
        }
      } else {
        console.error('Something went wrong with the form submit');
        setLoading(false);
      }
    },
    [
      API,
      analytics,
      appId,
      b2bOptions.isB2B,
      consumerApp,
      getFeatureList,
      goTo,
      isEmbeddableForms,
      isVbs,
      leadsTracking,
      marketConfig.lang,
      pathname,
      props,
      publicRuntimeConfig,
      purpose,
      purposeConfig,
      query,
      redirected,
      salesChannel,
      selectedModel,
      selectedRetailer,
      siteSlug,
      track,
      urlData,
    ],
  );

  return (
    <>
      {!inOverlay && (
        <FormLoadEventTracker
          isB2b={b2bOptions.isB2B}
          isVbs={isVbs}
          salesChannel={salesChannel}
        />
      )}
      <LeadsDefaultLayout hideModel={props.hideModelImage ?? false}>
        <LeadsForm
          additionalProps={additionalParameters}
          onSubmit={sendRequest}
          onError={(errors, setLoading) => {
            setLoading(false);
            props.OnError('General', errors);
          }}
        >
          {props.children}
        </LeadsForm>
      </LeadsDefaultLayout>
      {!inOverlay && (
        <FormLoadEventTracker
          isB2b={b2bOptions.isB2B}
          isVbs={isVbs}
          salesChannel={salesChannel}
        />
      )}
    </>
  );
};

export default FormPage;
