'use client';
import { cssJoin } from '@volvo-cars/css/utils';
import { useTracker } from '@volvo-cars/tracking';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { useState } from 'react';
import { FAQSchema } from 'src/components/molecules/FAQSchema';
import {
  FaqQuestion,
  type Question,
} from 'src/components/organisms/FaqQuestion';
import { FaqSearch } from 'src/components/organisms/FaqSearch';
import { MarkdownOrRichText } from 'src/components/organisms/MarkdownOrRichText';
import { NON_BREAKING_SPACE } from 'src/utils/constants';

import type { cbvFaqListGroup } from 'src/content-management/editorial-components/FaqList/FaqList.props';
import type faqListProps from 'src/content-management/editorial-components/FaqList/FaqList.props';
import { useCbvTranslate } from 'src/hooks/useDictionaries';
import { Spacing } from '../../layout/Spacing';
import styles from './faq-list.module.css';

export const FaqList = ({ content }: faqListProps) => {
  const {
    title,
    titleSize = 'normal',
    body,
    link,
    groups,
    withTabs,
    categoriesTitle,
    withSearch,
    searchInputPlaceholder,
    resultsFoundText,
    noResultsFoundText,
    withTopSpacing,
  } = content;

  const translate = useCbvTranslate();
  const translatedAll = translate('dictionaries.common.all');
  const pathname = usePathname() ?? '';
  const searchParams = useSearchParams();
  const router = useRouter();
  const queryCategory = searchParams?.get('topic') as string;

  const tabs = groups
    .reduce(
      (acc, curr) => {
        acc.push(curr.title as string);
        return acc;
      },
      [translatedAll],
    )
    .filter((tab) => typeof tab !== 'undefined');

  const [selectedTab, setSelectedTab] = useState(
    withTabs && tabs.includes(queryCategory) ? queryCategory : translatedAll,
  );

  const tracker = useTracker();
  const eventCategory = 'faq-list';

  const flattenedQuestions: Question[] = [];
  for (const group of groups) {
    for (const question of group.questions) {
      flattenedQuestions.push(question);
    }
  }

  const filteredGroups = groups.filter(
    (group) => selectedTab === translatedAll || group.title === selectedTab,
  );

  return (
    <>
      <FAQSchema questions={flattenedQuestions} />
      <Spacing topSpacing={withTopSpacing}>
        <div data-testid="faq-list" className="container-md">
          {title && <Title titleSize={titleSize}>{title}</Title>}
          {body && (
            <div
              className={cssJoin(
                styles['section-body'],
                'mb-24 lg:text-center',
              )}
            >
              <MarkdownOrRichText
                data-testid="faq-list__body"
                text={body}
                eventCategory="faq-list"
                enableTracking
              />
            </div>
          )}
          {withSearch && (
            <FaqSearch
              placeholder={searchInputPlaceholder || ''}
              resultsFoundText={resultsFoundText || ''}
              noResultsFoundText={noResultsFoundText || ''}
              questions={flattenedQuestions}
              testId="faq-list"
            />
          )}
          {withTabs && (
            <>
              {withSearch && (
                <h2
                  data-testid="faq-list__categories-title"
                  className="heading-2 mt-64 lg:pt-32 text-center"
                >
                  {categoriesTitle}
                </h2>
              )}
              <div
                data-testid="faq-list__tabs"
                className="mt-16 md:mt-32 flex flex-wrap lg:justify-center gap-8"
              >
                {tabs.map((tab) => {
                  const id = tab
                    .replace(/ /g, '-')
                    .replace(NON_BREAKING_SPACE, '-')
                    .toLowerCase();

                  return (
                    <button
                      className={cssJoin(
                        selectedTab === tab
                          ? 'button-filled'
                          : 'button-outlined',
                      )}
                      data-testid="faq-list__tabs__tab"
                      data-color="secondary"
                      data-size="small"
                      key={id}
                      type="button"
                      onClick={() => {
                        setSelectedTab(tab);
                        router.replace(
                          `${pathname.split('?')[0]}?topic=${tab}`,
                          { scroll: false },
                        );
                        tracker.customEvent({
                          eventCategory,
                          eventAction: 'button|click',
                          eventLabel: tab,
                        });
                      }}
                    >
                      {tab}
                    </button>
                  );
                })}
              </div>
            </>
          )}
          <div>
            {filteredGroups.map((group, i) => (
              <GroupContainer {...group} key={group.title || i} />
            ))}
          </div>
          {link?.text && link?.href && (
            <div className="flex justify-center mt-32 md:mt-48">
              <a
                className="button-text"
                data-color="accent"
                href={link.href}
                onClick={() => {
                  tracker.customEvent({
                    eventCategory,
                    eventAction: 'link|click',
                    eventLabel: `${link.text}|${link.href}`,
                  });
                }}
              >
                {link.text}
              </a>
            </div>
          )}
        </div>
      </Spacing>
    </>
  );
};

const Title: React.FC<
  React.PropsWithChildren<Pick<faqListProps['content'], 'titleSize'>>
> = ({ titleSize, children }) => {
  return React.createElement(
    titleSize === 'large' ? 'h1' : 'h2',
    {
      className: cssJoin(
        'mb-24 lg:text-center',
        (titleSize === 'normal' || !titleSize) && 'heading-2',
        titleSize === 'large' && 'heading-1',
        titleSize === 'small' && 'font-20 font-medium',
      ),
      'data-testid': 'faq-list__title',
    },
    children,
  );
};

const GroupContainer: React.FC<React.PropsWithChildren<cbvFaqListGroup>> = ({
  title,
  questions,
}) => (
  <div data-testid="faq-list__group" className="flex-col">
    {title && (
      <p
        data-testid="faq-list__group__title"
        className="font-20 border-ornament border-b pb-32 pt-32 font-medium"
      >
        {title}
      </p>
    )}
    {questions.map(
      (question) =>
        question.question && (
          <FaqQuestion
            testId="faq-list__question"
            {...question}
            key={`${title}-${question.question}`}
            eventCategory="faq list"
          />
        ),
    )}
  </div>
);
