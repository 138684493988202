import {
  AspectRatio,
  ResponsiveAspectRatio,
  getAspectRatioCSS,
  isResponsiveAspectRatio,
} from '@vcc-www/utils/getAspectRatioCSS';
import React from 'react';
import { Block, ExtendCSS } from 'vcc-ui';

type Props = {
  inViewRef: React.MutableRefObject<HTMLDivElement | null>;
  aspectRatio?: AspectRatio;
  children: React.ReactNode;
  background?: string;
};

const VideoBlock: React.FC<React.PropsWithChildren<Props>> = ({
  inViewRef,
  aspectRatio,
  children,
  background,
}) => {
  return (
    <Block ref={inViewRef} extend={containerCSS(aspectRatio, background)}>
      {children}
    </Block>
  );
};

/**
 * It's year 2021, safari still has a bug from 2009
 * https://bugs.webkit.org/show_bug.cgi?id=26559
 * It gets confused and `height: 100%` doesn't seem to work
 * so using a safari specific css query and setting it to
 * absolute position/size to fix it. [TC]
 */
const onlySafariQuery = '@supports (-webkit-backdrop-filter: blur(1px))';
const safariHack: ExtendCSS = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
};

function constructMediaQueries(aspectRatios?: ResponsiveAspectRatio) {
  return Object.entries(aspectRatios ?? {}).reduce<any>(
    (acc, [query, ratio]) => {
      if (ratio === 'responsive') {
        if (query === 'default') {
          acc.height = '100%';
          acc[onlySafariQuery] = safariHack;
        } else {
          acc[query] = safariHack;
        }
      }
      return acc;
    },
    {},
  );
}

const containerCSS =
  (aspectRatio?: AspectRatio, background?: string): ExtendCSS =>
  ({ theme: { breakpoints, color } }) => ({
    position: 'relative',
    background: background || color.background.secondary,
    extend: [
      {
        condition: !!aspectRatio,
        style: {
          height: 0,
          ...getAspectRatioCSS(breakpoints, aspectRatio),
        },
      },
      {
        condition: aspectRatio && aspectRatio === 'responsive',
        style: {
          height: '100%',
        },
      },
      {
        condition: isResponsiveAspectRatio(aspectRatio),
        style: constructMediaQueries(aspectRatio as ResponsiveAspectRatio),
      },
    ],
  });

export default VideoBlock;
