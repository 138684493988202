'use client';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { getMarketSite } from '@volvo-cars/market-sites';
import { useEffect, useState } from 'react';
import { FeaturedList } from '../../types';
import { fetchFeaturedDocumentsList } from '../fetchFeaturedDocumentsList';

interface UseFeaturedDocumentsProps {
  featureListName: string;
}

/**
 * @description Fetches the featured documents using the feature list name, site slug and language.
 * @param limit - The number of documents to return
 * @param featureListName - The name of the feature list
 * @returns - The featured documents
 */
export const useFeaturedDocuments = ({
  featureListName,
}: UseFeaturedDocumentsProps) => {
  const { siteSlug } = useVolvoCarsUrl();
  const { htmlLanguage } = getMarketSite(siteSlug);

  const [isLoading, setLoading] = useState(false);
  const [featuredList, setFeaturedList] = useState<FeaturedList | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (siteSlug) {
          const featuredDocuments = await Promise.resolve(
            fetchFeaturedDocumentsList({
              siteSlug: siteSlug,
              language: htmlLanguage,
              featureListName,
            }),
          );
          if (featuredDocuments) {
            setFeaturedList(featuredDocuments);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(
          'Contextual-Support-Content: Error fetching featured documents',
          error,
        );
      }
    };
    if (!featuredList) {
      setLoading(true);
      fetchData();
    }
  }, [featuredList, featureListName, htmlLanguage, siteSlug]);

  return {
    isLoading,
    featuredList,
  };
};
