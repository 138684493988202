import type { LeadsApp } from '@vcc-package/leads-utils/constants';

export enum StorageKey {
  submitData = 'submitData',
  redirected = 'redirected', // Used for tracking in Quote
  selectedModel = 'selectedModel',
  selectedRetailer = 'selectedRetailer',
  utmParameterData = 'utmParameterData',
  gtmParameterData = 'gtmParameterData',
  urlData = 'urlData',
}

const saveToStorage = <T = any>(
  storage: Storage,
  app: LeadsApp,
  key: string,
  data: T,
) => {
  storage.setItem(
    `${app}_${key}`,
    JSON.stringify(data === undefined ? null : data),
  );
};

const readFromStorage = <T = any>(
  storage: Storage,
  app: LeadsApp,
  key: string,
): T | null => {
  const rawItem = storage.getItem(`${app}_${key}`);
  try {
    return rawItem ? (JSON.parse(rawItem) as T) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const removeStorageItem = (storage: Storage, app: LeadsApp, key: string) => {
  storage.removeItem(`${app}_${key}`);
};

const clearStorage = (storage: Storage) => {
  storage.clear();
};

//Session storage
export const saveToSession = <T = any>(app: LeadsApp, key: string, data: T) => {
  if (typeof window === 'undefined') {
    return;
  }
  return saveToStorage(window.sessionStorage, app, key, data);
};

export const readFromSession = <T = any>(
  app: LeadsApp,
  key: string,
): T | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  return readFromStorage(window.sessionStorage, app, key);
};

export const removeSessionItem = (app: LeadsApp, key: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  removeStorageItem(window.sessionStorage, app, key);
};

export const clearSessionStorage = () => {
  if (typeof window === 'undefined') {
    return;
  }

  clearStorage(window.sessionStorage);
};
