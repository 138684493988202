import { updateBrowserCoordsInCookieClient } from './cookies.client';
import { Coordinates } from './validator';

// Singleton for PermissionStatus and onChange handler
let permissionStatusSingleton: PermissionStatus | null = null;

export function getBrowserLocation(): Promise<Coordinates | null> {
  return new Promise((resolve) => {
    if (!navigator.geolocation) {
      updateBrowserCoordsInCookieClient(null);
      resolve(null);
      return;
    }

    const handlePermissionChange = () => {
      if (permissionStatusSingleton?.state === 'denied') {
        updateBrowserCoordsInCookieClient(null);
      }
    };

    if (permissionStatusSingleton) {
      updateLocation(permissionStatusSingleton, resolve);
    } else {
      // Query permission for the first time
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus) => {
          permissionStatusSingleton = permissionStatus;
          permissionStatusSingleton.onchange = handlePermissionChange;

          updateLocation(permissionStatus, resolve);
        });
    }
  });
}

const updateLocation = (
  permissionStatus: PermissionStatus,
  resolve: (
    value: Coordinates | PromiseLike<Coordinates | null> | null,
  ) => void,
) => {
  if (
    permissionStatus.state === 'granted' ||
    permissionStatus.state === 'prompt'
  ) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const coordinates: Coordinates = {
          latitude: position.coords.latitude.toString(),
          longitude: position.coords.longitude.toString(),
        };
        updateBrowserCoordsInCookieClient(coordinates);
        resolve(coordinates);
      },
      () => {
        updateBrowserCoordsInCookieClient(null);
        resolve(null);
      },
    );
  } else {
    updateBrowserCoordsInCookieClient(null);
    resolve(null);
  }
};
