import { useEffect, useState } from 'react';
import { DealerModel } from '../../../../../../api';
import { useLeadsContext } from '../../../../../context/leadsContext';

export const useGetAllDealers = ({
  selectRetailer,
  setValue,
  name,
}: {
  selectRetailer: (dealer: DealerModel | null) => void;
  setValue: (name: string, value: string) => void;
  name: string;
}) => {
  const { API } = useLeadsContext();

  const [data, setData] = useState<DealerModel[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await API.getDealers();
        if (response) {
          setData(response);
          if (response?.length === 1) {
            //auto select the only dealer
            setValue(name, response[0].dealerId as string);
            selectRetailer(response[0]);
          }
        } else {
          setError('Something went wrong fetching dealers');
        }
      } catch (error: any) {
        console.error('Error fetching dealers', error);
        setError('Something went wrong fetching dealers');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { data, error, isLoading };
};
