import React from 'react';
import DiscoveryCard from '.';
import {
  CardImageType,
  DiscoveryCardContentType,
  DiscoveryCardTemplateProps,
} from '../utils/types';
import DiscoveryCardPartContent from './discovery-card.part.content';

type Props = DiscoveryCardTemplateProps<CardImageType>;

export const DiscoveryCardStacked = ({
  responsiveImages,
  children,
  className,
  ...rest
}: Props) => {
  return (
    <DiscoveryCard
      variant="stacked"
      responsiveImages={responsiveImages}
      className={className}
    >
      {children ? (
        <div slot="main">{children}</div>
      ) : (
        <DiscoveryCardPartContent {...(rest as DiscoveryCardContentType)} />
      )}
    </DiscoveryCard>
  );
};
