'use client';
import type { FinanceOptions } from '@vcc-www/api/finance-options';
import type React from 'react';
import { createContext, useContext } from 'react';

interface QueriesContextProps {
  financeOptions: FinanceOptions;
}

const QueriesContext = createContext<QueriesContextProps | null>(null);

export const QueriesProvider: React.FC<
  React.PropsWithChildren<QueriesContextProps>
> = ({ financeOptions, children }) => {
  return (
    <QueriesContext.Provider value={{ financeOptions }}>
      {children}
    </QueriesContext.Provider>
  );
};

export const useQueries = () => {
  const value = useContext(QueriesContext);

  if (value == null) {
    throw new Error('QueriesProvider is missing in the component tree');
  }

  return value;
};
