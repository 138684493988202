import type { ResponseCookie } from 'next/dist/compiled/@edge-runtime/cookies';

export type CookieSetterObj = {
  key: string;
  value: string;
  options?: Partial<ResponseCookie>;
};

const restrictedKeys = [
  'expires',
  'path',
  'domain',
  'secure',
  'samesite',
  'httponly',
];

export const parseCookieString = (
  cookies: string | null,
): Record<string, CookieSetterObj> => {
  const cookieDict: Record<string, CookieSetterObj> = {};

  if (!cookies) {
    return cookieDict;
  }

  cookies.split(';').forEach((cookie: string) => {
    const [first, ...rest] = cookie.split('=');
    const key = first.trim();
    const value = rest.join('=');
    const options = {} as Partial<ResponseCookie>;

    if (!key) {
      return;
    }

    if (restrictedKeys.includes(key.toLowerCase())) {
      const lastCookie = Object.values(cookieDict).pop();
      if (!lastCookie) {
        console.error('Invalid cookie key', key);
        return;
      }
      lastCookie.options = lastCookie.options ?? {};
      switch (key.toLowerCase()) {
        case 'expires':
          lastCookie.options.expires =
            (value as any) instanceof Date
              ? (value as unknown as Date)
              : new Date(value);
          break;
        case 'path':
          lastCookie.options.path = value;
          break;
        case 'domain':
          lastCookie.options.domain = value;
          break;
        case 'secure':
          lastCookie.options.secure = true;
          break;
        case 'httponly':
          lastCookie.options.httpOnly = true;
          break;
        case 'samesite':
          lastCookie.options.sameSite = value as 'strict' | 'lax' | 'none';
          break;
        default:
          break;
      }
      return;
    }

    cookieDict[key.trim()] = { key, value, options };
  });

  return cookieDict;
};

export const getCookieValue = (
  cookies: string | null,
  key: string,
): string | null => {
  const cookieDict = parseCookieString(cookies);

  return cookieDict[key]?.value ?? null;
};
