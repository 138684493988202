import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import { Markdown } from '../src/markdown';
import styles from './Disclaimer.module.css';

type DisclaimerViewProps = React.ComponentProps<typeof Markdown>;

export const DisclaimerView: React.FC<DisclaimerViewProps> = ({
  className,
  ...rest
}) => (
  <Markdown
    {...rest}
    className={cssMerge(
      'micro text-secondary',
      styles['disclaimer'],
      className,
    )}
  />
);
