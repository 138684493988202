import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { cssJoin } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';
import React from 'react';
import { FeaturedDocument } from '../types';
import styles from './FeaturedDocuments.module.css';

export interface FeaturedDocumentsListProps {
  documents: FeaturedDocument[];
  title?: string;
  description?: string;
  languageDirection?: 'ltr' | 'rtl';
  isTitleCentered?: boolean;
  children?: React.ReactNode;
}

/**
 * @description This component is used to display a grid view of featured documents
 * @param title - Title of the featured documents section
 * @param documents - List of featured documents
 * @param isTitleCentered - flag for setting the title to be centered
 * @param children - Children that will be loaded under the list. The url of the links inside that is pointing to the support/topic should be absolute
 * @returns A grid view of featured documents
 */
export const FeaturedDocumentsList = ({
  documents,
  title,
  description,
  languageDirection = 'ltr',
  isTitleCentered,
  children,
}: FeaturedDocumentsListProps) => {
  const { siteSlug } = useVolvoCarsUrl();
  const translate = useSharedComponentsTranslate();

  return (
    <section className="container" dir={languageDirection}>
      {title && (
        <h2
          className={cssJoin(
            'm-auto heading-2 mb-48',
            isTitleCentered && 'text-center',
          )}
        >
          {title}
        </h2>
      )}
      {description && <p className="m-auto text-center mb-16">{description}</p>}
      <div className="lg:grid-cols-3 md:grid-cols-2 grid gap-x-gutter gap-y-24">
        {documents.map((document, index) => (
          <Track
            eventLabel={document.title}
            eventAction="link|click"
            key={index}
          >
            <a
              className={`border rounded px-16 pt-24 pb-8 flex-col justify-between ${styles.card}`}
              target="_blank"
              rel="noreferrer"
              href={`/${siteSlug}/support/topic/${document.documentId}`}
              data-contextual-link
            >
              <div>
                <h3 className={`font-medium mb-8 ${styles.title}`}>
                  {document.title}
                </h3>
                <p className={`text-secondary mb-24 ${styles.description}`}>
                  {document.description}
                </p>
              </div>
              <p
                className={`font-medium text-accent-blue button-text justify-start ${styles.cardLink}`}
              >
                {translate('ContextualSupportContent.labels.moreDetails')}
              </p>
            </a>
          </Track>
        ))}
      </div>
      {children}
    </section>
  );
};
