import React from 'react';

import { PurposeType } from '@vcc-package/leads-utils/api';
import { globalStyling } from '@vcc-package/leads-utils/constants';
import { cssMerge } from '@volvo-cars/css/utils';

import { useLeadsContext } from '../context/leadsContext';

type PageProps = {
  className?: string;
  children: React.ReactNode;
};

export default function Page({ className = '', children }: PageProps) {
  const { isEmbeddableForms, purpose, features } = useLeadsContext();
  return (
    <section
      id="layout-root"
      className={cssMerge(
        'mx-auto flex flex-col',
        globalStyling.sectionGap,
        !isEmbeddableForms && globalStyling.pagePadding,
        purpose === PurposeType.KEEP_ME_UPDATED && 'lg:gap-48',
        className,
      )}
      style={{
        maxWidth:
          purpose === PurposeType.OFFER_REQUEST && !features.useNewQuoteFlow
            ? globalStyling.maxWidth
            : globalStyling.formMaxWidth,
      }}
    >
      {children}
    </section>
  );
}
