'use client';
import React from 'react';

import type { TranslationKey } from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { useFormContext } from 'react-hook-form';

import type { ValidatedFieldProps } from '../../flexible-forms/components';
import { ValidatedField } from '../../flexible-forms/components';

type GenericTextFieldProps = FieldProps & {
  name: string;
  autoComplete: string;
  labelTranslationKey: TranslationKey;
  hintTranslationKey?: TranslationKey;
};

export const GenericTextField = ({
  translate: t,
  validation,
  hideRequired,
  name,
  labelTranslationKey,
  autoComplete,
  hintTranslationKey,
}: GenericTextFieldProps) => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(t);
  const hint = hintTranslationKey ? t(hintTranslationKey) : undefined;
  const { type, args } = validation[name.toLowerCase()];
  return (
    <ValidatedField
      hideRequired={hideRequired}
      key={name}
      name={name}
      label={t(labelTranslationKey)}
      autoComplete={autoComplete as any}
      messages={messages}
      control={control}
      testid={name}
      hint={hint}
      inputType="Text"
      invalidMessageType={getInvalidMessageTypeByRegexArgs(args?.[0])}
      validate={(value: string) => {
        if (type === 'regex' && args) {
          return new RegExp(args[0]).test(value);
        }
        return true;
      }}
      {...validation[name.toLowerCase()]}
    />
  );
};

const getInvalidMessageTypeByRegexArgs = (
  regex: string = '',
): ValidatedFieldProps['invalidMessageType'] =>
  regex === '^[0-9]+$'
    ? 'digitsOnly'
    : regex === '^[0-9]{4}$'
      ? 'fourDigitsOnly'
      : 'invalid';
