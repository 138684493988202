import React from 'react';

import {
  type FlexFormFieldType,
  PurposeType,
} from '@vcc-package/leads-utils/api';
import { globalStyling } from '@vcc-package/leads-utils/constants';
import type { B2BOptions } from '@vcc-package/leads-utils/types';
import { FlexibleFormSections } from '@vcc-package/leads-utils/types';

import { cssMerge } from '@volvo-cars/css/utils';
import { useLeadsContext } from '../context';
import type { FlexibleFormProps } from './providers/FlexibleFormsProvider';
import FlexibleFormsProvider, {
  useFlexFormContext,
} from './providers/FlexibleFormsProvider';
import { VehiclePicker } from './sections/VehiclePicker';
import { CustomNamedSection } from './sections/custom';
import { DealerPickerSection } from './sections/dealerpicker';
import { KvkkSection } from './sections/kvkk';
import { LegalSection } from './sections/legal';
import { MainSection } from './sections/main';
import { SubmitSection } from './sections/submit';

export interface AdditionalProps {
  b2b?: B2BOptions;
  hideDealerPicker?: boolean;
  defaultValues?: any;
  hideFields?: FlexFormFieldType[];
  hideRequired?: boolean;
  customSubmitLabel?: string;
  useSingleColumn?: boolean;
  inOverlay?: boolean;
}

const LeadsFormSections = () => {
  /////////////
  // Hooks
  /////////////
  const { sections } = useFlexFormContext();
  if (!sections) {
    throw new Error('Not implemented yet');
  }
  /////////////
  // Variables
  /////////////
  const { features, purpose } = useLeadsContext();

  /* Push VehiclePicker and DealerPicker to Quote-form if useNewQuoteFlow is activated */
  let presentationSections = sections;

  if (features.useNewQuoteFlow && purpose === PurposeType.OFFER_REQUEST) {
    if (
      presentationSections.includes(FlexibleFormSections.VehiclePicker) ||
      presentationSections.includes(
        FlexibleFormSections.VehiclePicker.toLocaleLowerCase(),
      ) ||
      presentationSections.includes(FlexibleFormSections.DealerPicker)
    ) {
      presentationSections = presentationSections.filter(
        (section) =>
          section.toLowerCase() !==
            FlexibleFormSections.VehiclePicker.toLowerCase() &&
          section !== FlexibleFormSections.DealerPicker,
      );
    }

    presentationSections = [
      FlexibleFormSections.VehiclePicker,
      FlexibleFormSections.DealerPicker,
      ...presentationSections,
    ];
  }

  const formElements = presentationSections.map((section, i) => {
    const key = `${section}_${i}`;

    switch (section?.toLowerCase()) {
      case FlexibleFormSections.DealerPicker:
        return <DealerPickerSection key={key} />;
      case FlexibleFormSections.Main:
        return <MainSection key={key} />;
      case FlexibleFormSections.Legal:
        return <LegalSection key={key} />;
      case FlexibleFormSections.Kvkk:
        return <KvkkSection key={key} />;
      case FlexibleFormSections.Submit:
        return <SubmitSection key={key} />;
      case FlexibleFormSections.VehiclePicker.toLowerCase():
        return <VehiclePicker key={key} />;
      case FlexibleFormSections.Custom:
      default:
        return <CustomNamedSection key={key} sectionName={section} />;
    }
  });

  // If the submit-section isn't included in the config, add it at the end by default
  if (!sections.includes(FlexibleFormSections.Submit)) {
    formElements.push(<SubmitSection key="submit_section" />);
  }

  return (
    <div
      data-test-id="flexible_form_main_wrapper"
      className={cssMerge(
        `flex flex-col w-full ${globalStyling.sectionGap}`,
        purpose === PurposeType.KEEP_ME_UPDATED && 'lg:gap-48',
      )}
      key="flexible_form_main_wrapper"
    >
      {formElements}
    </div>
  );
};

export const FlexibleForm = (props: FlexibleFormProps) => {
  return (
    <FlexibleFormsProvider {...props}>
      <LeadsFormSections />
    </FlexibleFormsProvider>
  );
};
