import { useCallback, useMemo } from 'react';

import { useLeadsContext } from '../src/context';
import type { FieldValidation } from '../src/flexible-forms/components/types';

export type ValidationCallback = (value: string) => Promise<boolean> | boolean;

export const useValidation = (validation?: FieldValidation) => {
  const { API } = useLeadsContext();

  if (!API) {
    throw new Error('Leads Context needs to be declared');
  }

  const validateEmail: ValidationCallback = useCallback(
    async (value: string) => await API.validateEmail(value),
    [API],
  );

  const validatePhone: ValidationCallback = useCallback(
    async (value: string) => await API.validatePhone(value),
    [API],
  );

  const regex = useMemo(
    () => (validation?.args?.[0] ? new RegExp(validation?.args?.[0]) : null),
    [validation?.args],
  );

  const regexValidate: ValidationCallback = useCallback(
    (value: string) => {
      return isRegexValidationEnabled(validation) && regex
        ? regex.test(value)
        : true;
    },
    [validation, regex],
  );

  return { validateEmail, validatePhone, regexValidate, regex, ...validation };
};

const isRegexValidationEnabled = (
  val?: FieldValidation,
): val is FieldValidation & { args: string[] } =>
  !!val && val.type === 'regex' && !!val.args?.length;
