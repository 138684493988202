import type { LeadsContextProperties } from '../../../src/context';
import type { ABTest } from '../types';

export const retailerPickerOnTop: ABTest = async (
  context: LeadsContextProperties,
) => {
  if (context.features.retailerPickerOnTop && context.formConfig?.order) {
    console.log('Running experiment: retailerPickerOnTop');

    let order = context.formConfig.order;

    if (order.includes('dealerpicker') || order.includes('dealerPicker')) {
      order = order.filter((field) => field.toLowerCase() !== 'dealerpicker');

      context.formConfig.order = ['dealerpicker', ...order];
    }
  }

  return context;
};
