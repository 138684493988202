'use client';
import type { ChangeEvent } from 'react';
import React from 'react';

import { Select } from '@volvo-cars/react-forms';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { useRequiredLabel } from '../../../../hooks/useRequiredLabel';
import type { Messages, SelectOption } from '../types';

type FieldProps = {
  name: string;
  testid?: string;
  defaultValue?: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  control: Control;
  messages?: Messages;
  options: SelectOption[];
  hideRequired?: boolean;
  onSelect?: (o: ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectField = ({
  name,
  testid,
  defaultValue = '',
  label,
  placeholder = '',
  control,
  required = false,
  messages,
  options,
  hideRequired = false,
  onSelect,
}: FieldProps) => {
  const formattedLabel = useRequiredLabel(label, required, hideRequired, true);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{
        required: {
          value: required,
          message: messages?.required ? messages.required(label) : '',
        },
      }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => (
        <Select
          ref={ref}
          name={name}
          label={formattedLabel}
          data-testid={testid}
          value={value}
          onChange={(v) => {
            onChange(v);
            onSelect && onSelect(v);
          }}
          onBlur={onBlur}
          errorMessage={error ? error.message : ''}
          // @ts-ignore
          // Overflow: Prevent (long selected option's) hidden text to widen the viewport on iOS
          // Padding: Prevent overlapping long text & chevron
          style={{ overflowX: 'hidden', paddingRight: '54px' }}
        >
          {placeholder && (
            <option disabled hidden value="">
              {placeholder}
            </option>
          )}
          {options?.length &&
            options.map((option, i) => (
              <option
                key={`${name}_option_${i}`}
                data-testid={`${testid}_option_${i}`}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
        </Select>
      )}
    />
  );
};
