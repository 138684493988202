import { cssJoin } from '@volvo-cars/css/utils';
import React, { PropsWithChildren } from 'react';
import { CarPriceViewProps } from './car-price.types';
import styles from './car-price.view.module.css';
import { CarPriceViewSkeleton } from './car-price.view.skeleton';

export const CarPriceView = (props: CarPriceViewProps) => {
  const {
    carPrice,
    carPriceSources,
    variant,
    layout,
    loading,
    isValidCarPrice = true,
    noPriceFallback,
    'data-nosnippet': dataNoSnippet,
  } = props;

  if (!isValidCarPrice) {
    return typeof noPriceFallback === 'function'
      ? noPriceFallback()
      : (noPriceFallback ?? null);
  }

  if (loading) {
    return <CarPriceViewSkeleton variant={variant} layout={layout} />;
  }
  return (
    <span
      {...(dataNoSnippet ? { 'data-nosnippet': true } : undefined)}
      className={cssJoin(
        layout?.includes('COMPACT') && 'micro flex-grow',
        layout?.includes('BOLD') && 'font-medium',
      )}
      data-car-price
      data-sources={carPriceSources}
    >
      {carPrice}
    </span>
  );
};

export const PriceFirstLine: React.FC<{
  price: string | undefined;
  wasPrice?: string;
}> = ({ price, wasPrice }) => {
  return (
    <span key="purchase-price">
      <span data-autoid="car-price">{price}</span>
      {wasPrice ? (
        <React.Fragment key="was">
          &nbsp;
          <s
            key="was-strikethrough"
            className={cssJoin(
              'text-secondary font-light',
              styles['was-price'],
            )}
          >
            {wasPrice}
          </s>
        </React.Fragment>
      ) : (
        ''
      )}
    </span>
  );
};

export const PriceSecondLine: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <span key="second-line">
      <br key="line-break" />
      <span key="second-line" className="micro font-light">
        {children}
      </span>
    </span>
  );
};
