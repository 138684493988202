import { useCallback } from 'react';

import { useLeadsContext } from '../src/context';

const MIN_MANUAL_ADDRESS_LENGTH = 5;

type Props = { mainlandFilter?: boolean };
export const useSearchAddress = ({ mainlandFilter }: Props) => {
  const { API, features } = useLeadsContext();

  return useCallback(
    async (address: string) => {
      const result = await API.searchAddress({
        address,
        mainlandFilter: mainlandFilter || false,
        useLoqate: features.useLoqate || false,
      });

      if (
        features.useUserInputAsAddress &&
        address.length > MIN_MANUAL_ADDRESS_LENGTH
      ) {
        result.push({ address, outOfBounds: false, id: 'fromUserInput' });
      }

      return result;
    },
    [API, mainlandFilter, features.useLoqate, features.useUserInputAsAddress],
  );
};
