import type {
  FinanceOptions,
  SalesModelId,
} from '@vcc-www/api/finance-options';
import type { DEPRECATED_IconType } from '@volvo-cars/react-icons/compat';

import type { cbvFinanceOptionsComparisonOption } from 'src/content-management/editorial-components/FinanceOptionsComparison/FinanceOptionsComparison.props';
import type {
  FinanceOptionsComparisonServiceV2,
  MergedOption,
} from './finopt-comparison.types';

export const GA_EVENT_CATEGORY = 'finance options comparison';

export const salesModelToLabel: Record<SalesModelId, string> = {
  SUB: 'Subscription',
  SUB_FIXED: 'Lease',
  CASH: 'Purchase',
  LOAN: 'Volvo Loan',
  LEASE: 'Personal Contract Purchase',
  CONTRACT_HIRE: 'Contract Hire',
};

export const mergeOptions = (
  financeOptions: FinanceOptions,
  options: cbvFinanceOptionsComparisonOption[],
) =>
  options
    .reduce((acc, curr) => {
      if (financeOptions[curr.salesModel]) {
        acc.push({
          ...curr,
          ...financeOptions[curr.salesModel],
        } as MergedOption);
      }

      return acc;
    }, [] as MergedOption[])
    .filter(Boolean);

// Merging services into one array so we can render them per row with empty
// rows between them.
export const mergeServices = (options: MergedOption[]) =>
  options.reduce((acc, curr) => {
    for (const service of curr.services) {
      const existingService = acc.find(
        (item) => item.displayName === service.displayName,
      );

      if (!existingService) {
        acc.push({
          ...service,
          [curr.salesModel]: {
            isElectricOnly: service.isElectricOnly,
          },
        });
      } else {
        existingService[curr.salesModel] = {
          isElectricOnly: service.isElectricOnly,
        };
      }
    }

    return acc;
  }, [] as FinanceOptionsComparisonServiceV2[]);

export const iconsMap: Record<
  SalesModelId,
  Record<string, DEPRECATED_IconType>
> = {
  SUB: {
    endOfContract: 'car-carelectrictwoarrows-40',
    contractTerm: 'other-calendarthreedots-40',
    annualMileage: 'map-route-40',
    paymentMethod: 'refresh-40',
    prepayment: 'other-creditcard-40',
    excessFees: 'other-receipt-40',
    settlementOption: 'savings-40',
  },
  SUB_FIXED: {
    endOfContract: 'car-carelectricclock-40',
    contractTerm: 'other-calendarsevendots-40',
    annualMileage: 'map-route-40',
    paymentMethod: 'refresh-40',
    prepayment: 'other-creditcard-40',
    excessFees: 'other-receipt-40',
    settlementOption: 'savings-40',
  },
  CASH: {
    endOfContract: 'car-carelectricavatar-40',
    contractTerm: 'other-calendaronedot-40',
    annualMileage: 'map-mappin-40',
    paymentMethod: 'incentives-40',
    prepayment: 'other-creditcard-40',
    excessFees: 'other-receiptslash-40',
    settlementOption: 'nocosts-40',
  },
  LOAN: {
    endOfContract: 'car-carelectricavatar-40',
    contractTerm: 'other-calendarsevendots-40',
    annualMileage: 'map-mappin-40',
    paymentMethod: 'refresh-40',
    prepayment: 'other-creditcard-40',
    excessFees: 'other-receiptslash-40',
    settlementOption: 'savings-40',
  },
  LEASE: {
    endOfContract: 'car-carelectricthreearrows-40',
    contractTerm: 'other-calendarsevendots-40',
    annualMileage: 'map-route-40',
    paymentMethod: 'moneycirculararrows-40',
    prepayment: 'other-creditcard-40',
    excessFees: 'other-receipt-40',
    settlementOption: 'savings-40',
  },
  CONTRACT_HIRE: {
    endOfContract: 'car-carelectricthreearrows-40',
    contractTerm: 'other-calendarsevendots-40',
    annualMileage: 'map-route-40',
    paymentMethod: 'moneycirculararrows-40',
    prepayment: 'other-creditcard-40',
    excessFees: 'other-receipt-40',
    settlementOption: 'savings-40',
  },
};
