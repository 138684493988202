'use client';
import {
  DEFAULT_DESKTOP_RATIO,
  DEFAULT_MOBILE_RATIO,
} from '@vcc-www/content-types/video.constants';
import { BreakpointsMatchMap } from '@vcc-www/hooks';
import { ResponsiveAspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import { Sources } from '@vcc-www/utils/getMediaSources';
import { useCallback, useState } from 'react';

const getAspectTuple = (ratio: string = '') => ratio?.split(':').map(Number);

export const getAspectRatio = (
  mobileAspectRatio: string = DEFAULT_MOBILE_RATIO,
  desktopAspectRatio: string = DEFAULT_DESKTOP_RATIO,
): ResponsiveAspectRatio => {
  return {
    untilM:
      mobileAspectRatio === 'responsive'
        ? mobileAspectRatio
        : getAspectTuple(mobileAspectRatio),
    default:
      desktopAspectRatio === 'responsive'
        ? desktopAspectRatio
        : getAspectTuple(desktopAspectRatio),
  };
};

export const getSrc = (
  breakpoints: BreakpointsMatchMap,
  srcBreakpoints?: string | Sources | null,
): any => {
  if (!srcBreakpoints || typeof srcBreakpoints === 'string') {
    return srcBreakpoints;
  }
  const existingSrcBreakpoint = (
    Object.keys(breakpoints) as (keyof BreakpointsMatchMap)[]
  )
    .filter((key) => breakpoints[key] && srcBreakpoints[key])
    .pop();

  if (!existingSrcBreakpoint) {
    return srcBreakpoints.default || '';
  }

  return srcBreakpoints[existingSrcBreakpoint];
};

export const hasVideoContent = (content: any, key: string = 'videos') =>
  key in content && Array.isArray(content[key]) && content[key].length > 0;

/**
 * Tiny helper hook, to get durations of the video.
 * `video.duration` is not available straight away
 * and loading metada is async. So use `loadDuration`
 * for state handling and value where you need it.
 * @example
 * const { duration, loadDuration } = useVideoDuration();
 * return (
 *  <Video
 *    onLoadedMetadata={loadDuration}
 *    currentTime={progress * duration}
 *  />
 * )
 */
export function useVideoDuration() {
  const [duration, setDuration] = useState(0);
  const loadDuration = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement>) => {
      setDuration(event.currentTarget.duration);
    },
    [setDuration],
  );
  return {
    duration,
    loadDuration,
  };
}
