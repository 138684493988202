/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilteredMatches } from '../models/FilteredMatches';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressSearchService {
  /**
   * @returns FilteredMatches OK
   * @throws ApiError
   */
  public static getAddress({
    address,
    mainlandFilter,
    siteSlug,
    clientId,
  }: {
    address: string | null;
    mainlandFilter: boolean;
    siteSlug: string;
    clientId?: string;
  }): CancelablePromise<Array<FilteredMatches>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/forms-backend/v1/{siteSlug}/addresssearch/search-address',
      path: {
        siteSlug: siteSlug,
      },
      query: {
        address: address,
        mainlandFilter: mainlandFilter,
        clientId: clientId,
      },
    });
  }
}
