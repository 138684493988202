'use client';

import { useBreakpoints } from '@vcc-www/hooks';
import vtokens from '@volvo-cars/css/tokens';
import { useEffect } from 'react';

export const useSiteFooterMobileStyling = () => {
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilL } = useBreakpoints();

  useEffect(() => {
    const siteFooter = document.getElementById('vcc-site-footer');
    if (!siteFooter) return;
    if (untilL) {
      siteFooter.style.setProperty('padding-bottom', '72px');
      siteFooter.style.setProperty(
        'background-color',
        vtokens.colorBackgroundSecondary,
      );
      document.body.style.setProperty('--chat-button-bottom-offset', '72px');
    } else {
      siteFooter.style.removeProperty('padding-bottom');
      siteFooter.style.removeProperty('background-color');
      document.body.style.removeProperty('--chat-button-bottom-offset');
    }
  });
};
