import { cssMerge } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';
import React from 'react';

type CarouselCardProps = {
  href?: string | undefined;
  filterKey?: string[] | string;
  trackingLabel?: string;
} & React.HTMLAttributes<HTMLAnchorElement | HTMLDivElement>;

const CarouselCard = ({
  children,
  className,
  href,
  filterKey,
  trackingLabel,
  style,
  ...rest
}: React.PropsWithChildren<CarouselCardProps>) => {
  const combinedStyles = {
    ...style,
    '--background-color': 'var(--v-color-background-secondary)',
    outlineOffset: '-2px',
  };

  const elementProps = {
    className: cssMerge('snap-start flex-shrink-0 bg-secondary', className),
    ['data-filter-key']: filterKey,
    ['data-autoid']: 'carousel:card',
    style: combinedStyles,
    ...rest,
  };
  return (
    <Track
      eventAction="card|select"
      eventLabel={trackingLabel ?? 'Unset'}
      disabled={!href && !rest.onClick}
    >
      {href ? (
        <a
          className={cssMerge('snap-start tap-area flex-shrink-0', className)}
          data-filter-key={filterKey}
          tabIndex={0}
          href={href}
          data-autoid="carousel:card"
          style={combinedStyles}
          {...rest}
        >
          {children}
        </a>
      ) : (
        <div {...elementProps}>{children}</div>
      )}
    </Track>
  );
};

export default CarouselCard;
