import { cssJoin } from '@volvo-cars/css/utils';
import React from 'react';
import Carousel from '../../index';
import CarouselCTALink from './components/CarouselCTALink';
import CarouselHeading from './components/CarouselHeading';
import CarouselSliderWrapper from './components/CarouselSliderWrapper';
import CarouselToolbar from './components/CarouselToolbar';
import ErrorBoundary from './components/ErrorBoundary';
import { CarouselProps } from './types/carouselTypes';
import filterCarsOnBackend from './utils/filterCarsOnBackend';

export const BaseCarCarousel = (props: CarouselProps) => {
  const {
    content,
    headingLevel,
    centerAlign,
    cars,
    siteSlug,
    hideIfNoPrice,
    carPriceProps,
    environment,
    bleed,
  } = props;
  const { title, subTitle, filterAllLabel, disclaimerText, backendFilters } =
    content;
  const filteredBackendCars = filterCarsOnBackend(cars, backendFilters ?? []);

  return (
    <ErrorBoundary>
      <Carousel.Core bleed={bleed}>
        <div className="stack-16">
          {title !== undefined && (
            <>
              <CarouselHeading
                className={centerAlign ? 'text-center' : ''}
                headingLevel={headingLevel ?? 'h2'}
              >
                {title}
              </CarouselHeading>
              {subTitle && (
                <p
                  className={cssJoin(
                    'text-secondary',
                    centerAlign && 'text-center',
                  )}
                >
                  {subTitle}
                </p>
              )}
            </>
          )}
          <CarouselToolbar
            allLabel={filterAllLabel}
            centerAlign={Boolean(centerAlign)}
          />
          <CarouselSliderWrapper
            cars={filteredBackendCars}
            content={content}
            hideIfNoPrice={hideIfNoPrice}
            siteSlug={siteSlug}
            carPriceProps={carPriceProps}
            environment={environment}
            centerAlign={centerAlign}
          />
          <div className="stack-32 mt-32">
            <Carousel.Indicator />
            <div className={cssJoin('flex', centerAlign && 'justify-center')}>
              <CarouselCTALink className="justify-start" />
            </div>
            {typeof disclaimerText === 'string' &&
              disclaimerText.length > 0 && (
                <p
                  className={cssJoin(
                    'text-secondary micro',
                    centerAlign && 'text-center',
                  )}
                >
                  {disclaimerText}
                </p>
              )}
          </div>
        </div>
      </Carousel.Core>
    </ErrorBoundary>
  );
};
