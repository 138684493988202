'use client';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

import {
  PurposeType,
  RequestApiModel,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { PurposePrefixLookup } from '@vcc-package/leads-utils/constants';
import type { Translations, Translator } from '@vcc-package/leads-utils/types';
import { create } from 'handlebars';
type Handlebars = ReturnType<typeof create>;

import {
  type LeadsContextProperties,
  useLeadsContext,
  useModel,
  useRetailer,
} from '../src/context';
import type { ModelContextValues } from '../src/context/modelContext';
import type { RetailerContextValues } from '../src/context/retailerContext';
import type { Messages } from '../src/flexible-forms/components/types';
import { getTranslationsObject } from '../util';

const uppercaseDictionaryKeys = (dictionary: Translations) => {
  const newDictionary: Translations = {};
  for (const key in dictionary) {
    newDictionary[key?.toUpperCase() ?? ''] = dictionary[key];
  }
  return newDictionary;
};

const handlebarsTemplateRegex = /\{\{(.+?)\}\}/;

type createTranslatorProps = {
  dictionary: Translations;
  hideMissingTranslationMessage: boolean;
};

type TemplateStateCar = {
  id: string;
  pno34: string;
  title: string;
  thankYouImageUrl?: string;
  modelSideImageUrl?: string;
  engineTypeTranslation?: string;
  modelYear?: number;
  trimLevel?: string;
  isStockCar?: boolean;
  ctaLink?: string;
  commercialModelYear?: string;
};

type TemplateStateRetailer = {
  name?: string;
  phone?: string;
  dealerId?: string;
  vccDealerId?: string;
  email?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
};

type TemplateStateCce = {
  id?: string;
  url?: string;
  pricing?: string;
  displayPrice?: string;
};

type TemplateStateCustomer = {
  description?: string;
  title?: string;
  fullName?: string;
  firstName?: string;
  surName?: string;
  secondSurname?: string;
  mobilePhone?: string;
  address?: string;
  email?: string;
  gender?: string;
  landline?: string;
  additionalInfo?: string;
  business?: boolean;
  legalName?: string;
  contactChannels?: Array<string>;
  contactSlots?: Array<string>;
  isTestLead?: boolean;
};

type TemplateState = {
  purpose?: PurposeType;
  marketId: string;
  campaignCode?: string;

  model?: TemplateStateCar;
  retailer?: TemplateStateRetailer;

  car?: TemplateStateCar;
  dealer?: TemplateStateRetailer;
  customer?: TemplateStateCustomer;
  cce?: TemplateStateCce;
  languageId?: string;
  isRTL?: boolean;

  extra: any;
};

const hbs = create();
export const useCreateTranslator = (
  props: createTranslatorProps,
): Translator => {
  const { dictionary, hideMissingTranslationMessage } = props;
  const { submitData, purpose, isEmbeddableForms, fullConfig } =
    useLeadsContext();
  const { isInsideSales, selectedRetailer } = useRetailer();
  const { selectedModel } = useModel();

  const [dictionaryState, setDictionary] = useState(dictionary);

  useEffect(() => {
    const hasAnyTranslations = Object.keys(dictionaryState ?? {}).length > 0;
    if (!fullConfig?.translations || hasAnyTranslations) {
      return;
    }
    const translateDict = getTranslationsObject(fullConfig.translations);
    if (!translateDict) {
      return;
    }
    setDictionary(uppercaseDictionaryKeys(translateDict));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmbeddableForms, fullConfig, setDictionary, submitData]);

  return internalTranslator({
    dictionary: dictionaryState,
    hideMissingTranslationMessage,
    purpose,
    submitData,
    isInsideSales,
    selectedRetailer,
    selectedModel,
    setDictionary,
  });
};

type InternalTranslatorProps = createTranslatorProps &
  Pick<LeadsContextProperties, 'purpose' | 'submitData'> &
  Pick<RetailerContextValues, 'isInsideSales' | 'selectedRetailer'> &
  Pick<ModelContextValues, 'selectedModel'> & {
    setDictionary?: Dispatch<SetStateAction<Translations>>;
  };

const fallbackAddressFormatTemplate =
  '{{#if legalAddress}}{{legalAddress}}{{else}}{{addressOne}} {{addressTwo}}, {{postalCode}} {{city}}, {{country}}{{/if}}';
const formatSubmitDataAddress = (
  hbs: Handlebars,
  templateStr: string | null,
  request: RequestApiModel,
): string => {
  if (!request) {
    return '';
  }
  const legalAddress = request.legalAddress?.address;
  const addressOne = request.addressOne;
  const addressTwo = request.addressTwo;
  const postalCode = request.postalCode;
  const city = request.city;
  const country = request.addressCountry;

  const template = hbs.compile(templateStr ?? fallbackAddressFormatTemplate);

  const res = template({
    addressOne,
    addressTwo,
    postalCode,
    city,
    country,
    legalAddress,
  });

  return res;
};

export const internalTranslator = (
  props: InternalTranslatorProps,
): Translator => {
  let {
    hideMissingTranslationMessage,
    purpose,
    isInsideSales,
    selectedRetailer,
    selectedModel,
    setDictionary,
    submitData,
  } = props;

  const retailerString = isInsideSales ? 'INSIDE_SALES_' : '';
  const purposePrefix = purpose ? PurposePrefixLookup[purpose] : undefined;

  const dictionary = uppercaseDictionaryKeys({
    ...props.dictionary,
  });

  const translator: Translator = (
    key: string,
    extraState: Record<string, any> = {},
    dictionaryExtensions: Record<string, string> = {},
  ) => {
    const upperCaseKey = key?.toUpperCase();
    if (!dictionary) {
      console.error("Can't find translations");
      throw Error('Dictionary is missing');
    }
    const dic = { ...dictionaryExtensions, ...dictionary };

    const retailerLevelTranslation = `${purposePrefix}_${retailerString}${key}`;
    const purposeLevelTranslation = `${purposePrefix}_${upperCaseKey}`;

    let str = '';
    if (dic[retailerLevelTranslation]) {
      str = dic[retailerLevelTranslation];
    } else if (dic[purposeLevelTranslation]) {
      str = dic[purposeLevelTranslation];
    } else {
      str = dic[upperCaseKey];
    }

    const isHandlebarsTemplate = handlebarsTemplateRegex.test(str ?? key);

    if (isHandlebarsTemplate) {
      const template = hbs.compile(str ?? key);

      const state = {
        car: {
          id: selectedModel?.id,
          pno34: selectedModel?.pno34,
          title: selectedModel?.presentation?.title,
          thankYouImageUrl: selectedModel?.presentation?.images?.ThankYou,
          modelSideImageUrl: selectedModel?.presentation?.images?.ModelSide,
          engineTypeTranslation:
            selectedModel?.presentation?.engineTypeTranslation,
          modelYear: selectedModel?.modelYear,
          trimLevel: selectedModel?.trimLevel,
          isStockCar: selectedModel?.isStockCar,
          ctaLink: selectedModel?.ctaLink,
          commercialModelYear: selectedModel?.commercialModelYear,
        } as TemplateStateCar,
        customer: {
          description: submitData?.description,
          title: submitData?.title,
          fullName: submitData?.fullName,
          firstName: submitData?.firstName,
          surName: submitData?.surName,
          secondSurname: submitData?.secondSurname,
          mobilePhone: submitData?.mobilePhone,
          address: undefined,
          email: submitData?.email,
        } as TemplateStateCustomer,
        dealer: {
          name: selectedRetailer?.name,
          phone: selectedRetailer?.phone,
          dealerId: selectedRetailer?.dealerId,
          vccDealerId: selectedRetailer?.vccDealerId,
          email: selectedRetailer?.generalContactEmail,
          address: selectedRetailer?.address,
          latitude: selectedRetailer?.geoCode?.latitude,
          longitude: selectedRetailer?.geoCode?.longitude,
        } as TemplateStateRetailer,
        cce: {
          id: submitData?.cceId,
          url: submitData?.cceUrl,
          pricing: submitData?.ccePricing,
          displayPrice: submitData?.cceDisplayPrice,
        } as TemplateStateCce,
        purpose: purpose ?? undefined,
        marketId: submitData?.marketId,
        campaignCode: submitData?.campaignCode,
        languageId: submitData?.languageId,
        // retailer: selectedRetailer,
        // form: submitData,
        extra: extraState,
      } as TemplateState;

      //Fallback due to "bad data" in CM/Will be removed when CM is fixed
      state.model = state.car;
      state.retailer = state.dealer;

      if (state.customer && submitData) {
        state.customer.address = formatSubmitDataAddress(
          hbs,
          dic['ADDRESS_FORMAT_TEMPLATE'],
          submitData,
        );
      }

      const tmpl_str = template(state);

      return tmpl_str;
    }

    if (str === undefined) {
      if (hideMissingTranslationMessage) {
        return ' ';
      } else {
        return notFoundMessage(upperCaseKey);
      }
    }

    return str;
  };

  hbs.registerHelper('toUpperCase', (str: string) => str.toLocaleUpperCase());
  hbs.registerHelper('toLowerCase', (str: string) => str.toLocaleLowerCase());
  hbs.registerHelper('translate', (str) => translator(str));

  translator.hideMissingTranslationMessage = hideMissingTranslationMessage;
  translator.oldTranslator = (key: string, ...args: string[]) => {
    let str = translator(key);

    args.length > 0
      ? args.forEach((item, index) => {
          str = str.split('$' + index).join(item);
        })
      : str; // Fallback if no arguments are

    console.debug(
      'oldTranslator is deprecated, use new handlebars syntax instead',
      key,
      args,
      str,
    );

    return str;
  };
  translator.customArgumentRender = ({
    key,
    args,
    renderArgument,
  }): string[] => {
    if (!dictionary) {
      console.error("Can't find translations");
      throw Error('Dictionary is missing');
    }

    const upperCaseKey = key?.toUpperCase();

    let str = dictionary[upperCaseKey] as string | undefined;
    if (str === undefined) {
      if (hideMissingTranslationMessage) {
        str = ' ';
      } else {
        str = notFoundMessage(upperCaseKey);
      }
      return [str];
    }

    const splitString: { text: string; argument: boolean }[] = str
      .split(/(\$\d+)/)
      .map((s) => ({ text: s, argument: false }));

    if (args && args.length > 0) {
      args.forEach((item, index) => {
        const element = splitString.find((s) => s.text === `$${index}`);
        if (element) {
          element.text = item;
          element.argument = true;
        }
      });
    }

    return splitString.map((p, i) =>
      p.argument ? renderArgument(p.text, i) : p.text,
    );
  };
  translator.setDictionary = (newDictionary: Translations) => {
    setDictionary && setDictionary(uppercaseDictionaryKeys(newDictionary));
  };
  translator.getDictionary = (): Translations | null => {
    return dictionary;
  };
  return translator as Translator;
};

export const isTranslationFound = (
  translator: Translator,
  translation: string,
  translationKey: string,
): boolean => {
  if (translator.hideMissingTranslationMessage && translation !== ' ')
    return true;

  return (
    !translator.hideMissingTranslationMessage &&
    translation !== notFoundMessage(translationKey)
  );
};

export const useFallback =
  (translator: Translator, fallback: string) =>
  (key: string, dictionaryExtensions?: Record<string, string>) => {
    const upperCaseKey = key?.toUpperCase();
    const result = translator(upperCaseKey, dictionaryExtensions);

    if (translator.hideMissingTranslationMessage)
      return result === ' ' ? fallback : result;
    return result === notFoundMessage(upperCaseKey) ? fallback : result;
  };

export const useFallbacks =
  (translator: Translator, fallback: string) =>
  (key: string[], dictionaryExtensions?: Record<string, string>) => {
    let result = ' ';

    for (let i = 0; i < key.length; i++) {
      const upperCaseKey = key[i].toUpperCase();
      const translation = translator(upperCaseKey, dictionaryExtensions);

      if (
        translator.hideMissingTranslationMessage
          ? translation !== ' '
          : translation !== notFoundMessage(upperCaseKey)
      ) {
        result = translation;
        break;
      }
    }

    return result === ' ' ? fallback : result;
  };

const notFoundMessage = (key: string) => `<No transl. [${key}]>`;

export const getInputErrorMessages = (translate: Translator): Messages => {
  return {
    required: (...args) =>
      translate.oldTranslator(TranslationKey.REQUIRED_FIELD, ...args),
    length: (...args) =>
      translate.oldTranslator(TranslationKey.LENGTH_FIELD, ...args),
    invalid: (...args) =>
      translate.oldTranslator(TranslationKey.INVALID_FIELD, ...args),
    digitsOnly: (...args) =>
      translate.oldTranslator(TranslationKey.DIGITS_ONLY, ...args),
    fourDigitsOnly: (...args) =>
      translate.oldTranslator(TranslationKey.FOUR_DIGITS_ONLY, ...args),
  };
};
