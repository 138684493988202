'use client';
import { cssMerge } from '@volvo-cars/css/utils';
import NextImage from 'next/image';
import React from 'react';

import akamaiImageLoader from './akamai-loader';
import { BaseImageProps } from './types';

/**
 * BaseImage produce a simple <img> element. This is used as a base image in
 * <ResponsiveImage />, but can also be used directly when a simple <img> tag
 * is needed.
 */
export function BaseImage({
  width,
  height,
  className,
  sizes,
  src,
  alt,
  loading,
  ...props
}: BaseImageProps) {
  if (!src) {
    return null;
  }

  return (
    <NextImage
      width={width}
      height={height}
      className={cssMerge('img aspect-16/9', className)}
      src={src}
      alt={alt}
      sizes={sizes}
      loader={akamaiImageLoader}
      priority={loading === 'eager' ? true : undefined}
      {...props}
    />
  );
}
