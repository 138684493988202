import {
  ResponsiveAspectRatio,
  getAspectRatioCSS,
} from '@vcc-www/utils/getAspectRatioCSS';
import { CSSProperties } from 'react';
import { ExtendCSS } from 'vcc-ui';

export const buttonCSS = (
  largePlayIcon?: boolean,
  centeredPlayIcon?: boolean,
  hasCta?: boolean,
): ExtendCSS => ({
  position: 'absolute',
  bottom: 20,
  right: 20,
  width: largePlayIcon ? 50 : 40,
  height: largePlayIcon ? 50 : 40,
  transition: 'opacity 100ms ease-out',

  ...(centeredPlayIcon && centerCss),
  ...(hasCta && withCTADimensions),

  '&:hover': {
    opacity: 0.7,
  },
});

const centerCss: ExtendCSS = {
  transform: 'translate(50%, 50%)',
  bottom: '50%',
  right: '50%',
};

const withCTADimensions: ExtendCSS = {
  width: 'auto',
  height: 'auto',
};

export const videoFrameCSS: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

export const videoContainerCSS =
  (aspectRatio: ResponsiveAspectRatio, hasPortraitImage?: boolean): ExtendCSS =>
  ({ theme: { breakpoints } }) => ({
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',

    ...getAspectRatioCSS(breakpoints, aspectRatio),

    extend: [
      {
        condition: aspectRatio.default !== 'responsive' && hasPortraitImage,
        style: {
          paddingBottom: '56.25%',
        },
      },
      {
        condition: aspectRatio.untilM !== 'responsive' && hasPortraitImage,
        style: {
          untilM: {
            paddingBottom: '100%',
          },
        },
      },
      {
        condition: aspectRatio.default === 'responsive',
        style: {
          paddingBottom: 'unset',
          padding: 'unset',
          height: '100%',
        },
      },
      {
        condition: aspectRatio.untilM === 'responsive',
        style: {
          untilM: {
            paddingBottom: 'unset',
            padding: 'unset',
            height: '100%',
          },
        },
      },
    ],
  });

export const youtubeVideoContainerCSS: ExtendCSS = {
  position: 'relative',
};
