import { PurposeType, TranslationKey } from '../api';
import { useLeadsContext, useTranslate } from '../src/context';

export const useSubmitButtonLabel = (customSubmitLabel?: string) => {
  const translate = useTranslate();
  const { features } = useLeadsContext();
  const { purpose } = useLeadsContext();

  if (features.useDynamicCTA) {
    switch (purpose) {
      case PurposeType.KEEP_ME_UPDATED:
        return translate(TranslationKey.KMU_SUBMIT_BUTTON);
      case PurposeType.CONTACT_REQUEST:
        return translate(TranslationKey.CR_SUBMIT_BUTTON);
      case PurposeType.CBV_CALLBACK:
        return translate(TranslationKey.CB_SUBMIT_BUTTON);
      case PurposeType.OFFER_REQUEST:
        return translate(TranslationKey.QR_SUBMIT_BUTTON);
      default:
        break;
    }
  }

  return customSubmitLabel ?? translate(TranslationKey.SUBMIT);
};
