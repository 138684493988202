import { CarouselCarData } from '../types/carouselTypes';

type CarFilter = {
  [key in keyof CarouselCarData]?: string[];
};

export const bodyTypeFilters = ['SUV', 'CROSSOVER', 'ESTATE', 'SEDAN'];
export const engineTypeFilters = ['BEV', 'HEV', 'PHEV'];

type BodyTypeFilter = (typeof bodyTypeFilters)[number];
type EngineTypeFilter = (typeof engineTypeFilters)[number];
type CarFilters = ReadonlyArray<BodyTypeFilter | EngineTypeFilter>;

const filterCarsOnBackend = (
  cars: CarouselCarData[],
  filters: CarFilters | CarFilters[number] | undefined,
): CarouselCarData[] => {
  const filtersArray =
    (typeof filters === 'string' ? [filters] : filters)?.map((f) =>
      f.toUpperCase(),
    ) ?? []; // TODO: Sitecore does not support multiselect so we need to convert string to array for supporting old CMS
  const filter: CarFilter = {
    engineTypeKey: [],
    bodyKey: [],
  };

  filtersArray.forEach((f) => {
    if (bodyTypeFilters.includes(f)) filter.bodyKey?.push(f);
    if (engineTypeFilters.includes(f)) filter.engineTypeKey?.push(f);
  });

  return cars.filter((car) => {
    return Object.entries(filter).every(([key, values]) => {
      if (values && values.length > 0) {
        const val = car[key as keyof CarouselCarData];
        return typeof val === 'string' && values.includes(val);
      }
      return true;
    });
  });
};

export default filterCarsOnBackend;
