import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import type { StepsProps } from '../Steps';
import { StepBody } from './StepBody';
import { StepIndicator } from './StepIndicator';
import { StepSubBody } from './StepSubBody';
import { StepTitle } from './StepTitle';

// Internal props that should not be used by the consumer,
// but are used internally
export type StepInternalProps = 'orderNumber' | 'isLast';

export type StepProps = {
  body?: string;
  className?: string;
  'data-testid'?: string;
  orderNumber?: number;
  isLast?: boolean;
  stepStyle?: StepsProps['stepStyle'];
  subBody?: string;
  ticked?: boolean;
  title?: string;
  setTestId?: (name?: string | string[], index?: number | undefined) => string;
};

export const Step: React.FC<StepProps> = ({
  body,
  className,
  'data-testid': testID = '',
  orderNumber,
  isLast,
  stepStyle,
  subBody,
  ticked,
  title,
  setTestId,
}) => (
  <li className={cssMerge('flex-row gap-x-16', className)} data-testid={testID}>
    {/* Step Indicator */}
    <div className="flex-col">
      <StepIndicator
        stepStyle={stepStyle}
        ticked={ticked}
        orderNumber={orderNumber}
        setTestId={(name: string) => setTestId?.([testID, name])}
      />
      {/* Step's vertical line */}
      {!isLast && (
        <div
          className="inline h-full my-4 mx-auto border-l border-ornament"
          data-testid={setTestId?.([testID, 'line'])}
        />
      )}
    </div>
    {/* Step Section */}
    <div className="flex-col flex-shrink pb-24 self-stretch">
      <StepTitle data-testid={setTestId?.([testID, 'title'])}>
        {title}
      </StepTitle>
      <StepBody data-testid={setTestId?.([testID, 'body'])}>{body}</StepBody>
      <StepSubBody data-testid={setTestId?.([testID, 'sub-body'])}>
        {subBody}
      </StepSubBody>
    </div>
  </li>
);
