import React from 'react';

import { TranslationKey } from '../../api';
import { Translator } from '../../types';
import { useLeadsContext } from '../context';

type PrefillDataBannerProps = {
  translate: Translator;
};

export function PrefillDataBanner({ translate }: PrefillDataBannerProps) {
  const title = translate(TranslationKey.PREFILLED_DATA_TITLE);
  const text = translate(TranslationKey.PREFILLED_DATA_TEXT);
  const { features } = useLeadsContext();

  return (
    <>
      {!features.useNewCopyAndStyling && (
        <h2
          className="font-20 font-medium mb-16"
          data-testid="main-section-header-prefill-data-banner-title"
        >
          {title}
        </h2>
      )}
      <p
        className="mb-24"
        data-testid="main-section-header-prefill-data-banner-text"
      >
        {text}
      </p>
    </>
  );
}
