import { Track, TrackingProps } from '@volvo-cars/tracking';
import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { ClickUniqueProps, ComponentWithHref, Click as VCCClick } from 'vcc-ui';

export const Click = forwardRef<
  HTMLButtonElement & HTMLAnchorElement,
  ClickUniqueProps & TrackingProps
>(function Click(props, ref) {
  const {
    trackEventLabel,
    trackData,
    trackEvent,
    trackEventAction,
    ...restProps
  } = props;

  return (
    <Track
      eventAction={trackEventAction}
      eventLabel={trackEventLabel || ''}
      customData={trackData}
      event={trackEvent}
    >
      <VCCClick ref={ref} {...restProps} />
    </Track>
  );
}) as ComponentWithHref<TrackingProps & ClickUniqueProps, string | undefined>;

export type ClickProps = ComponentPropsWithRef<typeof Click>;
