/*
  N.B: List of region codes that are not multilingual but have a non-english region code,
      so we need to replace the region code with 'en' to get the correct locale for fed-graph
  */
export const nonMultilingualRegionCode = [
  'hy-am',
  'id-id',
  'be-by',
  'xk-xk',
  'cnr-me',
  'sr-latn-rs',
];
