'use client';

import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import {
  getSearchParamsInObjectForm,
  getTokenFromPathname,
} from '../../utils/router-params/router-params.utils';
import { RouterQueryType } from './router-query.types';

/**
 * `useRouterQuery`
 *
 * @returns {Object} An object containing:
 * - `searchParamsInObjectForm`: An object representation of the URL's search parameters.
 * - `routerToken`: The last segment of the pathname, considered as the "token".
 * - `routerQuery`: A combined object of the URL's parameters and search parameters, along with the router token.
 *
 * @example
 * const { searchParamsInObjectForm, routerToken, routerQuery } = useRouterQuery();
 *
 * @see {@link getSearchParamsInObjectForm} for how the search parameters are converted to an object.
 * @see {@link getTokenFromPathname} for how the token is extracted from the pathname.
 */

export const useRouterQuery = () => {
  const searchParams = useSearchParams();
  const params = useParams();
  const pathname = usePathname();

  const searchParamsInObjectForm = useMemo(
    () =>
      getSearchParamsInObjectForm({
        searchParams,
      }),
    [searchParams],
  );

  const routerToken = useMemo(
    () => getTokenFromPathname({ pathname: pathname ?? '' }) ?? '',
    [pathname],
  );

  const routerQuery: RouterQueryType = useMemo(
    () => ({ ...params, ...searchParamsInObjectForm, token: routerToken }),
    [params, searchParamsInObjectForm, routerToken],
  );

  return {
    searchParamsInObjectForm,
    routerToken,
    routerQuery,
  };
};
