'use client';

import { TranslationKey } from '@vcc-package/leads-utils/api';
import { LeadsApplications } from '@vcc-package/leads-utils/constants';
import { useTranslations } from '@vcc-package/leads-utils/hooks';
import { Dialog } from '@vcc-package/overlays';
import type { PageViewData } from '@volvo-cars/tracking';
import { useTracker } from '@volvo-cars/tracking';
import React, { useEffect, useState } from 'react';
import { LeadsApp } from '../constants';

type KeepMeUpdatedIntroductionPageProps = {
  onClose: () => void;
  consumerApp: string | null;
};

export default function KeepMeUpdatedIntroductionPage({
  onClose,
  consumerApp,
}: KeepMeUpdatedIntroductionPageProps) {
  const [isOpen, setIsOpen] = useState(true);

  const { t } = useTranslations();

  const track = useTracker();

  useEffect(() => {
    setIsOpen(true);
    track.pageView({
      pageName: LeadsApplications[LeadsApp.KeepMeUpdated].pageName,
      subPageName:
        LeadsApplications[LeadsApp.KeepMeUpdated].pageName +
        ' - introduction page',
      formType: LeadsApplications[LeadsApp.KeepMeUpdated].pageName,
      consumerApp,
    } as PageViewData);
  }, [track, consumerApp]);

  return (
    <Dialog
      className="w-md"
      size="small"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className="grid-cols-2">
        <img
          src="https://wizz.volvocars.com/images/2024/416/v2/exterior/location/topThreeQuartersFrontRight/exterior-location-topThreeQuartersFrontRight_66fd7dbd69fc7d3d5b7b29db1d721dab4b3f7174.png?client=studio&w=640"
          alt="Volvo EX30"
          style={{
            objectFit: 'cover',
            aspectRatio: '4/3',
            height: '100%',
            width: '100%',
          }}
        />
        <div className="flex flex-col items-start justify-center px-48 py-16 gap-24">
          <div>
            <h2 className="font-24 font-medium mb-8">
              {t(TranslationKey.BEFORE_YOU_GO)}
            </h2>
            <p>{t(TranslationKey.STAY_INFORMED_ON_WHATS_HAPPENING)}</p>
          </div>
          <button
            className="button-filled"
            data-color="accent"
            type="button"
            onClick={() => {
              setIsOpen(false);
              onClose();
            }}
          >
            {t(TranslationKey.SIGN_UP_FOR_NEWS_LETTER)}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
