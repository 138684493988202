import type {
  MarketConfiguration,
  PurposeType,
  RequestApiModel,
} from '@vcc-package/leads-utils/api';
import {
  AddressSearchService,
  ConfigurationService,
  ConsumerDataService,
  DealersService,
  FlexFormService,
  OpenAPI,
  RequestService,
  TranslationService,
  ValidationsService,
  VehiclesService,
} from '@vcc-package/leads-utils/api';
import type { LeadsApp } from '@vcc-package/leads-utils/constants';
import { getCookieValue } from '@vcc-package/leads-utils/util';
import type { SiteSlug } from '@volvo-cars/market-sites';

import type { PublicRuntimeConfig } from './config';

export interface ApiClientProps {
  appId: LeadsApp;
  clientId?: string;
  isTesting?: boolean;
  siteSlug: SiteSlug;
  publicRuntimeConfig: PublicRuntimeConfig;
  ssrCookies?: string;
  consent?: {
    TARGETING_AND_ADVERTISING: boolean;
    alertClosed: boolean;
  };
}

export const requestId = () =>
  `${Date.now()}#${Math.random().toString(36).substring(2, 10)}`;

type ArrayParameters<T extends (...args: any) => any> = Array<any> &
  Parameters<T>;
type RequiredOpenAPIHeaders = Exclude<(typeof OpenAPI)['HEADERS'], undefined>;

const lockQueue: Array<Promise<unknown>> = [];

function withHeaders<T extends (...args: any) => Promise<any>>(
  baseHeaders: RequiredOpenAPIHeaders,
  fn: T,
) {
  return async function <A extends ArrayParameters<T>, R extends ReturnType<T>>(
    ...args: A
  ): Promise<R | never> {
    // Wait for all previous requests to finish
    await Promise.all(lockQueue);

    const originalHeaders = OpenAPI.HEADERS;
    const originalCredentials = OpenAPI.CREDENTIALS;

    const specialHeaders: Record<string, string> = {
      'VCC-Api-Operation': requestId(),
    };
    if (typeof document !== 'undefined') {
      const gaCookie = getCookieValue(document.cookie, '_ga') ?? '';

      if (gaCookie) {
        specialHeaders['_ga'] = gaCookie;
      }
    }

    const lock = fn(...(args as any[]));
    lockQueue.push(lock);

    try {
      OpenAPI.HEADERS = {
        ...(baseHeaders ?? {}),
        ...specialHeaders,
      };

      if (OpenAPI.HEADERS.cookie) {
        OpenAPI.CREDENTIALS = 'include';
      }

      return await lock;
    } finally {
      lockQueue.splice(lockQueue.indexOf(lock), 1);
      OpenAPI.HEADERS = originalHeaders;
      OpenAPI.CREDENTIALS = originalCredentials;
    }
  };
}

/**
 * Leads Forms Backend API client
 */
export type API = ReturnType<typeof APIClient>;
export const APIClient = ({
  clientId,
  siteSlug,
  isTesting,
  publicRuntimeConfig,
}: ApiClientProps) => {
  OpenAPI.BASE = publicRuntimeConfig.apiHost;
  const headers: RequiredOpenAPIHeaders = {};

  // authHeader is important to set so that we can authenticate to test/qa system.
  // Otherwise, we need to log in with CDSID
  if (publicRuntimeConfig?.authHeaderName) {
    headers[publicRuntimeConfig.authHeaderName] =
      publicRuntimeConfig.authHeaderValue;
  }

  if (isTesting) {
    headers.SKIPRECAPTCHA = 'true';
  }

  OpenAPI.CREDENTIALS = 'include';
  if (publicRuntimeConfig.useCors || publicRuntimeConfig.appEnv === 'dev') {
    OpenAPI.MODE = 'cors';
  }

  return {
    searchAddress: withHeaders(
      headers,
      async (params: {
        address: string;
        mainlandFilter: boolean;
        useLoqate: boolean;
      }) => {
        return await AddressSearchService.getAddress({
          clientId,
          siteSlug,
          ...params,
        });
      },
    ),

    getMarketConfig: withHeaders(
      headers,
      async () =>
        await ConfigurationService.getConfiguration({ clientId, siteSlug }),
    ),

    getMarketConfigExtract: withHeaders(
      headers,
      async (settings: Array<keyof MarketConfiguration>) =>
        await ConfigurationService.getConfigurationextract({
          clientId,
          siteSlug,
          settings,
        }),
    ),

    getDealers: withHeaders(
      headers,
      async () =>
        await DealersService.getAllDealers({
          clientId,
          siteSlug,
        }),
    ),

    getDealer: withHeaders(
      headers,
      async (dealerId: string) =>
        await DealersService.getDealerByParmaPartnerCode({
          clientId,
          siteSlug,
          parmaPartnerCode: dealerId,
        }),
    ),

    getDealersByAddress: withHeaders(
      headers,
      async (params: { address: string; limit?: number; offset?: number }) =>
        await DealersService.getDealersByAddress({
          clientId,
          siteSlug,
          ...params,
        }),
    ),

    getDealersByZipCode: withHeaders(
      headers,
      async (zipCode: string) =>
        await DealersService.getDealersByZipCode({
          clientId,
          siteSlug,
          zipCode,
        }),
    ),

    getDealersByCoordinates: withHeaders(
      headers,
      async (params: {
        latitude: number;
        longitude: number;
        limit?: number;
        offset?: number;
      }) =>
        await DealersService.getDealersByCoordinates({
          clientId,
          siteSlug,
          ...params,
        }),
    ),

    getFlexFormConfig: withHeaders(
      headers,
      async (purpose: PurposeType) =>
        await FlexFormService.getFlexFormByPurpose({
          clientId,
          siteSlug,
          purpose,
        }),
    ),

    getTranslations: withHeaders(
      headers,
      async () =>
        await TranslationService.getAllTranslations({
          clientId,
          siteSlug,
        }),
    ),

    validateEmail: withHeaders(
      headers,
      async (userEmail: string) =>
        await ValidationsService.getValidateEmail({
          clientId,
          siteSlug,
          userEmail,
        }),
    ),

    validatePhone: withHeaders(
      headers,
      async (phoneNumber: string) =>
        await ValidationsService.getValidatePhone({
          clientId,
          siteSlug,
          phoneNumber,
        }),
    ),

    sendRequest: withHeaders(
      headers,
      async (data: RequestApiModel) =>
        await RequestService.postApiFormsBackendV1Request({
          clientId,
          siteSlug,
          requestBody: data,
        }),
    ),

    getVehicles: withHeaders(
      headers,
      async (purpose: PurposeType | undefined) =>
        await VehiclesService.getVehicles({
          clientId,
          siteSlug,
          purpose,
        }),
    ),

    getFullConfig: withHeaders(
      headers,
      async (params: { purpose: PurposeType; clientId?: string }) =>
        await ConfigurationService.getFull({
          clientId,
          siteSlug,
          ...params,
        }),
    ),

    getConsumerData: withHeaders(
      headers,
      async (id: string | undefined) =>
        await ConsumerDataService.getApiFormsBackendV1Consumerdata({
          id,
        }),
    ),

    getFlags: async (_ga: string) => {
      return await withHeaders(_ga ? { ...headers, _ga } : headers, () => {
        return ConfigurationService.getConfigurationextract({
          clientId,
          siteSlug,
          settings: ['dynamicFeatures'],
        });
      })();
    },
  };
};
