'use client';
import SubNavigation from '@vcc-package/sub-navigation';
import { useTopbarPosition } from '@volvo-cars/site-nav-embed';
import { TrackingProvider } from '@volvo-cars/tracking';
import type cbvDotcomPdpSubmenuProps from 'src/content-management/editorial-components/DotcomPdpSubmenu/DotcomPdpSubmenu.props';

export const DotcomPdpSubmenu = ({ content }: cbvDotcomPdpSubmenuProps) => {
  const { fallbackModelName, modelSubMenuPageLinks, alternativeCta } = content;
  useTopbarPosition('relative');

  if (modelSubMenuPageLinks === undefined) {
    return null;
  }

  return (
    <TrackingProvider eventCategory="dotcom pdp sub menu">
      <SubNavigation.Container background="solid" sticky>
        <SubNavigation.Title
          title={fallbackModelName}
          className="until-md:hidden"
        />
        <SubNavigation.Links
          links={modelSubMenuPageLinks.map((linkObject) => {
            return {
              eventLabel: `link | ${linkObject.link?.href}`,
              href: linkObject.link?.href || '',
              text: linkObject.link?.text || '',
              // TODO: Add accessibility label
              accessibilityLabel: '',
            };
          })}
        />
        {alternativeCta && (
          <SubNavigation.CTAs
            primaryCta={{
              eventLabel: `cta | ${alternativeCta?.href}`,
              href: alternativeCta?.href || '',
              text: alternativeCta?.text || '',
              // TODO: Add accessibility label
              accessibilityLabel: '',
            }}
          />
        )}
      </SubNavigation.Container>
    </TrackingProvider>
  );
};
