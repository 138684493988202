import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import { Dialog, type DialogProps } from '../dialog/Dialog';

export type SheetProps = Omit<DialogProps, 'size'>;

// TODO: When React 19 is out, we can use the new ref as a prop instead of forwardRef
// https://react.dev/blog/2024/04/25/react-19#ref-as-a-prop
export const Sheet = React.forwardRef<HTMLDialogElement, SheetProps>(
  (props, ref) => {
    const { open, onClose, title, children, className, ...rest } = props;
    return (
      <Dialog
        className={cssMerge(className)}
        size="sheet"
        open={open}
        onClose={onClose}
        title={title}
        ref={ref}
        {...rest}
      >
        {children}
      </Dialog>
    );
  },
);

Sheet.displayName = 'Sheet';
