import type { DealerModel } from '@vcc-package/leads-utils/api';
import React from 'react';
import { Card } from '../../../../components';

interface RetailerItemProps {
  id: string | null;
  item: DealerModel & { distanceToCenter?: number };
  selected?: boolean;
  onClick(dealer: DealerModel): void;
  disabled?: boolean;
}

export const RetailerItem = ({
  item,
  selected = false,
  onClick,
  disabled = false,
}: RetailerItemProps) => {
  const setSelected = () => {
    onClick(item);
  };

  const hasPrimaryAddressInfo = item.address?.addressLine1;

  const hasSecondaryAddressInfo =
    item.address?.city || item.address?.postalCode || item.address?.district;

  return (
    <Card
      ariaSelected={selected}
      dataTestid={`retailersuggestion_${item.name}`}
      onClick={() => setSelected()}
      disabled={disabled}
    >
      <div
        className={
          !hasPrimaryAddressInfo && !hasSecondaryAddressInfo
            ? 'flex flex-col items-start'
            : 'flex flex-col items-start gap-8'
        }
      >
        <p className="text-start text-primary font-medium">{item.name}</p>
        <div className="text-secondary w-full text-start">
          <p className="flex-row justify-between items-center w-full">
            <span className="flex">
              {hasPrimaryAddressInfo ? `${item.address?.addressLine1}` : ''}
              {hasSecondaryAddressInfo ? ',' : ''}
            </span>
            <span className="flex">
              {item.distanceToAddress ? item.distanceToAddress : null}
            </span>
          </p>
          <p>
            {item.address?.city ?? ''}
            {item.address?.postalCode ? `, ${item.address.postalCode}` : ''}
            {` ${item.address?.district ?? ''}`}
          </p>
        </div>
      </div>
    </Card>
  );
};
