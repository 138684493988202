import { DesktopSpacings } from './calculateSpacing';
import { SpacingSize, extendedSizes, sizes as sizes_ } from './params';

export type SpacingParams = {
  topOuterSpacing: SpacingSize;
  bottomOuterSpacing: SpacingSize;
  topInnerSpacing: SpacingSize;
  bottomInnerSpacing: SpacingSize;
};

type SpacingCSS = {
  marginTop: number;
  marginBottom: number;
  paddingTop: number;
  paddingBottom: number;
};

export type Spacing = {
  untilM: SpacingCSS;
  fromM: SpacingCSS;
};

export const sizes = {
  ...sizes_,
  ...extendedSizes,
};
const factor = 1.66;

/**
 * @deprecated - use calculateSpacing instead
 */
const getSpacing: <T extends number>({
  params,
  baselineGrid,
}: {
  params: SpacingParams & DesktopSpacings;
  baselineGrid: T;
}) => Spacing = ({ params, baselineGrid }) => {
  const {
    topOuterSpacing,
    bottomOuterSpacing,
    topInnerSpacing,
    bottomInnerSpacing,

    topOuterSpacingDesktop,
    bottomOuterSpacingDesktop,
    topInnerSpacingDesktop,
    bottomInnerSpacingDesktop,
  } = params;
  return {
    untilM: {
      marginTop: sizes[topOuterSpacing] * baselineGrid,
      marginBottom: sizes[bottomOuterSpacing] * baselineGrid,
      paddingTop: sizes[topInnerSpacing] * baselineGrid,
      paddingBottom: sizes[bottomInnerSpacing] * baselineGrid,
    },
    fromM: {
      marginTop:
        factor *
        sizes[topOuterSpacingDesktop || topOuterSpacing] *
        baselineGrid,
      marginBottom:
        factor *
        sizes[bottomOuterSpacingDesktop || bottomOuterSpacing] *
        baselineGrid,
      paddingTop:
        factor *
        sizes[topInnerSpacingDesktop || topInnerSpacing] *
        baselineGrid,
      paddingBottom:
        factor *
        sizes[bottomInnerSpacingDesktop || bottomInnerSpacing] *
        baselineGrid,
    },
  };
};

export default getSpacing;
