import { Markdown } from '@vcc-package/text';
import RichText from '@vcc-www/rich-text';
import React from 'react';

const richTextRegex = /<\/?(?!br|sup|sub)\w*\b[^>]*>/m;

export const StepBody = ({
  children,
  'data-testid': testID,
}: {
  children?: string;
  'data-testid'?: string;
}) => {
  if (!children) return null;

  const bodyIsRichText = richTextRegex.test(children);
  if (!bodyIsRichText) {
    return <Markdown data-testid={testID} markdown={children} enableTracking />;
  }

  return (
    <div className="font-16" data-testid={testID}>
      <RichText enableTracking html={children} />
    </div>
  );
};
