'use client';
import { PromotionalBanner as SharedPromotionalBanner } from '@vcc-package/promotions';
import type React from 'react';
import type PromotionalBannerProps from 'src/content-management/editorial-components/PromotionalBanner/PromotionalBanner.props';

export const PromotionalBanner: React.FC<PromotionalBannerProps> = ({
  content,
}) => {
  const { promotionalBanner } = content;
  return <SharedPromotionalBanner {...promotionalBanner} />;
};
