'use client';

import * as React from 'react';
import { useState } from 'react';

import { TranslationKey } from '@vcc-package/leads-utils/api';

import { useTranslate } from '../context/translateContext';

import { Icon } from '@volvo-cars/react-icons';
import { Message } from '.';

interface UseMyLocationButtonProps {
  onGeolocationRetrievalSuccess: (position: GeolocationCoordinates) => void;
  onGeolocationRetrievalError?: (error: GeolocationPositionError) => void;
}

const UseMyLocationButton = ({
  onGeolocationRetrievalSuccess,
  onGeolocationRetrievalError,
}: UseMyLocationButtonProps) => {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [isGeoLocationDisabled, setIsGeoLocationDisabled] = useState(false);

  const onClick = () => {
    if (!('geolocation' in navigator)) {
      setIsGeoLocationDisabled(true);
      return;
    }
    setIsGeoLocationDisabled(false);
    setIsLoading(true);

    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        onGeolocationRetrievalSuccess(position.coords);
        setIsLoading(false);
      },
      (error: GeolocationPositionError) => {
        setIsGeoLocationDisabled(true);
        onGeolocationRetrievalError?.(error);
        setIsLoading(false);
      },
    );
  };

  return (
    <>
      <button
        type="button"
        className="w-fit button-text link-underlined flex-row gap-8 font-light"
        data-arrow="none"
        data-color="blue"
        aria-disabled={isLoading}
        onClick={onClick}
      >
        <Icon
          icon="map-pin"
          alt="map-pin"
          size={24}
          style={{ marginInlineStart: 0 }}
        />
        {translate(TranslationKey.REDES_RETAILER_MAP_USE_MY_LOCATION)}
      </button>
      {isGeoLocationDisabled && (
        <Message
          intent="Caution"
          description={translate(TranslationKey.PLEASE_ENABLE_GEO_LOCATION)}
        />
      )}
    </>
  );
};

export default UseMyLocationButton;
