'use client';
import {
  ExitIntent,
  LeadsExternalContextProps,
} from '@vcc-package/leads-utils';
import { Dialog, DialogMain } from '@vcc-package/overlays';
// eslint-disable-next-line no-restricted-imports
import { isStorybook } from '@vcc-www/constants';
import { cssMerge } from '@volvo-cars/css/utils';
import type { PropsWithChildren, ReactNode } from 'react';
import React, { useEffect, useState, useCallback } from 'react';
import { LeadsApp } from '../constants';
import { useEmbeddableFormsTracking } from '../hooks/useEmbeddableFormsTracking';
import { useHashTrigger } from '../hooks/useHashTrigger';
import { useRecaptchaAndDialogCompatibility } from '../hooks/useRecaptchaAndDialogCompatibility';
import KeepMeUpdatedIntroductionPage from './KeepMeUpdatedIntroductionPage';
import RequiresEvergageAndEnabledPageTriggersToLoad from './RequiresEvergageAndEnabledPageTriggersToLoad';

export const DialogTrigger = (
  props: PropsWithChildren<{
    title: string;
    dialogContent: ReactNode;
    appId: LeadsApp;
    onExitIntentIntroductionComponent?: LeadsExternalContextProps['onExitIntentIntroductionComponent'];
    hashTrigger?: string;
    consumerApp: string;
    onClick?: (e?: React.MouseEvent<Element>) => void;
    dialogTriggerProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    dialogTriggerClassName?: string;
  }>,
) => {
  const [embeddedFormDialogOpen, setEmbeddedFormDialogOpen] = useState(false);
  useRecaptchaAndDialogCompatibility({
    dialogOpen: embeddedFormDialogOpen,
    setDialogOpen: setEmbeddedFormDialogOpen,
  });
  const {
    onExitIntentIntroductionComponent,
    appId,
    consumerApp,
    onClick,
    dialogTriggerClassName,
  } = props;
  const track = useEmbeddableFormsTracking({
    inDialog: true,
    appId,
    isEmbeddableForms: true,
    dialogOpen: embeddedFormDialogOpen,
    consumerApp,
  });

  const showMainDialogAndTrack = useCallback(
    (e?: React.MouseEvent<Element>) => {
      onClick?.(e);
      setEmbeddedFormDialogOpen(true);
      track.pageView();
      if (props.appId !== LeadsApp.QuoteRequest) {
        track.formLoad();
      }
    },
    [track, props.appId, onClick],
  );

  const { hashTriggerCleanup } = useHashTrigger({
    showMainDialogAndTrack,
    hashTrigger: props?.hashTrigger,
  });

  const onCloseEmbeddedFormDialog = useCallback(() => {
    hashTriggerCleanup();
    setEmbeddedFormDialogOpen(false);
  }, [hashTriggerCleanup, setEmbeddedFormDialogOpen]);

  useEffect(() => {
    if (!window.location.href.includes('testing=true') && !isStorybook) return;

    window.Evergage = {};
    console.log('Evergage mock added');
    window.Evergage.getCurrentPage = function () {
      return { user: { enablePageTriggers: true } };
    };
  }, []);

  const DialogContent = (
    <>
      <button
        type="button"
        onClick={showMainDialogAndTrack}
        className={cssMerge('w-full', dialogTriggerClassName)}
        style={{ zIndex: 1 }}
        {...props.dialogTriggerProps}
      >
        {props.children}
      </button>

      <Dialog
        open={embeddedFormDialogOpen}
        onClose={onCloseEmbeddedFormDialog}
        title={props.title}
        className="h-fit"
      >
        <DialogMain>{props.dialogContent}</DialogMain>
      </Dialog>
    </>
  );

  return (
    <>
      {onExitIntentIntroductionComponent === 'KeepMeUpdatedIntroductionPage' ? (
        <RequiresEvergageAndEnabledPageTriggersToLoad>
          <ExitIntent componentId={onExitIntentIntroductionComponent}>
            <KeepMeUpdatedIntroductionPage
              onClose={showMainDialogAndTrack}
              consumerApp={consumerApp}
            />
          </ExitIntent>
          {DialogContent}
        </RequiresEvergageAndEnabledPageTriggersToLoad>
      ) : (
        DialogContent
      )}
    </>
  );
};
