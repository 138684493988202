import type {
  CustomTranslation,
  FlexFormFieldType,
  FlexFormLegalFieldType,
  TitleOption,
} from '@vcc-package/leads-utils/api';

import type { FieldValidations } from '../src/flexible-forms/components/types';

import type { Translator } from './translations';

// NOTE: These are old FieldName values that are kind of still in use (?). They are currently not listed in the API
export enum NonApiFieldName {
  Business = 'business',
  Description = 'description',
  KvkkOptin = 'kvkk_optin',
  LegalName = 'legalname',
  ManualAddress = 'manualaddress',
  PhoneValidation = 'phonevalidation',
  RetailerId = 'retailerid',
}

export type Options = Record<string, Array<TitleOption>>;
export type CustomTranslations = Record<string, Array<CustomTranslation>>;

export interface FieldProps {
  translate: Translator;
  validation: FieldValidations;
  customTranslation: CustomTranslations;
  hideRequired?: boolean;
  className?: string;
}

export interface TitleFieldProps extends FieldProps {
  options: Options;
}

export enum FlexibleFormSections {
  DealerPicker = 'dealerpicker',
  Main = 'main',
  Legal = 'legal',
  Kvkk = 'kvkk',
  Submit = 'submit',
  Custom = '',
  VehiclePicker = 'vehiclePicker',
}

export type B2BOptions = {
  shouldRender: boolean;
  isB2B: boolean;
};

type EnumToType<key> = key extends FlexFormFieldType.PRODUCTS_OF_INTEREST
  ? string[]
  : string;

export type FormValues = {
  [key in
    | FlexFormLegalFieldType
    | FlexFormFieldType
    | NonApiFieldName]: EnumToType<key>;
};
