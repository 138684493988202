import React from 'react';

import type { MarketVehicleConfiguration } from '@vcc-package/leads-utils/api';
import { cssJoin } from '@volvo-cars/css/utils';

type VehicleCardProps = {
  vehicle: MarketVehicleConfiguration;
  variant?: 'default' | 'small';
};

export const VehicleTitle = ({
  vehicle,
  variant = 'default',
}: VehicleCardProps) => {
  return (
    <p className={cssJoin('flex flex-col', variant === 'small' && ' micro')}>
      <strong className={variant === 'small' ? 'font-light' : 'font-medium'}>
        {vehicle.presentation.title}
      </strong>
      <span className="text-secondary">
        {vehicle.presentation.engineTypeTranslation}
      </span>
    </p>
  );
};
