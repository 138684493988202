'use client';

import { useEffect, useState } from 'react';

type UseDialogMainSlotOptions = {
  /**
   * The ref to the container element.
   */
  ref:
    | React.RefObject<HTMLElement | null>
    | React.MutableRefObject<HTMLElement | undefined>;
};

type UseDialogMainSlotResults = {
  /**
   * The tab index of the container, if the container has no focusable children.
   */
  tabIndex?: 0;
};

export function useDialogMainSlot({
  ref,
}: UseDialogMainSlotOptions): UseDialogMainSlotResults {
  const [hasFocusableChildren, setHasFocusableChildren] = useState(false);

  useEffect(() => {
    const container = ref.current;
    if (!container) return;
    setHasFocusableChildren(elementHasFocusableChildren(container));
  }, [ref]);

  return { tabIndex: hasFocusableChildren ? undefined : 0 };
}

// Taken from reel in `@volvo-cars/react-headless`: https://github.com/volvo-cars/design-system-web/blob/560336a0470a06adecc37931d3cc063fc6fac475/packages/react-headless/src/reel.ts
function elementHasFocusableChildren(element: HTMLElement) {
  const treeWalker = document.createTreeWalker(
    element,
    NodeFilter.SHOW_ELEMENT,
    {
      acceptNode(node: HTMLElement) {
        return isFocusable(node)
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_SKIP;
      },
    },
  );
  return !!treeWalker.nextNode();
}

/**
 * The `tabIndex` property does not directly access the `tabindex` attribute
 * but gives you the computed "tab index" which works even for elements which
 * are focusable by default.
 */
function isFocusable(node: Node) {
  return node.nodeType === 1 && (node as HTMLElement).tabIndex >= 0;
}
