'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { TitleFieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { SelectField } from '../../flexible-forms/components';

export const GenderField = ({
  translate,
  validation,
  options,
  customTranslation,
  hideRequired,
}: TitleFieldProps) => {
  const { control } = useFormContext();
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.GENDER,
  );

  const messages = getInputErrorMessages(translate);

  return (
    <SelectField
      hideRequired={hideRequired}
      key="gender"
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.GENDER_FIELD_LABEL)
      }
      name={FlexFormFieldType.GENDER}
      testid={FlexFormTestID.Gender}
      control={control}
      options={options.Gender}
      messages={messages}
      {...validation.gender}
    />
  );
};
