import { cssMerge } from '@volvo-cars/css/utils';
import React, { CSSProperties, HTMLAttributes } from 'react';
import styles from '../Carousel.module.css';

interface CSSPropertiesWithCSSVariable extends CSSProperties {
  '--current-line-count': string;
  '--current-line-height': string;
}

const SkeletonPart = ({
  children,
  className,
  lineCount = 1,
  style,
  ...props
}: HTMLAttributes<HTMLDivElement> & { lineCount?: number }) => {
  const fontSize = className?.includes('font-')
    ? className.split('font-')[1].split(' ')[0]
    : '16';

  const mergedStyles: CSSPropertiesWithCSSVariable = {
    '--current-line-count': `${lineCount}em`,
    '--current-line-height': `var(--v-font-${fontSize}-lineheight)`,
    ...style,
  };

  return (
    <div
      className={cssMerge('w-full skeleton', className, styles.skeleton_part)}
      {...props}
      style={mergedStyles}
    >
      {children}
    </div>
  );
};

export default SkeletonPart;
