'use client';

import { PopUpDisclaimer, PopUpDisclaimerProps } from '@vcc-package/overlays';
import { cssMerge } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';
import React, { useState, useEffect, useRef } from 'react';
import { Markdown } from '../markdown';

export interface HeadingAndTextProps {
  ariaLabelReadMoreButton?: string;
  enableLineBreak?: boolean;
  classNameHeading?: string;
  classNameText?: string;
  heading?: string;
  readMoreTitle?: string;
  readMoreTrackingLabel?: string;
  supportMarkdown?: boolean;
  text: string;
  HeadingLevel?: 'h1' | 'h2' | 'h3' | 'h4';
  popUpDisclaimer?: PopUpDisclaimerProps;
  index?: number;
  background?: 'white' | 'light' | 'dark' | 'black';
  sources?: {
    readMoreTitle?: string;
    heading?: string | string[];
    text?: string;
  };
}

const HeadingAndText: React.FC<
  React.PropsWithChildren<HeadingAndTextProps>
> = ({
  ariaLabelReadMoreButton = 'headingAndTextReadMore',
  enableLineBreak = false,
  classNameHeading,
  classNameText,
  heading,
  readMoreTitle,
  readMoreTrackingLabel,
  supportMarkdown = false,
  text,
  HeadingLevel = 'h3',
  popUpDisclaimer,
  index,
  background = 'white',
  sources,
}) => {
  const [isReadMoreExpanded, expandReadMore] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [text]);

  const handleReadMoreClick = () => {
    expandReadMore(!isReadMoreExpanded);
  };

  const showExpendBtn = readMoreTitle && !isReadMoreExpanded && height > 48;

  const readMoreBackgroundClassName =
    background === 'dark' || background === 'light'
      ? 'bg-secondary'
      : 'bg-primary';

  return (
    <>
      {heading && (
        <>
          {popUpDisclaimer && popUpDisclaimer.description ? (
            <PopUpDisclaimer
              {...popUpDisclaimer}
              trackLabel={`open PopUpDisclaimer | ${heading}`}
            >
              {(icon) => (
                <HeadingLevel
                  className={cssMerge(
                    'font-medium font-20 mb-8',
                    classNameHeading,
                  )}
                  data-sources={sources?.heading}
                >
                  {heading} {icon}
                </HeadingLevel>
              )}
            </PopUpDisclaimer>
          ) : (
            <HeadingLevel
              className={cssMerge('font-medium font-20 mb-8', classNameHeading)}
              data-sources={sources?.heading}
            >
              {heading}
            </HeadingLevel>
          )}
        </>
      )}
      <div className="flex-col relative">
        <div ref={ref}>
          {supportMarkdown ? (
            <Markdown
              markdown={text}
              className={cssMerge(
                'text-secondary',
                showExpendBtn && `relative max-h-48 truncate whitespace-normal`,
                showExpendBtn && isReadMoreExpanded && 'max-h-full',
                classNameText,
              )}
              data-sources={sources?.text}
            />
          ) : (
            <p
              className={cssMerge(
                'text-secondary',
                showExpendBtn && `relative max-h-48 truncate whitespace-normal`,
                showExpendBtn && isReadMoreExpanded && 'max-h-full',
                enableLineBreak && 'whitespace-pre-line',
                classNameText,
              )}
              data-sources={sources?.text}
            >
              {text}
            </p>
          )}
        </div>
        {showExpendBtn && (
          <Track
            eventAction="link|click"
            eventLabel={
              readMoreTrackingLabel
                ? readMoreTrackingLabel
                : `read more | ${
                    index || index === 0 ? index + ' | ' : ''
                  }${heading}`
            }
          >
            <button
              data-sources={sources?.readMoreTitle}
              type="button"
              className="absolute bottom-0 end-0"
              onClick={handleReadMoreClick}
              aria-label={ariaLabelReadMoreButton || ''}
              aria-expanded={isReadMoreExpanded}
              tabIndex={0}
            >
              <p className={`px-4 font-16 ${readMoreBackgroundClassName}`}>
                ... {readMoreTitle}
              </p>
            </button>
          </Track>
        )}
      </div>
    </>
  );
};

export default HeadingAndText;
