'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { ValidatedAddressSearch } from '../../flexible-forms/components';

export const LegalAddressAsyncField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps) => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.LEGAL_ADDRESS_ASYNC,
  );

  const defaultValue = control._defaultValues[FlexFormFieldType.LEGAL_ADDRESS];

  return (
    <ValidatedAddressSearch
      hideRequired={hideRequired}
      name={FlexFormFieldType.LEGAL_ADDRESS}
      testid={FlexFormTestID.LegalAddressAsync}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.ADDRESS_FIELD_LABEL)
      }
      control={control}
      messages={messages}
      maxLength={250}
      waitOnInput={300}
      {...validation.legaladdressasync}
      defaultValue={defaultValue}
    />
  );
};
