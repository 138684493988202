'use client';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { EmbeddableForms } from '@vcc-package/embeddable-forms';
import {
  DialogFooter,
  DialogMain,
  DialogNavigationPage,
  NavigationDialog,
} from '@vcc-package/overlays';
import React, { useEffect, useMemo, useState } from 'react';
import { initiatePasswordlessLogin } from '../api/initiatePasswordlessLogin';
import { getPartExchangeCarDetails } from '../api/utils/getPartExchangeCarDetails';
import { getPartExchangeQuestions } from '../api/utils/getPartExchangeQuestions';
import { getPartExchangeValuationOffer } from '../api/utils/getPartExchangeValuationOffer';
import { useTranslate } from '../part-exchange.dictionaries-provider';
import {
  usePartExchange,
  usePartExchangeTool,
  useValuationSummary,
} from '../part-exchange.hook';
import { CarDetailsProps, PartExchangeError } from '../part-exchange.types';
import {
  createApolloClient,
  enableContinue,
  pageNavigationMap,
  setupQuestionsAndAnswers,
} from '../part-exchange.utils';
import CarDetailsView from '../part-exchange.view.car-details';
import { CarInputView } from '../part-exchange.view.car-input';
import { QuestionsView } from '../part-exchange.view.questions';
import { SummaryView } from '../part-exchange.view.summary';

export type PartExchangeOverlayProps = {
  open: boolean;
  onClose: () => void;
  overlayTitle?: string;
  opensLeadForm?: boolean;
};

export const PartExchangeOverlay = ({
  onClose,
  open,
  overlayTitle,
  opensLeadForm,
}: PartExchangeOverlayProps) => {
  const {
    questions,
    setQuestions,
    registrationNumber,
    mileage,
    outstandingFinance,
    error,
    setError,
    loginRoute,
    authTokenRoute,
    sendTrackingInfo,
  } = usePartExchange();

  const { getItemFromLocalStorage, saveItemToLocalStorage } =
    usePartExchangeTool();

  const [client, setClient] = useState(
    {} as ApolloClient<NormalizedCacheObject>,
  );

  const [carDetails, setCarDetails] = useState({} as CarDetailsProps);
  const [loading, setLoading] = useState(false);
  const [carInputError, setCarInputError] = useState(false);
  const { equity, questionsWithAnswers } = useValuationSummary();

  const translate = useTranslate();

  const valuationError: PartExchangeError = useMemo(() => {
    return {
      title: translate('financeOptions.partExchangeTool.valuationErrorTitle'),
      body: translate('financeOptions.partExchangeTool.valuationErrorBody'),
    };
  }, [translate]);

  const generalError: PartExchangeError = useMemo(() => {
    return {
      title: translate('financeOptions.partExchangeTool.errorViewTitle'),
      body: translate('financeOptions.partExchangeTool.errorViewBody'),
    };
  }, [translate]);

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const { authToken, authError } = await initiatePasswordlessLogin(
        loginRoute,
        authTokenRoute,
      );
      const apolloClient = createApolloClient(
        authToken || '',
        'volvo',
        'volvo_uk_volvo',
      );
      setClient(apolloClient);
      const questionsWithAnswersFromLS = getItemFromLocalStorage(
        'questionsWithAnswers',
      );
      if (!questionsWithAnswersFromLS) {
        const { data, error } = await getPartExchangeQuestions(apolloClient);
        if (authError || error) {
          setLoading(false);
          setError(generalError);
          return;
        }
        const q = data.getPartExchangeQuestions.payload.questions;
        const questionsWithAnswers = setupQuestionsAndAnswers([...q]);
        setQuestions(questionsWithAnswers);
      } else {
        setQuestions(questionsWithAnswersFromLS);
      }
      setLoading(false);
    };
    if (open) {
      func();
    }
  }, [
    setQuestions,
    getItemFromLocalStorage,
    setError,
    translate,
    loginRoute,
    authTokenRoute,
    generalError,
    open,
  ]);

  const handleCarDetails = async (
    navigate: (path: string) => void,
    goBack: () => void,
  ) => {
    setLoading(true);
    navigate('/car-details');

    const { data, error } = await getPartExchangeCarDetails(
      client,
      registrationNumber,
      mileage,
    );

    if (error) {
      setError(generalError);
      setCarInputError(true);
      goBack(); // Go back to the prev page if there's an error
    }

    if (!data.partExchangeVrmLookup.success) {
      setCarInputError(true);
      goBack(); // Go back to the prev page if there's an errors
    }

    // If successful, proceed to set the data
    if (data.partExchangeVrmLookup.success) {
      const carData = data.partExchangeVrmLookup.payload.vehicles[0];
      setCarInputError(false);
      setCarDetails(carData);
      setLoading(false);
    }
  };

  const getValuation = async (navigate: (path: string) => void) => {
    const answers = questions.flat().map((q) => q.answer);
    const {
      make,
      mileage,
      model,
      plateLetter,
      registrationMonth,
      registrationYear,
      transmission,
      vehicleServiceId,
    } = carDetails;
    const carDetailsInput = {
      make,
      mileage,
      model,
      plateLetter: plateLetter === null ? '' : plateLetter,
      registrationMonth,
      registrationYear,
      transmission,
      vehicleServiceId,
      vrm: registrationNumber,
    };
    navigate('/summary');
    setLoading(true);
    const { data, error } = await getPartExchangeValuationOffer(
      client,
      carDetailsInput,
      answers,
    );
    if (
      error ||
      !data.getPartExchangeValuationOffer.success ||
      data.getPartExchangeValuationOffer.payload?.valuationOffer?.valuation ===
        0
    ) {
      setLoading(false);
      setError(valuationError);
      return;
    }
    const carValuation =
      data.getPartExchangeValuationOffer.payload.valuationOffer.valuation;

    saveItemToLocalStorage('carValuation', carValuation);
    setLoading(false);
  };

  const handleTracking = (currentPath: string) => {
    let trackingInfo = '';
    let stepNumber = currentPath;

    switch (currentPath) {
      case '/': {
        // Page 1
        trackingInfo = mileage;
        break;
      }

      case '/car-details': {
        // Page 2
        trackingInfo = `${carDetails.make} ${carDetails.model}`;
        break;
      }

      case '/questions-0': {
        // Page 3, question group 0
        const answer0 = questions[0][0].question.options.find(
          (option) => option.slug === questions[0][0].answer.answer,
        )?.text;
        trackingInfo = answer0 ?? '';
        break;
      }

      case '/questions-1': {
        // Page 4, question group 1
        let eventLabel1 = '';
        questions[1].forEach((question, i) => {
          const answer = question.answer.answer === 'yes' ? '1' : '0';
          eventLabel1 += `|${i}-${answer}`;
        });
        trackingInfo = eventLabel1;
        break;
      }

      case '/questions-2': {
        // Page 5, question group 2
        const eventLabel2 =
          questions[2][0]?.answer?.answer === 'yes'
            ? `yes|${outstandingFinance}`
            : 'no';
        trackingInfo = eventLabel2;
        break;
      }

      case '/summary': {
        // Page 6
        trackingInfo = `equity|${equity}`;
        break;
      }

      default: {
        console.warn('Unhandled tracking path:', currentPath);
      }
    }

    // Send tracking info with the current path and tracking details
    sendTrackingInfo(`step ${stepNumber}|${trackingInfo}`, 'button|click');
  };

  const handleClose = () => {
    onClose();
    setError(null);
    sendTrackingInfo('close', 'overlay|close');
  };

  const handleGoBack = () => {
    setError(null);
    sendTrackingInfo('back', 'arrow|previous');
  };

  const handleContinueClick = (
    currentPath: string,
    navigate: (path: string) => void,
    goBack: () => void,
  ) => {
    handleTracking(currentPath); // Send tracking info on continue click
    if (currentPath === '/') {
      handleCarDetails(navigate, goBack); // Handle car details fetching on the initial page
      return;
    }

    if (currentPath === '/questions-2') {
      getValuation(navigate); // Handle valuation fetching on the last question page
      return;
    }

    // For all other paths, navigate based on pageNavigationMap
    const nextPath = pageNavigationMap[currentPath];
    if (nextPath) {
      navigate(nextPath);
    } else {
      handleClose(); // Close the overlay if there's no next path
    }
  };

  return (
    <NavigationDialog
      onClose={handleClose}
      open={open}
      onBackNav={handleGoBack}
      size="large"
      render={({ stack, goBack, navigate, scrollableElementRef }) => {
        const currentPage = stack[stack.length - 1].path;
        const isSummaryPage = currentPage === '/summary';
        const isLeadFormPage = currentPage === '/lead-form';

        return (
          <>
            <DialogMain ref={scrollableElementRef}>
              <DialogNavigationPage title={overlayTitle} pathName="/">
                <CarInputView carInputError={carInputError} />
              </DialogNavigationPage>
              <DialogNavigationPage
                title={overlayTitle}
                pathName="/car-details"
              >
                <CarDetailsView loading={loading} carDetails={carDetails} />
              </DialogNavigationPage>
              <DialogNavigationPage
                title={overlayTitle}
                pathName="/questions-0"
              >
                <QuestionsView groupIndex={0} />
              </DialogNavigationPage>
              <DialogNavigationPage
                title={overlayTitle}
                pathName="/questions-1"
              >
                <QuestionsView groupIndex={1} />
              </DialogNavigationPage>
              <DialogNavigationPage
                title={overlayTitle}
                pathName="/questions-2"
              >
                <QuestionsView groupIndex={2} />
              </DialogNavigationPage>
              <DialogNavigationPage title={overlayTitle} pathName="/summary">
                <SummaryView isLoading={loading} />
              </DialogNavigationPage>
              <DialogNavigationPage title={overlayTitle} pathName="/lead-form">
                {isLeadFormPage && (
                  <EmbeddableForms
                    formType="ContactRequest"
                    consumerApp="part-exchange-tool"
                    className="py-0 lg:py-0"
                    settings={{
                      formParameters: {
                        campaigncode: 'tradeinrequestVCUK',
                      },
                      clientId: 'tradein-uk',
                    }}
                    additionalInfo={{
                      VRM: registrationNumber || '',
                      vehicleCondition:
                        questionsWithAnswers[0]?.[0]?.answer?.answer || '',
                      valuation: equity || 0,
                    }}
                  />
                )}
              </DialogNavigationPage>
            </DialogMain>
            <DialogFooter open>
              {(() => {
                if (error || isLeadFormPage) {
                  // No buttons for error or lead form pages
                  return null;
                }

                if (isSummaryPage) {
                  // Summary page logic
                  if (opensLeadForm) {
                    return (
                      <>
                        <button
                          type="button"
                          onClick={() =>
                            handleContinueClick('/summary', navigate, goBack)
                          }
                          className="button-filled"
                        >
                          Contact retailer
                        </button>
                        <button
                          type="button"
                          onClick={() => handleClose()}
                          className="button-outlined"
                        >
                          {translate(
                            'financeOptions.partExchangeTool.closeButton',
                          )}
                        </button>
                      </>
                    );
                  }
                  return (
                    <button
                      type="button"
                      onClick={() => handleClose()}
                      className="button-filled"
                    >
                      {translate('financeOptions.partExchangeTool.closeButton')}
                    </button>
                  );
                }

                // Default case: Show Continue button
                return (
                  <button
                    data-testid="part-exchange-tool:cta"
                    disabled={
                      !enableContinue(
                        currentPage,
                        questions,
                        registrationNumber,
                        mileage,
                        outstandingFinance,
                      )
                    }
                    type="button"
                    onClick={() =>
                      handleContinueClick(currentPage, navigate, goBack)
                    }
                    className="button-filled"
                  >
                    {translate('financeOptions.partExchangeTool.ctaText')}
                  </button>
                );
              })()}
            </DialogFooter>
          </>
        );
      }}
    />
  );
};
