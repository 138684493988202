import { Property } from 'csstype';
import React from 'react';
import {
  Block,
  CurrentTheme,
  ExtendCSS,
  ExtendCSSObject,
  Flex,
  ThemeBreakpoints,
  useTheme,
} from 'vcc-ui';

type MediaQueries = {
  [mediaQuery in keyof ThemeBreakpoints | 'default']?: number;
};
type ExtendCSSObjectWithTheme = ExtendCSSObject<CurrentTheme>;
type Props = {
  size: number | MediaQueries;
  extend?: ExtendCSS;
  extendOuterWrapper?: ExtendCSS;
  contentJustify?: Property.JustifyContent;
  noWrapper?: boolean;
};

const ResponsiveGridHelper: React.FC<React.PropsWithChildren<Props>> = ({
  size: initSize = 12,
  children,
  extend,
  extendOuterWrapper,
  contentJustify,
  noWrapper,
}) => {
  const size = typeof initSize === 'number' ? { default: initSize } : initSize;

  const createWidthMediaQueries = useMediaQueryStyleBlocksCreator(size);

  const component = (
    <Block
      extend={[
        ({ theme: { baselineGrid } }) => ({
          margin: '0 auto',
          width: '100%',
          ...createWidthMediaQueries((size) => ({
            maxWidth:
              baselineGrid * 10.0875 * size + baselineGrid * 3 * (size - 1),
          })),
        }),
        extend,
      ]}
    >
      {children}
    </Block>
  );

  if (noWrapper) {
    return component;
  }

  return (
    <Flex
      extend={[
        {
          alignSelf: 'auto',
          flex: '1 1 auto',
          flexDirection: 'row',
          justifyContent: 'center',
          fromL: {
            justifyContent:
              contentJustify === 'left' ? 'flex-end' : 'flex-start',
          },
        },
        extendOuterWrapper,
      ]}
    >
      {component}
    </Flex>
  );
};

const useMediaQueryStyleBlocksCreator = (mediaQueries: MediaQueries) => {
  const { breakpoints } = useTheme();

  return (callback: (size: number) => ExtendCSSObjectWithTheme) => {
    return (
      Object.entries(mediaQueries) as [[keyof MediaQueries, number | undefined]]
    ).reduce<ExtendCSSObjectWithTheme>((styles, [mediaQueryKey, value]) => {
      if (value === undefined) return styles;
      return Object.assign(
        styles,
        mediaQueryKey === 'default'
          ? callback(value)
          : {
              [breakpoints[mediaQueryKey]]: callback(value),
            },
      );
    }, {});
  };
};

export default ResponsiveGridHelper;
