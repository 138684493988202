'use client';
import React from 'react';

import {
  FlexFormLegalFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import type {
  CustomTranslations,
  Translator,
} from '@vcc-package/leads-utils/types';
import { FlexFormLegalTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext, useFormState } from 'react-hook-form';

import { CheckboxField } from '../../components';
import { getOptin } from '../../optinUtils';

type ValidatedSmsOptinProps = {
  translate: Translator;
  defaultValue: boolean;
  disabled: boolean;
  customTranslation: CustomTranslations;
};

export const ValidatedSmsOptinField = ({
  translate,
  defaultValue,
  disabled,
  customTranslation,
}: ValidatedSmsOptinProps) => {
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormLegalFieldType.VALIDATED_SMS_OPTIN,
  );
  const description = React.useMemo(() => {
    const optinWithValidation = getOptin(
      FlexFormLegalFieldType.VALIDATED_SMS_OPTIN,
    );
    const dependencyFieldName = optinWithValidation.requiredField;
    const requiredFieldDescriptionKey =
      optinWithValidation.requiredFieldDescriptionKey;
    if (dependencyFieldName && requiredFieldDescriptionKey) {
      const dependencyValue = watch(dependencyFieldName);
      const dependencyError = errors[dependencyFieldName];
      const fieldValue = watch(FlexFormLegalFieldType.SMS_OPTIN);
      if ((!dependencyValue || dependencyError) && fieldValue) {
        return translate(requiredFieldDescriptionKey);
      }
      return undefined;
    }
    return undefined;
  }, [errors, translate, watch]);
  return (
    <CheckboxField
      name={FlexFormLegalFieldType.SMS_OPTIN}
      testid={FlexFormLegalTestID.SmsValidated}
      control={control}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.SMS_OPTIN_FIELD_LABEL)
      }
      required={false}
      key={FlexFormLegalFieldType.VALIDATED_SMS_OPTIN}
      defaultValue={defaultValue}
      description={description}
      disabled={disabled}
    />
  );
};
