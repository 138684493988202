'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import {
  getInputErrorMessages,
  useValidation,
} from '@vcc-package/leads-utils/hooks';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import {
  FlexFormTestID,
  NonApiFieldName,
  PhoneValidation,
} from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { ValidatedField } from '../../flexible-forms/components';

import { HiddenInput } from '.';
import { useLeadsContext } from '../../context';

export const MobilePhoneField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
  className,
}: FieldProps) => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.MOBILE_PHONE,
  );
  const { regexValidate } = useValidation(validation?.mobilephone);
  const { features } = useLeadsContext();

  return (
    <>
      <ValidatedField
        hideRequired={hideRequired}
        key="mobilephone"
        name={FlexFormFieldType.MOBILE_PHONE}
        testid={FlexFormTestID.MobilePhone}
        label={
          features.useNewCopyAndStyling
            ? translate(TranslationKey.PHONE_NUMBER_FIELD_LABEL)
            : customTranslationKeys
              ? translate(customTranslationKeys.label)
              : translate(TranslationKey.PHONE_FIELD_LABEL)
        }
        inputType="Tel"
        autoComplete="tel"
        control={control}
        validate={regexValidate}
        messages={messages}
        className={className}
        {...validation.mobilephone}
      />
      <HiddenInput
        name={NonApiFieldName.PhoneValidation}
        value={PhoneValidation.IGNORED}
      />
    </>
  );
};
