'use client';

import { useWindowHeightAndScroll } from '@vcc-www/hooks';
import { cssMerge } from '@volvo-cars/css/utils';
import React, { useEffect, useRef, useState } from 'react';
import styles from './Tooltip.module.css';

export type TooltipProps = {
  content: React.ReactNode;
  className?: string;
  displayAbove?: boolean;
};

export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  content,
  className,
  displayAbove,
  children,
}) => {
  const { windowScroll, windowHeight } = useWindowHeightAndScroll();
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [offsetXLeft, setOffsetXLeft] = useState<number | null>(null);
  const [offsetXRight, setOffsetXRight] = useState<number | null>(null);
  const [halfContainerWidth, setHalfContainerWidth] = useState<number | null>(
    null,
  );
  const [displayAboveChildren, setDisplayAboveChildren] = useState(
    displayAbove || false,
  );

  // Calculating offset needed to keep the tooltip within the viewport
  useEffect(() => {
    const calculateOffsetX = () => {
      if (containerRef?.current && contentRef?.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const contentRect = contentRef.current.getBoundingClientRect();

        const halfContainerWidth = containerRect.width / 2;

        setHalfContainerWidth(halfContainerWidth);

        const positionLeft = containerRect.left - contentRect.width / 2;
        const positionRight = containerRect.right + contentRect.width / 2;

        if (positionLeft < 0) {
          setOffsetXLeft(Math.abs(positionLeft));
        } else if (positionRight > document.body.offsetWidth) {
          setOffsetXRight(
            -(
              document.body.offsetWidth -
              Math.abs(contentRect.width / 2 - containerRect.right)
            ),
          );
        } else {
          setOffsetXLeft(null);
          setOffsetXRight(null);
        }
      }
    };

    calculateOffsetX();

    if (typeof ResizeObserver === 'function') {
      const observer = new ResizeObserver(calculateOffsetX);
      observer.observe(document.body);
      return () => observer.disconnect();
    }
  }, [containerRef, contentRef]);

  useEffect(() => {
    if (!displayAbove && containerRef?.current && contentRef?.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();

      setDisplayAboveChildren(
        containerRect.bottom + contentRect.height > windowHeight,
      );
    }
  }, [displayAbove, containerRef, contentRef, windowScroll, windowHeight]);

  return (
    <div className={styles.tooltip} ref={containerRef}>
      {!displayAboveChildren && children}
      <div
        className={cssMerge(
          'bg-primary',
          styles.tooltipContent,
          displayAboveChildren && styles.tooltipContentAbove,
          className,
        )}
        style={{
          insetInlineStart: offsetXLeft
            ? offsetXLeft
            : offsetXRight
              ? 'unset'
              : undefined,
          insetInlineEnd: offsetXRight ?? undefined,
        }}
        data-color-mode="dark"
        ref={contentRef}
      >
        <div
          style={
            (offsetXLeft || offsetXRight) && halfContainerWidth
              ? {
                  insetInlineStart: offsetXLeft
                    ? `calc(50% - ${offsetXLeft - halfContainerWidth}px)`
                    : offsetXRight
                      ? 'unset'
                      : undefined,
                  insetInlineEnd: offsetXRight
                    ? `calc(-50% - ${offsetXRight - halfContainerWidth}px)`
                    : undefined,
                  transform: offsetXRight ? 'translateX(50%)' : undefined,
                }
              : undefined
          }
          className={`${styles.arrow} ${
            displayAboveChildren ? styles.arrowAbove : ''
          }`}
        />
        {content}
      </div>
      {displayAboveChildren && children}
    </div>
  );
};
