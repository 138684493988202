import { cssJoin, cssMerge } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import React from 'react';
import { MessageType } from './info-message';
import styles from './info-message.module.css';

type InfoMessageViewProps = {
  dismissible: boolean | null | undefined;
  dismissInfoMessage: () => false | void | '';
  link: null | {
    url: string;
    urlText: string;
  };
  message: string;
  messageType: MessageType;
  title?: string | null;
  closeAriaLabel: string;
  className?: string;
  classNameInner?: string;
};

export const InfoMessageView = ({
  dismissible,
  dismissInfoMessage,
  link,
  message,
  messageType,
  title,
  closeAriaLabel,
  className,
  classNameInner,
}: InfoMessageViewProps) => {
  return (
    <div
      className={cssMerge(
        'flex',
        messageType === 'dialog' ? styles['dialog'] : 'p-24',
        className,
      )}
    >
      <div
        data-autoid="info-message"
        className={cssJoin(
          'flex justify-between flex-grow p-16',
          messageType !== 'dialog' && 'bg-secondary',
          ['alert', 'default', 'success'].includes(messageType) &&
            styles[messageType],
          classNameInner,
        )}
      >
        <div className="flex-col">
          {!!title && (
            <h1 className={cssJoin(styles.font, styles.title)}>{title}</h1>
          )}
          <p className={styles.font}>
            {message}{' '}
            {!!link && (
              <a className={styles.link} href={link.url}>
                {link.urlText}
              </a>
            )}
          </p>
        </div>
        {!!dismissible && (
          <button
            type="button"
            className="w-16 h-16 flex ml-16"
            onClick={dismissInfoMessage}
            data-autoid="info-message:remove-button"
            aria-label={closeAriaLabel}
          >
            <Icon icon="x" size={16} />
          </button>
        )}
      </div>
    </div>
  );
};
