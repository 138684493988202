export const camelToKebab = (camelString: string | null | undefined) =>
  (camelString ?? '').replace(/[A-Z]/g, (s) => `-${s.toLowerCase()}`);

export const snakeToKebab = (snakeString: string | null | undefined) =>
  (snakeString ?? '').replace(/_/g, '-').toLowerCase();

export const camelCaseToSpaces = (str: string): string => {
  return str.replace(/([A-Z])/g, ' $1').toLowerCase();
};

export const spaceToKebab = (str: string): string =>
  str.replace(/\s+/g, '-').toLowerCase();
