// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-properties */
'use client';

import type React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { Tracker } from './Tracker';
import type { TrackerOptions } from './TrackerOptions';
import {
  DomTrackingWrapper,
  TrackingContext,
  areTrackingServiceConfigsEqual,
  useDevErrorOnChange,
} from './TrackingProvider';
import type { TrackingData } from './types';

export interface PageTrackingProviderProps
  extends TrackingData,
    Omit<TrackerOptions, 'disabled'> {
  /**
   * Use React Context instead of `data-` attributes to maintain tracking data.
   *
   * @default true
   */
  enableReactTracking?: boolean;
}

export const PageTrackingProvider: React.FC<PageTrackingProviderProps> = ({
  children,
  logging,
  forceLowerCase,
  enableReactTracking = true,
  trackingServiceConfig,
  ...props
}) => {
  const trackingContext = useContext(TrackingContext);

  const trackingData = {
    ...trackingContext.trackingData,
    ...props,
  };

  const isReactTrackingEnabledForTree =
    enableReactTracking || trackingContext.isReactTrackingEnabledForTree;

  const finalTrackingServiceConfig =
    trackingServiceConfig || trackingContext.trackingServiceConfig;

  const didPushPageTracking = useRef(false);

  if (
    trackingContext.hasPageTrackingProvider &&
    process.env.NODE_ENV !== 'production'
  ) {
    throw new Error(
      'Multiple PageTrackingProvider components detected. Please use only one PageTrackingProvider at the root level of your application.',
    );
  }

  if (
    process.env.NODE_ENV !== 'production' &&
    !enableReactTracking &&
    trackingContext.isReactTrackingEnabledForTree
  ) {
    throw new Error(
      `Don't disable React Tracking after it has been enabled in an outer provider`,
    );
  }

  useDevErrorOnChange(enableReactTracking, 'enableReactTracking');
  useDevErrorOnChange(
    trackingServiceConfig,
    'trackingServiceConfig',
    areTrackingServiceConfigsEqual,
  );

  const shouldLog =
    typeof logging === 'boolean' ? logging : trackingContext.logging;

  useEffect(() => {
    if (enableReactTracking && !didPushPageTracking.current) {
      window.dataLayer = window.dataLayer || [];
      const tracker = new Tracker(null, {
        logging: shouldLog,
        forceLowerCase,
        trackingServiceConfig: finalTrackingServiceConfig,
      });
      tracker.pageView(trackingData);
      didPushPageTracking.current = true;
    }
  });

  const trackingChildren = isReactTrackingEnabledForTree ? (
    children
  ) : (
    <DomTrackingWrapper pageload context={props}>
      {children}
    </DomTrackingWrapper>
  );

  return (
    <TrackingContext.Provider
      value={{
        ...trackingContext,
        hasPageTrackingProvider: true,
        trackingData,
        isReactTrackingEnabledForTree,
        trackingServiceConfig: finalTrackingServiceConfig,
        logging: shouldLog,
        forceLowerCase:
          typeof forceLowerCase === 'boolean'
            ? forceLowerCase
            : trackingContext.forceLowerCase,
      }}
    >
      {trackingChildren}
    </TrackingContext.Provider>
  );
};
