import { AccordionSummary as VCCAccordionSummary } from '@volvo-cars/react-accordion';
import type React from 'react';

const AccordionSummary = ({ children }: { children: React.ReactNode }) => {
  return (
    <VCCAccordionSummary
      className="font-20 font-medium py-24"
      data-testid="finance-options-comparison:accordion:label"
    >
      {children}
    </VCCAccordionSummary>
  );
};

export default AccordionSummary;
