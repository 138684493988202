'use client';

import React from 'react';

import { LeadsApp } from '@vcc-package/embeddable-forms';
import { TranslationKey } from '@vcc-package/leads-utils/api';

import { urls } from '../../constants';
import { getUrlParamsAsString } from '../../util';
import { useModel, useRetailer, useTranslate } from '../context';
import { useLeadsContext } from '../context/leadsContext';

const InfoBox = ({
  type,
  hideCTA,
  children,
}: {
  type: 'retailer' | 'model';
  hideCTA: boolean;
  children: React.ReactNode;
}) => {
  const {
    navigation: { goTo, query },
  } = useLeadsContext();
  const { clearSelectedModel } = useModel();
  const { clearSelectedRetailer } = useRetailer();

  const searchParams = new URLSearchParams(query as Record<string, string>);
  const { quotePathWithParams, retailerPathWithParams } =
    useGetUrls(searchParams);
  const translate = useTranslate();
  const dictionary = translate.getDictionary() ?? {};

  const modelTranslationKey = dictionary[TranslationKey.MODEL.toUpperCase()]
    ? TranslationKey.MODEL
    : TranslationKey.MODEL_UPPERCASE;

  const titleTranslationKey =
    type === 'model' ? modelTranslationKey : TranslationKey.RETAILER;

  const url = type === 'model' ? quotePathWithParams : retailerPathWithParams;

  return (
    <div className="flex flex-col gap-4">
      <p className="micro text-secondary">{translate(titleTranslationKey)}</p>
      <div className="flex w-full justify-between items-start">
        <div>{children}</div>
        {!hideCTA && (
          <a
            href={url}
            className="text-accent-blue link-plain font-medium"
            onClick={(ev) => {
              ev.preventDefault();
              if (type === 'model') clearSelectedModel();
              if (type === 'retailer') clearSelectedRetailer();
              goTo(url);
            }}
          >
            {translate(TranslationKey.CHANGE)}
          </a>
        )}
      </div>
    </div>
  );
};

export default InfoBox;

const useGetUrls = (searchParams: URLSearchParams) => {
  const quotePath = urls[LeadsApp.QuoteRequest].root;
  const retailerPath = urls[LeadsApp.QuoteRequest].retailer;

  const searchParamsWithoutModel = getUrlParamsAsString({
    searchParams,
    excludedParams: ['pno', 'modelkey'],
  });
  const searchParamsWithoutRetailerId = getUrlParamsAsString({
    searchParams,
    excludedParams: ['retailerid'],
  });

  const quotePathWithParams = `${quotePath}${searchParamsWithoutModel ? '?' + searchParamsWithoutModel : ''}`;
  const retailerPathWithParams = `${retailerPath}${searchParamsWithoutRetailerId ? '?' + searchParamsWithoutRetailerId : ''}`;

  return {
    quotePathWithParams,
    retailerPathWithParams,
  };
};
