import type { DealerModel } from '@vcc-package/leads-utils/api';
import { ErrorMessage } from '@volvo-cars/react-forms';
import { Icon } from '@volvo-cars/react-icons';
import React, { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AddressAutocompleteInput } from '../../../../components';
import { useLeadsContext, useRetailer } from '../../../../context';
import type { UseGetRetailersProps } from './hooks/useGetRetailers';
import { useGetRetailers } from './hooks/useGetRetailers';
import { RetailerItem } from './retailerItem';
import type { RetailerPickerProps } from './types';

const PAGE_SIZE = 4;

const defaultGetRetailerProps: UseGetRetailersProps = {
  retailerListType: 'Address',
  address: '',
  offset: 0,
  limit: PAGE_SIZE,
};

export const RetailerPicker = ({
  name,
  selectRetailerCloseToAddressLabel,
  changeRetailerLabel,
  selectRetailerLoadMoreLabel,
  messages,
  required,
}: RetailerPickerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { clearErrors, control } = useFormContext();
  const { onRetailerSelect } = useRetailer();

  const [selectedRetailerState, setSelectedRetailer] =
    useState<DealerModel | null>(null);

  const [getRetailersProps, setGetRetailersProps] =
    useState<UseGetRetailersProps>(defaultGetRetailerProps);

  const { purposeConfig } = useLeadsContext();

  const { retailers, isLoading, canLoadMore } =
    useGetRetailers(getRetailersProps);

  if (
    !purposeConfig?.mapConfig?.center ||
    !purposeConfig.mapConfig.country ||
    !purposeConfig.mapConfig.zoom
  ) {
    throw new Error(
      'MapConfiguration is not available or set up for the current market',
    );
  }

  const updateAddress = async (address: string) => {
    setGetRetailersProps((prev) => ({
      ...prev,
      ...defaultGetRetailerProps,
      address,
    }));
  };

  const selectRetailer = (dealer: DealerModel | null) => {
    setSelectedRetailer(dealer);
    if (dealer) {
      onRetailerSelect(dealer);
      if (dealer.dealerId) clearErrors(name);
    }
    if (dealer === null) return;
    if (!ref?.current) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const onGeolocationRetrievalSuccess = ({
    latitude,
    longitude,
  }: GeolocationCoordinates) => {
    setGetRetailersProps((prev) => ({
      ...prev,
      ...defaultGetRetailerProps,
      latitude,
      longitude,
      retailerListType: 'Coordinates',
    }));
  };

  return (
    <Controller
      control={control}
      defaultValue={undefined}
      name={name}
      rules={{
        required: {
          value: required,
          message: messages?.required
            ? messages.required(selectRetailerCloseToAddressLabel)
            : '',
        },
      }}
      render={({
        field: { onChange, ref: textFieldRef },
        fieldState: { error, invalid },
      }) => (
        <div
          className="flex flex-col"
          ref={ref}
          style={{ scrollMarginTop: '140px' }}
        >
          {selectedRetailerState === null ? (
            <div>
              <AddressAutocompleteInput
                onSelect={updateAddress}
                onGeolocationRetrievalSuccess={onGeolocationRetrievalSuccess}
                testId="search-retailer"
                textFieldRef={textFieldRef}
                invalid={invalid}
                placeTypes={
                  purposeConfig?.mapConfig?.validationForRetailerSearch
                    ?.onlyPostalCodeResults
                    ? ['postal_code']
                    : ['geocode']
                }
              />
            </div>
          ) : null}
          {retailers.length > 0 && (
            <div className="flex flex-col mt-24">
              {selectedRetailerState ? (
                <>
                  <RetailerItem
                    id={selectedRetailerState.dealerId}
                    key={selectedRetailerState.dealerId}
                    item={selectedRetailerState}
                    onClick={() => {
                      selectRetailer(null);
                      onChange(null);
                    }}
                    selected
                  />
                  <button
                    type="button"
                    className="button-text w-fit"
                    data-arrow="none"
                    data-color="black"
                    data-testid="change-retailer"
                    onClick={() => {
                      selectRetailer(null);
                      onChange(null);
                    }}
                  >
                    {changeRetailerLabel}
                  </button>
                </>
              ) : (
                <div className="flex flex-col gap-16">
                  {retailers.map((item, i) => (
                    <RetailerItem
                      item={item}
                      key={item.dealerId + i.toString()}
                      onClick={(dealer) => {
                        selectRetailer(dealer);
                        onChange(dealer.dealerId);
                      }}
                      id={item.dealerId}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          {canLoadMore && !selectedRetailerState && (
            <button
              type="button"
              className="button-text"
              data-arrow="none"
              data-color="black"
              aria-disabled={isLoading}
              onClick={(ev) => {
                ev.preventDefault();
                setGetRetailersProps((prev) => ({
                  ...prev,
                  offset: prev.offset + PAGE_SIZE,
                }));
              }}
            >
              <span>{selectRetailerLoadMoreLabel}</span>
              <Icon icon="chevron-down" size={12} />
            </button>
          )}

          {isLoading && (
            <div className="flex flex-col items-center">
              <progress aria-label="Loading" className="spinner w-32 h-32" />
            </div>
          )}

          {error && (
            <ErrorMessage errorMessage={error.message} id="retailer-error" />
          )}
        </div>
      )}
    />
  );
};
