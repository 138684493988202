// Optimistic mapping from model names to PNO34 entries
export const PNO34_MAP: { [model: string]: string[] } = {
  C40: ['539E'],
  EC40: ['539E'],
  EX30: ['416E'],
  EX40: ['536E'],
  EX40ELECTRIC: ['536E'],
  EX90: ['356E'],
  S60: ['224K', '224L'],
  S60HYBRID: ['224H', '224B'],
  S90: ['234K', '2340', '234L', '2380', '238L'],
  S90HYBRID: ['234H', '238H', '234B'],
  V60: ['225K', '2250', '225L'],
  V60CC: ['227K', '227L'],
  V60HYBRID: ['225H', '225B'],
  V90: ['235K', '2350', '235L'],
  V90CC: ['236K', '236L', '2360'],
  V90HYBRID: ['235H'],
  XC40: ['536K', '536L', '5361', '536A'],
  XC40BEV: ['536E'],
  XC40ELECTRIC: ['536E'],
  XC40HYBRID: ['536B'],
  XC60: ['246K', '246M', '246L', '2460'],
  XC60HYBRID: ['246H', '246B'],
  XC90: ['256K', '256M', '2560', '256L'],
  XC90HYBRID: ['256H', '256B'],
  ES90: ['334D'],
};
