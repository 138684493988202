import { ZodError, ZodSchema, object, string, infer as zInfer } from 'zod';

export function validateAndParseCookie<T>(
  value: unknown,
  name: string,
): T | null {
  if (!value) {
    return null;
  }
  const schema = cookieSchemas[name];
  const result = schema.safeParse(JSON.parse(value as string));

  if (!result.success) {
    throw new ZodError(result.error.issues);
  }

  return result.data;
}

const coordsSchema = object({
  latitude: string(),
  longitude: string(),
});

const userInputSchema = coordsSchema.extend({
  zipCode: string(),
  regionName: string().optional(),
  regionCode: string().optional(),
  country: string(),
  city: string(),
  formattedAddress: string().optional(),
});

export const ipLocationCookieSchema = coordsSchema.extend({
  zipCode: string(),
  region: string().nullable().optional(),
  country: string(),
  city: string(),
});

export const userLocationCookieSchema = object({
  userInput: userInputSchema.optional(),
  browserCoords: coordsSchema.optional(),
});

export const cookieSchemas: Record<string, ZodSchema> = {
  ew_user_region: ipLocationCookieSchema,
  user_location: userLocationCookieSchema,
};
export type IpLocationCookie = zInfer<typeof ipLocationCookieSchema>;
export type Coordinates = zInfer<typeof coordsSchema>;
export type UserInput = zInfer<typeof userInputSchema>;
export type UserLocationCookie = zInfer<typeof userLocationCookieSchema>;

export interface UserLocation {
  ip?: IpLocationCookie | null;
  userInput?: UserInput | null;
  browserCoords?: Coordinates | null;
}

export type LocationType = UserLocation[keyof UserLocation];
