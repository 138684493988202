import {
  getUserLocation as getServerLocation,
  updateUserLocationCookieServer,
} from './cookies.server';

import { IncomingMessage, ServerResponse } from 'http';
import { getBrowserLocation } from './browser';
import {
  getUserLocation as getClientLocaion,
  updateUserInputInCookieClient,
} from './cookies.client';
import { Coordinates, UserInput, UserLocation } from './validator';

const isServer = (): boolean => typeof window === 'undefined';

export const getUserLocationServer = (
  reqOrCookies: IncomingMessage | Record<any, any>,
): UserLocation => {
  if (!isServer()) {
    throw new Error('getUserLocationServer should only be used on the server');
  }
  return getServerLocation(reqOrCookies);
};

export const getUserLocationClient = async (
  { getBrowserCoords }: { getBrowserCoords?: boolean } = {
    getBrowserCoords: false,
  },
): Promise<UserLocation> => {
  if (isServer()) {
    throw new Error('getUserLocationClient should only be used on the client');
  }

  let userLocation = getClientLocaion();
  let browserCoords: null | Coordinates = null;
  if (getBrowserCoords) {
    browserCoords = await getBrowserLocation();
  }

  return {
    ...userLocation,
    browserCoords,
  };
};

export const setUserLocationFromInputServer = (
  location: UserInput | null,
  reqOrCookies: IncomingMessage | Record<any, any>,
  res: ServerResponse,
) => {
  if (!isServer()) {
    throw new Error(
      'setUserLocationFromInputServer should only be used on the server',
    );
  }
  updateUserLocationCookieServer('userInput', location, reqOrCookies, res);
};

export const setUserLocationFromInputClient = (location: UserInput | null) => {
  if (isServer()) {
    throw new Error(
      'setUserLocationFromInputClient should only be used on the client',
    );
  }
  updateUserInputInCookieClient(location);
};
