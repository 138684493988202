import { BaseImage } from '@vcc-package/media';
import { Dialog } from '@vcc-package/overlays';
import { DisclaimerView } from '@vcc-package/text/disclaimers';
import type { LinkField } from '@vcc-www/content-management-jss-client';
import type CalloutItem from '@vcc-www/content-types/CalloutItem.props';
import { cssJoin } from '@volvo-cars/css/utils';
import React, { useCallback } from 'react';
import { useOverlayState } from './CalloutsOverlayProvider';

export type CalloutsOverlayProps = {
  title?: string;
  callouts: readonly CalloutItem[];
  cta?: LinkField;
  disclaimer?: string;
  isOpen?: boolean;
  /**
   * Defaults to setting overlayIsOpen to false
   */
  closeOverlay?: () => void;
};

const CalloutsOverlay: React.FC<CalloutsOverlayProps> = ({
  title,
  callouts,
  isOpen,
  cta,
  disclaimer,
  closeOverlay,
}) => {
  const { overlayIsOpen, setOverlayIsOpen } = useOverlayState(isOpen);
  const defaultCloseOverlay = useCallback(
    () => setOverlayIsOpen(false),
    [setOverlayIsOpen],
  );
  closeOverlay = closeOverlay || defaultCloseOverlay;

  return (
    <Dialog
      open={overlayIsOpen}
      onClose={closeOverlay}
      title={title}
      size="full"
    >
      <div
        className="w-xl max-w-full flex-col mt-8 mx-auto md:mt-64 mb-48 md:mb-64"
        data-testid="CalloutsOverlay"
      >
        {callouts.map(({ title, description, image, disclaimer }, i) => (
          <React.Fragment key={title || `calloutOverlayItem-${i}`}>
            <div
              className={cssJoin(
                i % 2 !== 0 ? 'md:flex-row-reverse' : 'md:flex-row',
                i > 0 && 'mt-64',
                'flex-col items-center justify-center px-24',
              )}
            >
              <div className="w-full bg-secondary">
                {image && (
                  <BaseImage
                    src={image.src}
                    alt={image.alt}
                    className="aspect-4/3 object-cover w-full h-full"
                    width={1024}
                    height={768}
                    data-autoid={`CalloutsOverlayImage-${i}`}
                  />
                )}
              </div>
              <div
                className={cssJoin(
                  i % 2 !== 0 ? 'md:mr-24' : 'md:ml-24',
                  'mt-24 md:mt-0 w-full flex-col justify-center',
                )}
              >
                <h3
                  className="font-20 font-medium"
                  data-autoid={`CalloutsOverlayTitle-${i}`}
                >
                  {title}
                </h3>
                <p
                  className="mt-8 text-secondary"
                  data-autoid={`CalloutsOverlayDesc-${i}`}
                >
                  {description}
                </p>
              </div>
            </div>
            {disclaimer && (
              <DisclaimerView
                className="mt-32 mx-24 md:text-center"
                markdown={disclaimer}
              />
            )}
          </React.Fragment>
        ))}
        {!!cta?.text && !!cta?.href && (
          <div className="text-center mt-48 md:mt-64">
            <a
              className="button-outlined"
              aria-label={cta?.title}
              data-color="accent"
              href={cta.href}
              target={cta?.target}
            >
              {cta.text}
            </a>
          </div>
        )}
        {!!disclaimer && (
          <DisclaimerView
            className="mt-48 md:mt-64 mx-24 md:text-center"
            markdown={disclaimer}
          />
        )}
      </div>
    </Dialog>
  );
};

export default CalloutsOverlay;
