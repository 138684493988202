import { Source, getMediaSources } from '@vcc-www/utils/getMediaSources';
import React from 'react';
import { Block, BlockProps, useTheme } from 'vcc-ui';
import { PropsImageWithLoadingStrategy } from './Image.types';
import { containerCSS, getSrc, getSrcSet, imageCSS } from './Image.utils';
import type { ImageRefProp } from './ImageDEPRECATED';
import { getSizes } from './ImageNative.utils';
import { EAGER, LAZY, NATIVE_LAZY } from './constants';

const ImageNative: React.FC<
  React.PropsWithChildren<PropsImageWithLoadingStrategy & ImageRefProp>
> = ({
  alt,
  imageDensity,
  imageWidth,
  aspectRatio,
  extend,
  src,
  srcSet,
  source,
  sources,
  sizes,
  objectFit,
  objectPosition,
  disableAutoOptimize = false,
  loadingStrategy,
  onLoad,
  onError,
  imageRef,
  fetchPriority,
  ...otherProps
}) => {
  const { breakpoints } = useTheme();
  const loadingAttribute = loadingStrategy === NATIVE_LAZY ? LAZY : EAGER;
  const legacySrc = getSrc(source, true, loadingStrategy, disableAutoOptimize);

  const preparedSources = disableAutoOptimize
    ? []
    : getMediaSources(sources, breakpoints);

  const handleOnLoad = () => {
    if (onLoad) {
      onLoad();
    }
  };

  const handleError = (e: React.SyntheticEvent) => {
    if (onError && legacySrc) {
      onError({
        src: src || legacySrc,
        srcSet,
        sources,
        error: e.nativeEvent,
      });
    }
  };

  const hasSrc = !!(disableAutoOptimize ? src : legacySrc);

  return (
    <Block
      as="picture"
      extend={[containerCSS({ imageWidth, aspectRatio, objectFit }), extend]}
    >
      {preparedSources.map(({ src, media }: Source) => (
        <Block
          as="source"
          srcSet={getSrcSet(src, undefined, undefined, imageDensity)}
          media={media}
          key={media}
        />
      ))}
      {hasSrc && (
        <Block
          as="img"
          ref={imageRef}
          {...(otherProps as BlockProps<'img'>)}
          onLoad={handleOnLoad}
          onError={handleError}
          {...(disableAutoOptimize
            ? { src }
            : {
                src: legacySrc,
                srcSet: getSrcSet(source, srcSet, undefined, imageDensity),
              })}
          {...(fetchPriority ? { fetchPriority } : {})}
          sizes={sizes && getSizes(sizes)}
          loading={loadingAttribute === LAZY ? loadingAttribute : undefined}
          extend={imageCSS({
            objectFit,
            objectPosition,
            aspectRatio,
          })}
          alt={alt}
        />
      )}
    </Block>
  );
};

export default ImageNative;
