'use client';
import { EmbeddableForms } from '@vcc-package/embeddable-forms';
import {
  DialogFooter,
  DialogMain,
  DialogNavigationPage,
  NavigationDialog,
} from '@vcc-package/overlays';
import { Markdown } from '@vcc-package/text';
import { useFeatureFlags } from '@vcc-www/feature-flags';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { LinkEntry } from '@volvo-cars/content-management-client';
import { cssJoin } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';
import React, { useState } from 'react';
import ContentTypeProps from '../content-management/editorial-components/MerchandisingPromotion/merchandisingPromotion.props';
import { useTranslate } from './dictionaries';
import OfferCard from './merchandising-offer-card';
import OfferDetail from './merchandising-offer-detail';
import styles from './merchandising.module.css';

type Props = {
  offersOverlayContent?: ContentTypeProps['content']['offersOverlay'];
  ctaText: ContentTypeProps['content']['ctaText'];
};

const MerchandisingOffersOverlay = ({
  offersOverlayContent,
  ctaText,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { globalFlags, loading: flagsLoading } = useFeatureFlags();
  const translate = useTranslate();
  const url = useVolvoCarsUrl();
  const REQUEST_QUOTE_CTA_TEXT = translate('shared.merchandising.requestQuote');

  // Get button CTA style className safely. Any misspelling or issues with the
  // flag, default to an outlined button.
  const ctaClassName =
    {
      'button-outlined': 'button-outlined',
      'button-text': 'button-text',
    }[globalFlags.merchandisingPromoCtaStyle] || 'button-outlined';

  const [selectedOfferIndex, setSelectedOfferIndex] = useState<number | null>(
    null,
  );

  if (!offersOverlayContent) {
    return null;
  }

  const { offerCards, disclaimer, overlayTitle, campaignCode } =
    offersOverlayContent;

  const isOfferSelected = selectedOfferIndex !== null;
  const selectedOffer = isOfferSelected ? offerCards[selectedOfferIndex] : null;

  return (
    <>
      {/*
        Not ideal, hiding the CTA until flags have loaded. The other option is
        having the CTA potentially swap from outlined to text, which feels worse.
        Until reliably fetching feature flags server side in edge workers we
        are advised to fetch them client side though.

        If a consuming app is lacking a FeatureFlagsProvider, loading will always
        be true. Therefore, check agains an empty flags object as well to not end
        up in a situation where we don't render the CTA.
      */}
      {(!flagsLoading || Object.keys(globalFlags).length === 0) && (
        <Track eventLabel="merchandising-initiative|open-overlay">
          <button
            onClick={() => setIsOpen(true)}
            className={ctaClassName}
            type="button"
          >
            {ctaText}
          </button>
        </Track>
      )}

      <NavigationDialog
        className="w-lg"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        render={({ navigate, currentPage, scrollableElementRef }) => {
          const activeCampaignCode =
            currentPage === '/' ? campaignCode : selectedOffer?.campaigncode;
          const cta =
            currentPage === '/details'
              ? selectedOffer?.offerDetail?.offerDetailCta
              : undefined;

          return (
            <>
              <DialogMain ref={scrollableElementRef}>
                <DialogNavigationPage title={overlayTitle} pathName="/">
                  {offerCards?.length > 0 && (
                    <div
                      className={cssJoin(
                        styles['cards-grid'],
                        'grid lg:grid-cols-2 gap-16',
                      )}
                    >
                      {offerCards.map((offerCard, index) => (
                        <OfferCard
                          key={index}
                          offerCard={offerCard}
                          index={index}
                          navigate={navigate}
                          setSelectedOfferIndex={setSelectedOfferIndex}
                        />
                      ))}
                    </div>
                  )}
                  {disclaimer && (
                    <Markdown
                      className="micro text-secondary mt-16"
                      markdown={disclaimer}
                    />
                  )}
                </DialogNavigationPage>

                <DialogNavigationPage
                  title={selectedOffer?.title}
                  pathName="/details"
                >
                  {selectedOffer && (
                    <OfferDetail selectedOffer={selectedOffer} />
                  )}
                </DialogNavigationPage>

                <DialogNavigationPage
                  title={REQUEST_QUOTE_CTA_TEXT}
                  pathName="/form"
                >
                  <EmbeddableForms
                    formType="ContactRequest"
                    consumerApp="merchandising-initiative"
                    className="py-0 lg:py-0"
                    settings={{
                      formParameters: {
                        campaigncode: activeCampaignCode,
                      },
                    }}
                    additionalInfo={{
                      url: url.href,
                      additionalInfo:
                        selectedOffer?.additionalInfoToForm ||
                        'No offer details provided - General request.',
                    }}
                  />
                </DialogNavigationPage>
              </DialogMain>

              {currentPage !== '/details' && currentPage !== '/' ? null : (
                <Footer
                  text={REQUEST_QUOTE_CTA_TEXT}
                  navigate={navigate}
                  campaignCode={activeCampaignCode}
                  cta={cta}
                />
              )}
            </>
          );
        }}
      />
    </>
  );
};

const Footer = ({
  text,
  navigate,
  campaignCode,
  cta,
}: {
  text: string;
  navigate: (path: string) => void;
  campaignCode?: string;
  cta?: LinkEntry;
}) => {
  if (!cta && !campaignCode) {
    return null;
  }

  return (
    <DialogFooter>
      <div className="flex flex-row">
        {campaignCode && text && (
          <Track eventLabel={text}>
            <button
              className="button-filled"
              type="button"
              onClick={() => navigate('/form')}
            >
              {text}
            </button>
          </Track>
        )}
        {cta && (
          <Track eventLabel="merchandising-initiative|car-configurator-cta">
            <a
              className="button-outlined ml-16 w-fit"
              href={cta.href}
              target={cta.target}
            >
              {cta.text}
            </a>
          </Track>
        )}
      </div>
    </DialogFooter>
  );
};

export default MerchandisingOffersOverlay;
