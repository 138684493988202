import { type LeadsContextProperties } from '../../src/context';

import * as experiments from './experiments';
import { leadFormJourneyUpdates } from './experiments/leadFormJourneyUpdates';

export const abTestingContextMapper = async (
  context: LeadsContextProperties,
): Promise<LeadsContextProperties> => {
  if (context.features._not_set !== undefined) {
    return context;
  }
  if (context.navigation.query.disableexperiments === 'true') {
    console.debug('Experiments disabled');
    return context;
  }

  await leadFormJourneyUpdates(context);

  for (const experiment of Object.values(experiments)) {
    context = await experiment(context);
  }

  return context;
};
