import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import { ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';

const logMissingTranslation = memoize((key, { translations, lang }) => {
  const isMissing = !Object.prototype.hasOwnProperty.call(translations, key);
  console.warn(
    `${
      !isMissing ? 'No string value for' : 'Missing'
    } dictionary item: ${key} language: ${lang}`,
  );
});

export const applyTemplate = (
  string: string,
  obj: { [key: string]: any },
): string => {
  return string?.replace(/{(\w+)}/g, (orig, key) => obj[key] ?? orig);
};

export const applyJsxTemplate = (
  string: string | ReactNode[],
  obj: { [key: string]: string | ((match: string) => React.ReactNode) },
): ReactNode[] => {
  let replacedText = string;

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'function') {
      const pattern = new RegExp(`<${key}>(.*?)</${key}>`, 'g');

      replacedText = reactStringReplace(
        replacedText,
        pattern,
        value,
      ) as ReactNode[];
    }
  }
  return typeof replacedText === 'string' ? [replacedText] : replacedText;
};

export const applyJsxAndStringTemplate = (
  string: string,
  obj: { [key: string]: string | ((match: string) => React.ReactNode) },
) => {
  const replacedContent = applyJsxTemplate(string, obj).map(
    (s: string | ReactNode) => {
      if (typeof s === 'string') return applyTemplate(s, obj);
      return s;
    },
  );

  return replacedContent.length === 1 ? replacedContent[0] : replacedContent;
};

export const applyJsxTokenTemplate = (
  string: string | ReactNode[],
  obj: {
    [key: string]: string | ((match: string) => React.ReactNode);
  },
): ReactNode[] => {
  let replacedText = string;

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'function') {
      const pattern = new RegExp(`({${key}})`, 'g');
      replacedText = reactStringReplace(
        replacedText,
        pattern,
        value,
      ) as ReactNode[];
    }
  }
  return typeof replacedText === 'string' ? [replacedText] : replacedText;
};

export const cleanTemplate = (
  string: string,
  obj: { [key: string]: string | null },
) => string.replace(/{(\w+)}/g, (orig, key) => (key in obj ? '' : orig));

export const createTranslate =
  <R = string>(data?: {
    translations: any;
    settings: any;
    outputJsx?: boolean;
  }) =>
  (key: string, params?: any): R | string => {
    const { translations, settings, outputJsx = {} } = data || {};
    const isRequired = params?.isRequired ?? true;

    if (!translations) return ''; // during getDataFromTree

    const missingTranslation = `{${key.replace(/\./g, '.\u200B')}}`;

    // prevent missing translation key errors on getDataFromTree
    if (isEmpty(translations)) {
      return missingTranslation;
    }

    if (
      Object.prototype.hasOwnProperty.call(translations, key) &&
      typeof translations[key] === 'string' &&
      translations[key].trim() !== ''
    ) {
      const translation = translations[key].trim();
      return params
        ? outputJsx
          ? (applyJsxAndStringTemplate(translation, params) as R)
          : applyTemplate(translation, params)
        : translation;
    }

    if (isRequired) {
      logMissingTranslation(key, { translations, lang: settings.lang || '' });
    }

    const dev = translations.devTranslations;
    if (
      dev &&
      Object.prototype.hasOwnProperty.call(dev, key) &&
      typeof dev[key] === 'string' &&
      dev[key].trim() !== ''
    ) {
      console.warn(
        `Falling back to dev translation key: ${key} language: ${
          settings.lang || ''
        }`,
      );
      return dev[key].trim();
    }

    return isRequired ? missingTranslation : '';
  };
