'use client';
import { cssJoin } from '@volvo-cars/css/utils';
import { TextInput } from '@volvo-cars/react-forms';
import { Icon } from '@volvo-cars/react-icons';
import { useTracker } from '@volvo-cars/tracking';
import debounce from 'lodash/debounce';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { FaqQuestion, type Question } from './FaqQuestion';
import styles from './faq.module.css';

const SEARCH_DEBOUNCE_RATE = 500;
const SEARCH_TRACKING_DEBOUNCE_RATE = 3000;

interface FaqSearchProps {
  placeholder: string;
  resultsFoundText: string;
  noResultsFoundText: string;
  questions: Question[];
  testId?: string;
}

// Filters the question based on the input query and returns sorted results
const filterAndSortQuestions = (questions: Question[], query: string) => {
  // Split query into words and remove special characters
  const splitQuery = query
    .toLowerCase()
    .replace(/\?|\.|,/g, '')
    .split(' ');
  const sortedQuestions: { question: Question; score: number }[] = [];
  for (const question of questions) {
    // Assign a score to each question based on how many words in the query are found in the question and the answer
    let score = 0;
    for (const word of splitQuery) {
      const searchDocument = `${question.question} ${question.answer}`;
      const normalizedDocument = searchDocument
        .toLowerCase()
        .replace(/\?|\.|,/g, '');
      if (normalizedDocument.includes(word)) {
        score++;
      }
    }

    if (score > 0) {
      sortedQuestions.push({ question, score });
    }
  }

  return sortedQuestions
    .sort((a, b) => b.score - a.score)
    .map((result) => result.question);
};

export const FaqSearch = ({
  placeholder,
  resultsFoundText,
  questions,
  testId,
}: FaqSearchProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Question[]>([]);
  const [hasSearched, setHasSearched] = useState(false);

  const tracker = useTracker();
  const eventCategory = 'faq-search';

  const runSearchQuery = useCallback(
    (query: string) => {
      debounce(() => {
        if (query.length >= 3) {
          setHasSearched(true);

          const searchResults = filterAndSortQuestions(questions, query);
          setSearchResults(searchResults);
        }
      }, SEARCH_DEBOUNCE_RATE)();
    },
    [questions],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: need to investigate if removing deps breaks the functionality
  const debouncedTrackSearchQuery = useCallback(
    debounce((query: string, searchResultsLength: number) => {
      if (query) {
        tracker.customEvent({
          eventCategory,
          eventAction: 'input field|click',
          eventLabel: `${query}|${
            searchResultsLength === 0 ? 'no result' : 'result'
          }`,
        });
      }
    }, SEARCH_TRACKING_DEBOUNCE_RATE + SEARCH_DEBOUNCE_RATE),
    [tracker, eventCategory],
  );

  const cancelDebouncedTrackSearchQuery = () => {
    debouncedTrackSearchQuery.cancel();
  };

  useEffect(() => {
    runSearchQuery(searchQuery);
    debouncedTrackSearchQuery(searchQuery, searchResults.length);

    if (searchQuery.length < 3) {
      setHasSearched(false);
    }
  }, [
    debouncedTrackSearchQuery,
    runSearchQuery,
    searchQuery,
    searchResults.length,
  ]);

  const groupedSearchResults: Record<string, Question[]> = searchResults.reduce(
    (acc, curr) => {
      if (!curr.topic) {
        return acc;
      }

      if (!acc[curr.topic]) {
        acc[curr.topic] = [];
      }

      acc[curr.topic].push({ question: curr.question, answer: curr.answer });

      return acc;
    },
    {} as Record<string, Question[]>,
  );

  return (
    <>
      <div className={styles['search-input-container']}>
        <TextInput
          name={`${testId}__search`}
          data-testid={`${testId}__search`}
          autoComplete="off"
          label={placeholder}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => {
            tracker.customEvent({
              eventCategory,
              eventAction: 'button|click',
              eventLabel: 'start',
            });
          }}
        />
        <TextInputIcon
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>
      {hasSearched && (
        <>
          <p
            data-testid={`${testId}__search__results`}
            className={cssJoin('mt-32', searchResults.length > 0 && 'mb-24')}
          >
            <strong>
              {resultsFoundText.replace(
                /{amount}/i,
                searchResults.length.toString(),
              )}
            </strong>
          </p>
          {Object.keys(groupedSearchResults).length > 0 ? (
            <div className="flex-col gap-32">
              {Object.entries(groupedSearchResults).map(([topic, results]) => (
                <div key={topic}>
                  <small className="micro uppercase text-secondary font-medium">
                    {topic}
                  </small>
                  {results.map((question, i) => (
                    <SearchResult
                      key={`${question.question.replace(/ /g, '-')}-${i}`}
                      cancelDebouncedTrackSearchQuery={
                        cancelDebouncedTrackSearchQuery
                      }
                      searchQuery={searchQuery}
                      testId={testId}
                      eventCategory={eventCategory}
                      {...question}
                    />
                  ))}
                </div>
              ))}
            </div>
          ) : (
            searchResults.map((question, i) => (
              <SearchResult
                key={`${question.question.replace(/ /g, '-')}-${i}`}
                cancelDebouncedTrackSearchQuery={
                  cancelDebouncedTrackSearchQuery
                }
                searchQuery={searchQuery}
                testId={testId}
                eventCategory={eventCategory}
                {...question}
              />
            ))
          )}
        </>
      )}
    </>
  );
};

const TextInputIcon = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}) => (
  <button
    type="button"
    className={styles['search-button']}
    disabled={!searchQuery}
    onClick={() => {
      setSearchQuery('');
    }}
  >
    <Icon icon={searchQuery ? 'x' : 'search'} size={24} />
  </button>
);

const SearchResult = ({
  testId,
  eventCategory,
  cancelDebouncedTrackSearchQuery,
  searchQuery,
  ...question
}: Question &
  Pick<FaqSearchProps, 'testId'> & {
    cancelDebouncedTrackSearchQuery: () => void;
    searchQuery: string;
    eventCategory: string;
  }) => {
  const tracker = useTracker();

  return (
    <FaqQuestion
      testId={`${testId}__search__result`}
      eventCategory={eventCategory}
      onTrackClick={() => {
        // Cancels the regular search query tracking as we don't need double events
        // if the user clicks a search result within 3s after seeing results.
        cancelDebouncedTrackSearchQuery();
        tracker.customEvent({
          eventCategory,
          eventAction: 'accordion|expand',
          eventLabel: `${searchQuery}|${question.question.toLowerCase()}`,
        });
      }}
      {...question}
    />
  );
};
