import React from 'react';

import { cssJoin } from '@volvo-cars/css/utils';

export type SpinnerProps = {
  className?: string;
  style?: Record<string, string | number>;
};
export const Spinner = ({ className, style }: SpinnerProps) => (
  <div
    className={cssJoin('flex w-full justify-center items-center', className)}
    style={{ height: 340, ...(style ?? {}) }}
  >
    <progress aria-label="Loading" className="spinner" />
  </div>
);
