import React from 'react';

import GridList, { type GridListItemProps } from './components/GridList';
import styles from './finopt-comparison.module.css';
import type { GridProps, MergedOption } from './finopt-comparison.types';
import { iconsMap } from './finopt-comparison.utils';

const PaymentGrid = ({
  options,
  firstSalesModel,
  secondSalesModel,
  thirdSalesModel,
}: GridProps) => {
  const firstFinanceOption = options.find(
    ({ salesModel }) => salesModel === firstSalesModel,
  ) as MergedOption;
  const secondFinanceOption = options.find(
    ({ salesModel }) => salesModel === secondSalesModel,
  ) as MergedOption;
  const thirdFinanceOption = options.find(
    ({ salesModel }) => salesModel === thirdSalesModel,
  ) as MergedOption;

  const firstItemList: GridListItemProps[] = [
    {
      value: firstFinanceOption?.paymentMethod,
      iconName: firstSalesModel && iconsMap[firstSalesModel].paymentMethod,
      column: 1,
    },
    {
      value: firstFinanceOption?.prepayment,
      iconName: firstSalesModel && iconsMap[firstSalesModel].prepayment,
      column: 1,
    },
    {
      value: firstFinanceOption?.excessFees,
      iconName: firstSalesModel && iconsMap[firstSalesModel].excessFees,
      column: 1,
    },
    {
      value: firstFinanceOption?.settlementOption,
      iconName: firstSalesModel && iconsMap[firstSalesModel].settlementOption,
      column: 1,
    },
  ];
  const secondItemList: GridListItemProps[] = [
    {
      value: secondFinanceOption?.paymentMethod,
      iconName: secondSalesModel && iconsMap[secondSalesModel].paymentMethod,
      column: 2,
    },
    {
      value: secondFinanceOption?.prepayment,
      iconName: secondSalesModel && iconsMap[secondSalesModel].prepayment,
      column: 2,
    },
    {
      value: secondFinanceOption?.excessFees,
      iconName: secondSalesModel && iconsMap[secondSalesModel].excessFees,
      column: 2,
    },
    {
      value: secondFinanceOption?.settlementOption,
      iconName: secondSalesModel && iconsMap[secondSalesModel].settlementOption,
      column: 2,
    },
  ];
  const thirdItemList: GridListItemProps[] = [
    {
      value: thirdFinanceOption?.paymentMethod,
      iconName: thirdSalesModel ? iconsMap[thirdSalesModel].paymentMethod : '',
      column: 3,
    },
    {
      value: thirdFinanceOption?.prepayment,
      iconName: thirdSalesModel ? iconsMap[thirdSalesModel].prepayment : '',
      column: 3,
    },
    {
      value: thirdFinanceOption?.excessFees,
      iconName: thirdSalesModel ? iconsMap[thirdSalesModel].excessFees : '',
      column: 3,
    },
    {
      value: thirdFinanceOption?.settlementOption,
      iconName: thirdSalesModel
        ? iconsMap[thirdSalesModel].settlementOption
        : '',
      column: 3,
    },
  ];

  return (
    <div
      className={styles['grid-container']}
      data-testid="finance-options-comparison:payment"
    >
      <GridList listItems={firstItemList} />
      <GridList listItems={secondItemList} />
      {thirdFinanceOption && <GridList listItems={thirdItemList} />}
    </div>
  );
};

export default PaymentGrid;
