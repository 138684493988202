'use client';
import React, { useMemo } from 'react';
import { CarPriceProviderWithSiteSlug } from '../providers/CarPriceProviderWithSiteSlug';
import { BaseCarCarousel } from './BaseCarCarousel';
import { CarCarouselSkeleton } from './components/CarCarouselSkeleton';
import { useCarsForCarousel } from './hooks/useCarsForCarousel';
import { CarouselProps } from './types/carouselTypes';
import { filterCarColorsMapping } from './utils/filterCarColorsMapping';

type ClientCarCarouselProps = Omit<
  CarouselProps,
  'hideIfNoPrice' | 'cars' | 'siteSlug'
> & {
  hideIfNoPrice?: boolean;
};

const Carousel = ({
  throwIfNoCars,
  hideIfNoPrice,
  colorMapping = {},
  carModelYearMapping,
  ...rest
}: ClientCarCarouselProps) => {
  const stringOfCarKeys: string[] = useMemo(
    () => rest.content.cars?.map((car) => car.key).filter(Boolean) || [],
    [rest.content.cars],
  );

  const { cars, isLoading, siteSlug } = useCarsForCarousel(
    stringOfCarKeys,
    Boolean(rest.content.recommendedCars),
    colorMapping,
    carModelYearMapping,
  );

  if (!cars.length && !isLoading && throwIfNoCars) {
    throw new Error('No cars found');
  }

  const filteredColorMapping = filterCarColorsMapping(colorMapping);

  if (
    Object.keys(filteredColorMapping).length === 0 &&
    process.env.DEPLOY_ENV !== 'prod'
  ) {
    console.warn(
      'colorMapping is required in order to get correct car images. Please use the CarColors.colorMapping dictionary from sharedComponents in volvo-cm',
    );
  }

  return !isLoading ? (
    <CarPriceProviderWithSiteSlug>
      <BaseCarCarousel
        {...rest}
        cars={cars}
        siteSlug={siteSlug}
        hideIfNoPrice={Boolean(hideIfNoPrice)}
      />
    </CarPriceProviderWithSiteSlug>
  ) : (
    <CarCarouselSkeleton centerAlign={rest.centerAlign} {...rest.content} />
  );
};

export default Carousel;
