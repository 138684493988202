/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TranslationKey {
  ___PER_H__OLD_ADDRESS_ONE_FIELD_LABEL = '___PerH__OLD_AddressOneFieldLabel',
  ___PERH__OLD_CITY_FIELD_LABEL = '___PERH__OLD_CityFieldLabel',
  ___PERH__OLD_POSTAL_CODE_FIELD_LABEL = '___PERH__OLD_PostalCodeFieldLabel',
  _1PM6PM = '_1PM6PM',
  _21AND_OVER = '_21AndOver',
  _404 = '_404',
  _8AM1PM = '_8AM1PM',
  ACCEPT_CONDITIONS = 'AcceptConditions',
  ACCEPT_PAYMENT_POLICIES = 'AcceptPaymentPolicies',
  ACCEPT_POLICIES = 'AcceptPolicies',
  ACCEPT_THE_TERMS = 'AcceptTheTerms',
  ACCEPT_THE_TERMS_PDF_URL = 'AcceptTheTermsPdfUrl',
  ACCOMPANIED_TEST_DRIVE_DESCRIPTION = 'AccompaniedTestDriveDescription',
  ACCOMPANIED_TEST_DRIVE_LABEL = 'AccompaniedTestDriveLabel',
  ADDITIONAL_NOTES_FIELD_DESCRIPTION = 'AdditionalNotesFieldDescription',
  ADDITIONAL_NOTES_FIELD_LABEL = 'AdditionalNotesFieldLabel',
  ADDITIONAL_NOTES_FIELD_PLACEHOLDER = 'AdditionalNotesFieldPlaceholder',
  ADDITIONAL_NOTES_FOR_DELIVERY_AND_COLLECTION = 'AdditionalNotesForDeliveryAndCollection',
  ADDITIONAL_SURNAME_FIELD_LABEL = 'AdditionalSurnameFieldLabel',
  ADDITIONAL_SURNAME_FIELD_PLACEHOLDER = 'AdditionalSurnameFieldPlaceholder',
  ADDRESS_EG = 'AddressEg',
  ADDRESS_FIELD_LABEL = 'AddressFieldLabel',
  ADDRESS_FIELD_PLACEHOLDER = 'AddressFieldPlaceholder',
  ADDRESS_ONE_FIELD_LABEL = 'AddressOneFieldLabel',
  ADDRESS_ONE_FIELD_PLACEHOLDER = 'AddressOneFieldPlaceholder',
  ADDRESS_TWO_FIELD_LABEL = 'AddressTwoFieldLabel',
  ADDRESS_TWO_FIELD_PLACEHOLDER = 'AddressTwoFieldPlaceholder',
  ADDRESS_UPPERCASE = 'AddressUppercase',
  AFTERNOON = 'Afternoon',
  AGREE_TO_SUBMIT = 'AgreeToSubmit',
  ALREADY_CANCELLED = 'AlreadyCancelled',
  ANY = 'Any',
  APP_HEADER_TITLE = 'AppHeaderTitle',
  APP_HEADER_TITLE_POPUP = 'AppHeaderTitlePopup',
  AR_ADDITIONAL_NOTES_FIELD_LABEL = 'AR_additionalNotesFieldLabel',
  AR_ADDITIONAL_NOTES_FIELD_PLACEHOLDER = 'AR_AdditionalNotesFieldPlaceholder',
  AR_ADDRESS_FIELD_PLACEHOLDER = 'AR_AddressFieldPlaceholder',
  AR_FORM_HEADING = 'AR_FormHeading',
  AR_FORM_SUB_HEADING = 'AR_FormSubHeading',
  AR_HEADER_TITLE = 'AR_HeaderTitle',
  AR_INBYTETEXT1 = 'AR_inbytetext1',
  AR_INBYTETEXT2 = 'AR_inbytetext2',
  AR_THANK_YOU_HEADING = 'AR_ThankYouHeading',
  AR_THANK_YOU_SUB_HEADING = 'AR_ThankYouSubHeading',
  ARCH_ = 'Arch_',
  ASSIGNING_APPOINTED_RETAILER = 'AssigningAppointedRetailer',
  AT_CHOSEN_LOCATION = 'AtChosenLocation',
  AT_CHOSEN_LOCATION_UPPERCASE = 'AtChosenLocationUppercase',
  AT_HOME_AT_WORK_OR_LOCATION = 'AtHomeAtWorkOrLocation',
  AT_RETAILER = 'AtRetailer',
  ATRETAILER = 'ATRETAILER',
  AT_RETAILER_UPPERCASE = 'AtRetailerUppercase',
  AVAILABLE_DATE = 'AvailableDate',
  AVAILABLE_RETAILERS = 'AvailableRetailers',
  B2B_BUSINESS_SPECIAL_LABEL = 'B2bBusinessSpecialLabel',
  B2B_BUSINESS_SPECIAL_PLACEHOLDER = 'B2bBusinessSpecialPlaceholder',
  BAKK___FH_ = 'Bakk___FH_',
  BAKK_ = 'Bakk_',
  BEFORE_YOU_GO = 'BeforeYouGo',
  BEGDPR1 = 'BEGDPR1',
  BEGDPR2 = 'BEGDPR2',
  BOBILE_PHONE_ASYNC_SPECIAL_PLACEHOLDER = 'BobilePhoneAsyncSpecialPlaceholder',
  BOOKING_REFERENCE = 'BookingReference',
  BOOKING_SUMMARY = 'BookingSummary',
  BOOK_YOUR = 'BookYour',
  CANCEL_BOOKING = 'cancelBooking',
  CANCEL_ERROR_FAILED = 'CancelErrorFailed',
  CANCEL_ERROR_NOT_FOUND = 'CancelErrorNotFound',
  CANCEL_HEADER = 'CancelHeader',
  CANCEL_REASON1 = 'CancelReason1',
  CANCEL_REASON2 = 'CancelReason2',
  CANCEL_REASON3 = 'CancelReason3',
  CANCEL_REASON4 = 'CancelReason4',
  CANCEL_REASON_OTHER = 'CancelReasonOther',
  CANCEL_REASON_URL1 = 'CancelReasonUrl1',
  CANCEL_REASON_URL2 = 'CancelReasonUrl2',
  CANCEL_REASON_URL3 = 'CancelReasonUrl3',
  CANCEL_REASON_URL4 = 'CancelReasonUrl4',
  CANCEL_REASON_URL_DEFAULT = 'CancelReasonUrlDefault',
  CANCEL_REASON_URL_OTHER = 'CancelReasonUrlOther',
  CANCEL_TY_BUTTON = 'CancelTyButton',
  CANCEL_TY_BUTTON1 = 'CancelTyButton1',
  CANCEL_TY_BUTTON2 = 'CancelTyButton2',
  CANCEL_TY_BUTTON3 = 'CancelTyButton3',
  CANCEL_TY_BUTTON4 = 'CancelTyButton4',
  CANCEL_TY_BUTTON_OTHER = 'CancelTyButtonOther',
  CANCEL_TY_HERO_TEXT = 'CancelTyHeroText',
  CANCEL_TY_TEXT = 'CancelTyText',
  CANCEL_YOUR_BOOKING = 'CancelYourBooking',
  CAR_DETAILS = 'CarDetails',
  CB_CHANNELS_REQUIRED_MESSAGE = 'CB_channelsRequiredMessage',
  CB_CONTACT_CHANNELS_FIELD = 'CB_ContactChannelsField',
  CB_CONTACT_SLOTS_FIELD = 'CB_ContactSlotsField',
  CB_SLOTS_REQUIRED_MESSAGE = 'CB_slotsRequiredMessage',
  CB_V_COMMENT_FIELD = 'CbV_CommentField',
  CBV_OPTION_FIELD_LABEL = 'CbvOptionFieldLabel',
  CBVPROCESS_PERSONAL_DATA = 'CBVProcessPersonalData',
  CHANGE = 'Change',
  CHOOSE_DIFFERENT_COLLECTION_ADDRESS = 'ChooseDifferentCollectionAddress',
  CHOOSE_NIGHTS_FOR_EXTENDED = 'ChooseNightsForExtended',
  CHOOSE_RETAILER = 'ChooseRetailer',
  CHOSEN_LOCATION = 'ChosenLocation',
  CITY_FIELD_LABEL = 'CityFieldLabel',
  CITY_FIELD_PLACEHOLDER = 'CityFieldPlaceholder',
  COLLECTION = 'Collection',
  COLLECTION_ADDRESS = 'CollectionAddress',
  COLLECTION_NOT_AVAILABLE_AT_SELECTED_ADDRESS = 'CollectionNotAvailableAtSelectedAddress',
  COLLECTION_TIME_SLOT = 'CollectionTimeSlot',
  COMPANY_NAME_FIELD_LABEL = 'CompanyNameFieldLabel',
  COMPANY_NAME_FIELD_PLACEHOLDER = 'CompanyNameFieldPlaceholder',
  CONCENT_CONTENT = 'ConcentContent',
  CONCENT_HEADER = 'ConcentHeader',
  CONDITION_OF_CAR = 'ConditionOfCar',
  CONSENT = 'Consent',
  CONSENT_TO_PROCESSING = 'ConsentToProcessing',
  CONSENT_TO_PROCESSING2 = 'ConsentToProcessing2',
  CONTACT_DETAILS = 'ContactDetails',
  CONTACT_DETAILS_EX90 = 'ContactDetailsEx90',
  CONTACT_DETAILS_UPPERCASE = 'ContactDetailsUppercase',
  CONTACT_INFORMATION = 'ContactInformation',
  COULD_NOT_CHOOSE_ASSET = 'CouldNotChooseAsset',
  CR_ADDITIONAL_NOTES_FIELD_LABEL = 'CR_AdditionalNotesFieldLabel',
  CR_CBV_DISCOVER_MODEL = 'CR_CBV_DiscoverModel',
  CR_CBV_EXPLORE_MODEL = 'CR_CBV_ExploreModel',
  CR_CBV_FINISHED_CTA_HEADING = 'CR_CBV_FinishedCtaHeading',
  CR_CBV_FINISHED_CTA_LINK_TEXT = 'CR_CBV_FinishedCtaLinkText',
  CR_CBV_FINISHED_CTA_TEXT = 'CR_CBV_FinishedCtaText',
  CR_CBV_FORM_HEADING = 'CR_CBV_FormHeading',
  CR_CBV_FORM_SUB_HEADING = 'CR_CBV_FormSubHeading',
  CR_CBV_LEARN_MORE_GET_MOST_OF_CBV = 'CR_CBV_LearnMoreGetMostOfCBV',
  CR_CBV_THANK_YOU_HEADING = 'CR_CBV_ThankYouHeading',
  CR_CBV_THANK_YOU_IFRAME_CTA_TEXT = 'CR_CBV_ThankYouIframeCtaText',
  CR_CBV_THANK_YOU_SUB_HEADING = 'CR_CBV_ThankYouSubHeading',
  CR_CONTACT_DETAILS = 'CR_ContactDetails',
  CR_FORM_HEADING = 'CR_FormHeading',
  CR_FORM_SUB_HEADING = 'CR_FormSubHeading',
  CR_HEADER_TITLE = 'CR_HeaderTitle',
  CR_PRIVACY = 'CR_Privacy',
  CR_PROCESS_PERSONAL_DATA = 'CR_ProcessPersonalData',
  CR_THANK_YOU_HEADING = 'CR_ThankYouHeading',
  CR_THANK_YOU_SUB_HEADING = 'CR_ThankYouSubHeading',
  CUSTOM_BOOKING_LEGAL_PHONE_OPTIN_LABEL = 'CustomBookingLegalPhoneOptinLabel',
  CUSTOM_BOOKING_LEGAL_PHONE_OPTIN_PLACEHOLDER = 'CustomBookingLegalPhoneOptinPlaceholder',
  CUSTOM_FIRSTNAME_BOOKING_LABEL = 'CustomFirstnameBookingLabel',
  CUSTOM_FIRSTNAME_BOOKING_PLACEHOLDER = 'CustomFirstnameBookingPlaceholder',
  CUSTOM_FIRSTNAME_REQUEST_LABEL = 'CustomFirstnameRequestLabel',
  CUSTOM_FIRSTNAME_REQUEST_PLACEHOLDER = 'CustomFirstnameRequestPlaceholder',
  CUSTOM_MOBILE_PHONE_BOOKING_LABEL = 'CustomMobilePhoneBookingLabel',
  CUSTOM_MOBILE_PHONE_BOOKING_PLACERHOLDER = 'CustomMobilePhoneBookingPlacerholder',
  CUSTOM_NOTES_BOOKING_LABEL = 'CustomNotesBookingLabel',
  CUSTOM_NOTES_BOOKING_PLACEHOLDER = 'CustomNotesBookingPlaceholder',
  DATE_AMP_TIME = 'DateAmpTime',
  DATE_AMP_TIME_UPPERCASE = 'DateAmpTimeUppercase',
  DDR_ = 'DDr_',
  DE_HEER = 'De_heer',
  DEGDPR1 = 'DEGDPR1',
  DELIVERY = 'Delivery',
  DELIVERY_ADDRESS = 'DeliveryAddress',
  DELIVERY_AMP_COLLECTION = 'DeliveryAmpCollection',
  DELIVERY_NOT_AVAILABLE_AT_SELECTED_ADDRESS = 'DeliveryNotAvailableAtSelectedAddress',
  DELIVERY_TIME_SLOT = 'DeliveryTimeSlot',
  DESCRIPTION = 'Description',
  DI__FH_ = 'DI__FH_',
  DI_ = 'DI_',
  DI_DR_ = 'DI_Dr_',
  DI_MAG_ = 'DI_Mag_',
  DIGITS_ONLY = 'DigitsOnly',
  DISCOVER_LINK_NEXT_STEPS = 'DiscoverLinkNextSteps',
  DISCOVER_MODEL = 'DiscoverModel',
  DISTRICT_FIELD_LABEL = 'DistrictFieldLabel',
  DISTRICT_FIELD_PLACEHOLDER = 'DistrictFieldPlaceholder',
  DKFM__DR_ = 'Dkfm__Dr_',
  DKFM_ = 'Dkfm_',
  DOESNT_EXIST = 'DoesntExist',
  DR_ = 'Dr_',
  DR = 'Dr',
  EMAIL_FIELD_LABEL = 'EmailFieldLabel',
  EMAIL_FIELD_PLACEHOLDER = 'EmailFieldPlaceholder',
  EMAIL_OPTIN_FIELD_LABEL = 'EmailOptinFieldLabel',
  EMAIL_OPTIN_SPECIAL_TRANSLATION = 'EmailOptinSpecialTranslation',
  ENGINE = 'Engine',
  ENGINE_SELECTION = 'EngineSelection',
  ENGINE_SELECTION_LABEL = 'EngineSelectionLabel',
  ENTER_ADDRESS_POSTCODE_OR_CITY = 'EnterAddressPostcodeOrCity',
  ENTER_YOUR_ADDRESS = 'EnterYourAddress',
  ENTRY_POINT_COMPONENT_CTA = 'EntryPointComponentCTA',
  ERBJUDANDEN_OCH_REKLAM = 'ErbjudandenOchReklam',
  ESGDPR1 = 'ESGDPR1',
  EVENT_CTABUTTON = 'EventCTAButton',
  EVENT_CTALINK = 'EventCTALink',
  EVENT_FAQBUTTON = 'EventFAQButton',
  EVENT_FAQLABEL = 'EventFAQLabel',
  EVENT_FAQLINK = 'EventFAQLink',
  EVENT_FAQTEXT = 'EventFAQText',
  EXCELLENT = 'Excellent',
  EXPLORE_MODEL = 'ExploreModel',
  EXTENDED = 'Extended',
  EXTENDED_BOOKING_DISCLAIMER = 'ExtendedBookingDisclaimer',
  EXTENDED_CTABUTTON = 'ExtendedCTAButton',
  EXTENDED_CTALINK = 'ExtendedCTALink',
  EXTENDED_PERIOD = 'ExtendedPeriod',
  EXTENDED_PERIOD_UPPERCASE = 'ExtendedPeriodUppercase',
  FAQ = 'FAQ',
  FAQURL = 'FAQUrl',
  FILTER_BY_OPTIONAL = 'FilterByOptional',
  FIND_DATE_AND_TIME = 'FindDateAndTime',
  FINISHED_CTA_HEADING = 'FinishedCtaHeading',
  FINISHED_CTA_LINK_TEXT = 'FinishedCtaLinkText',
  FINISHED_CTA_TEXT = 'FinishedCtaText',
  FIRST_NAME_FIELD_LABEL = 'FirstNameFieldLabel',
  FIRST_NAME_FIELD_PLACEHOLDER = 'FirstNameFieldPlaceholder',
  FIRST_PARAGRAPH = 'FirstParagraph',
  FLEET_MANAGER_BOOK = 'FleetManagerBook',
  FLEET_MANAGER_BOOK_URL = 'FleetManagerBookUrl',
  FLEX_FORM_CONFIRMED = 'flexFormConfirmed',
  FOR_EXTENDED_PERIOD = 'ForExtendedPeriod',
  FORM = 'Form',
  FOUR_DIGITS_ONLY = 'FourDigitsOnly',
  GDPRFR1 = 'GDPRFR1',
  GENDER_FIELD_LABEL = 'GenderFieldLabel',
  GENDER_FIELD_PLACEHOLDER = 'GenderFieldPlaceholder',
  GENERIC_ERROR_MESSAGE = 'GenericErrorMessage',
  GOOD = 'Good',
  GO_TO_VOLVO_CARS = 'GoToVolvoCars',
  HEER = 'Heer',
  HIS_ROYAL_HIGHNESS = 'His_Royal_Highness',
  HON_ = 'Hon_',
  ID_ = 'Id_',
  IFJ_ = 'Ifj_',
  INFORMATION_NOTICE = 'InformationNotice',
  INFORMATION_NOTICE_URL = 'InformationNoticeUrl',
  ING_ = 'Ing_',
  INVALID_FIELD = 'InvalidField',
  INVALID_LEGAL_ADDRESS = 'InvalidLegalAddress',
  IS_B2B_LABEL = 'IsB2bLabel',
  ISPRIVACY1 = 'ISPRIVACY1',
  ISUPDATED_CONSENT = 'ISUpdatedConsent',
  ITGDPR1 = 'ITGDPR1',
  ITGDPR2 = 'ITGDPR2',
  ITGDPR3 = 'ITGDPR3',
  JPGDPR1 = 'JPGDPR1',
  KMU_ADDITIONAL_NOTES_FIELD_LABEL = 'KMU_AdditionalNotesFieldLabel',
  KMU_ADDITIONAL_NOTES_FIELD_PLACEHOLDER = 'KMU_AdditionalNotesFieldPlaceholder',
  KMU_FORM_HEADING = 'KMU_FormHeading',
  KMU_FORM_SUB_HEADING = 'KMU_FormSubHeading',
  KMU_HEADER_TITLE = 'KMU_HeaderTitle',
  KMU_ONE_MARKETING_CONSENT_FIELD_LABEL = 'KMU_OneMarketingConsentFieldLabel',
  KMU_SUB_HEADING = 'KMU_SubHeading',
  KMU_THANK_YOU_HEADING = 'KMU_ThankYouHeading',
  KMU_THANK_YOU_SUB_HEADING = 'KMU_ThankYouSubHeading',
  KMUCONSENT = 'KMUconsent',
  KMUCONSENT1 = 'KMUconsent1',
  KMUCONSENT2 = 'KMUconsent2',
  KMUCONSENT_TO_PROCESSING = 'KMUconsentToProcessing',
  KMUGDPR = 'KMUGDPR',
  KMUHEADERTEXT = 'KMUheadertext',
  KMUPROCESS_PERSONAL_DATA = 'KMUprocessPersonalData',
  KRGDPR1 = 'KRGDPR1',
  KVKKFIELD_LABEL = 'KVKKFieldLabel',
  LANDLINE_FIELD_LABEL = 'LandlineFieldLabel',
  LANDLINE_FIELD_PLACEHOLDER = 'LandlineFieldPlaceholder',
  LEARN_MORE_GET_MOST_OF_TD = 'LearnMoreGetMostOfTD',
  LEGAL_NAME_FIELD_LABEL = 'LegalNameFieldLabel',
  LEGAL_NAME_FIELD_PLACEHOLDER = 'LegalNameFieldPlaceholder',
  LENGTH_FIELD = 'LengthField',
  LICENSE_PLATE = 'LicensePlate',
  LOADING_APP = 'LoadingApp',
  LOADING_AVAILABILITY = 'LoadingAvailability',
  LOADING_RETAILERS_NEAR_ADDRESS = 'LoadingRetailersNearAddress',
  LOCATION = 'Location',
  LOCATION_UPPERCASE = 'LocationUppercase',
  M_ = 'M_',
  MADAME = 'Madame',
  MAG___FH_ = 'Mag___FH_',
  MAG_ = 'Mag_',
  MAIL_OPTIN_FIELD_LABEL = 'MailOptinFieldLabel',
  MANUAL_ADDRESS_CHECKBOX_DESCRIPTION = 'ManualAddressCheckboxDescription',
  MANUAL_ADDRESS_CHECKBOX_LABEL = 'ManualAddressCheckboxLabel',
  MANUAL_ADDRESS_LABEL = 'ManualAddressLabel',
  MANUAL_ADDRESS_PLACEHOLDER = 'ManualAddressPlaceholder',
  MAP_DISTANCE_UNIT = 'MapDistanceUnit',
  MARKETING = 'Marketing',
  MAX_FOUR_WEEK_ADVANCE = 'MaxFourWeekAdvance',
  MAX_SIX_WEEKS_ADVANCE = 'MaxSixWeeksAdvance',
  MEVROUW = 'Mevrouw',
  MILEAGE = 'Mileage',
  MISS_ = 'Miss_',
  MISS = 'Miss',
  MMAG_ = 'MMag_',
  MME = 'Mme',
  MOBILE_PHONE_ASYNC_SPECIAL_LABEL = 'MobilePhoneAsyncSpecialLabel',
  MOBILE_PHONE_LABEL1 = 'MobilePhoneLabel1',
  MOBILE_PHONE_PLACERHOLDER1 = 'MobilePhonePlacerholder1',
  MODEL = 'Model',
  MODEL_SELECTOR_ENGINE_FILTER_ALL = 'ModelSelectorEngineFilter_ALL',
  MODEL_SELECTOR_ENGINE_FILTER_ELECTRIC = 'ModelSelectorEngineFilter_ELECTRIC',
  MODEL_SELECTOR_ENGINE_FILTER_HYBRID = 'ModelSelectorEngineFilter_HYBRID',
  MODEL_SELECTOR_ENGINE_FILTER_MILDHYBRID = 'ModelSelectorEngineFilter_MILDHYBRID',
  MODEL_SELECTOR_GROUP_CROSSOVER = 'ModelSelectorGroup_CROSSOVER',
  MODEL_SELECTOR_GROUP_SEDAN = 'ModelSelectorGroup_SEDAN',
  MODEL_SELECTOR_GROUP_SUV = 'ModelSelectorGroup_SUV',
  MODEL_SELECTOR_GROUP_WAGON = 'ModelSelectorGroup_WAGON',
  MODELSELECTOR_HEADER = 'ModelselectorHeader',
  MODELSELECTOR_PREAMBLE = 'ModelselectorPreamble',
  MODEL_UPPERCASE = 'ModelUppercase',
  MODEL_YEAR = 'ModelYear',
  MONSIEUR = 'Monsieur',
  MORNING = 'Morning',
  MR_ = 'Mr_',
  MR = 'Mr',
  MRS_ = 'Mrs_',
  MRS = 'Mrs',
  MS_ = 'Ms_',
  MS = 'Ms',
  NA = 'NA',
  NEED_TO_SELECT_VALID_COLLECTION_ADDRESS = 'NeedToSelectValidCollectionAddress',
  NEED_TO_SELECT_VALID_DELIVERY_ADDRESS = 'NeedToSelectValidDeliveryAddress',
  NEED_TO_VERIFY = 'NeedToVerify',
  NEXT = 'Next',
  NO_ASSETS_RETURNED = 'NoAssetsReturned',
  NO_RETAILERS_FOUND = 'NoRetailersFound',
  NOTES_CUSTOM_CB_VDESCRIPTION = 'NotesCustomCbVDescription',
  NOTES_CUSTOM_CB_VLABEL = 'NotesCustomCbVLabel',
  NOTES_CUSTOM_CB_VPLACEHOLDER = 'NotesCustomCbVPlaceholder',
  NOTES_CUSTOM_TEST_LABEL = 'NotesCustomTestLabel',
  NOTES_CUSTOM_TEST_PLACEHOLDER = 'NotesCustomTestPlaceholder',
  NZGDPR1 = 'NZGDPR1',
  NZGDPR2 = 'NZGDPR2',
  OFFERS_AND_PROMOTIONS = 'OffersAndPromotions',
  ONE_MARKETING_CONSENT_FIELD_LABEL = 'OneMarketingConsentFieldLabel',
  ONE_MARKETING_CONSENT_PRIVACY = 'OneMarketingConsentPrivacy',
  ONE_MARKETING_CONSENT_QUOTE_PRIVACY = 'OneMarketingConsentQuotePrivacy',
  OOPS = 'Oops',
  OPTIN_CONTROL_FIELD_LABEL = 'OptinControlFieldLabel',
  OUT_OF_BOUNDS = 'OutOfBounds',
  PHONE_FIELD_LABEL = 'PhoneFieldLabel',
  PHONE_FIELD_PLACEHOLDER = 'PhoneFieldPlaceholder',
  PHONE_OPTIN_CUSTOM_TRANSLATION = 'PhoneOptinCustomTranslation',
  PHONE_OPTIN_REQUIRED_DESCRIPTION = 'PhoneOptinRequiredDescription',
  PHONE_OPTION_FIELD_LABEL = 'PhoneOptionFieldLabel',
  PLEASE_ENABLE_GEO_LOCATION = 'PleaseEnableGeoLocation',
  PLEASE_NOTE_PAYMENT = 'PleaseNotePayment',
  PLEASE_RELOAD_PAGE = 'PleaseReloadPage',
  PLEASE_SELECT_ENGINE = 'PleaseSelectEngine',
  POOR = 'Poor',
  POPUP_ACCEPT_THE_TERMS = 'PopupAcceptTheTerms',
  POPUP_ADDITIONAL_NOTES_FIELD_LABEL = 'PopupAdditionalNotesFieldLabel',
  POPUP_ADDRESS_UPPERCASE = 'PopupAddressUppercase',
  POPUP_AFTERNOON = 'PopupAfternoon',
  POPUP_ALTERNATIVE_OPTIONS = 'PopupAlternativeOptions',
  POPUP_ALTERNATIVE_POPUP_OPTIONS_BUTTON = 'PopupAlternativePopupOptionsButton',
  POPUP_ALTERNATIVE_POPUP_OPTIONS_URL = 'PopupAlternativePopupOptionsURL',
  POPUP_ANY = 'PopupAny',
  POPUP_AVAILABILITY = 'PopupAvailability',
  POPUP_AVAILABLE_DATE = 'PopupAvailableDate',
  POPUP_BOOKING = 'PopupBooking',
  POPUP_CONCEPT_CONTENT = 'PopupConceptContent',
  POPUP_CONCEPT_CTA = 'PopupConceptCTA',
  POPUP_CONCEPT_HEADER = 'PopupConceptHeader',
  POPUP_CONCEPT_URL = 'PopupConceptURL',
  POPUP_CONSENT = 'PopupConsent',
  POPUP_CONSENT_TO_PROCESSING = 'PopupConsentToProcessing',
  POPUP_CONTACT_DETAILS = 'PopupContactDetails',
  POPUP_DATE_AMP_TIME = 'PopupDateAmpTime',
  POPUP_DURATION = 'PopupDuration',
  POPUP_FILTER_BY_OPTIONAL = 'PopupFilterByOptional',
  POPUP_INFORMATION_NOTICE = 'PopupInformationNotice',
  POPUP_INFORMATION_NOTICE_URL = 'PopupInformationNoticeUrl',
  POPUP_LEARN_MORE_OF_CONCEPT = 'PopupLearnMoreOfConcept',
  POPUP_LOADING_AVAILABILITY = 'PopupLoadingAvailability',
  POPUP_LOCATION = 'PopupLocation',
  POPUP_MORNING = 'PopupMorning',
  POPUP_NOT_ACTIVATED = 'PopupNotActivated',
  POPUP_PAST_END_DATE = 'PopupPastEndDate',
  POPUP_POPULAR = 'PopupPopular',
  POPUP_PRIVACY = 'PopupPrivacy',
  POPUP_PROCESS_PERSONAL_DATA = 'PopupProcessPersonalData',
  POPUP_SELECT_DATE = 'PopupSelectDate',
  POPUP_SELECTED_DATE = 'PopupSelectedDate',
  POPUP_SELECTED_LOCATION_UPPERCASE = 'PopupSelectedLocationUppercase',
  POPUP_SELECTED_MODEL_UPPERCASE = 'PopupSelectedModelUppercase',
  POPUP_SELECT_TIME = 'PopupSelectTime',
  POPUP_SHARE_PERSONAL_DATA = 'PopupSharePersonalData',
  POPUP_SUBMIT = 'PopupSubmit',
  POPUP_THANK_YOU_FOR_BOOKING = 'PopupThankYouForBooking',
  POPUP_THANK_YOU_OTHER = 'PopupThankYouOther',
  POPUP_TODAYS_DATE = 'PopupTodaysDate',
  POPUP_UNAVAILABLE_DATE = 'PopupUnavailableDate',
  POPUP_YOUR_CONTACT_DETAILS_UPPERCASE = 'PopupYourContactDetailsUppercase',
  POPUP_YOUR_CONTACT_INFORMATION_UPPERCASE = 'PopupYourContactInformationUppercase',
  POSTAL_CODE_FIELD_LABEL = 'PostalCodeFieldLabel',
  POSTAL_CODE_FIELD_PLACEHOLDER = 'PostalCodeFieldPlaceholder',
  PREFECTURES_FIELD_LABEL = 'PrefecturesFieldLabel',
  PREFECTURES_FIELD_PLACEHOLDER = 'PrefecturesFieldPlaceholder',
  PREFERRED_CONTACT_CHANNEL = 'PreferredContactChannel',
  PRIVACY = 'Privacy',
  PROCESS_PERSONAL_DATA = 'ProcessPersonalData',
  PROCESS_PERSONAL_DATA_KMU = 'ProcessPersonalDataKMU',
  PRODUCT_OF_INTEREST_FIELD_TITLE = 'ProductOfInterestFieldTitle',
  PROF__DDR_ = 'Prof__DDr_',
  PROF__DI_DR_ = 'Prof__DI_Dr_',
  PROF__MAG__DR_ = 'Prof__Mag__Dr_',
  PROF_ = 'Prof_',
  PROF_DR_ = 'Prof_Dr_',
  PROF = 'PROF',
  QR_ADDITIONAL_NOTES_FIELD_LABEL = 'QR_additionalNotesFieldLabel',
  QR_ADDITIONAL_NOTES_FIELD_PLACEHOLDER = 'QR_AdditionalNotesFieldPlaceholder',
  QR_DDITIONAL_NOTES_FIELD_LABEL = 'QR_dditionalNotesFieldLabel',
  QR_FORM_HEADING = 'QR_FormHeading',
  QUOTE_FAQBUTTON = 'QuoteFAQButton',
  QUOTE_FAQLABEL = 'QuoteFAQLabel',
  QUOTE_FAQLINK = 'QuoteFAQLink',
  QUOTE_FAQTEXT = 'QuoteFAQText',
  QUOTE_MODELSELECTOR_HEADER = 'QuoteModelselectorHeader',
  QUOTE_MODELSELECTOR_PREAMBLE = 'QuoteModelselectorPreamble',
  REASON_FOR_CANCELLATION = 'ReasonForCancellation',
  REATAILER_UPPERCASE = 'ReatailerUppercase',
  REDES_AT_LOCATION_ADDRESS_VIEW_HEADER = 'redes_atLocationAddressView_header',
  REDES_AT_RETAILER_CALENDAR_VIEW_HEADER = 'redes_atRetailerCalendarView_header',
  REDES_BOOKING_SUMMARY_DATE = 'redes_bookingSummary_date',
  REDES_BOOKING_SUMMARY_HEADER = 'redes_bookingSummary_header',
  REDES_BOOKING_SUMMARY_MODEL = 'redes_bookingSummary_model',
  REDES_BOOKING_SUMMARY_RETAILER = 'redes_bookingSummary_retailer',
  REDES_BOOKING_SUMMARY_TIME = 'redes_bookingSummary_time',
  REDES_CONFIRMATION_VIEW_BOOKING_SUMMARY = 'redes_confirmationView_bookingSummary',
  REDES_CONFIRMATION_VIEW_DATE = 'redes_confirmationView_Date',
  REDES_CONFIRMATION_VIEW_EDIT = 'redes_confirmationView_edit',
  REDES_CONFIRMATION_VIEW_EMAIL = 'redes_confirmationView_email',
  REDES_CONFIRMATION_VIEW_HEADER = 'redes_confirmationView_header',
  REDES_CONFIRMATION_VIEW_MODEL = 'redes_confirmationView_model',
  REDES_CONFIRMATION_VIEW_NAME = 'redes_confirmationView_name',
  REDES_CONFIRMATION_VIEW_PHONE = 'redes_confirmationView_phone',
  REDES_CONFIRMATION_VIEW_RETAILER = 'redes_confirmationView_retailer',
  REDES_CONFIRMATION_VIEW_REVIEW_DETAILS = 'redes_confirmationView_reviewDetails',
  REDES_CONFIRMATION_VIEW_SUBMIT_REQUEST_ERROR_MESSAGE = 'redes_confirmationView_submitRequestErrorMessage',
  REDES_CONFIRMATION_VIEW_TIME = 'redes_confirmationView_time',
  REDES_CONFIRMATION_VIEW_YOUR_PERSONAL_DETAILS = 'redes_confirmationView_yourPersonalDetails',
  REDES_CONTACT_INFORMATION_HEADER = 'redes_contactInformation_header',
  REDES_EVENT_CALENDAR_VIEW_HEADER = 'redes_eventCalendarView_header',
  REDES_EXTENDED_CALENDAR_VIEW_HEADER = 'redes_extendedCalendarView_header',
  REDES_FAQ_SECTION_CONTACT_US = 'redes_faqSection_contactUs',
  REDES_FAQ_SECTION_CONTACT_US_URL = 'redes_faqSection_contactUsURL',
  REDES_FAQ_SECTION_HEADER = 'redes_faqSection_header',
  REDES_FAQ_SECTION_RESCHEDULE = 'redes_faqSection_reschedule',
  REDES_FAQ_SECTION_RESCHEDULE_URL = 'redes_faqSection_rescheduleURL',
  REDES_LOADING_VIEW_HEADER = 'redes_loadingView_header',
  REDES_PACKAGE_SELECTOR_VIEW_HEADER = 'redes_packageSelectorView_header',
  REDES_PERSONAL_DETAILS_SUMMARY_EDIT = 'redes_personalDetailsSummary_edit',
  REDES_PERSONAL_DETAILS_SUMMARY_EMAIL = 'redes_personalDetailsSummary_email',
  REDES_PERSONAL_DETAILS_SUMMARY_HEADER = 'redes_personalDetailsSummary_header',
  REDES_PERSONAL_DETAILS_SUMMARY_NAME = 'redes_personalDetailsSummary_name',
  REDES_PERSONAL_DETAILS_SUMMARY_PHONE = 'redes_personalDetailsSummary_phone',
  REDES_RETAILER_CALENDAR_VIEW_CALENDAR_REQUEST_ERROR_MESSAGE = 'redes_retailerCalendarView_calendarRequestErrorMessage',
  REDES_RETAILER_CARD_SELECT_RETAILER = 'redes_retailerCard_selectRetailer',
  REDES_RETAILER_MAP_AVAILABLE_RETAILERS = 'redes_retailerMap_availableRetailers',
  REDES_RETAILER_MAP_CALLBACK = 'redes_retailerMap_callback',
  REDES_RETAILER_MAP_CALLBACK_DESCRIPTION = 'redes_retailerMap_callbackDescription',
  REDES_RETAILER_MAP_DESCRIPTION = 'redes_retailerMap_description',
  REDES_RETAILER_MAP_FETCH_RETAILERS_ERROR_MESSAGE = 'redes_retailerMap_fetchRetailersErrorMessage',
  REDES_RETAILER_MAP_HEADING = 'redes_retailerMap_heading',
  REDES_RETAILER_MAP_NO_AVAILABLE_RETAILERS = 'redes_retailerMap_noAvailableRetailers',
  REDES_RETAILER_MAP_ONE_AVAILABLE_RETAILER = 'redes_retailerMap_oneAvailableRetailer',
  REDES_RETAILER_MAP_PHYSICAL_VISIT = 'redes_retailerMap_physicalVisit',
  REDES_RETAILER_MAP_PHYSICAL_VISIT_DESCRIPTION = 'redes_retailerMap_physicalVisitDescription',
  REDES_RETAILER_MAP_SELECT_CALLBACK = 'redes_retailerMap_selectCallback',
  REDES_RETAILER_MAP_SELECT_RETAILER = 'redes_retailerMap_selectRetailer',
  REDES_RETAILER_MAP_SUB_HEADING = 'redes_retailerMap_subHeading',
  REDES_RETAILER_MAP_USE_MY_LOCATION = 'redes_retailerMap_useMyLocation',
  REDES_RETAILER_MAP_BOTTOM_BAR_LIST = 'redes_retailerMapBottomBar_list',
  REDES_RETAILER_MAP_BOTTOM_BAR_MAP = 'redes_retailerMapBottomBar_map',
  REDES_RETAILER_SELECTOR_VIEW_HEADER = 'redes_retailerSelectorView_header',
  REDES_THANK_YOU_ICAL_TEXT = 'redes_thankYou_icalText',
  REDES_THANK_YOU_VIEW_CTA_DISPLAY_TEXT_EVENT = 'redes_thankYouView_ctaDisplayTextEvent',
  REDES_THANK_YOU_VIEW_CTA_LINK_EVENT = 'redes_thankYouView_ctaLinkEvent',
  REDES_THANK_YOU_VIEW_EMAIL_CONFIRMATION = 'redes_thankYouView_emailConfirmation',
  REDES_THANK_YOU_VIEW_HEADER = 'redes_thankYouView_header',
  REDES_THANK_YOU_VIEW_SUBMIT_DRIVERS_LICENSE = 'redes_thankYouView_submitDriversLicense',
  REDES_TIME_PICKER_AFTERNOON = 'redes_timePicker_afternoon',
  REDES_TIME_PICKER_HEADER = 'redes_timePicker_header',
  REDES_TIME_PICKER_MORNING = 'redes_timePicker_morning',
  REQUEST_SUB_HEADING = 'Request_SubHeading',
  REQUEST_AR = 'RequestAR',
  REQUEST_ARHEADER_TITLE = 'RequestARHeaderTitle',
  REQUEST_ARLEARN_MORE = 'RequestARLearnMore',
  REQUEST_ARTHANK_YOU = 'RequestARThankYou',
  REQUEST_ARUPPERCASE = 'RequestARUppercase',
  REQUEST_CR = 'RequestCR',
  REQUEST_CRHEADER_TITLE = 'RequestCRHeaderTitle',
  REQUEST_CRLEARN_MORE = 'RequestCRLearnMore',
  REQUEST_CRTHANK_YOU = 'RequestCRThankYou',
  REQUEST_CRUPPERCASE = 'RequestCRUppercase',
  REQUEST_KMU = 'RequestKMU',
  REQUEST_KMUHEADER_TITLE = 'RequestKMUHeaderTitle',
  REQUEST_KMULEARN_MORE = 'RequestKMULearnMore',
  REQUEST_KMUTHANK_YOU = 'RequestKMUThankYou',
  REQUEST_KMUUPPERCASE = 'RequestKMUUppercase',
  REQUEST_QUOTE_ADDITIONAL_NOTES_FIELD_LABEL = 'RequestQuote_additionalNotesFieldLabel',
  REQUEST_QUOTE = 'RequestQuote',
  REQUEST_QUOTE_HEADER_TITLE = 'RequestQuoteHeaderTitle',
  REQUEST_QUOTE_LEARN_MORE = 'RequestQuoteLearnMore',
  REQUEST_QUOTE_MODEL = 'RequestQuoteModel',
  REQUEST_QUOTE_THANK_YOU = 'RequestQuoteThankYou',
  REQUEST_QUOTE_THANK_YOU_SUB_HEADING = 'RequestQuoteThankYouSubHeading',
  REQUEST_QUOTE_UPPERCASE = 'RequestQuoteUppercase',
  REQUIRED = 'Required',
  REQUIRED_FIELD = 'RequiredField',
  RETAILER = 'Retailer',
  RETAILER_NAME = 'RetailerName',
  RETAILER_SELECTION = 'RetailerSelection',
  RETAILER_UPPERCASE = 'RetailerUppercase',
  ROGDPR1 = 'ROGDPR1',
  SEARCH = 'Search',
  SELECT = 'Select',
  SELECT_DATE = 'SelectDate',
  SELECTED_DATE = 'SelectedDate',
  SELECTED_LOCATION_UPPERCASE = 'SelectedLocationUppercase',
  SELECTED_MODEL_UPPERCASE = 'SelectedModelUppercase',
  SELECTED_RETAILER_UPPERCASE = 'SelectedRetailerUppercase',
  SELECTED_TIME_UPPERCASE = 'SelectedTimeUppercase',
  SELECT_RETAILER = 'SelectRetailer',
  SELECT_RETAILER_CLOSE_TO_ADDRESS = 'SelectRetailerCloseToAddress',
  SELECT_RETAILER_ENTER_ADDRESS = 'SelectRetailerEnterAddress',
  SELECT_RETAILER_HEADER = 'SelectRetailerHeader',
  SELECT_RETAILER_LOAD_MORE = 'SelectRetailerLoadMore',
  SELECT_RETAILER_VERIFICATION_NOTICE = 'SelectRetailerVerificationNotice',
  SELECT_TIME = 'SelectTime',
  SELECT_YOUR_APPOINTED_RETAILER = 'SelectYourAppointedRetailer',
  SHARE_PERSONAL_DATA = 'SharePersonalData',
  SIG_ = 'Sig_',
  SIG_RA_ = 'Sig_ra_',
  SIGN_UP_FOR_NEWS_LETTER = 'SignUpForNewsLetter',
  SMS_OPTIN_FIELD_LABEL = 'SmsOptinFieldLabel',
  SMS_OPTIN_REQUIRED_DESCRIPTION = 'SmsOptinRequiredDescription',
  SOCIAL_OPTIN_FIELD_LABEL = 'SocialOptinFieldLabel',
  SOMETHING_WENT_WRONG = 'SomethingWentWrong',
  STAY_INFORMED_ON_WHATS_HAPPENING = 'StayInformedOnWhatsHappening',
  SUBMIT = 'Submit',
  SUBSCRIPTION_RO = 'SubscriptionRO',
  SURNAME_FIELD_LABEL = 'SurnameFieldLabel',
  SURNAME_FIELD_PLACEHOLDER = 'SurnameFieldPlaceholder',
  TECHNICAL_ISSUES_MESSAGE = 'TechnicalIssuesMessage',
  THANK_OU_FAQLABEL = 'Thank_ouFAQLabel',
  THANK_YOU_FAQLABEL = 'Thank_YouFAQLabel',
  THANK_YOU_EXTENDED = 'ThankYouExtended',
  THANK_YOU_FAQ = 'ThankYouFAQ',
  THANK_YOU_FAQBUTTON = 'ThankYouFAQButton',
  THANK_YOU_FAQLINK = 'ThankYouFAQLink',
  THANK_YOU_FOR_YOUR = 'ThankYouForYour',
  THANK_YOU_OTHER = 'ThankYouOther',
  THANK_YOU_REQUEST = 'ThankYouRequest',
  THE_HONORABLE = 'The_Honorable',
  THE_MASTER = 'The_Master',
  THE_PRINCE = 'The_Prince',
  THE_NEW_WAY = 'TheNewWay',
  TIME = 'Time',
  TIME_NOT_SUITABLE = 'TimeNotSuitable',
  TITLE_FIELD_LABEL = 'TitleFieldLabel',
  TITLE_FIELD_PLACEHOLDER = 'TitleFieldPlaceholder',
  ENTRY_POINT_COMPONENT_CTAKEEP_ME_UPDATED = 'EntryPointComponentCTAKeepMeUpdated',
  TODAYS_DATE = 'TodaysDate',
  UKGDPR1 = 'UKGDPR1',
  UKGDPR2 = 'UKGDPR2',
  UNAVAILABLE_DATE = 'UnavailableDate',
  UNIV_DOZ__DDR_ = 'Univ_Doz__DDr_',
  UNIV_DOZ__DI_DR_ = 'Univ_Doz__DI_Dr_',
  UNIV_DOZ__DR_ = 'Univ_Doz__Dr_',
  UNIV_DOZ__MAG__DR_ = 'Univ_Doz__Mag__Dr_',
  UNIV_DOZ_ = 'Univ_Doz_',
  UNIV_PROF__DDR_ = 'Univ_Prof__DDr_',
  UNIV_PROF__DI_DR_ = 'Univ_Prof__DI_Dr_',
  UNIV_PROF__DR_ = 'Univ_Prof__Dr_',
  UNIV_PROF__MAG__DR_ = 'Univ_Prof__Mag__Dr_',
  UNIV_PROF_ = 'Univ_Prof_',
  VALIDATION_SERVICE = 'ValidationService',
  VERIFY_LICENCE = 'VerifyLicence',
  VIRTUOUS = 'Virtuous',
  XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXREDES_CONFIRMATION_VIEW_SUBMIT_REQUEST_ERROR_MESSAGE = 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXredes_confirmationView_submitRequestErrorMessage',
  YOUR_APPOINTED_RETAILER = 'YourAppointedRetailer',
  YOUR_CONTACT_DETAILS = 'YourContactDetails',
  YOUR_CONTACT_DETAILS_UPPERCASE = 'YourContactDetailsUppercase',
  YOUR_CONTACT_INFORMATION_UPPERCASE = 'YourContactInformationUppercase',
  PREFILLED_DATA_TITLE = 'PrefilledDataTitle',
  PREFILLED_DATA_TEXT = 'PrefilledDataText',
  ANYTIME = 'Anytime',
  EVENING = 'Evening',
  COUNTRY_CODE = 'CountryCode',
  OPTIONAL = 'Optional',
  ADDITIONAL_NOTES_TITLE = 'AdditionalNotesTitle',
  TITLE = 'Title',
  CB_TITLE = 'CB_Title',
  CR_TITLE = 'CR_Title',
  KMU_TITLE = 'KMU_Title',
  QR_TITLE = 'QR_Title',
  CB_SUBMIT_BUTTON = 'CB_Submit_Button',
  CR_SUBMIT_BUTTON = 'CR_Submit_Button',
  KMU_SUBMIT_BUTTON = 'KMU_Submit_Button',
  QR_SUBMIT_BUTTON = 'QR_Submit_Button',
  KMUSUB_HEADING = 'KMUSubHeading',
  EMAIL_SUBJECT_YOUR_ENQUIRY = 'EmailSubjectYourEnquiry',
  EMAIL_SUBJECT_YOUR_ENQUIRY_WITH_MODEL = 'EmailSubjectYourEnquiryWithModel',
  TITLE_THANKS_FOR_YOUR_INTEREST = 'TitleThanksForYourInterest',
  TITLE_THANKS_FOR_YOUR_REQUEST = 'TitleThanksForYourRequest',
  EMAIL_CONFIRMATION_VOLVO_SPECIALISTS = 'EmailConfirmationVolvoSpecialists',
  EMAIL_CONFIRMATION_RETAILER = 'EmailConfirmationRetailer',
  CALL_US_ON_PHONE_NO = 'CallUsOnPhoneNo',
  EMAIL_PLEASE_DO_NOT_REPLY = 'EmailPleaseDoNotReply',
  EMAIL_PRIVACY_POLICY = 'EmailPrivacyPolicy',
  EMAIL_COMPANY_INFO = 'EmailCompanyInfo',
  EMAIL_FACEBOOK_LINK = 'EmailFacebookLink',
  EMAIL_INSTAGRAM_LINK = 'EmailInstagramLink',
  EMAIL_YOUTUBE_LINK = 'EmailYoutubeLink',
  EMAIL_XLINK = 'EmailXLink',
  YOUR_DETAILS = 'YourDetails',
  BEST_TIME_TO_CONTACT = 'BestTimeToContact',
  PHONE_NUMBER_FIELD_LABEL = 'PhoneNumberFieldLabel',
  IAM_BUSINESS_CUSTOMER = 'IAmBusinessCustomer',
  SEARCH_ADDRESS_CITY_OR_POSTCODE_FIELD_LABEL = 'SearchAddressCityOrPostcodeFieldLabel',
  PRIVACY_POLICY = 'PrivacyPolicy',
  EMAIL_HI_CUSTOMER_NAME = 'EmailHiCustomerName',
  EMAIL_KIND_REGARDS = 'EmailKindRegards',
  EMAIL_YOUR_VOLVO_TEAM = 'EmailYourVolvoTeam',
  CBVCALL_US_ON_PHONE_NO = 'CBVCallUsOnPhoneNo',
  EMAIL_WERE_HERE_TO_HELP = 'EmailWereHereToHelp',
  THANK_YOU_BODY_TEXT = 'ThankYouBodyText',
  CB_THANK_YOU_BODY_TEXT = 'CB_ThankYouBodyText',
  KMU_THANK_YOU_BODY_TEXT = 'KMU_ThankYouBodyText',
  QR_THANK_YOU_BODY_TEXT = 'QR_ThankYouBodyText',
  ADDITIONAL_NOTES_FIELD = 'AdditionalNotesField',
  DEALER_PICKER_HEADING = 'DealerPickerHeading',
  OVERLAY_DIALOG_TITLE = 'OverlayDialogTitle',
  QR_OVERLAY_DIALOG_TITLE = 'QR_OverlayDialogTitle',
  KMU_OVERLAY_DIALOG_TITLE = 'KMU_OverlayDialogTitle',
}
