'use client';
import React, { useEffect } from 'react';
import { useDisclaimers } from './DisclaimerContext';

type DisclaimerRefProps = {
  disclaimer: {
    id: string;
    text: string;
    sources?: { text?: string | string[] };
    type: 'appendix' | 'inline';
    symbol?: string;
  };
};

/**
 * `DisclaimerRef` is a React component that renders a reference to a disclaimer.
 * It uses the `useDisclaimers` hook to add the disclaimer and retrieve its symbol based on its type.
 *
 * @param {DisclaimerRefProps} props - The props for the component.
 * @param {Disclaimer} props.disclaimer - The disclaimer object containing its id and type.
 *
 * @returns {JSX.Element} A JSX element representing a superscript reference to the disclaimer.
 *
 * @example
 * ```tsx
 * <DisclaimerRef disclaimer={{ id: '1', type: 'appendix', text: 'Disclaimer text' }} />
 * ```
 */
export const DisclaimerRef: React.FC<DisclaimerRefProps> = ({ disclaimer }) => {
  const { addDisclaimer, getDisclaimerSymbolByType, disclaimers } =
    useDisclaimers();

  useEffect(() => {
    addDisclaimer(disclaimer);
  }, [disclaimer, addDisclaimer]);

  // Find existing disclaimer in the list and get its symbol server-side
  const existingDisclaimer = disclaimers.find((d) => d.id === disclaimer.id);
  const symbolString = `${existingDisclaimer?.symbol || getDisclaimerSymbolByType(disclaimer.id, disclaimer.type)}`;

  return (
    <sup className="micro" id={`note-${disclaimer.id}`}>
      <a href={`#disclaimer-${disclaimer.id}`}>[{symbolString}]</a>
    </sup>
  );
};
