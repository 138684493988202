import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import React from 'react';

import GridList, { type GridListItemProps } from './components/GridList';
import styles from './finopt-comparison.module.css';
import type {
  FinanceOptionsComparisonServiceV2,
  GridProps,
} from './finopt-comparison.types';

const ServicesGrid = ({
  firstSalesModel,
  secondSalesModel,
  thirdSalesModel,
  services,
  electricLegend,
}: Omit<GridProps, 'options'> & {
  services: FinanceOptionsComparisonServiceV2[];
  electricLegend?: string;
}) => {
  const first: GridListItemProps[] = [];
  const second: GridListItemProps[] = [];
  const third: GridListItemProps[] = [];

  for (const service of services) {
    first.push({
      value: firstSalesModel in service ? service.displayName : '',
      iconName:
        firstSalesModel in service ? 'checkmark-16' : 'navigation-close-16',
      largeOnMobile: false,
      isElectricOnly: firstSalesModel
        ? !!service[firstSalesModel]?.isElectricOnly
        : false,
      column: 1,
    });
    second.push({
      value: secondSalesModel in service ? service.displayName : '',
      iconName:
        secondSalesModel in service ? 'checkmark-16' : 'navigation-close-16',
      largeOnMobile: false,
      isElectricOnly: secondSalesModel
        ? !!service[secondSalesModel]?.isElectricOnly
        : false,
      column: 2,
    });
    third.push({
      value:
        thirdSalesModel && thirdSalesModel in service
          ? service.displayName
          : '',
      iconName:
        thirdSalesModel && thirdSalesModel in service
          ? 'checkmark-16'
          : 'navigation-close-16',
      largeOnMobile: false,
      isElectricOnly: thirdSalesModel
        ? !!service[thirdSalesModel]?.isElectricOnly
        : false,
      column: 3,
    });
  }

  return (
    <>
      <div
        className={styles['grid-container']}
        data-testid="finance-options-comparison:included-services"
      >
        <GridList listItems={first} />
        <GridList listItems={second} />
        {thirdSalesModel && <GridList listItems={third} />}
      </div>
      {!!electricLegend && (
        <div
          data-testid="finance-options-comparison:included-services:electric-legend"
          className={cssJoin(
            'flex mt-16 mb-8 items-center',
            styles['electric-legend-container'],
          )}
        >
          <Icon icon="lightning-bolt" size={32} />
          <p className="font-medium">{electricLegend}</p>
        </div>
      )}
    </>
  );
};

export default ServicesGrid;
