import { useTracker } from '@volvo-cars/tracking';
import { useEffect, useRef, useState } from 'react';

const useTouchScrollEvent = (carouselRef: React.RefObject<HTMLElement>) => {
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);
  const tracker = useTracker();
  const directionRef = useRef<string | null>(null);

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      setTouchStartX(event.touches[0].clientX);
    };

    const handleTouchMove = (event: TouchEvent) => {
      setTouchEndX(event.touches[0].clientX);
    };

    const handleTouchEnd = () => {
      if (touchStartX === null || touchEndX === null) return;

      directionRef.current = touchEndX < touchStartX ? 'right' : 'left';

      tracker.interaction({
        eventAction: 'carousel|swipe',
        eventLabel: directionRef.current,
      });

      // Reset touch positions
      setTouchStartX(null);
      setTouchEndX(null);
    };

    const carouselElement = carouselRef.current;

    if (carouselElement) {
      carouselElement.addEventListener('touchstart', handleTouchStart);
      carouselElement.addEventListener('touchmove', handleTouchMove);
      carouselElement.addEventListener('touchend', handleTouchEnd);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('touchstart', handleTouchStart);
        carouselElement.removeEventListener('touchmove', handleTouchMove);
        carouselElement.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [carouselRef, touchStartX, touchEndX, tracker]);

  return {
    direction: directionRef.current,
  };
};

export default useTouchScrollEvent;
