import { HeadingAndText } from '@vcc-package/text';
import { Markdown_DEPRECATED_VCCUI } from '@vcc-package/text/DEPRECATED';
import Badge from '@vcc-www/car-badges/Badge';
import IconTextList from '@vcc-www/icon-text-list';
import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import styles from './ImageWithText.module.css';
import { MainContentParams, MainContentProps } from './ImageWithText.types';

const MainContent = ({
  titleAlignment = 'left',
  textAlignment = 'left',
  ctas,
  isBlue,
  hasQuotes = false,
  title,
  subtitle,
  text,
  isMarkdown,
  iconTexts,
  badgeLabel,
  readMoreTitle,
}: MainContentProps & MainContentParams & { ctas: React.ReactNode }) => (
  <div className="flex-col justify-center items-start">
    {!!title && (
      <span>
        {hasQuotes && (
          <span aria-hidden className={styles.quote}>
            {'\u201C'}
          </span>
        )}
        <h2
          className={`relative heading-2 w-full whitespace-pre-line ${
            titleAlignment === 'left'
              ? 'text-start'
              : titleAlignment === 'right'
                ? 'text-end lg:text-start'
                : 'text-center lg:text-start'
          }`}
          data-autoid="imageWithText:title"
        >
          {hasQuotes ? `“${title}”` : title}
        </h2>
      </span>
    )}
    {!!badgeLabel && (
      <div className="flex mt-16 lg:mt-24">
        <Badge label={badgeLabel} />
      </div>
    )}
    {!!subtitle && (
      <p
        className={cssMerge(
          `mt-16 lg:mt-24 font-medium ${hasQuotes ? 'text-secondary' : ''}`,
        )}
        data-autoid="imageWithText:subTitle"
      >
        {subtitle}
      </p>
    )}
    {!!text && (
      <>
        <div className="mt-16" />
        {readMoreTitle ? (
          <div
            style={{
              whiteSpace: 'pre-line',
              textAlign: textAlignment,
            }}
            className="flex-col max-w-full"
            data-autoid="imageWithText:description"
          >
            <HeadingAndText
              text={text}
              readMoreTitle={readMoreTitle}
              supportMarkdown={isMarkdown}
            />
          </div>
        ) : isMarkdown ? (
          <Markdown_DEPRECATED_VCCUI
            variant="columbus"
            extend={{
              color: isBlue ? 'color-primary' : 'color-secondary',
              maxWidth: '100%',
              whiteSpace: 'pre-line',
              textAlign: textAlignment,
              display: 'inherit',
            }}
            data-autoid="imageWithText:description"
            markdown={text}
          />
        ) : (
          <p
            className={`${isBlue ? 'text-primary' : 'text-secondary'} ${
              textAlignment === 'left'
                ? 'text-start'
                : textAlignment === 'right'
                  ? 'text-end lg:text-start'
                  : 'text-center lg:text-start'
            }`}
            data-autoid="imageWithText:description"
          >
            {text}
          </p>
        )}
      </>
    )}
    {iconTexts && !!iconTexts.length && (
      <>
        <div className="mt-32" />
        <IconTextList
          iconHeight={25}
          content={{ iconTexts }}
          orientation="horizontal"
          textVariant="kelly"
          hideIcons
        />
      </>
    )}
    {ctas}
  </div>
);

export default MainContent;
