import { applyJsxTokenTemplate } from '@vcc-www/utils/stringTemplate';
import React from 'react';
import { GetCarPriceElementProps } from '../car-price.types';
import { PriceFirstLine, PriceSecondLine } from '../car-price.view';

/** Replaces {tokens} in the label with JSX elements */
export const replaceTextWithJSX = ({
  label,
  hasMonthly,
  hideDiscountPricingStrikethroughPrice,
  monthlyPrice,
  monthlyWasPrice,
  hasPurchase,
  purchasePrice,
  purchaseWasPrice,
  showVATLabel,
  vatLabel,
}: GetCarPriceElementProps) => {
  if (!label) return [];

  return applyJsxTokenTemplate(label, {
    purchasePrice: () =>
      hasPurchase ? (
        <PriceFirstLine
          key="purchase-price"
          price={purchasePrice}
          wasPrice={
            !hideDiscountPricingStrikethroughPrice
              ? purchaseWasPrice
              : undefined
          }
        />
      ) : (
        ''
      ),
    monthlyPrice: () =>
      hasMonthly ? (
        <PriceFirstLine
          key="monthly-price"
          price={monthlyPrice}
          wasPrice={
            !hideDiscountPricingStrikethroughPrice ? monthlyWasPrice : undefined
          }
        />
      ) : (
        ''
      ),
    vatLabel: () =>
      showVATLabel ? <span key="vat-label">{vatLabel}</span> : '',
  })?.filter(Boolean);
};

/** Formats the text label with the correct JSX elements */
export const getCarPriceElement = (props: GetCarPriceElementProps) => {
  const {
    label,
    savingsLabel,
    monthlySavingAmount,
    purchaseSavingAmount,
    shouldShowSavingsElement,
  } = props;
  const labelLines = label?.split('\n') ?? [];
  const isMultiLineLabel = labelLines?.length > 1;

  const firstLine = replaceTextWithJSX({
    ...props,
    label: labelLines?.[0],
  }).filter(Boolean);
  const hasSecondLine = !!labelLines?.[1];
  const secondLine = isMultiLineLabel
    ? replaceTextWithJSX({ ...props, label: labelLines?.[1] })
    : [];
  const savingsAmount =
    props?.hasMonthly && monthlySavingAmount
      ? monthlySavingAmount
      : props?.hasPurchase && purchaseSavingAmount
        ? purchaseSavingAmount
        : '';
  const savingsElement =
    shouldShowSavingsElement && savingsAmount
      ? applyJsxTokenTemplate(savingsLabel ?? '', {
          amount: () => <span key="saving-amount">{savingsAmount}</span>,
        })
      : [];
  const shouldShowSecondLine = isMultiLineLabel || shouldShowSavingsElement;
  const hasCarPriceToShow =
    firstLine.length > 0 || savingsElement.length > 0 || secondLine.length > 0;

  return hasCarPriceToShow ? (
    <>
      {firstLine}
      {shouldShowSecondLine && (
        <PriceSecondLine key="second-line">
          {hasSecondLine ? secondLine : savingsElement}
        </PriceSecondLine>
      )}
    </>
  ) : null;
};
