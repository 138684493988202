import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import React from 'react';
import { ListItemLink } from './list-item.link.view';
import styles from './list-item.module.css';
import { ListItemCardViewProps } from './list-item.types';
import { getTitleColor, renderSecondaryInfoDefault } from './list-item.utils';

export const Card: React.FC<React.PropsWithChildren<ListItemCardViewProps>> = ({
  description,
  descriptionSources,
  descriptionVariant,
  interactive,
  isOpen,
  isPriceBreakDown,
  linkText,
  linkTextSources,
  linkTextClosed,
  linkTextClosedSources,
  linkTextOpened,
  linkTextOpenedSources,
  linkVariant,
  onHover,
  onPress,
  renderSecondaryInfo,
  secondaryInfo,
  secondaryInfoSources,
  testId,
  testIds,
  title,
  titleSources,
  thumbnail,
  verticalCenter,
}) => {
  return (
    <div
      className={cssJoin(
        'flex-row justify-start',
        !!verticalCenter && 'items-center',
      )}
    >
      {thumbnail && (
        <div
          className={cssJoin(
            'flex-col flex-shrink-0 items-center justify-center bg-secondary mr-16 rounded overflow-hidden',
            styles['list-item-image'],
          )}
        >
          {thumbnail}
        </div>
      )}
      <div className="flex-wrap flex-grow flex-shrink">
        <div className="flex-row justify-between items-start">
          <div
            className={cssJoin(
              'flex-col items-start',
              styles['list-item-secondary-info'],
            )}
          >
            {secondaryInfo
              ? renderSecondaryInfo
                ? renderSecondaryInfo(secondaryInfo, secondaryInfoSources)
                : renderSecondaryInfoDefault({
                    secondaryInfo,
                    secondaryInfoSources,
                    testId,
                  })
              : null}
            <div className="flex-row items-center">
              <p
                className={cssJoin(
                  'pr-4',
                  'font-medium',
                  getTitleColor({
                    interactive,
                    onPress,
                    onHover,
                    isPriceBreakDown,
                  }),
                  isPriceBreakDown ? 'font-20' : 'font-16',
                )}
                data-sources={titleSources}
                aria-label={`${typeof title === 'string' ? title : ''}`}
                data-testid={testIds?.title ?? `${testId}-title`}
              >
                {title}
              </p>
              {linkVariant === 'chevron-title' ? (
                <Icon
                  icon="chevron-forward"
                  size={12}
                  color={
                    onPress ? 'secondary' : onHover ? 'accent-blue' : 'primary'
                  }
                />
              ) : null}
            </div>
          </div>
          <ListItemLink
            isOpen={isOpen}
            isPriceBreakDown={isPriceBreakDown}
            interactive={interactive}
            onHover={onHover}
            onPress={onPress}
            linkText={linkText}
            linkTextSources={linkTextSources}
            linkTextClosed={linkTextClosed}
            linkTextClosedSources={linkTextClosedSources}
            linkTextOpened={linkTextOpened}
            linkTextOpenedSources={linkTextOpenedSources}
            linkVariant={linkVariant}
            testId={testId}
          />
        </div>
        {description && (
          <p
            className={cssJoin(
              'text-secondary',
              descriptionVariant || 'micro',
              descriptionVariant === 'font-16' ? 'pt-4' : 'pt-8',
              // TODO: Missing pt-8 as default padding for S breakpoints
            )}
            data-sources={descriptionSources}
            aria-label={`${
              typeof description === 'string'
                ? description
                : (description.props.ariaLabel ?? '')
            }`}
            data-testid={
              testIds?.titleDescription ?? `${testId}-title-description`
            }
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};
