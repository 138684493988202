'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { TextField } from '../../flexible-forms/components';

export const AddressTwoField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps) => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.ADDRESS_TWO,
  );

  return (
    <TextField
      hideRequired={hideRequired}
      key="address-two"
      name={FlexFormFieldType.ADDRESS_TWO}
      testid={FlexFormTestID.AddressTwo}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.ADDRESS_TWO_FIELD_LABEL)
      }
      messages={messages}
      control={control}
      {...validation.addresstwo}
    />
  );
};
