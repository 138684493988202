'use client';

import { cssJoin } from '@volvo-cars/css/utils';
import React, { useEffect, useState } from 'react';
import styles from './DialogNavigationPage.module.css';
import { useDialogNavigation } from './useDialogNavigation';

type AnimationState = 'not-started' | 'in-progress' | 'done';

/**
 * Represents a page component used in the dialog navigation.
 *
 * @component
 * @example
 * ```tsx
 * <DialogNavigationPage pathName="/home">
 *   <HomePage />
 * </DialogNavigationPage>
 * ```
 *
 * @param {React.PropsWithChildren<{ pathName: string; title: string: }>} props - The component props.
 * @param {string} props.pathName - The path name of the page.
 * @param {string} props.title - The title of the page.
 * @returns {JSX.Element} The rendered `DialogNavigationPage` component.
 */
export const DialogNavigationPage: React.FC<
  React.PropsWithChildren<{
    pathName: string;
    title?: string;
    persistScrollPosition?: boolean;
  }>
> = ({ pathName, title, persistScrollPosition, children }) => {
  const {
    stack,
    currentPage,
    previousPage,
    dirty,
    setTitle,
    scrollToPrevScrollPosition,
  } = useDialogNavigation();
  const prevIndex = stack.map((path) => path.path).indexOf(previousPage || '');
  const currentIndex = stack.map((path) => path.path).indexOf(currentPage);
  const animationDirection =
    prevIndex > -1 && prevIndex < currentIndex ? 'forward' : 'backward';
  const [animationState, setAnimationState] =
    useState<AnimationState>('not-started');

  const onAnimationStart = () => {
    if (animationState === 'in-progress') return;
    setAnimationState('in-progress');
    scrollToPrevScrollPosition(persistScrollPosition);
  };
  const onAnimationEnd = () => {
    if (animationState === 'not-started') return;
    setAnimationState('not-started');
  };

  useEffect(() => {
    // Set the title when this page is shown
    if (stack[currentIndex].path === pathName) {
      setTitle(title);
    }
  }, [currentIndex, pathName, setTitle, stack, title]);

  return (
    <div
      className={cssJoin(styles['page-wrapper'])}
      // Scrollable elements should be keyboard selectable: https://accessibilityinsights.io/info-examples/web/scrollable-region-focusable/
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div
        className={cssJoin(
          dirty && animationDirection === 'forward' && styles['slide-in'],
          dirty && animationDirection === 'backward' && styles['slide-out'],
          animationState === 'not-started' &&
            stack[currentIndex].path !== pathName &&
            styles['hidden-page'],
        )}
        onAnimationEnd={onAnimationEnd}
        onAnimationStart={onAnimationStart}
      >
        {children}
      </div>
    </div>
  );
};
