import { TranslationKey } from '../api';
import { useLeadsContext, useTranslate } from '../src/context';

export const useRequiredLabel = (
  label: string,
  required: boolean,
  hideRequired: boolean,
  hideOptionalLabel: boolean,
) => {
  const translate = useTranslate();
  const { features } = useLeadsContext();

  if (!features.requiredToOptional && !hideRequired) {
    return required ? `${label} *` : label;
  }

  const requiredToOptional = translate(TranslationKey.OPTIONAL);

  if (!required && hideOptionalLabel) {
    return label;
  }

  if (!required) {
    return `${label} (${requiredToOptional})`;
  }

  return label;
};
