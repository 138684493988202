import { DiscoveryCardStacked } from '@vcc-package/discovery';
import { Track } from '@volvo-cars/tracking';
import React from 'react';
import MerchandisingOfferCard from '../content-management/content-types/merchandisingOfferCard.props';

type OfferCardProps = {
  offerCard: MerchandisingOfferCard;
  index: number;
  navigate: (path: string) => void;
  setSelectedOfferIndex: (index: number) => void;
};

const OfferCard: React.FC<OfferCardProps> = ({
  offerCard,
  index,
  navigate,
  setSelectedOfferIndex,
}) => {
  const { cta, title, body, image, offerDetail = {}, campaigncode } = offerCard;

  const getEventLabel = () => {
    const action =
      Object.keys(offerDetail).length > 0
        ? 'offer-card-open-detail-overlay'
        : campaigncode
          ? 'offer-card-open-form'
          : 'offer-card';

    return `merchandising-initiative|${action}|${cta?.text}`;
  };

  const handleButtonClick = (path: string) => {
    setSelectedOfferIndex(index);
    navigate(path);
  };

  const renderButton = () => {
    const eventLabel = getEventLabel();
    let path: string | null = null;

    if (Object.keys(offerDetail).length > 0) {
      path = '/details';
    } else if (campaigncode) {
      path = '/form';
    }

    return (
      <Track eventLabel={eventLabel}>
        {path ? (
          <button
            type="button"
            className="button-text"
            onClick={() => handleButtonClick(path)}
          >
            {cta?.text}
          </button>
        ) : (
          <a className="button-text" href={cta?.href} target={cta?.target}>
            {cta?.text}
          </a>
        )}
      </Track>
    );
  };

  return (
    <DiscoveryCardStacked
      key={index}
      responsiveImages={{
        sm: {
          src: image.src,
          alt: image.alt,
        },
        smAspectRatio: '21:9',
      }}
    >
      <div className="stack-text">
        <h2>{title}</h2>
        <p className="text-secondary">{body}</p>
        {cta?.text && renderButton()}
      </div>
    </DiscoveryCardStacked>
  );
};

export default OfferCard;
