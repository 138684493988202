import { maxContentWidth12Columns } from '@vcc-www/constants/layout';
import React from 'react';
import { Block, ExtendCSS, ThemePicker, useTheme } from 'vcc-ui';
import getSpacing from './getSpacing';
import { IdParam, Params } from './params';

type Props = Params &
  IdParam & {
    extend?: ExtendCSS;
    children: React.ReactNode;
  };

/**
 * @deprecated - use SectionContained instead, this has issues with
 * style specificity and will be removed in a future version.
 */
const DEPRECATED_BROKEN_SectionContained = React.forwardRef<HTMLElement, Props>(
  (
    {
      topOuterSpacing = 'none',
      bottomOuterSpacing = 'none',
      topInnerSpacing = 'medium',
      bottomInnerSpacing = 'medium',
      background = 'white',
      marginsOnMobile = false,
      children,
      extend,
      ...props
    },
    ref,
  ) => {
    const { direction } = useTheme();
    return (
      <ThemePicker
        direction={direction}
        variant={['dark', 'black'].includes(background) ? 'dark' : 'light'}
      >
        <Block
          as="section"
          extend={[
            rootCSS({
              topOuterSpacing,
              bottomOuterSpacing,
              topInnerSpacing,
              bottomInnerSpacing,
              background,
              // I don't get a proper bool from the data, i get a string of "0 | 1 | true | false"
              // so to test that I do the following
              marginsOnMobile: /(1|true)/.test(marginsOnMobile + ''),
            }),
            extend,
          ]}
          {...props}
          ref={ref}
        >
          {children}
        </Block>
      </ThemePicker>
    );
  },
);

DEPRECATED_BROKEN_SectionContained.displayName =
  'DEPRECATED_BROKEN_SectionContained';

export default DEPRECATED_BROKEN_SectionContained;
const rootCSS =
  ({ background, marginsOnMobile, ...params }: Required<Params>): ExtendCSS =>
  ({ theme: { baselineGrid, color } }) => ({
    backgroundColor: {
      // We automatically switch to dark theme if background is `black` or `dark`
      white: color.background.primary,
      black: color.background.primary,
      light: color.background.secondary,
      dark: color.background.secondary,
      transparent: 'transparent',
    }[background],
    ...getSpacing({ baselineGrid, params }).untilM,

    maxWidth: maxContentWidth12Columns,
    width: `calc(100% - ${marginsOnMobile ? baselineGrid * 3 * 2 : 0}px)`,
    marginLeft: marginsOnMobile ? baselineGrid * 3 : 0,
    marginRight: marginsOnMobile ? baselineGrid * 3 : 0,

    fromM: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: `calc(100% - ${baselineGrid * 6}px)`,
      ...(background !== 'white' && {
        paddingLeft: `${baselineGrid * 3}px`,
        paddingRight: `${baselineGrid * 3}px`,
      }),
      fromM: getSpacing({ baselineGrid, params }).fromM,
    },
  });
