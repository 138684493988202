'use client';

// eslint-disable-next-line no-restricted-imports
import { useInView } from '@vcc-www/hooks';
import React, { useEffect, useState } from 'react';
import {
  EmbeddableFormsWrapper,
  EmbeddableFormsWrapperProps,
} from './EmbeddableFormsWrapper';

type EmbeddableFormsProps = Omit<
  EmbeddableFormsWrapperProps,
  'onExitIntentIntroductionComponent'
> & {
  lazyLoad?: boolean;
};

export const EmbeddableForms: React.FC<EmbeddableFormsProps> = ({
  lazyLoad = false,
  ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [contentInViewRef, contentInView] = useInView<HTMLDivElement>({
    triggerOnce: true,
    disabled: !lazyLoad,
    rootMargin: '2500px 0px',
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!contentInView) return <div ref={contentInViewRef} />;
  if (!mounted) return null;

  return <EmbeddableFormsWrapper {...props} />;
};
