'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { globalStyling } from '@vcc-package/leads-utils/constants';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { TitleFieldProps } from '@vcc-package/leads-utils/types';
import { ErrorMessage, Radio, RadioGroup } from '@volvo-cars/react-forms';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldLegend } from '../components/elements';

type ContactSlotsFieldProps = Omit<TitleFieldProps, 'customTranslation'>;

export const ContactSlotsSingleField = ({
  translate,
  options,
  hideRequired,
  validation,
}: ContactSlotsFieldProps) => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const header = translate(TranslationKey.CB_CONTACT_SLOTS_FIELD);
  const errorMessage = translate(TranslationKey.CB_SLOTS_REQUIRED_MESSAGE);
  const { ContactSlotsSingle } = options;
  const column1Indexes = Math.round(ContactSlotsSingle.length / 2);
  const required = !!validation.contactslots?.required;

  const translatedOptions = ContactSlotsSingle.map((option) => ({
    label: translate(option.label),
    value: option.value,
  }));

  return (
    <div className={`flex flex-col ${globalStyling.fieldGap}`}>
      <Controller
        control={control}
        defaultValue={[]}
        name={FlexFormFieldType.CONTACT_SLOTS}
        rules={{
          validate: (data: Array<string>) => {
            return data.length > 0
              ? true
              : messages?.required
                ? messages.required(errorMessage)
                : false;
          },
        }}
        render={({
          field: { onChange, onBlur, name, ref },
          fieldState: { error },
        }) => {
          return (
            <div>
              <RadioGroup
                name={name}
                legend={FieldLegend({
                  title: header,
                  required: !!required && !!hideRequired,
                })}
                defaultValue=""
                onChange={(e) => onChange([e.target.value])}
              >
                <div
                  className="grid-cols-2 gap-16"
                  style={{ gridAutoFlow: 'row dense' }}
                >
                  {translatedOptions.map(({ label = '', value }, i) => (
                    <Radio
                      onBlur={onBlur}
                      label={label as string}
                      value={value}
                      data-testid={`contact-slots-single-${i}`}
                      className="text-primary"
                      ref={ref}
                      key={i}
                      style={{
                        gridColumn: i < column1Indexes ? 1 : 2,
                      }}
                    />
                  ))}
                </div>
              </RadioGroup>
              {error?.message ? (
                <ErrorMessage
                  errorMessage={error?.message}
                  className="mt-8"
                  id={FlexFormFieldType.CONTACT_SLOTS + '-error'}
                />
              ) : null}
            </div>
          );
        }}
      />
    </div>
  );
};
