import { cssMerge } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import {
  type DEPRECATED_IconType,
  getIconPropsFromDeprecatedIconType,
} from '@volvo-cars/react-icons/compat';
import React, { HTMLAttributes } from 'react';

const Image = ({
  iconType,
  className,
  ...rest
}: {
  iconType: DEPRECATED_IconType;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      slot="media"
      className={cssMerge('bg-primary p-16 rounded-lg w-fit mb-24', className)}
      {...rest}
    >
      <Icon {...getIconPropsFromDeprecatedIconType(iconType)} />
    </div>
  );
};

export default Image;
