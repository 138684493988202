import type {
  MarketVehicleImage,
  MarketVehiclePresentation,
  Package,
} from '@vcc-package/leads-utils/api';
import { VehicleImageType } from '@vcc-package/leads-utils/api';

import { useLeadsContext } from '../src/context';

export const useAssetPath = () => {
  const {
    publicRuntimeConfig,
    navigation: { hostname },
  } = useLeadsContext();

  if (publicRuntimeConfig.useCors) {
    return `/`;
  }

  return (
    publicRuntimeConfig?.assetPath ?? `https://${hostname}/static/leads-forms`
  );
};

const useApiPath = () => {
  const {
    publicRuntimeConfig,
    navigation: { hostname },
  } = useLeadsContext();

  return `${
    publicRuntimeConfig?.apiHost ?? hostname ?? ''
  }/api/forms-backend/v1/vehicles/image`;
};

/**
 * @param images A record with MarketVehicleImage keyed by VehicleImageType
 * @param type The VehicleImageType that should be displayed
 * @returns source path to the model's ModelSide image
 */
export const useModelImageSourceByType = (
  images: MarketVehiclePresentation['images'],
  type: VehicleImageType,
): string => {
  const baseUrl = useApiPath();

  return `${baseUrl}?imageId=${images?.[type]?.id}`;
};

/**
 * @returns source path to the model's ThankYou image
 * If no model provided, returns default ThankYou image
 */
export const useModelThankYouImageSource = (
  purposeConfig?: Package,
  image?: MarketVehicleImage,
): string => {
  const baseUrl = useApiPath();
  const assetPath = useAssetPath();
  const {
    publicRuntimeConfig: { appEnv },
  } = useLeadsContext();

  let thankYouImgDefault = `${assetPath}/static/leads-forms/images/thank_you/thank_you.jpg`;

  if (appEnv === 'dev') {
    thankYouImgDefault = thankYouImgDefault.replace('/static/leads-forms', '');
  }

  const defaultImage = purposeConfig?.images?.find(
    (x) => x.imageType === VehicleImageType.THANK_YOU,
  );
  const id = image?.id ?? defaultImage?.id;
  return id ? `${baseUrl}?imageId=${id}` : thankYouImgDefault;
};
