'use client';
import { useEffect } from 'react';

import { getGtmFormTags, sendGALoadEvent } from '@vcc-package/leads-utils/util';
import { isStorybook } from '@vcc-www/constants';
import type { SalesChannel } from '@volvo-cars/tracking';
import { useTracker } from '@volvo-cars/tracking';

import { useLeadsContext } from '../context/leadsContext';
import { useLeadsTracking } from '../context/leadsTrackingContext';

export interface FormLoadEventTrackerProps {
  isB2b?: boolean;
  isVbs?: boolean;
  salesChannel?: SalesChannel;
}

/**
 * Component for sending form load tracking events to GTM
 */
export const FormLoadEventTracker = ({
  isB2b,
  isVbs,
  salesChannel,
}: FormLoadEventTrackerProps) => {
  const track = useTracker();
  const leadsTrackerHelper = useLeadsTracking();
  const { appId, siteSlug } = useLeadsContext();

  useEffect(() => {
    if (isStorybook) return;

    const { formId, formType } = getGtmFormTags({
      appId,
      isB2b,
      isVbs,
      marketId: siteSlug,
    });

    // GA3
    sendGALoadEvent(formId, formType, salesChannel);

    // GA4
    if (leadsTrackerHelper?.formLoad) {
      track.formLoad(leadsTrackerHelper.formLoad());
    }
    // eslint-disable-next-line
  }, []);

  return null;
};
