import { FlexFormFieldType } from '../../../api';
import type { LeadsContextProperties } from '../../../src/context';
import type { ABTest } from '../types';

export const addressAsPlainTextField: ABTest = async (
  context: LeadsContextProperties,
) => {
  if (!context.features.AddressAsPlainTextField || !context?.formConfig)
    return context;

  console.log('Running experiment: addressAsPlainTextField');

  const transformedMainRows = context.formConfig.main.rows.map((row) =>
    row.map((f) =>
      f === FlexFormFieldType.LEGAL_ADDRESS_ASYNC
        ? FlexFormFieldType.PLAIN_ADDRESS
        : f,
    ),
  );

  context.formConfig.main.rows = transformedMainRows;

  return context;
};
