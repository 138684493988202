import React from 'react';

import GridList, { type GridListItemProps } from './components/GridList';
import styles from './finopt-comparison.module.css';
import type { GridProps, MergedOption } from './finopt-comparison.types';
import { iconsMap } from './finopt-comparison.utils';

const HighlightsGrid = ({
  options,
  firstSalesModel,
  secondSalesModel,
  thirdSalesModel,
}: GridProps) => {
  const firstFinanceOption = options.find(
    ({ salesModel }) => salesModel === firstSalesModel,
  ) as MergedOption;
  const secondFinanceOption = options.find(
    ({ salesModel }) => salesModel === secondSalesModel,
  ) as MergedOption;
  const thirdFinanceOption = options.find(
    ({ salesModel }) => salesModel === thirdSalesModel,
  ) as MergedOption;

  const firstItemList: GridListItemProps[] = [
    {
      value: firstFinanceOption?.endOfContract,
      iconName: firstSalesModel && iconsMap[firstSalesModel].endOfContract,
      column: 1,
    },
    {
      value: firstFinanceOption?.contractTerm,
      iconName: firstSalesModel && iconsMap[firstSalesModel].contractTerm,
      column: 1,
    },
    {
      value: firstFinanceOption?.annualMileage,
      iconName: firstSalesModel && iconsMap[firstSalesModel].annualMileage,
      column: 1,
    },
  ];
  const secondItemList: GridListItemProps[] = [
    {
      value: secondFinanceOption?.endOfContract,
      iconName: secondSalesModel && iconsMap[secondSalesModel].endOfContract,
      column: 2,
    },
    {
      value: secondFinanceOption?.contractTerm,
      iconName: secondSalesModel && iconsMap[secondSalesModel].contractTerm,
      column: 2,
    },
    {
      value: secondFinanceOption?.annualMileage,
      iconName: secondSalesModel && iconsMap[secondSalesModel].annualMileage,
      column: 2,
    },
  ];
  const thirdItemList: GridListItemProps[] = [
    {
      value: thirdFinanceOption?.endOfContract,
      iconName: thirdSalesModel ? iconsMap[thirdSalesModel].endOfContract : '',
      column: 3,
    },
    {
      value: thirdFinanceOption?.contractTerm,
      iconName: thirdSalesModel ? iconsMap[thirdSalesModel].contractTerm : '',
      column: 3,
    },
    {
      value: thirdFinanceOption?.annualMileage,
      iconName: thirdSalesModel ? iconsMap[thirdSalesModel].annualMileage : '',
      column: 3,
    },
  ];

  return (
    <div
      className={styles['grid-container']}
      data-testid="finance-options-comparison:highlights"
    >
      <GridList listItems={firstItemList} />
      <GridList listItems={secondItemList} />
      {thirdFinanceOption && <GridList listItems={thirdItemList} />}
    </div>
  );
};

export default HighlightsGrid;
