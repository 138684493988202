'use client';
import { ResponsiveImage } from '@vcc-package/media';
import { Markdown } from '@vcc-package/text';
import { useBreakpoints } from '@vcc-www/hooks';
import { cssJoin } from '@volvo-cars/css/utils';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Spacing } from 'src/components/layout/Spacing';
import type cbvParallaxImageWithUSPsProps from 'src/content-management/editorial-components/ParallaxImageWithUSPs/ParallaxImageWithUSPs.props';
import styles from './styles.module.css';
import { Background, backgroundMap } from './utils';

export const ParallaxImageWithUSPs = ({
  content,
}: cbvParallaxImageWithUSPsProps) => {
  let { usps } = content;
  const {
    responsiveImages,
    background = Background.Warm,
    noPadding,
    disclaimer,
  } = content;
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { onlyM } = useBreakpoints();

  usps = usps.slice(0, 3);

  return (
    <Spacing>
      <ParallaxProvider>
        <div
          data-testid="parallax-image-with-usps"
          className={cssJoin(
            'mx-auto container',
            styles['parallax-container'],
            styles['horizontal-padding'],
            !noPadding && styles['vertical-padding'],
          )}
          style={{ background: backgroundMap[background] }}
        >
          <div
            className={cssJoin(
              'flex justify-between',
              styles['inner-container'],
            )}
          >
            <div
              className={cssJoin(
                styles['image-container'],
                styles['flex-shrink'],
              )}
            >
              {responsiveImages && (
                <Parallax speed={onlyM ? -10 : -5} scale={[1.2, 1.2]}>
                  <ResponsiveImage
                    data-testid="parallax-image-with-usps:image"
                    images={responsiveImages}
                    alt={responsiveImages.sm?.alt}
                    sizes={{ md: 1 / 2 }}
                  />
                </Parallax>
              )}
            </div>
            <div
              className={cssJoin(
                'py-32 px-24 md:p-48',
                styles['usps-container'],
                styles['flex-shrink'],
              )}
            >
              {usps.map((usp) => (
                <div
                  key={usp.title}
                  data-testid="parallax-image-with-usps:usp"
                  className={cssJoin('flex-col', styles['usps-item-container'])}
                >
                  <h2
                    data-testid="parallax-image-with-usps:usp:title"
                    className="heading-2 mb-16"
                  >
                    {usp.title}
                  </h2>
                  <p data-testid="parallax-image-with-usps:usp:body">
                    {usp.body}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {disclaimer && (
          <Markdown
            className="container micro text-secondary text-center mt-48"
            markdown={disclaimer}
          />
        )}
      </ParallaxProvider>
    </Spacing>
  );
};
