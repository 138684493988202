import { FlexFormFieldType } from '../../../api';
import type { LeadsContextProperties } from '../../../src/context';
import type { ABTest } from '../types';

export const phoneWithCountryCode: ABTest = async (
  context: LeadsContextProperties,
) => {
  if (context.features.phoneWithCountry && context.formConfig) {
    console.log('Running experiment: phoneWithCountryCode');

    const hasMobilePhone =
      context.formConfig?.main?.rows?.some(
        (row) =>
          row.includes(FlexFormFieldType.MOBILE_PHONE) ||
          row.includes(FlexFormFieldType.MOBILE_PHONE_ASYNC),
      ) ?? false;

    const hasPhoneWithCountryCode =
      context.formConfig?.main?.rows?.some((row) =>
        row.includes(FlexFormFieldType.PHONE_WITH_COUNTRY_CODE),
      ) ?? false;

    if (!hasPhoneWithCountryCode && hasMobilePhone) {
      context.formConfig.main.rows = context.formConfig?.main?.rows.map(
        (row) => {
          if (
            row.includes(FlexFormFieldType.MOBILE_PHONE) ||
            row.includes(FlexFormFieldType.MOBILE_PHONE_ASYNC)
          ) {
            const filteredRow = row.filter(
              (field) =>
                field !== FlexFormFieldType.MOBILE_PHONE &&
                field !== FlexFormFieldType.MOBILE_PHONE_ASYNC,
            );

            return [...filteredRow, FlexFormFieldType.PHONE_WITH_COUNTRY_CODE];
          }

          return row;
        },
      );
    }
  }

  return context;
};
