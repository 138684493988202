import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import styles from './ImageWithText.module.css';
import { ImageWithTextProps } from './ImageWithText.types';

export const MainContainer: React.FC<
  React.PropsWithChildren<
    Pick<ImageWithTextProps, 'alignImage' | 'negativeSideMargin'> &
      Required<Pick<ImageWithTextProps, 'reverseColumn'>>
  >
> = ({ alignImage, reverseColumn, children, negativeSideMargin }) => {
  return (
    <div
      className={cssMerge(
        'relative',
        alignImage === 'left' ? 'lg:flex-row' : 'lg:flex-row-reverse',
        reverseColumn ? 'flex-col-reverse' : 'flex-col',
        styles.main_container,
      )}
      style={{
        margin: '0' + (negativeSideMargin && ` ${-4 * negativeSideMargin}px`),
      }}
    >
      {children}
    </div>
  );
};

export const ContentContainer: React.FC<
  React.PropsWithChildren<
    Pick<ImageWithTextProps, 'mobileFullWidthText' | 'reverseColumn'>
  >
> = ({ mobileFullWidthText, reverseColumn, children }) => (
  <div
    className={cssMerge(
      'flex-row justify-center self-center w-full py-48 md:pb-0 lg:my-0 lg:mx-24 lg:p-0',
      mobileFullWidthText ? 'px-0' : 'px-24',
      reverseColumn ? 'pb-48 lg:pb-0' : '',
      styles.content_wrapper,
    )}
  >
    {children}
  </div>
);
