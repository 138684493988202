'use client';

import React from 'react';
import { DisclaimerObject, useDisclaimers } from './DisclaimerContext';
import { DisclaimerView } from './DisclaimerView';

export type DisclaimerProps = {
  text: DisclaimerObject['text'];
  sources?: DisclaimerObject['sources'];
  id?: DisclaimerObject['id'];
  type?: DisclaimerObject['type'];
  symbol?: DisclaimerObject['symbol'];
  includeSymbol?: boolean;
  className?: string;
};

/**
 * Disclaimer component that displays a disclaimer text with optional symbol.
 *
 * @param {DisclaimerProps} props - The props for the Disclaimer component.
 * @param {string} props.text - The disclaimer text to display.
 * @param {DisclaimerObject['link']} props.link - The disclaimer link to display.
 * @param {DisclaimerObject['sources']} props.sources - The sources for the disclaimer text and link, used for SourcesProvider in debug mode.
 * @param {string} props.id - The unique identifier for the disclaimer.
 * @param {boolean} [props.includeSymbol=false] - Whether to include a symbol before the disclaimer text.
 * @param {string} [props.type='appendix'] - The type of disclaimer symbol to include.
 * @param {string} [props.className] - Additional class names to apply to the disclaimer container.
 * @param {Object} [props.rest] - Additional props to spread onto the disclaimer container.
 *
 * @returns {JSX.Element} The rendered Disclaimer component.
 */
export const Disclaimer: React.FC<DisclaimerProps> = ({
  text,
  sources,
  id = '',
  includeSymbol = false,
  type = 'appendix',
  symbol,
  className,
  ...rest
}) => {
  const { getDisclaimerSymbolByType, disclaimers } = useDisclaimers();

  const existingDisclaimer = disclaimers.find((d) => d.id === id);
  const symbolString = `${symbol || existingDisclaimer?.symbol || getDisclaimerSymbolByType(id, type)}${type === 'appendix' ? '. ' : ' '}`;
  const extendedText = includeSymbol ? `${symbolString}${text}` : text;
  return (
    <DisclaimerView
      {...rest}
      markdown={extendedText}
      enableTracking
      data-sources={sources?.text}
      className={className}
      id={id ? `disclaimer-${id}` : undefined}
    />
  );
};
