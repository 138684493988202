import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

export function useRecaptchaAndDialogCompatibility({
  dialogOpen,
  setDialogOpen,
}: {
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const onDOMChange = useCallback(
    (mutationRecords: MutationRecord[]) => {
      mutationRecords.forEach((mutationRecord) => {
        const node = mutationRecord.addedNodes[0] as HTMLElement;
        const reCaptchaIframe = node?.querySelector?.(
          'iframe[title*="reCAPTCHA"]',
        );
        if (!reCaptchaIframe) return;

        const reCaptchaChallengeOverlayDiv = node.firstChild?.parentElement;
        if (!reCaptchaChallengeOverlayDiv) return;

        if (reCaptchaChallengeOverlayDiv?.style?.visibility === 'visible') {
          setDialogOpen(false);
        }

        const reCaptchaChallengeClosureObserver = new MutationObserver(() => {
          setDialogOpen(
            reCaptchaChallengeOverlayDiv?.style?.visibility !== 'visible',
          );
        });

        reCaptchaChallengeClosureObserver.observe(
          reCaptchaChallengeOverlayDiv,
          {
            attributes: true,
            attributeFilter: ['style'],
          },
        );
      });
    },
    [setDialogOpen],
  );

  useEffect(() => {
    if (!dialogOpen) return;

    const observer = new MutationObserver(onDOMChange);
    observer.observe(document.body, {
      childList: true,
      attributes: false,
      attributeOldValue: false,
      characterData: false,
      characterDataOldValue: false,
      subtree: false,
    });

    return () => observer.disconnect();
  }, [onDOMChange, dialogOpen]);
}
