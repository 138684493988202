import React from 'react';

type TitleSources = {
  title?: string;
  subtitle?: string;
};

type TitleProps = {
  title?: string;
  subtitle?: string;
  className?: string;
  TitleLevel?: 'h1' | 'h2' | 'p';
  sources?: TitleSources;
};

const Title: React.FC<TitleProps> = ({
  title,
  subtitle,
  className,
  TitleLevel = 'p',
  sources,
}) => {
  if (!title && !subtitle) return null;

  return (
    <div
      className={className}
      data-autoid="sub-navigation:title"
      data-testid="sub-navigation:title"
      slot="front"
    >
      {title && (
        <TitleLevel
          className="font-medium whitespace-nowrap pr-16"
          data-sources={[sources?.title, sources?.subtitle].filter(Boolean)}
        >
          {title}
          {subtitle && (
            <>
              {' '}
              <span className="font-light inline flow-root">{subtitle}</span>
            </>
          )}
        </TitleLevel>
      )}
    </div>
  );
};

export default Title;
