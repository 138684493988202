'use client';
import React from 'react';

import {
  FlexFormLegalFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import type {
  CustomTranslations,
  Translator,
} from '@vcc-package/leads-utils/types';
import { FlexFormLegalTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { useLeadsContext } from '../../../context/leadsContext';
import { CheckboxField } from '../../components';

export type OneMarketingConsentProps = {
  translate: Translator;
  defaultValue: boolean;
  disabled: boolean;
  customTranslation: CustomTranslations;
  required?: boolean;
};

const OneMarketingConsent = ({
  translate,
  defaultValue,
  disabled,
  customTranslation,
  required,
}: OneMarketingConsentProps) => {
  const { features } = useLeadsContext();
  const { control } = useFormContext();
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN,
  );
  const label = customTranslationKeys
    ? translate(customTranslationKeys.label)
    : translate(TranslationKey.ONE_MARKETING_CONSENT_FIELD_LABEL);

  const errorMessage = required
    ? translate(TranslationKey.ACCEPT_POLICIES)
    : undefined;

  return (
    <CheckboxField
      name={FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN}
      testid={FlexFormLegalTestID.OneMarketingConsent}
      control={control}
      label={label}
      key={FlexFormLegalFieldType.ONE_MARKETING_CONSENT_OPTIN}
      defaultValue={
        features.oneMarketingConsentPreChecked ?? defaultValue ?? false
      }
      disabled={disabled}
      required={required}
      className="micro"
      errorMessage={errorMessage}
    />
  );
};

export default OneMarketingConsent;
