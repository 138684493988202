import areaCodes from './areaCodes';
import { AreaCodeData, CountriesWithAreaCodes } from './types';

/**
 * getPhoneAreaCodeByCountry
 * @param {string} [country] - Format: 2 letter ISO code, see available countries in the types file
 * @returns {string[] | undefined} - Returns the area codes for specified country
 */

const getPhoneAreaCodeByCountry = (
  country: CountriesWithAreaCodes,
): string[] => {
  return areaCodes[country]?.areaCodes || undefined;
};

/**
 * getAllPhoneAreaCodeData
 * @returns {AreaCodeData} - Returns all stored area code data
 */

const getAllPhoneAreaCodeData = (): AreaCodeData => {
  return areaCodes;
};

export { getPhoneAreaCodeByCountry, getAllPhoneAreaCodeData };
