'use client';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

export type DisclaimerObject = {
  id: string;
  text: string;
  sources?: {
    text?: string | string[];
  };
  type: 'appendix' | 'inline';
  symbol?: string;
};

export type DisclaimerContextType = {
  disclaimers: DisclaimerObject[];
  addDisclaimer: (disclaimer: DisclaimerObject) => void;
  getDisclaimerSymbolByType: (
    id: DisclaimerObject['id'],
    type: DisclaimerObject['type'],
  ) => string;
};

const DisclaimerContext = createContext<DisclaimerContextType | undefined>(
  undefined,
);

/**
 * DisclaimerProvider component that provides a context for managing disclaimers.
 *
 * @param {Object} props - The props object.
 * @param {DisclaimerObject[]} props.defaultData - The default list of disclaimers.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the provider.
 *
 * @returns {JSX.Element} The DisclaimerContext.Provider component with the provided value.
 *
 * @example
 * ```tsx
 * const defaultDisclaimers = [{ id: 1, text: 'Sample disclaimer' }];
 *
 * <DisclaimerProvider defaultData={defaultDisclaimers}>
 *   <YourComponent />
 * </DisclaimerProvider>
 * ```
 */
export const DisclaimerProvider: React.FC<
  React.PropsWithChildren<{
    defaultData: DisclaimerObject[];
  }>
> = ({ defaultData = [], children }) => {
  // Generate a symbol for each disclaimer based on its type and position in the list.
  const createSymbolForDisclaimer = (
    disclaimer: Partial<DisclaimerObject>,
    allDisclaimers: DisclaimerObject[],
  ) => {
    const count =
      allDisclaimers
        .filter((d) => d.type === disclaimer.type)
        .findIndex((d) => d.id === disclaimer.id) + 1;

    return disclaimer.type === 'inline' ? '*'.repeat(count) : `${count}`;
  };

  // Identify the position of each disclaimer in the list and assign a symbol based on its type.
  const disclaimersWithSymbols: DisclaimerObject[] = defaultData.map(
    (disclaimer) => {
      return {
        ...disclaimer,
        symbol:
          disclaimer.symbol ||
          createSymbolForDisclaimer(disclaimer, defaultData),
      };
    },
  );

  const [disclaimers, setDisclaimers] = useState<DisclaimerObject[]>(
    disclaimersWithSymbols,
  );

  const getDisclaimerSymbolByType = useCallback(
    (id: DisclaimerObject['id'], type: DisclaimerObject['type']) => {
      return createSymbolForDisclaimer({ id, type }, disclaimers);
    },
    [disclaimers],
  );

  const addDisclaimer = useCallback(
    (disclaimer: DisclaimerObject) => {
      setDisclaimers((prev) => {
        if (!prev.find((d) => d.id === disclaimer.id)) {
          return [
            ...prev,
            {
              ...disclaimer,
              symbol:
                disclaimer.symbol ||
                createSymbolForDisclaimer(
                  { id: disclaimer.id, type: disclaimer.type },
                  disclaimers,
                ),
            },
          ];
        }
        return prev;
      });
    },
    [disclaimers],
  );

  const value = useMemo(
    () => ({ disclaimers, addDisclaimer, getDisclaimerSymbolByType }),
    [disclaimers, addDisclaimer, getDisclaimerSymbolByType],
  );

  return (
    <DisclaimerContext.Provider value={value}>
      {children}
    </DisclaimerContext.Provider>
  );
};

/**
 * Custom hook to interact with the DisclaimerContext.
 * Provides methods to add disclaimers and generate disclaimer symbols based on their type.
 *
 * @throws {Error} If the hook is used outside of a DisclaimerProvider.
 *
 * @returns {Object} An object containing the context values and the following methods:
 * - addDisclaimer: Adds a disclaimer to the context if it does not already exist.
 * - getDisclaimerSymbolByType: Generates a disclaimer symbol based on the disclaimer type and its position in the list.
 */
export const useDisclaimers = () => {
  const context = useContext(DisclaimerContext);
  if (!context) {
    throw new Error('useDisclaimers must be used within a DisclaimerProvider');
  }

  /**
   * Adds a disclaimer to the context if it does not already exist.
   *
   * @param {DisclaimerObject} disclaimer - The disclaimer to be added.
   * @returns {void}
   */
  const addDisclaimer = (disclaimer: DisclaimerObject) => {
    if (
      disclaimer &&
      !context.disclaimers.find((d) => d.id === disclaimer.id)
    ) {
      context.addDisclaimer(disclaimer);
    }
  };

  /**
   * Generates a disclaimer symbol based on the disclaimer type and its position in the list.
   *
   * @param id - The unique identifier of the disclaimer.
   * @param type - The type of the disclaimer, which determines the symbol format.
   * @returns A string representing the disclaimer symbol. If the type is 'inline', it returns a string of asterisks
   *          corresponding to the disclaimer's position. Otherwise, it returns the position followed by a period.
   */
  const getDisclaimerSymbolByType = context.getDisclaimerSymbolByType;

  return { ...context, addDisclaimer, getDisclaimerSymbolByType };
};
