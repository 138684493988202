import { ResponsiveImage } from '@vcc-package/media';
import { VideoProps } from '@vcc-www/content-types/videoFieldType';
import ResponsiveGridHelper from '@vcc-www/responsive-grid-helper';
import SharedVideo, { hasVideoContent, VideoContentType } from '@vcc-www/video';
import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import styles from './ImageWithText.module.css';
import { MediaParams, MediaProps } from './ImageWithText.types';

const aspectVideoMap = {
  auto: null,
  '1:1': [1, 1],
  '3:2': [3, 2],
  '4:3': [4, 3],
};

const aspectImageMap = {
  auto: null,
  '1:1': 'aspect-1/1',
  '3:2': 'aspect-2/3',
  '4:3': 'aspect-4/3',
};

const Media: React.FC<
  React.PropsWithChildren<MediaProps & MediaParams & VideoProps>
> = ({
  loopVideo,
  videos,
  loop,
  videoMp4,
  videoWebM,
  image,
  centeredPlayIcon,
  lazyLoadPoster,
  largePlayIcon,
  autoplay,
  imageAspectRatio = '4:3',
  alignImage,
  videoLoading,
}) => {
  const aspectVideoRatio = {
    // Sometimes would get weird numbers from sitecore routes. Defaulting to 4:3
    default: aspectVideoMap[imageAspectRatio] ?? [4, 3],
  };
  const aspectImageRatio = aspectImageMap[imageAspectRatio] ?? 'aspect-4/3';
  return (
    <div
      className={cssMerge(
        'flex-row self-center m-0',
        alignImage === 'left' ? 'items-end' : 'items-start',
        styles.media,
      )}
    >
      <ResponsiveGridHelper
        size={{ default: 5, onlyS: 12 }}
        extend={{ margin: 0 }}
        contentJustify={alignImage}
      >
        {/** Use SharedVideo if deprecated fields are used */}
        {videoMp4?.src || videoWebM?.src ? (
          <SharedVideo
            srcMp4={videoMp4?.src}
            srcWebM={videoWebM?.src}
            poster={image?.src}
            loop={loopVideo}
            shouldAutoplay={loopVideo}
            lazyLoadPoster
            aspectRatio={aspectVideoRatio}
            controls={loopVideo ? 'disabled' : 'enabled'}
            data-autoid="imageWithText:video"
            data-testid="imageWithText:video"
            data-align-video={alignImage || 'right'}
            loading={videoLoading}
          />
        ) : hasVideoContent({ videos }) ? (
          <SharedVideo
            content={{ videos } as VideoContentType}
            loop={loop}
            autoplay={autoplay}
            lazyLoadPoster={lazyLoadPoster}
            largePlayIcon={largePlayIcon}
            centeredPlayIcon={centeredPlayIcon}
            data-autoid="imageWithText:video"
            data-testid="imageWithText:video"
            data-align-video={alignImage || 'right'}
            loading={videoLoading}
          />
        ) : image?.src ? (
          <ResponsiveImage
            className={
              imageAspectRatio !== 'auto'
                ? `${aspectImageRatio} object-cover`
                : ''
            }
            images={{
              sm: { src: image.src, alt: image.alt },
            }}
            alt={image.alt}
            sizes={{
              lg: '50vw',
            }}
            loading="lazy"
            data-autoid="imageWithText:image"
            data-align-image={alignImage || 'right'}
          />
        ) : null}
      </ResponsiveGridHelper>
    </div>
  );
};

export default Media;
