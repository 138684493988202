'use client';

import * as React from 'react';
import { useCallback, useRef, useState } from 'react';

import { TranslationKey } from '@vcc-package/leads-utils/api';
import { getMarketSite } from '@volvo-cars/market-sites';
import { TextInput } from '@volvo-cars/react-forms';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { useLeadsContext } from '../../context/leadsContext';
import { IconMagnifyingGlass } from '../../icons';
import UseMyLocationButton from '../UseMyLocationButton';

import { useTranslate } from '../../context';
import { getPredictionDescriptionAsHTML } from './getPredictionDescriptionAsHTML';
import useAddressAutocompleteInputKeyEventHandler from './useAddressAutocompleteInputKeyEventHandler';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

type AddressAutocompleteInputsProps = {
  onSelect: (address: string) => void;
  placeTypes?: AutocompletePrediction['types'];
  onGeolocationRetrievalSuccess: (coords: GeolocationCoordinates) => void;
  showUseMyLocationButton?: boolean;
  testId?: string;
  textFieldRef?: any;
  invalid?: boolean;
};

const AddressAutocompleteInput = ({
  onSelect,
  placeTypes = ['geocode'],
  onGeolocationRetrievalSuccess,
  showUseMyLocationButton = true,
  testId = 'address-autocomplete',
  textFieldRef,
  invalid = false,
}: AddressAutocompleteInputsProps) => {
  const name = 'address-autocomplete';

  const { siteSlug, marketConfig, features } = useLeadsContext();
  const translate = useTranslate();
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const suggestionsWrapper = useRef<HTMLDivElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectingAddress, setSelectingAddress] = useState(false);
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: marketConfig.googleKey ?? undefined,
    debounce: 300,
    libraries: ['places'],
    options: {
      types: placeTypes,
      input: value,
      componentRestrictions: {
        country: getMarketSite(siteSlug).regionCode ?? siteSlug,
      },
    },
  });

  const selectAddress = useCallback(
    (address: string) => {
      onSelect(address);
      setValue(address);
      setShowSuggestions(false);
    },
    [onSelect],
  );

  useAddressAutocompleteInputKeyEventHandler({
    inputRef,
    suggestionsWrapper,
    setShowSuggestions,
    selectAddress,
  });

  const Suggestion = (predictionItem: AutocompletePrediction) => {
    const descriptionAsHTML = getPredictionDescriptionAsHTML(predictionItem);

    return (
      <button
        role="option"
        aria-selected="false"
        className="tap-area text-start p-8 block w-full overflow-hidden border-2 border-transparent selected:border-primary"
        style={{ textOverflow: 'ellipsis', outlineOffset: '-2px' }}
        key={predictionItem.description}
        type="button"
        dangerouslySetInnerHTML={{ __html: descriptionAsHTML }}
        value={predictionItem.description}
        onMouseDown={() => setSelectingAddress(true)}
        onClick={() => selectAddress(predictionItem.description)}
      />
    );
  };

  const label = features.useNewCopyAndStyling
    ? translate(TranslationKey.SEARCH_ADDRESS_CITY_OR_POSTCODE_FIELD_LABEL)
    : translate(TranslationKey.ENTER_ADDRESS_POSTCODE_OR_CITY);

  return (
    <div
      role="combobox"
      aria-expanded={showSuggestions}
      aria-haspopup="listbox"
      aria-controls={`${name}_suggestions`}
      aria-owns={`${name}_suggestions`}
    >
      <TextInput
        id={`${name}_input`}
        label={label}
        placeholder={translate(TranslationKey.ADDRESS_EG)}
        autoComplete="off"
        value={value}
        name={name}
        ref={(e) => {
          inputRef.current = e as HTMLInputElement;
          if (typeof textFieldRef === 'function') textFieldRef(e);
          else if (textFieldRef) textFieldRef.current = e;
        }}
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value });
          setValue(evt.target.value);
          setShowSuggestions(true);
        }}
        contentAfter={<IconMagnifyingGlass />}
        data-testid={testId}
        onBlur={() => {
          if (!selectingAddress) {
            setShowSuggestions(false);
          }
        }}
        aria-invalid={invalid}
        aria-autocomplete="list"
        aria-controls={`${name}_suggestions`}
        aria-owns={`${name}_suggestions`}
      />
      {showSuggestions && placePredictions?.length > 0 ? (
        <div
          id={`${name}_suggestions`}
          title="Suggestions"
          role="listbox"
          tabIndex={-1}
          onMouseLeave={() => setSelectingAddress(false)}
          data-testid="suggestions"
          className="border overflow-y-auto text-start rounded flex flex-col relative"
          style={{ maxHeight: '300px' }}
          ref={suggestionsWrapper}
        >
          {placePredictions.map(Suggestion)}
        </div>
      ) : (
        showUseMyLocationButton && (
          <UseMyLocationButton
            onGeolocationRetrievalSuccess={(
              position: GeolocationCoordinates,
            ) => {
              setValue('');
              onGeolocationRetrievalSuccess(position);
            }}
          />
        )
      )}
    </div>
  );
};

export default AddressAutocompleteInput;
