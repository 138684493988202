import React from 'react';
import { TCTAButton } from '../types';
import { StepsProps } from './Steps';
import { StepsCTAButton } from './StepsCTAButton';

export const StepsActions = ({
  children,
  className,
  'data-testid': testID,
  href,
  onClick: handleClick,
  text,
}: {
  children?: TCTAButton[] | TCTAButton;
  className?: string;
  'data-testid'?: string;
  href?: string;
  onClick?: StepsProps['onClickCta'];
  text?: string;
}) => {
  if (React.Children.count(children))
    return (
      <footer className={className} data-testid={testID}>
        {children}
      </footer>
    );

  if (!text && !href) return null;

  return (
    <footer className={className} data-testid={testID}>
      <StepsCTAButton href={href} onClick={handleClick} text={text} />
    </footer>
  );
};
