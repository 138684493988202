'use client';

import { useEffect, useMemo } from 'react';

import {
  type OptionalPublicRuntimeConfig,
  type PublicRuntimeConfig,
  getLocationBasedEnvProps,
  ssrPublicConfig,
} from './config';

export type usePublicConfigProps =
  | {
      isEmbeddableForms: boolean;
    }
  | undefined;

export type usePublicConfigReturnType<T> = T extends usePublicConfigProps & {
  isEmbeddableForms: true;
}
  ? OptionalPublicRuntimeConfig
  : PublicRuntimeConfig;

export const usePublicConfig = <T extends usePublicConfigProps>(
  props?: T,
): usePublicConfigReturnType<T> => {
  const { isEmbeddableForms = false } = props ?? {};

  const publicRuntimeConfig = useMemo(() => {
    if (isEmbeddableForms) {
      if (typeof window === 'undefined') {
        return null as usePublicConfigReturnType<T>;
      }
      return {
        ...getLocationBasedEnvProps(),
        rootPath: '',
        commitId: '',
        port: '8080',
        version: '1.0.0', //FIXME: We need an solution to set this from the package build process
      } as usePublicConfigReturnType<T>;
    }

    return ssrPublicConfig();
  }, [isEmbeddableForms]);

  useEffect(() => {
    if (
      publicRuntimeConfig &&
      (publicRuntimeConfig.appEnv === 'dev' || isEmbeddableForms)
    ) {
      publicRuntimeConfig.useCors = true;
    }
    if (typeof window === 'undefined' || !isEmbeddableForms) {
      return;
    }
    const props = getLocationBasedEnvProps();
    const shouldUpdate =
      !publicRuntimeConfig ||
      props.apiHost !== publicRuntimeConfig.apiHost ||
      props.useCors !== publicRuntimeConfig.useCors;
    if (shouldUpdate) {
      for (const [key, value] of Object.entries(props)) {
        if (
          publicRuntimeConfig &&
          Object.hasOwn(publicRuntimeConfig as object, key)
        ) {
          (publicRuntimeConfig as Record<string, unknown>)[key] = value;
        }
      }
    }
  }, [publicRuntimeConfig, isEmbeddableForms]);

  return publicRuntimeConfig;
};
