import { cssJoin } from '@volvo-cars/css/utils';
import React from 'react';
import {
  MONTHLY_SKELETON_VARIANTS,
  PURCHASE_SKELETON_VARIANTS,
} from './car-price.constants';
import { CarPriceProps } from './car-price.types';
import styles from './car-price.view.skeleton.module.css';

export const CarPriceViewSkeleton: React.FC<
  Pick<CarPriceProps, 'variant' | 'layout'>
> = ({ variant, layout }) => {
  const hasMonthlyPrice =
    variant && MONTHLY_SKELETON_VARIANTS.includes(variant);
  const hasPurchasePrice =
    variant && PURCHASE_SKELETON_VARIANTS.includes(variant);

  return (
    <div className="gap-4">
      {hasMonthlyPrice && (
        <div
          className={cssJoin(
            'skeleton bg-secondary',
            !layout && 'h-16',
            hasPurchasePrice && 'mb-4',
            styles['monthly-price'],
          )}
        >
          &nbsp;
        </div>
      )}
      {hasPurchasePrice && (
        <div
          className={cssJoin(
            'skeleton bg-secondary',
            hasMonthlyPrice && hasPurchasePrice && 'micro',
            styles['purchase-price'],
          )}
        >
          &nbsp;
        </div>
      )}
    </div>
  );
};
