import type { MarketConfiguration } from '@vcc-package/leads-utils/api';

type FeatureSessionData = Record<string, boolean>;

export const removeNulls = (
  obj: Record<string, boolean | null>,
): Record<string, boolean> => {
  const newObj: Record<string, boolean> = {};
  for (const key in obj) {
    newObj[key] = obj[key] ?? false;
  }
  return newObj;
};

export const sortDictionary = (
  obj: Record<string, boolean | null>,
): Record<string, boolean> => {
  return Object.keys(obj)
    .sort()
    .reduce(
      (newObj, key) => {
        newObj[key] = obj[key] ?? false;
        return newObj;
      },
      {} as Record<string, boolean>,
    );
};

export const getFeatureFlags = (
  marketConfig?: MarketConfiguration | null,
  dynamicFeatures?: Record<string, boolean | null>,
): FeatureSessionData => {
  return marketConfig
    ? sortDictionary({
        ...getDynamicFeatureFlags(marketConfig),
        ...removeNulls(dynamicFeatures ?? {}),
        ...removeNulls(marketConfig.features),
      })
    : {};
};

export const getDynamicFeatureFlags = (
  marketConfig: MarketConfiguration,
): FeatureSessionData => {
  try {
    return sortDictionary({
      ...removeNulls(marketConfig.dynamicFeatures),
    });
  } catch (e: any) {
    console.error(e);
    return {};
  }
};

export function generateClientId(gaVersion: string = '1.1') {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const randomNum = Math.floor(Math.random() * 1000000000);
  return `GA${gaVersion}.${randomNum}.${timestamp}`;
}
