import React from 'react';
import type { IStep } from '../../types';
import type { StepsProps } from '../Steps';
import { TickIcon } from './TickIcon';

type Props = {
  stepStyle: StepsProps['stepStyle'];
  ticked: IStep['ticked'];
  orderNumber?: string | number;
  setTestId?: (id: string) => string | undefined;
};

export const StepIndicator = ({
  ticked,
  orderNumber,
  setTestId,
  stepStyle,
}: Props) => {
  if (stepStyle === 'numbers') {
    return (
      <div
        className="flex flex-shrink justify-center items-center w-24 h-24 border border-ornament rounded-full"
        data-testid={setTestId?.('number')}
      >
        <p className="text-primary">{orderNumber}</p>
      </div>
    );
  }

  if (ticked) {
    return <TickIcon data-testid={setTestId?.('tick')}>{orderNumber}</TickIcon>;
  }

  return (
    <div className="block justify-center items-center w-24 h-24 min-h-24 text-primary border border-accent-blue rounded-full" />
  );
};
