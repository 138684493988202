'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { TextField } from '../../flexible-forms/components';

export const SurnameField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps) => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.SURNAME,
  );

  return (
    <TextField
      hideRequired={hideRequired}
      key="surname"
      name={FlexFormFieldType.SURNAME}
      testid={FlexFormTestID.Surname}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.SURNAME_FIELD_LABEL)
      }
      autoComplete="family-name"
      messages={messages}
      control={control}
      {...validation.surname}
    />
  );
};
